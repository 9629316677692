import { useMutation, useQuery } from "@apollo/client"
import {
  DeprecatedButton,
  DeprecatedField,
  DeprecatedFieldSelect,
  DeprecatedSpinner,
  DeprecatedTooltip,
  InfoIcon,
  Typography,
  TypographyVariant,
  deprecatedToaster,
} from "@hero/krypton"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { HeroErrorDisplayer } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { GqlHeroError } from "../../../../01_technical/requesting/request-error.type"
import { isGqlHeroError, isValidationError } from "../../../../01_technical/requesting/request-error.utils"
import { CenteringContainer } from "../component/CenteringContainer"
import { Footer } from "../component/Footer"
import { Panel } from "../component/Pannel"
import { Slot } from "../component/Slot"
import {
  GET_MERCHANT,
  GetMerchantType,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_RESPONSE,
  UpdateMerchantVariablesType,
} from "./updateMerchant.requests"

const DeprecatedHeader = styled.div``

const Caption = styled(Typography)<{ $variant?: TypographyVariant }>`
  font-size: 0.875rem;
`

const CaptionWithTooltip = styled.div`
  display: flex;
  justify-content: space-between;
`

type UpdateMerchantFormType = {
  siret: string | null
  VATNumber: string | null
  publicEmail: string
  tradingName: string
  juridicalName: string | null
  websiteUrl: string | null
  paymentNotificationEmail: string | null
  manualReviewNotificationEmail: string | null
  address: {
    line1: string
    line2: string
    city: string
    zipCode: string
    region: string
    countryCode: string
  }
}

type FieldProps<T> =
  | {
      disabled: boolean
      field: keyof T
      functional: string
      type: "text" | "email"
      tooltip?: string
      required: boolean
    }
  | {
      disabled: boolean
      field: keyof T
      functional: string
      type: "select"
      options: { label: string; value: string }[]
      tooltip?: string
      required: boolean
    }

export const UpdateMerchantForm = () => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<GetMerchantType>(GET_MERCHANT)

  const [updateMerchant, { loading: updateMerchantLoading, error: updateMerchantApolloError }] = useMutation<
    UPDATE_MERCHANT_RESPONSE,
    UpdateMerchantVariablesType
  >(UPDATE_MERCHANT)

  const { register, setError, handleSubmit, formState, reset, getValues } = useForm<UpdateMerchantFormType>()

  const onSubmit = handleSubmit(async (formData) => {
    updateMerchant({
      variables: {
        siret: formData.siret ? formData.siret : null,
        VATNumber: formData.VATNumber ? formData.VATNumber : null,
        juridicalName: formData.juridicalName ? formData.juridicalName : null,
        websiteUrl: formData.websiteUrl ? formData.websiteUrl : null,
        tradingName: formData.tradingName ?? "",
        paymentNotificationEmail: formData.paymentNotificationEmail ? formData.paymentNotificationEmail.trim() : null,
        manualReviewNotificationEmail: formData.manualReviewNotificationEmail
          ? formData.manualReviewNotificationEmail.trim()
          : null,
        publicEmail: (formData.publicEmail ?? "").trim(),
        line1: (formData.address.line1 ?? "").trim(),
        line2: (formData.address.line2 ?? "").trim(),
        zipCode: (formData.address.zipCode ?? "").trim(),
        city: (formData.address.city ?? "").trim(),
        region: (formData.address.region ?? "").trim(),
        countryCode:
          formData.address.countryCode && formData.address.countryCode.length === 2
            ? formData.address.countryCode
            : undefined,
      },
      refetchQueries: [{ query: GET_MERCHANT }],
    }).then((result) => {
      onGraphQLResponse(result.data?.merchantUpdateSelf, {
        success: () => {
          deprecatedToaster.success(t("merchant.tools.setting.updateMerchant.updateSuccess"))
        },
        error: () => {
          deprecatedToaster.error(t("merchant.tools.setting.updateMerchant.errorUnspecified"))
        },
        validationErrors: {
          siret: {
            MISSING_ATTRIBUTE_SIRET: () => {
              setError("siret", { message: t("merchant.tools.setting.updateMerchant.siret.error.invalid") })
            },
          },
          VATnumber: {
            MISSING_ATTRIBUTE_TVANUMBER: () => {
              setError("VATNumber", { message: t("merchant.tools.setting.updateMerchant.VATNumber.error.invalid") })
            },
          },
          publicEmail: {
            INVALID_PUBLIC_EMAIL: () => {
              setError("publicEmail", { message: t("merchant.tools.setting.updateMerchant.publicEmail.error.invalid") })
            },
          },
          paymentNotificationEmail: {
            INVALID_PAYMENT_NOTIFICATION_EMAIL: () => {
              setError("paymentNotificationEmail", {
                message: t("merchant.tools.setting.updateMerchant.paymentNotificationEmail.error.invalid"),
              })
            },
          },
          manualReviewNotificationEmail: {
            INVALID_MANUAL_REVIEW_NOTIFICATION_EMAIL: () => {
              setError("manualReviewNotificationEmail", {
                message: t("merchant.tools.setting.updateMerchant.manualReviewNotificationEmail.error.invalid"),
              })
            },
          },
          tradingName: {
            MISSING_ATTRIBUTE_TRADING_NAME: () => {
              setError("tradingName", {
                message: t("merchant.tools.setting.updateMerchant.tradingName.error.invalid"),
              })
            },
          },
          juridicalName: {
            MISSING_ATTRIBUTE_JURIDICAL_NAME: () => {
              setError("juridicalName", {
                message: t("merchant.tools.setting.updateMerchant.juridicalName.error.invalid"),
              })
            },
          },
          websiteUrl: {
            MISSING_ATTRIBUTE_WEBSITEURL: () => {
              setError("websiteUrl", { message: t("merchant.tools.setting.updateMerchant.websiteUrl.error.invalid") })
            },
          },
        },
      })
    })
  })

  const countryCodeOptions = useMemo(
    () => [
      { label: t("common:country.austria"), value: "AT" },
      { label: t("common:country.belgium"), value: "BE" },
      { label: t("common:country.bulgaria"), value: "BG" },
      { label: t("common:country.cyprus"), value: "CY" },
      { label: t("common:country.czechRepublic"), value: "CZ" },
      { label: t("common:country.germany"), value: "DE" },
      { label: t("common:country.denmark"), value: "DK" },
      { label: t("common:country.estonia"), value: "EE" },
      { label: t("common:country.spain"), value: "ES" },
      { label: t("common:country.finland"), value: "FI" },
      { label: t("common:country.france"), value: "FR" },
      { label: t("common:country.greece"), value: "GR" },
      { label: t("common:country.croatia"), value: "HR" },
      { label: t("common:country.hungary"), value: "HU" },
      { label: t("common:country.ireland"), value: "IE" },
      { label: t("common:country.italy"), value: "IT" },
      { label: t("common:country.lithuania"), value: "LT" },
      { label: t("common:country.luxembourg"), value: "LU" },
      { label: t("common:country.latvia"), value: "LV" },
      { label: t("common:country.malta"), value: "MT" },
      { label: t("common:country.netherlands"), value: "NL" },
      { label: t("common:country.poland"), value: "PL" },
      { label: t("common:country.portugal"), value: "PT" },
      { label: t("common:country.romania"), value: "RO" },
      { label: t("common:country.sweden"), value: "SE" },
      { label: t("common:country.slovenia"), value: "SI" },
      { label: t("common:country.slovakia"), value: "SK" },
      { label: t("common:country.unitedKingdom"), value: "GB" },
      { label: t("common:country.switzerland"), value: "CH" },
    ],
    [t],
  )

  useEffect(() => {
    if (!data) {
      return
    }

    reset({
      ...data.getmerchant,
      VATNumber: data.getmerchant.tvaNumber,
      address: data.getmerchant.address || {
        line1: "",
        line2: "",
        city: "",
        zipCode: "",
        region: "",
        countryCode: "",
      },
    })
  }, [data, reset])

  if (loading) {
    return (
      <CenteringContainer>
        <DeprecatedSpinner />
      </CenteringContainer>
    )
  }

  if (error) {
    return <CenteringContainer>{error.message}</CenteringContainer>
  }

  if (!data) {
    return <div>{t("merchant.tools.setting.updateMerchant.errorNoData")}</div>
  }

  return (
    <>
      <DeprecatedHeader>
        <HeroErrorDisplayer data={data} err={error || updateMerchantApolloError} />
      </DeprecatedHeader>
      <form onSubmit={onSubmit}>
        <Panel>
          {(
            [
              {
                disabled: !!data.getmerchant.siret,
                field: "siret",
                type: "text",
                functional: t("merchant.tools.setting.updateMerchant.siret.label"),
                required: Boolean(getValues("siret")),
              },
              {
                disabled: !!data.getmerchant.tvaNumber,
                field: "VATNumber",
                type: "text",
                functional: t("merchant.tools.setting.updateMerchant.vatNumber.label"),
                required: Boolean(getValues("VATNumber")),
              },
              {
                disabled: !!data.getmerchant.juridicalName,
                field: "juridicalName",
                type: "text",
                functional: t("merchant.tools.setting.updateMerchant.juridicalName.label"),
                required: Boolean(getValues("juridicalName")),
              },
              {
                disabled: !!data.getmerchant.websiteUrl,
                field: "websiteUrl",
                type: "text",
                functional: t("merchant.tools.setting.updateMerchant.websiteUrl.label"),
                required: Boolean(getValues("websiteUrl")),
              },
              {
                disabled: !!data.getmerchant.tradingName,
                field: "tradingName",
                type: "text",
                functional: t("merchant.tools.setting.updateMerchant.tradingName.label"),
                required: Boolean(getValues("tradingName")),
              },
              {
                disabled: false,
                field: "publicEmail",
                functional: t("merchant.tools.setting.updateMerchant.publicEmail.label"),
                type: "email",
                tooltip: t("merchant.tools.setting.updateMerchant.publicEmail.tooltip"),
                required: false,
              },
              {
                disabled: false,
                field: "paymentNotificationEmail",
                functional: t("merchant.tools.setting.updateMerchant.paymentNotificationEmail.label"),
                type: "email",
                tooltip: t("merchant.tools.setting.updateMerchant.paymentNotificationEmail.tooltip"),
                required: Boolean(getValues("paymentNotificationEmail")),
              },
              {
                disabled: false,
                field: "manualReviewNotificationEmail",
                functional: t("merchant.tools.setting.updateMerchant.manualReviewNotificationEmail.label"),
                type: "email",
                tooltip: t("merchant.tools.setting.updateMerchant.manualReviewNotificationEmail.tooltip"),
                required: Boolean(getValues("manualReviewNotificationEmail")),
              },
            ] as FieldProps<Omit<UpdateMerchantFormType, "address">>[]
          ).map(({ disabled, field, functional, required, type, tooltip }, index) => (
            <Slot key={field}>
              <DeprecatedField
                {...register(field)}
                fieldLabel={
                  tooltip ? (
                    <CaptionWithTooltip>
                      <Caption as="div" $variant="caption-2">
                        {functional}
                      </Caption>
                      <DeprecatedTooltip content={functional} id={`tooltip-${index}`} position="top-left">
                        <InfoIcon />
                      </DeprecatedTooltip>
                    </CaptionWithTooltip>
                  ) : (
                    functional
                  )
                }
                type={type}
                disabled={disabled}
                placeholder={functional}
                $fullWidth
                required={required}
                errorMessage={formState.errors[field]?.message}
              />
            </Slot>
          ))}

          {(
            [
              {
                disabled: false,
                field: "line1",
                type: "input",
                functional: t("merchant.tools.setting.updateMerchant.address.line1.label"),
                required: Boolean(getValues("address.line1")),
              },
              {
                disabled: false,
                field: "line2",
                type: "input",
                functional: t("merchant.tools.setting.updateMerchant.address.line2.label"),
                required: false,
              },
              {
                disabled: false,
                field: "zipCode",
                type: "input",
                functional: t("merchant.tools.setting.updateMerchant.address.zipCode.label"),
                required: Boolean(getValues("address.zipCode")),
              },
              {
                disabled: false,
                field: "city",
                type: "input",
                functional: t("merchant.tools.setting.updateMerchant.address.city.label"),
                required: Boolean(getValues("address.city")),
              },
              {
                disabled: false,
                field: "region",
                type: "input",
                functional: t("merchant.tools.setting.updateMerchant.address.region.label"),
                required: Boolean(getValues("address.region")),
              },
              {
                disabled: false,
                field: "countryCode",
                type: "select",
                options: countryCodeOptions,
                functional: t("merchant.tools.setting.updateMerchant.address.country.label"),
                required: Boolean(getValues("address.countryCode")),
              },
            ] as FieldProps<UpdateMerchantFormType["address"]>[]
          ).map((props) => {
            const fieldForm = register(`address.${props.field}`)

            return (
              <Slot key={props.field}>
                {props.type === "select" ? (
                  <DeprecatedFieldSelect
                    fieldLabel={props.functional}
                    required={props.required}
                    $fullWidth
                    {...fieldForm}
                  >
                    <option value="" disabled>
                      {t("merchant.tools.setting.updateMerchant.country.empty")}
                    </option>
                    {props.options.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </DeprecatedFieldSelect>
                ) : (
                  <DeprecatedField
                    {...fieldForm}
                    fieldLabel={props.functional}
                    disabled={props.disabled}
                    placeholder={props.functional}
                    required={props.required}
                    $fullWidth
                  />
                )}
              </Slot>
            )
          })}
        </Panel>

        <hr />

        <Footer>
          <DeprecatedButton type="submit" isLoading={updateMerchantLoading} disabled={!formState.isValid}>
            {t("merchant.tools.setting.updateMerchant.submit")}
          </DeprecatedButton>
        </Footer>
      </form>
    </>
  )
}

const onGraphQLResponse = <T,>(
  data: T | undefined,
  actions: {
    success: (data: T) => void
    error?: (data: GqlHeroError) => void
    validationErrors?: Record<string, Record<string, () => void>>
  },
) => {
  if (!data) {
    return
  }

  if (isGqlHeroError(data)) {
    return actions.error && actions.error(data)
  }

  if (isValidationError(data)) {
    const validationErrorsAction = actions.validationErrors
    if (!validationErrorsAction) {
      return
    }

    return data.validationErrors
      .map(
        ({ path, validationError }) =>
          validationErrorsAction[path.join(".")] && validationErrorsAction[path.join(".")][validationError],
      )
      .filter(isDefined)
      .forEach((action) => action())
  }

  return actions.success(data)
}

const isDefined = <T,>(value: T | undefined): value is T => {
  return value !== undefined
}
