import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

interface MerchantExposure {
  totalAllowedExposure: number | null
  totalInternalDebt: number
}

const query = gql`
  query merchantExposure {
    getMerchantExposure {
      ... on GetMerchantExposureSuccess {
        totalAllowedExposure
        totalInternalDebt
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useMerchantExposure = ({ skip }: { skip: boolean }) => {
  return useHeroQuery<MerchantExposure>({
    gqlQuerySchema: query,
    skip,
  })
}
