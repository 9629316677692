import { DeprecatedFieldSelect, DeprecatedSpinner, Typography, theme } from "@hero/krypton"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { HeroErrorDisplayer } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { useGetBarChartData } from "../hooks/useGetBarChartData"
import {
  getApFundedForChart,
  getCaForChart,
  getFinancingRateForChart,
  getFooterTooltips,
  getLabelsForChart,
} from "../utils/CockpitBarChartWithNewKPI.utils"
import { BarChartWithNewKPI } from "./BarChartWithNewKPI"

export const PRIMARY_Y_AXIS_ID = "y1"
export const SECONDARY_Y_AXIS_ID = "y2"

const BarChartContainer = styled.div`
  position: relative;
  flex: 1;
  margin-top: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  box-shadow: ${({ theme }) => theme.shadows.mid};
`

const TopContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectPeriod = styled(DeprecatedFieldSelect)`
  width: 11rem;
  height: 2rem;
`

export const CockpitBarChartWithNewKPI = ({ selectedMarketplace }: { selectedMarketplace: string | null }) => {
  const [params, setSearchParams] = useSearchParams()
  const period = params.get("period") ?? ""
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser?.merchantId)

  const { t, i18n } = useDashboardTranslation()

  const { barChartData, barChartTechnicalError, barChartFunctionalError, barChartLoading } = useGetBarChartData(
    { period: period || "thisWeek" },
    { useDemoData: isDemoMerchant },
  )

  useEffect(() => {
    if (!period) {
      setSearchParams({ period: "thisWeek" })
    }
  }, [period, setSearchParams])

  const caForChart = getCaForChart(barChartData, selectedMarketplace)
  const apFundedForChart = getApFundedForChart(barChartData, selectedMarketplace)
  const financingRateForChart = getFinancingRateForChart(barChartData, selectedMarketplace)
  const labelsForChart = getLabelsForChart(barChartData, i18n)

  const tooltipFooters = getFooterTooltips(barChartData, selectedMarketplace)

  return (
    <BarChartContainer data-test-id="merchant-ap-cockpit-chart">
      <TopContentContainer>
        <Typography $variant="body-3-semibold" as="h2">
          {t("ap.cockpit.barChart.legend.title")}
        </Typography>
        <SelectPeriod
          size="small"
          value={period}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setSearchParams({ marketplace: selectedMarketplace ?? "all", period: e.target.value })
          }}
        >
          <option value="thisWeek">{t("ap.cockpit.filterPeriod.thisWeek")}</option>
          <option value="lastWeek">{t("ap.cockpit.filterPeriod.lastWeek")}</option>
          <option value="thisMonth">{t("ap.cockpit.filterPeriod.thisMonth")}</option>
          <option value="lastMonth">{t("ap.cockpit.filterPeriod.lastMonth")}</option>
        </SelectPeriod>
      </TopContentContainer>
      {barChartLoading ? <DeprecatedSpinner /> : null}
      <BarChartWithNewKPI
        data={{
          labels: labelsForChart,
          tooltipFooters,
          datasets: [
            {
              yAxisID: SECONDARY_Y_AXIS_ID,
              label: t("ap.cockpit.heroFinancingRates"),
              data: financingRateForChart,
              backgroundColor: Array(financingRateForChart.length).fill(theme.colors.warning.$200),
              borderColor: Array(financingRateForChart.length).fill(theme.colors.warning.$200),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              type: "line" as any, // react-chartjs typing limitation, this line is usefull, don't remove it
            },
            {
              yAxisID: PRIMARY_Y_AXIS_ID,
              label: t("ap.cockpit.marketplaceVolume"),
              data: caForChart,
              backgroundColor: Array(caForChart.length).fill(theme.colors.primary.$200),
            },
            {
              yAxisID: PRIMARY_Y_AXIS_ID,
              label: t("ap.cockpit.heroPaymentVolume"),
              data: apFundedForChart,
              backgroundColor: Array(apFundedForChart.length).fill(theme.colors.secondary.$200),
            },
          ],
        }}
      />
      <HeroErrorDisplayer
        err={barChartTechnicalError}
        data={barChartFunctionalError}
        messages={{
          UNAUTHORIZED: "Vous n'êtes pas autorisé à accéder à cette page",
          INTERNAL_SERVER_ERROR:
            "Nous avons rencontré un problème technique inattendu. Le problème nous a été remonté.",
        }}
      />
    </BarChartContainer>
  )
}
