import { AddIcon, DeprecatedButton, Typography } from "@hero/krypton"
import * as ScrollArea from "@radix-ui/react-scroll-area"
import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../../01_context/businessAccount.context"
import { useBusinessAccount } from "../../hooks/useBusinessAccount"
import { useBusinessAccounts } from "../../hooks/useBusinessAccounts"
import { currencyFormatter } from "../../utils"
import {
  Drawer as DrawerPrimitive,
  DrawerContent as DrawerPrimitiveContent,
  DrawerHeader as DrawerPrimitiveHeader,
  DrawerTrigger as DrawerPrimitiveTrigger,
} from "../Drawer"
import { TypographyButton } from "../TypographyButton"

const DrawerTrigger = DrawerPrimitiveTrigger

const ScrollAreaRoot = styled(ScrollArea.Root)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  padding-bottom: 0.25rem;
`

const StyledDrawerContent = styled(DrawerPrimitiveContent)<{ $bannerOffset: number }>`
  position: fixed;
  inset-x: 0;
  bottom: 0;
  z-index: 50;
  top: ${({ $bannerOffset }) => $bannerOffset}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1rem;
  gap: 0.75rem;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${({ theme }) => theme.colors.grey.$100};
`

const StyledDrawerHeader = styled(DrawerPrimitiveHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`

const AllAccountsItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 0.25rem;
`

const AllAccountsItem = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
`

const AccountList = styled.ul`
  list-style: none;
  margin: 0;
  height: 30vh;
  border-radius: 10px;
  padding: 0.25rem;
`

const AccountItem = styled.li<{ $isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  background: ${({ $isSelected, theme }) => ($isSelected ? theme.colors.grey.$100 : "none")};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.grey.$100};
  }
`

const ButtonText = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${({ theme }) => theme.colors.white};
  line-height: unset;
`

const NewAccountButton = styled(DeprecatedButton).attrs(() => ({
  $variant: "primary",
  size: "medium",
}))`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const AccountDrawerContent = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const { businessAccounts, totalBalance } = useBusinessAccounts()
  const { businessAccount } = useBusinessAccount()
  const { t } = useDashboardTranslation()
  const { accountNb } = useParams<{ accountNb: string }>()
  const { bannerAccountInVerification } = useBusinessAccountContext()

  const handleSelectAccount = (ledgerId: string) => {
    navigate(`/accounts/${ledgerId}/transactions`)
    setIsOpen(false)
  }

  const handleSelectAllAccount = () => {
    navigate(`/accounts/transactions`)
    setIsOpen(false)
  }

  return (
    <DrawerPrimitive open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild>
        <TypographyButton>{businessAccount?.name || t("accounts.drawer.trigger.text")}</TypographyButton>
      </DrawerTrigger>
      <StyledDrawerContent $bannerOffset={bannerAccountInVerification.bannerHeight}>
        <StyledDrawerHeader>
          <Typography $variant="title-3-semibold">{t("accounts.drawer.header.title")}</Typography>

          <NewAccountButton forwardedAs={Link} to="/accounts/create" disabled={bannerAccountInVerification.isActive}>
            <AddIcon width={20} />
            <ButtonText>{t("accounts.drawer.header.buttonText")}</ButtonText>
          </NewAccountButton>
        </StyledDrawerHeader>
        <AllAccountsItemWrapper onClick={handleSelectAllAccount}>
          <AllAccountsItem>
            <Typography $variant="body-4-medium">{t("accounts.drawer.allAccounts.text")}</Typography>
            <Typography $variant="body-4-regular">{currencyFormatter(totalBalance)}</Typography>
          </AllAccountsItem>
        </AllAccountsItemWrapper>
        <ScrollAreaRoot>
          <ScrollArea.Viewport>
            <AccountList>
              {businessAccounts.map((account, index) => (
                <AccountItem
                  key={index}
                  onClick={handleSelectAccount.bind(null, account.ledgerId)}
                  $isSelected={account.ledgerId === accountNb}
                >
                  <Typography $variant="body-4-medium">{account.name}</Typography>
                  <Typography $variant="body-4-regular">{currencyFormatter(account.balance)}</Typography>
                </AccountItem>
              ))}
            </AccountList>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar orientation="horizontal">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
          <ScrollArea.Corner />
        </ScrollAreaRoot>
      </StyledDrawerContent>
    </DrawerPrimitive>
  )
}
