import { DeprecatedErrorBlock } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { useGetActiveMRFData } from "./ActiveMRF.request"
import ActiveMRFDetailsDetailsCard from "./ActiveMRFDetailsDetailsCard"
import ActiveMRFDetailsOverviewCard from "./ActiveMRFDetailsOverviewCard"
import ActiveMRFInstallmentsTable from "./ActiveMRFInstallmentsTable"
import { ActiveMRFScreenHeader } from "./ActiveMRFScreenHeader"

interface ActiveMRFScreenProps {
  mrfs: {
    id: string
    logoUrl?: string
    name: string
    acceptationDate: string
  }[]
}

const Wrapper = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
`

const ActiveMRFScreenWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const Error = styled(DeprecatedErrorBlock)`
  margin: 2rem 3rem;
`

const ActiveMRFDetailsCards = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0rem 3rem;
`

const ActiveMRFScreen = ({ mrfs: marketplaces }: ActiveMRFScreenProps) => {
  const [selectedTab, setSelectedTab] = useState(marketplaces[0].id)
  const { loading, error, data } = useGetActiveMRFData(selectedTab)

  return (
    <ActiveMRFScreenWrapper>
      <ActiveMRFScreenHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} mrfs={marketplaces} />

      {loading && <CenteredLoading />}

      {error && <Error>{error.translatedMessage}</Error>}

      {data && (
        <Wrapper>
          <ActiveMRFDetailsCards>
            <ActiveMRFDetailsOverviewCard details={data} />
            <ActiveMRFDetailsDetailsCard details={data} />
          </ActiveMRFDetailsCards>

          <ActiveMRFInstallmentsTable installments={data.installments} />
        </Wrapper>
      )}
    </ActiveMRFScreenWrapper>
  )
}

export default ActiveMRFScreen
