import {
  BankIcon,
  CreditCardIcon,
  deprecatedToaster,
  DeprecatedToggle,
  PermIdentityIcon,
  Separator,
  theme,
  Typography,
} from "@hero/krypton"
import styled from "styled-components"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../00_shared/components/Accordion"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../00_shared/components/Sheet"
import { Spacer } from "../../../00_shared/components/Spacer"
import { DateStyle, toInternationalDate } from "../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import { User } from "../00_shared/User"
import { fullname } from "../00_shared/utils"
import { SidePanelCapabilitiesStrategy } from "../ProductCapabilities/UserManagementSidePanelCapabilitiesStrategy"
import { useUpdateMerchantUserRole } from "./hooks/useUpdateMerchantUserRole"
import { useUpdateMerchantUserStatus } from "./hooks/useUpdateMerchantUserStatus"
import { InvitationButton } from "./UserManagementInvitationButton"
import { UserStatusBadge } from "./UserManagementUserStatusBadge"

const SRPBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeprecatedCheckbox = styled.input`
  margin-top: 0.3125rem;
  height: 0.875rem;
  width: 0.875rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};

  accent-color: ${theme.colors.majorelle};
`

const CheckboxGroup = styled.div<{ $isChecked?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  border: 1px solid ${({ theme, $isChecked }) => ($isChecked ? "#624AFF" : theme.colors.grey.$200)};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;

  transition: all 0.2s;

  & * {
    transition: all 0.2s;
  }
`

const CheckboxGroupSummary = styled(Accordion)``
const CheckboxGroupSummaryAccordionTrigger = styled(AccordionTrigger)`
  flex-direction: row-reverse;
  flex-grow: 0;
  flex-basis: auto;
  gap: 0.5rem;
  min-height: 2rem;
  padding: 0.5rem 0;
`

const CheckboxGroupSummaryItem = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }
`

export const SlidingRightPanel = ({
  user,
  onClose,
  refetchUserList,
}: {
  refetchUserList: () => void
  user: User
  onClose: () => void
}) => {
  const { currentUser } = useAuthContext()
  const { t, lang } = useDashboardTranslation()

  const userIsOwnerWithLegalRepresentativeRole = user.role === "OWNER" && user.isLegalRepresentative
  const { mutate: changeMerchantUserRole } = useUpdateMerchantUserRole()
  const { mutate: changeMerchantUserStatus } = useUpdateMerchantUserStatus()

  const activateUser = (user: User) => {
    deprecatedToaster.promise(
      changeMerchantUserStatus({ userId: user.id, status: "ACTIVE" }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.activateUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.activateUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.activateUserStatus.error"),
      },
      { position: "top-center" },
    )
  }
  const deactivateUser = (user: User) => {
    deprecatedToaster.promise(
      changeMerchantUserStatus({ userId: user.id, status: "INACTIVE" }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.disableUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.disableUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.disableUserStatus.error"),
      },
      { position: "top-center" },
    )
  }

  const changeUserOwner = (user: User) => {
    deprecatedToaster.promise(
      changeMerchantUserRole({ userId: user.id, role: "OWNER" }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserStatus.error"),
      },
      { position: "top-center" },
    )
  }
  const changeUserMember = (user: User) => {
    deprecatedToaster.promise(
      changeMerchantUserRole({ userId: user.id, role: "MEMBER" }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserStatus.error"),
      },
      { position: "top-center" },
    )
  }

  return (
    <Sheet
      open={!!user}
      onOpenChange={(open) => {
        if (open) {
          return
        }
        onClose()
      }}
    >
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>{t("userManagement.sidePanel.header.title")}</SheetTitle>
        </SheetHeader>

        <SRPBlock>
          <div>
            <Typography $variant="title-2-bold">{fullname(user)}</Typography>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("userManagement.sidePanel.nameBlock.createdOn", {
                createdAt: toInternationalDate({ date: user.createdAt, language: lang, style: DateStyle.TEXTUAL }),
              })}
            </Typography>
          </div>
          <div>
            <UserStatusBadge status={user.status} hasPendingInvitation={user.hasPendingInvitation} />
          </div>
        </SRPBlock>

        {currentUser.role === "owner" && user.hasPendingInvitation && <InvitationButton user={user} />}

        <Spacer />
        <Separator />
        <Spacer />

        {userIsOwnerWithLegalRepresentativeRole ? (
          <CheckboxGroup disabled={true} $isChecked={user.role === "OWNER"}>
            <div>
              <DeprecatedCheckbox disabled={true} type="radio" checked={user.role === "OWNER"} name="selected-role" />
            </div>
            <div>
              <Typography $variant="body-4-medium">
                {t("userManagement.sidePanel.roleToggle.ownerPlusLegalRep.title")}
              </Typography>
              <Typography $variant="caption-2">
                {t("userManagement.sidePanel.roleToggle.ownerPlusLegalRep.description")}
              </Typography>
            </div>
          </CheckboxGroup>
        ) : (
          <>
            <CheckboxGroup $isChecked={user.role === "MEMBER"}>
              <div>
                <DeprecatedCheckbox
                  type="radio"
                  checked={user.role === "MEMBER"}
                  name="selected-role"
                  disabled={currentUser.role !== "owner"}
                  onChange={(e) => {
                    changeUserMember(user)
                  }}
                />
              </div>
              <div>
                <label onClick={() => changeUserMember(user)} onKeyUp={() => null} role="presentation">
                  <Typography $variant="body-4-medium">
                    {t("userManagement.sidePanel.roleToggle.collaborator.title")}
                  </Typography>
                  <Typography $variant="caption-2">
                    {t("userManagement.sidePanel.roleToggle.collaborator.description")}
                  </Typography>
                </label>

                <CheckboxGroupSummary type="single" collapsible>
                  <AccordionItem value="collaborator-benefits" style={{ border: "none" }}>
                    <CheckboxGroupSummaryAccordionTrigger>
                      <Typography $variant="caption-2" style={{ color: theme.colors.majorelle }}>
                        {t("userManagement.sidePanel.roleToggle.seeMore")}
                      </Typography>
                    </CheckboxGroupSummaryAccordionTrigger>
                    <AccordionContent>
                      <CheckboxGroupSummaryItem>
                        <CreditCardIcon />
                        <Typography $variant="caption-2" as="span">
                          {t("userManagement.sidePanel.roleToggle.collaborator.benefits.card")}
                        </Typography>
                      </CheckboxGroupSummaryItem>
                      <CheckboxGroupSummaryItem>
                        <BankIcon />
                        <Typography $variant="caption-2" as="span">
                          {t("userManagement.sidePanel.roleToggle.collaborator.benefits.account")}
                        </Typography>
                      </CheckboxGroupSummaryItem>
                    </AccordionContent>
                  </AccordionItem>
                </CheckboxGroupSummary>
              </div>
            </CheckboxGroup>

            <Spacer />

            <CheckboxGroup $isChecked={user.role === "OWNER"}>
              <div>
                <DeprecatedCheckbox
                  type="radio"
                  checked={user.role === "OWNER"}
                  name="selected-role"
                  disabled={currentUser.role !== "owner"}
                  onChange={(e) => {
                    changeUserOwner(user)
                  }}
                />
              </div>
              <div>
                <label onClick={() => changeUserOwner(user)} onKeyUp={() => null} role="presentation">
                  <Typography $variant="body-4-medium">
                    {t("userManagement.sidePanel.roleToggle.admin.title")}
                  </Typography>
                  <Typography $variant="caption-2">
                    {t("userManagement.sidePanel.roleToggle.admin.description")}
                  </Typography>
                </label>

                <CheckboxGroupSummary type="single" collapsible>
                  <AccordionItem value="owner-benefits" style={{ border: "none" }}>
                    <CheckboxGroupSummaryAccordionTrigger>
                      <Typography $variant="caption-2" style={{ color: theme.colors.majorelle }}>
                        {t("userManagement.sidePanel.roleToggle.seeMore")}
                      </Typography>
                    </CheckboxGroupSummaryAccordionTrigger>
                    <AccordionContent>
                      <CheckboxGroupSummaryItem>
                        <PermIdentityIcon />
                        <Typography $variant="caption-2" as="span">
                          {t("userManagement.sidePanel.roleToggle.admin.benefits.roles")}
                        </Typography>
                      </CheckboxGroupSummaryItem>
                      <CheckboxGroupSummaryItem>
                        <CreditCardIcon />
                        <Typography $variant="caption-2" as="span">
                          {t("userManagement.sidePanel.roleToggle.admin.benefits.cards")}
                        </Typography>
                      </CheckboxGroupSummaryItem>
                      <CheckboxGroupSummaryItem>
                        <BankIcon />
                        <Typography $variant="caption-2" as="span">
                          {t("userManagement.sidePanel.roleToggle.admin.benefits.access")}
                        </Typography>
                      </CheckboxGroupSummaryItem>
                    </AccordionContent>
                  </AccordionItem>
                </CheckboxGroupSummary>
              </div>
            </CheckboxGroup>
          </>
        )}

        {currentUser.role === "owner" && user.role === "MEMBER" && (
          <>
            <Spacer />
            <Separator />
            <Spacer />
            <SidePanelCapabilitiesStrategy actor={user.id} />
          </>
        )}

        <Spacer />
        <Separator />
        <Spacer />

        {!userIsOwnerWithLegalRepresentativeRole && (
          <>
            <Typography $variant="body-3-semibold">
              {t("userManagement.sidePanel.status.title")}
              <span style={{ padding: "1em" }}>
                {user.status === "SUSPENDED" && (
                  <UserStatusBadge status={user.status} hasPendingInvitation={user.hasPendingInvitation} />
                )}
              </span>
            </Typography>
            <SRPBlock>
              <Typography $variant="body-4-regular" $color="grey.$500" aria-label="status-description">
                {t("userManagement.sidePanel.status.description.inactive")}
              </Typography>
              {user.status !== "SUSPENDED" && (
                <div>
                  <DeprecatedToggle
                    checked={user.status === "ACTIVE"}
                    aria-labelledby="status-description"
                    $variant="primary"
                    onClick={() => {
                      if (user.status === "ACTIVE") {
                        deactivateUser(user)
                      }
                      if (user.status === "INACTIVE") {
                        activateUser(user)
                      }
                    }}
                  />
                </div>
              )}
            </SRPBlock>

            <Spacer />
            <Separator />
            <Spacer />
          </>
        )}

        <Typography $variant="body-3-semibold">{t("userManagement.sidePanel.memberInformation.title")}</Typography>
        <SRPBlock>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {t("userManagement.sidePanel.memberInformation.email")}
          </Typography>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {user.email}
          </Typography>
        </SRPBlock>

        <SRPBlock>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {t("userManagement.sidePanel.memberInformation.phoneNumber")}
          </Typography>
          <Typography $variant="body-4-regular" $color="grey.$600">
            {user.phone}
          </Typography>
        </SRPBlock>

        {userIsOwnerWithLegalRepresentativeRole && (
          <>
            <Spacer />
            <Separator />
            <Spacer />

            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("userManagement.sidePanel.disclaimerForLegalRep")}
            </Typography>
          </>
        )}
      </SheetContent>
    </Sheet>
  )
}
