import { DeprecatedErrorBlock } from "@hero/krypton"
import { InactiveAccountError } from "./errors/InactiveAccount.error"
import { UnauthorizedError } from "./errors/Unauthorized.error"

/**
 * @deprecated use useHeroQuery instead
 */
export const ApiErrors = ({
  err,
  strategies,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any
  strategies?: Record<string, (extensions?: { [key: string]: unknown }) => React.ReactNode>
}) => {
  strategies = {
    UNAUTHORIZED: () => <UnauthorizedError />,
    INACTIVE_ACCOUNT: () => <InactiveAccountError />,
    ...strategies,
  }

  if (!err) {
    return null
  }

  if (err.graphQLErrors) {
    if (err.graphQLErrors.length > 0) {
      const message = err.graphQLErrors[0].message
      const extensions = err.graphQLErrors[0].extensions
      return <DeprecatedErrorBlock>{strategies?.[message]?.(extensions) ?? message}</DeprecatedErrorBlock>
    }
  }

  if (err.networkError) {
    return (
      <DeprecatedErrorBlock>
        {err.networkError.statusCode}
        {(err.networkError.result?.errors as [])?.map(({ message }: { message: string }, index) => {
          return <DeprecatedErrorBlock key={index}>{message}</DeprecatedErrorBlock>
        })}
      </DeprecatedErrorBlock>
    )
  }

  if (err.message) {
    if (strategies?.[err.message]) {
      return <DeprecatedErrorBlock>{strategies[err.message]()}</DeprecatedErrorBlock>
    }

    return <DeprecatedErrorBlock>{err.message}</DeprecatedErrorBlock>
  }

  return <DeprecatedErrorBlock>{JSON.stringify(err)}</DeprecatedErrorBlock>
}
