import { DeprecatedSpinner, Typography } from "@hero/krypton"
import { useState } from "react"
import { Button } from "../../../../00_shared/components/krypton2/Button"
import { CreditOutstandingIcon } from "../../../../00_shared/components/krypton2/icons/icons"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useSendIncreaseExposureLimitRequest } from "../hooks/useSendIncreaseExposureLimitRequest.hook"
import { SendIncreaseExposureLimitRequestModal } from "./SendIncreaseExposureLimitRequestModal"

export const SendIncreaseExposureLimitRequestButton = () => {
  const { t } = useDashboardTranslation()

  const [isSendIncreaseExposureLimitRequestModalOpen, setIsSendIncreaseExposureLimitRequestModalOpen] = useState(false)
  const [sendIncreaseExposureLimitRequest, { loading: sendIncreaseExposureLimitRequestLoading }] =
    useSendIncreaseExposureLimitRequest()
  return (
    <>
      <SendIncreaseExposureLimitRequestModal
        isOpen={isSendIncreaseExposureLimitRequestModalOpen}
        onClose={() => setIsSendIncreaseExposureLimitRequestModalOpen(false)}
      />
      <Button
        onClick={async () => {
          const { success } = await sendIncreaseExposureLimitRequest({})
          setIsSendIncreaseExposureLimitRequestModalOpen(success)
        }}
        size="small"
        $variant="secondary"
        RightIcon={CreditOutstandingIcon}
        disabled={sendIncreaseExposureLimitRequestLoading}
      >
        {!sendIncreaseExposureLimitRequestLoading && (
          <Typography $variant="caption-2" $color="grey.$500">
            {t("creditWireTransfer.applicationForm.outstandingActionsButton")}
          </Typography>
        )}
        {sendIncreaseExposureLimitRequestLoading && <DeprecatedSpinner />}
      </Button>
    </>
  )
}
