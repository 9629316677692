import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedErrorBlock,
  DeprecatedSpinner,
  DeprecatedTable,
  DeprecatedTBody,
  DeprecatedTHead,
  ScheduleIcon,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import { DateTime } from "luxon"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { TransferStatus } from "../../00_shared/business/enum/IncomingTransferStatus.enum"
import { CustomDiv, CustomPagination, CustomTdImg, EmptyTable, NoLogoUrl } from "../BalanceTables.components"
import { IncomeFilters } from "./IncomingTransfersFilters"
import { useGetIncomingTransfers } from "./useGetIncomingTransfers"

const PAGE_SIZE_LIMIT = 20

type SearchParams = {
  transaction: "income"
  page: number
  marketplaces?: string[]
  ibans?: string[]
  amountMin?: number
  amountMax?: number
  amountEqual?: number
  createdBefore?: string
  createdAfter?: string
}

const TransferStatusBadge = ({ status }: { status: TransferStatus }) => {
  const { t } = useDashboardTranslation()

  const translateStatusKeyMap: Record<TransferStatus, "pending" | "refunded" | "received" | "sent"> = {
    [TransferStatus.WAITING]: "pending",
    [TransferStatus.REFUNDED]: "refunded",
    [TransferStatus.ALLOCATED]: "received",
    [TransferStatus.SENT]: "sent",
  }

  const statusText = t(`ap.balance.status.${translateStatusKeyMap[status]}`)

  switch (status) {
    case TransferStatus.WAITING:
      return (
        <DeprecatedBadge $variant="warning" icon={<ScheduleIcon />}>
          {statusText}
        </DeprecatedBadge>
      )
    case TransferStatus.REFUNDED:
      return (
        <DeprecatedBadge $variant="danger" icon={<CloseStrokeIcon />}>
          {statusText}
        </DeprecatedBadge>
      )
    case TransferStatus.ALLOCATED:
      return (
        <DeprecatedBadge $variant="primary" icon={<ValidIcon />}>
          {statusText}
        </DeprecatedBadge>
      )
    case TransferStatus.SENT:
      return (
        <DeprecatedBadge $variant="primary" icon={<ValidIcon />}>
          {statusText}
        </DeprecatedBadge>
      )
  }
}

export const BalanceIncomeTable = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const { t, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const getAPIncomingTransfersSearchParam = <T extends keyof SearchParams>(param: T) => {
    if (!getSearchParam(param)) {
      return undefined
    } else if (param === "page" || param === "amountMin" || param === "amountMax" || param === "amountEqual") {
      return Number(getSearchParam(param))
    } else if (param === "marketplaces") {
      return getSearchParam(param)?.split(",")
    } else {
      return getSearchParam(param)
    }
  }

  const pageNumber = (getAPIncomingTransfersSearchParam("page") ?? 1) as number
  const marketplaceNamesFilter = getAPIncomingTransfersSearchParam("marketplaces") as string[]
  const amountMin = getAPIncomingTransfersSearchParam("amountMin") as number | undefined
  const amountMax = getAPIncomingTransfersSearchParam("amountMax") as number | undefined
  const amountEqual = getAPIncomingTransfersSearchParam("amountEqual") as number | undefined
  const createdBefore = getAPIncomingTransfersSearchParam("createdBefore") as string | undefined
  const createdAfter = getAPIncomingTransfersSearchParam("createdAfter") as string | undefined

  const { data, error, loading } = useGetIncomingTransfers(
    {
      marketplaceNames: marketplaceNamesFilter,
      amountMin: amountMin,
      amountMax: amountMax,
      amountEqual: amountEqual,
      createdBefore: createdBefore ? DateTime.fromISO(createdBefore).toJSDate() : undefined,
      createdAfter: createdAfter ? DateTime.fromISO(createdAfter).toJSDate() : undefined,
    },
    {
      pageNumber,
      pageSize: PAGE_SIZE_LIMIT,
    },
    {
      useDemoData: isDemoMerchant,
    },
  )

  const totalPages = data?.pagination ? data.pagination.totalPage : 1
  const transfers = data?.transfers ?? []
  const marketplaces = data?.marketplaces ?? []

  const columns = [
    t("ap.balance.columns.marketplace"),
    t("ap.balance.columns.amount"),
    t("ap.balance.columns.status"),
    t("ap.balance.columns.date"),
    t("ap.balance.columns.account"),
  ]

  return (
    <>
      <IncomeFilters
        listMarketplaces={marketplaces}
        currentParams={{
          page: pageNumber,
          marketplaces: marketplaceNamesFilter,
          amountMin,
          amountMax,
          amountEqual,
          createdAfter,
          createdBefore,
        }}
        setSearchParams={(params) => setSearchParam({ transaction: "income", ...params })}
      />

      {error && <DeprecatedErrorBlock $margin="1.5rem 3rem">{error?.translatedMessage}</DeprecatedErrorBlock>}

      {loading && (
        <LoadingWrapper>
          <DeprecatedSpinner />
          <Title>{t("loading")}...</Title>
        </LoadingWrapper>
      )}

      <DeprecatedTable data-test-id="merchant-ap-balance-income-table">
        <DeprecatedTHead>
          <tr>
            {columns.map((label) => (
              <Th key={label}>{label}</Th>
            ))}
          </tr>
        </DeprecatedTHead>

        <DeprecatedTBody $clickable={!!transfers.length}>
          {!transfers.length && (
            <tr>
              <EmptyTable colSpan={6}>{t("ap.balance.incoming.empty")}</EmptyTable>
            </tr>
          )}
          {transfers.map(
            ({ swanTransactionId, amount, date, logoUrl, marketplaceName, status, account: { number, name } }) => {
              const transactionDetailsUrl = `/ap/transfers/incoming/${swanTransactionId}`
              return (
                <tr key={swanTransactionId}>
                  <td>
                    <TableCellLink to={transactionDetailsUrl} />
                    <CustomDiv>
                      {logoUrl ? <CustomTdImg src={logoUrl} alt={t("ap.balance.logoAlt")} /> : <NoLogoUrl />}
                      <Typography>{titleCase(marketplaceName)}</Typography>
                    </CustomDiv>
                  </td>
                  <td>
                    <TableCellLink to={transactionDetailsUrl} />
                    {toEuros(amount)}
                  </td>

                  <td>
                    <TableCellLink to={transactionDetailsUrl} />
                    <TransferStatusBadge status={status} />
                  </td>

                  <td>
                    <TableCellLink to={transactionDetailsUrl} />
                    {toInternationalDate({ date: date, language: i18n.language })}
                  </td>

                  <td>
                    <TableCellLink to={transactionDetailsUrl} />
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <Typography>Account - {name}</Typography>
                    <Typography $variant="caption-2">{number}</Typography>
                  </td>
                </tr>
              )
            },
          )}
        </DeprecatedTBody>
      </DeprecatedTable>

      <CustomPagination
        currentPage={pageNumber}
        totalPages={totalPages}
        onPageChange={(pageNumber) => setSearchParam({ transaction: "income", page: pageNumber })}
      />
    </>
  )
}

const Title = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
`

const Th = styled.th`
  text-transform: uppercase;
`
