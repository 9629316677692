import { Page, Text, View, Document, StyleSheet, usePDF, Svg, Path, Font } from "@react-pdf/renderer"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { theme } from "@hero/krypton"
import { toKEuros } from "../../../../../00_shared/utils/currency.converter"

Font.register({
  family: "Inter",
  src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hjp-Ek-_0ew.woff",
})

const styles = StyleSheet.create({
  page: {
    height: "100%",
    fontFamily: "Inter",
    marginHorizontal: "40px",
    position: "relative",
  },
  header: {
    marginTop: "50px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 515, // A4 width - margins
  },
  body: {
    gap: "40px",
    marginTop: "40px",
    flexDirection: "column",
    width: "100%",
    maxWidth: 515,
  },
  blocTitle: {
    color: theme.colors.majorelle,
    fontSize: "12px",
    fontWeight: 700,
  },
  blocParty: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  blocPartySection: {
    width: "45%",
  },
  blocContent: {
    flexDirection: "column",
    gap: "16px",
  },
  subBloc: {
    flexDirection: "column",
    gap: "4px",
  },
  blocSubTitle: {
    fontSize: "11px",
    fontWeight: 600,
  },
  blocValue: {
    fontSize: "9px",
    fontWeight: 400,
    color: theme.colors.grey.$500,
  },
  insideSubBloc: {
    flexDirection: "row",
    gap: "40px",
  },
  flexGrow: {
    flex: 1,
  },
  footer: {
    fontSize: "8px",
    fontWeight: 400,
    marginVertical: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: 515,
    position: "absolute",
    bottom: 20,
  },
  footerDivider: {
    width: 0.5,
    height: 13,
    backgroundColor: theme.colors.grey.$500,
    margin: "0 14px",
  },
})

const HeroSvg = () => (
  <Svg width="75" height="34" viewBox="0 0 75 34">
    <Path
      d="M3.84934 7.67271C4.12365 6.15312 5.45662 5.04688 7.01335 5.04688H71.1497C73.1517 5.04688 74.6664 6.84193 74.3136 8.79604L71.1318 26.4239C70.8575 27.9435 69.5243 29.0497 67.9676 29.0497H3.83129C1.82942 29.0497 0.314541 27.2547 0.667285 25.3005L3.84934 7.67271Z"
      fill="black"
    />
    <Path
      d="M8.24048 24.4317H12.9002L13.8322 19.1879H18.269L17.3371 24.4317H21.9969L24.5496 10.0667H19.8898L19.0187 15.0492H14.5615L15.4529 10.0667H10.7932L8.24048 24.4317ZM23.5144 24.4317H34.8802L35.569 20.6144H28.8427L29.1669 18.8464H34.6776L35.2853 15.4109H29.7746L30.0583 13.884H36.7643L37.4328 10.0667H26.0671L23.5144 24.4317ZM36.2559 24.4317H40.9359L41.7463 19.9514H42.6783C42.8606 19.9514 43.0227 20.1121 43.124 20.3733L44.7447 24.4317H49.992V24.3915L47.5001 18.9067C49.1816 18.4044 51.3696 17.199 51.3696 14.0447C51.3696 11.3525 49.2627 10.0667 46.1629 10.0667H38.8086L36.2559 24.4317ZM42.354 16.3753L42.7998 13.7835H45.2107C46.0616 13.7835 46.6086 14.1652 46.6086 14.8885C46.6086 15.873 45.7983 16.3753 44.5421 16.3753H42.354ZM58.2365 24.8134C63.3621 24.8134 67.1508 20.956 67.1508 16.0337C67.1508 11.8749 64.2739 9.68497 60.4245 9.68497C55.2988 9.68497 51.5305 13.6027 51.5305 18.4848C51.5305 22.6436 54.4073 24.8134 58.2365 24.8134ZM58.8442 20.7148C57.264 20.7148 56.3928 19.5095 56.3928 17.862C56.3928 15.5716 58.0136 13.7835 59.8978 13.7835C61.4577 13.7835 62.2884 15.0091 62.2884 16.6565C62.2884 18.9268 60.7487 20.7148 58.8442 20.7148Z"
      fill="#F8F8F8"
    />
  </Svg>
)

interface Props {
  generationDate: string
  debtorName: string
  debtorIBAN: string
  debtorBIC: string
  creditorName: string
  creditorIBAN: string
  creditorBIC: string | null
  createdAtDate: string
  createdAtHour: string
  amount: number
  reference: string | null
  transactionId: string
}

const Spacer = ({ width = 227.5 }: { width?: number }) => (
  <View style={{ width, height: 1, backgroundColor: theme.colors.grey.$300, margin: "8px 0" }} />
)

export const WireProof = ({ ...props }: Props) => {
  const { t } = useCommonTranslation()
  return (
    <Document>
      <Page style={styles.page} size={"A4"}>
        <View style={styles.header}>
          <View style={{ flexDirection: "column", gap: "4px" }}>
            <Text style={{ fontWeight: 700, fontSize: 22 }}>{t("ba.wireProof.title")}</Text>
            <Text style={{ fontWeight: 400, fontSize: 11, color: theme.colors.grey.$500 }}>
              {t("ba.wireProof.generationDate") + " " + props.generationDate}
            </Text>
          </View>
          <View>
            <HeroSvg />
          </View>
        </View>

        <View style={styles.body}>
          <View>
            <Text style={{ fontWeight: 400, fontSize: 11 }}>{t("ba.wireProof.acknowledgement")}</Text>
          </View>

          <View style={styles.blocParty}>
            <View style={styles.blocPartySection}>
              <Text style={styles.blocTitle}>{t("ba.wireProof.debtorDetails")}</Text>
              <Spacer />
              <View style={styles.blocContent}>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.accountOwner")}</Text>
                  <Text style={styles.blocValue}>{props.debtorName}</Text>
                </View>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.IBAN")}</Text>
                  <Text style={styles.blocValue}>{props.debtorIBAN}</Text>
                </View>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.BIC")}</Text>
                  <Text style={styles.blocValue}>{props.debtorBIC}</Text>
                </View>
              </View>
            </View>

            <View style={styles.blocPartySection}>
              <Text style={styles.blocTitle}>{t("ba.wireProof.creditorDetails")}</Text>
              <Spacer />
              <View style={styles.blocContent}>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.accountOwner")}</Text>
                  <Text style={styles.blocValue}>{props.creditorName}</Text>
                </View>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.IBAN")}</Text>
                  <Text style={styles.blocValue}>{props.creditorIBAN}</Text>
                </View>
                {props.creditorBIC && (
                  <View style={styles.subBloc}>
                    <Text style={styles.blocSubTitle}>{t("ba.wireProof.BIC")}</Text>
                    <Text style={styles.blocValue}>{props.creditorBIC}</Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          <View>
            <Text style={styles.blocTitle}>{t("ba.wireProof.wireDetails")}</Text>
            <Spacer width={515} />
            <View style={styles.blocContent}>
              <View style={styles.insideSubBloc}>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.date")}</Text>
                  <Text style={styles.blocValue}>{props.createdAtDate}</Text>
                </View>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.hour")}</Text>
                  <Text style={styles.blocValue}>{props.createdAtHour}</Text>
                </View>
              </View>
              <View style={styles.insideSubBloc}>
                <View style={styles.subBloc}>
                  <Text style={styles.blocSubTitle}>{t("ba.wireProof.amount")}</Text>
                  <Text style={styles.blocValue}>{toKEuros(props.amount)}</Text>
                </View>
                {props.reference && (
                  <View style={styles.subBloc}>
                    <Text style={styles.blocSubTitle}>{t("ba.wireProof.ref")}</Text>
                    <Text style={styles.blocValue}>{props.reference}</Text>
                  </View>
                )}
              </View>
              <View style={styles.subBloc}>
                <Text style={styles.blocSubTitle}>{t("ba.wireProof.transactionId")}</Text>
                <Text style={styles.blocValue}>{props.transactionId}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={{ color: theme.colors.grey.$500 }}>231 rue SaintHonoré, 75001, Paris</Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: theme.colors.majorelle }}>www.heropay.eu</Text>
            <View style={styles.footerDivider} />
            <Text style={{ color: theme.colors.grey.$500 }}>contact@heropay.eu</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export const useDownloadWireProof = (props: Props) => {
  return usePDF({ document: <WireProof {...props} /> })
}
