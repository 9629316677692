import { DeprecatedModal, Typography } from "@hero/krypton"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { MrfInstallment } from "./ActiveMRF.request"

interface ActiveMRFInstallmentsTableDetailsModalProps {
  installment: MrfInstallment
  isOpen: boolean
  onClose: () => void
}

const DeprecatedTable = styled.table`
  width: 100%;
  margin: 1rem 0rem;
`

const ActiveMRFInstallmentsTableDetailsModal = ({
  installment,
  isOpen,
  onClose,
}: ActiveMRFInstallmentsTableDetailsModalProps) => {
  const { t, i18n } = useDashboardTranslation()

  return (
    <>
      {createPortal(
        <>
          <DeprecatedModal isOpen={isOpen} onClose={onClose}>
            <Typography as="h2" $variant="title-3-semibold">
              {t("mrf.details.installmentModal.title", {
                date: toInternationalDate({ date: installment.dueDate, language: i18n.language }),
              })}
            </Typography>
            {installment.movements.length <= 0 && (
              <Typography $variant="body-4-medium">{t("mrf.details.installmentModal.noDebit")}</Typography>
            )}

            <DeprecatedTable>
              {installment.movements.map((debit) => (
                <tr>
                  <td>{toInternationalDate({ date: debit.createdAt, language: i18n.language })}</td>
                  {debit.isFeesRepayment && <td>{t("mrf.details.installmentModal.installmentLabel")}</td>}
                  {!debit.isFeesRepayment && <td>{t("mrf.details.installmentModal.fees")}</td>}
                  <Typography $variant="body-4-medium">{toEuros(debit.amount)}</Typography>
                </tr>
              ))}
            </DeprecatedTable>
          </DeprecatedModal>
        </>,
        document.body,
      )}
    </>
  )
}

export default ActiveMRFInstallmentsTableDetailsModal
