import { gql, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  useIsBANewAccountBannerActive,
  useIsBaUserMustBeOnboardedActive,
  useIsFFBOV3Active,
  useIsUserManagementAciveOnBA,
} from "../../../00_shared/hooks/useFeatureFlag.hook"
import { useAuthContext } from "../../../Auth/auth.context"
import { MerchantOnboardingFlowStatus } from "../../../business/enums/Merchant.enum"
import { IssuingCardsMenuEntry } from "../../IssuingCards/routes/listMenuEntry"
import { MerchantOperation } from "../00_shared/business"
import { ProAccountMenuEntry, TransfersMenuEntry } from "../routes/listMenuEntry"
import { CreateTransferNavigationProvider } from "./createTransferNavigation.context"
import { MerchantOperationsVariables, useMerchantOperations } from "./hook/useMerchantOperations"

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
  isMainAccount: boolean
  createdAt: string
}

type GetMerchantMainBusinessAccountResponse = {
  getMerchantMainBusinessAccount: { businessAccount: BusinessAccount[] }
}

const GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantMainBusinessAccount {
    getMerchantMainBusinessAccount {
      ... on GetMerchantMainBusinessAccountOutput {
        businessAccount {
          name
          balance
          ledgerId
          virtualIbanId
          isMainAccount
          createdAt
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

/**
 * @public
 */
export type BusinessAccountContextType = {
  isBaActive: boolean
  merchantMainAccountId?: string
  bannerAccountInVerification: {
    isActive: boolean
    bannerHeight: number
    setBannerHeight: (height: number) => void
  }
  isUserAllowedToCreateTransfers: boolean
  isUserAllowedToViewAccounts: boolean
  isUserAllowedToCreateBeneficiary: boolean
  businessAccountState: {
    isNew: boolean
    isLoading: boolean
  }
  accountOperations: {
    operations: MerchantOperation[]
    pagination: {
      limit: number
      total: number
      currentPage: number
      totalPages: number
      hasNext: boolean
    }
    loading: boolean
    setOperationsFilter: Dispatch<SetStateAction<MerchantOperationsVariables>>
  }
}

const BusinessAccountContext = createContext<BusinessAccountContextType>({} as BusinessAccountContextType)

export const useBusinessAccountContext = () => useContext(BusinessAccountContext)

export const BusinessAccountProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const isBOV3FeatureFlagActive = useIsFFBOV3Active()
  const location = useLocation()
  const { currentUser } = useAuthContext()

  const isActive = (path: string) => location.pathname.startsWith(path)

  const { error, data } = useQuery<GetMerchantMainBusinessAccountResponse>(GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY, {
    skip: !isBOV3FeatureFlagActive,
  })
  const { operations, pagination, loading, setVariables: setOperationsFilter } = useMerchantOperations()

  const [merchantMainAccountId, setMerchantMainAccountId] = useState<string | undefined>()
  const [isBaActive, setIsBaActive] = useState<boolean>(false)
  const [isBannerAccountInVerificationActive, setIsBannerAccountInVerificationActive] = useState<boolean>(false)
  const isBaUserMustOnboardedFFActive = useIsBaUserMustBeOnboardedActive()
  const [bannerHeight, setBannerHeight] = useState<number>(0)
  const isBaUserManagementFFActive = useIsUserManagementAciveOnBA()
  const isBANewAccountBannerActive = useIsBANewAccountBannerActive()

  useEffect(() => {
    if (
      (currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.PROCESSING ||
        currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.NOT_STARTED) &&
      isBaUserMustOnboardedFFActive
    ) {
      setIsBannerAccountInVerificationActive(true)
      return
    }

    if (
      data?.getMerchantMainBusinessAccount.businessAccount.length &&
      currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.VALIDATED
    ) {
      setIsBaActive(true)
      setMerchantMainAccountId(data.getMerchantMainBusinessAccount.businessAccount[0].ledgerId)
      return
    }
  }, [currentUser, data, isBaUserMustOnboardedFFActive])

  const isBaActiveWithOnBoardingFF = isBaUserMustOnboardedFFActive ? isBaActive : true
  const isOnActiveBannerRoute =
    isActive(ProAccountMenuEntry.to) || isActive(TransfersMenuEntry.to) || isActive(IssuingCardsMenuEntry.to)

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          reason: "An error occurred while fetching the main business account verification",
        },
      })
    }
  }, [error])

  return (
    <BusinessAccountContext.Provider
      value={{
        isBaActive: isBaActiveWithOnBoardingFF,
        bannerAccountInVerification: {
          isActive: isOnActiveBannerRoute && isBannerAccountInVerificationActive,
          bannerHeight,
          setBannerHeight,
        },
        merchantMainAccountId,
        isUserAllowedToCreateTransfers: isBaUserManagementFFActive ? currentUser.isUserAllowedToSendWire : true,
        isUserAllowedToViewAccounts: isBaUserManagementFFActive
          ? currentUser.isUserAllowedToViewBusinessAccounts
          : true,
        isUserAllowedToCreateBeneficiary: isBaUserManagementFFActive ? currentUser.isUserAllowedToAddBeneficiary : true,
        businessAccountState: {
          isNew: isBANewAccountBannerActive && !loading ? operations.length === 0 : false,
          isLoading: loading,
        },
        accountOperations: {
          operations,
          pagination,
          loading,
          setOperationsFilter,
        },
      }}
    >
      <CreateTransferNavigationProvider>{children}</CreateTransferNavigationProvider>
    </BusinessAccountContext.Provider>
  )
}
