import {
  DeprecatedButton,
  DeprecatedFieldError,
  DeprecatedFieldInput,
  DeprecatedLabel,
  deprecatedToaster,
  Typography,
} from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { Dispatch, SetStateAction } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { z } from "zod"
import { DialogContent, Dialog as DialogDefault } from "../../../../00_shared/components/Dialog"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { IbanSchema, StringSchema } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../01_context/businessAccount.context"
import BeneficiariesIcon from "../00_shared/icons/beneficiaries-icon.png"
import { CreateTransferState, useCreateTransferContext } from "../CreateTransfer/CreateTransferContext"
import { useAddBeneficiary } from "./hooks/useAddBeneficiary"

const Dialog = styled(DialogDefault)`
  z-index: -1;
`

const ContentContainer = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
  }
`

const FormContainer = styled.form`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

const FieldInputContainer = styled(DeprecatedFieldInput)`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 768px) {
    justify-content: start;
  }
`

const getBeneficiaryDetailsSchema = (t: CommonTFunction) => {
  return z.object({
    fullName: StringSchema(t),
    iban: IbanSchema(t),
    label: StringSchema(t)
      .trim()
      .optional()
      .or(z.literal(""))
      .transform((value) => (value === "" ? null : value))
      .nullable()
      .default(null),
  })
}

const useAddBeneficiaryForm = () => {
  const { t } = useCommonTranslation()
  const formSchema = getBeneficiaryDetailsSchema(t)
  type SchemaType = z.infer<typeof formSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(formSchema),
  })
}

export const AddBeneficiary = () => {
  return <AddBeneficiaryPageContent />
}

export const AddBeneficiaryInTransferCreation = () => {
  const { setState } = useCreateTransferContext()
  return <AddBeneficiaryPageContent setState={setState} />
}

const AddBeneficiaryPageContent = ({ setState }: { setState?: Dispatch<SetStateAction<CreateTransferState>> }) => {
  const { isBaActive } = useBusinessAccountContext()
  const { t } = useCommonTranslation()
  const navigate = useNavigate()
  const { mutate, loading, is2faModalOpen } = useAddBeneficiary()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { track } = useAmplitude()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useAddBeneficiaryForm()

  const handleClickClose = () => {
    navigate(-1)
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      track("transfer_beneficiary_add_a_beneficiary_validate_form_clicked")

      const result = await mutate(data)

      if (result?.success) {
        setState &&
          setState((prevState) => ({
            ...prevState,
            beneficiaryId: result.beneficiaryId,
            beneficiaryName: data.fullName,
            beneficiaryIban: data.iban,
          }))
        deprecatedToaster.success(t("beneficiary.create.success"))
        track("transfer_beneficiary_add_a_beneficiary_success_view")
        navigate(-1)
      } else {
        throw new Error()
      }
    } catch (error) {
      deprecatedToaster.error(t("beneficiary.create.fail"))
    }
  })

  return (
    <Dialog open={!is2faModalOpen} onOpenChange={handleClickClose}>
      <DialogContent $disableAnimations={true} width="100%" height="100%">
        <ScrollArea width="100%">
          <ContentContainer>
            <img src={BeneficiariesIcon} alt={"beneficiaries"} width={100} />
            <Typography $variant="title-2-bold">{t("beneficiary.create.title")}</Typography>
            <FormContainer onSubmit={onSubmit}>
              <div>
                <DeprecatedLabel>{t("beneficiary.create.field.fullname.label")}</DeprecatedLabel>
                <FieldInputContainer
                  placeholder={t("beneficiary.create.field.fullname.placeholder")}
                  {...register("fullName")}
                  disabled={!isBaActive}
                />
                <DeprecatedFieldError>{errors.fullName?.message || <>&nbsp;</>}</DeprecatedFieldError>
              </div>
              <FlexContainer $direction={isMobile ? "column" : "row"} $justify="space-between" $gap=".5rem">
                <FlexItem $grow={1}>
                  <DeprecatedLabel>{t("beneficiary.create.field.iban.label")}</DeprecatedLabel>
                  <FieldInputContainer
                    autoComplete="off"
                    placeholder={t("beneficiary.create.field.iban.placeholder")}
                    {...register("iban", {
                      onBlur: (e) => {
                        setValue("iban", e.target.value.replace(/\s/g, ""))
                      },
                    })}
                    disabled={!isBaActive}
                  />
                  <DeprecatedFieldError>{errors.iban?.message || <>&nbsp;</>}</DeprecatedFieldError>
                </FlexItem>
              </FlexContainer>
              {/* <div>
                <DeprecatedLabel>{t("transfers.addBeneficiary.emailLabel")}</DeprecatedLabel>
                <FieldInputContainer
                  placeholder={t("transfers.addBeneficiary.emailPlaceholder")}
                  {...register("email")}
                />
                {errors.email && <DeprecatedErrorBlock>{errors.email.message}</DeprecatedErrorBlock>}
              </div> */}
              <FlexContainer $justify="space-between">
                <FlexItem $grow={1}>
                  <DeprecatedLabel>{t("beneficiary.create.field.label.label")}</DeprecatedLabel>
                  <FieldInputContainer
                    placeholder={t("beneficiary.create.field.label.placeholder")}
                    {...register("label")}
                    disabled={!isBaActive}
                  />
                  <DeprecatedFieldError>{errors.label?.message || <>&nbsp;</>}</DeprecatedFieldError>
                </FlexItem>
                {/* <FlexItem>
                  <DeprecatedLabel>{t("transfers.addBeneficiary.categoryLabel")}</DeprecatedLabel>
                  <FieldInputContainer
                    placeholder={t("transfers.addBeneficiary.categoryPlaceholder")}
                    {...register("category")}
                  />
                  {errors.category && <DeprecatedErrorBlock>{errors.category.message}</DeprecatedErrorBlock>}
                </FlexItem> */}
              </FlexContainer>
              <ButtonContainer>
                <DeprecatedButton
                  isLoading={loading}
                  type="submit"
                  $variant="primary"
                  size="medium"
                  disabled={!isBaActive}
                >
                  {t("beneficiary.create.action")}
                </DeprecatedButton>
              </ButtonContainer>
            </FormContainer>
          </ContentContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
