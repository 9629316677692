import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const query = gql`
  query getIsApActivated($merchantId: String!) {
    getIsApActivated(merchantId: $merchantId) {
      ... on GetIsApActivatedOutput {
        isApActivated
        hasCreatedAp
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

interface ApActivationStatus {
  isApActivated: boolean
  hasCreatedAp: boolean
}

export const useGetIsApActivated = (variables: { merchantId: string }, useFakeData = false) => {
  const queryResult = useHeroQuery<ApActivationStatus>({
    gqlQuerySchema: query,
    variables,
    fetchPolicy: "cache-and-network",
  })

  if (useFakeData) {
    return {
      error: null,
      loading: false,
      data: fakeStatus,
    }
  }

  return queryResult
}

const fakeStatus: ApActivationStatus = {
  isApActivated: false,
  hasCreatedAp: false,
}
