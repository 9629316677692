import { BankIcon, ChevronRightIcon, FileIcon, SettingsIcon, Typography, theme } from "@hero/krypton"
import { useCallback } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent } from "../../../../../00_shared/components/Card"
import { useIsBaBankStatementsActive } from "../../../../../00_shared/hooks/useFeatureFlag.hook"
import useMediaQuery from "../../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"

const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

const CardContainer = styled(Card)`
  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }
`

const CardContentContainer = styled(CardContent)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  @media (max-width: 768px) {
    padding: 0;
    gap: 0;
  }
`

const DeprecatedMenuItem = styled(Link)<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  background: ${({ $isSelected }) => ($isSelected ? theme.colors.grey.$100 : "none")};
  border: none;
  width: 100%;
  justify-content: flex-start;
  color: ${theme.colors.grey.$600};
  text-decoration: none;

  &:hover,
  &:focus {
    background: ${theme.colors.grey.$100};
    color: unset;
  }

  @media (max-width: 768px) {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-radius: 0;
    border-bottom: 1px solid ${theme.colors.grey.$200};
  }
`

const MenuText = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${theme.colors.grey.$600};
`

export const AccountDetailsTabs = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 768px)")

  const isPathSelected = useCallback((path: string) => location.pathname.endsWith(path), [location])
  const { currentUser } = useAuthContext()
  const isBankStatementFFActive = useIsBaBankStatementsActive(currentUser.merchantId)

  return (
    <CardContainer>
      <CardContentContainer>
        <DeprecatedMenuItem to="bank-details" $isSelected={isPathSelected("bank-details")}>
          <FlexContainer>
            <BankIcon />
            <MenuText>{t("accounts.details.tabs.bankDetails.text")}</MenuText>
          </FlexContainer>
          {isMobile && <ChevronRightIcon />}
        </DeprecatedMenuItem>
        {isBankStatementFFActive && (
          <DeprecatedMenuItem to="statements" $isSelected={isPathSelected("statements")}>
            <FlexContainer>
              <FileIcon />
              <MenuText>{t("accounts.details.tabs.accountStatements.text")}</MenuText>
            </FlexContainer>
            {isMobile && <ChevronRightIcon />}
          </DeprecatedMenuItem>
        )}
        <DeprecatedMenuItem to="settings" $isSelected={isPathSelected("settings")}>
          <FlexContainer>
            <SettingsIcon />
            <MenuText>{t("accounts.details.tabs.settings.text")}</MenuText>
          </FlexContainer>
          {isMobile && <ChevronRightIcon />}
        </DeprecatedMenuItem>
      </CardContentContainer>
    </CardContainer>
  )
}
