import { AppsIcon, DeprecatedErrorBlock, DeprecatedTab, DeprecatedTabs, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import CenteredLoading from "../../00_shared/components/CenteredLoading"
import { useDashboardTranslation } from "../../01_technical/translations"
import { useAuthContext } from "../../Auth/auth.context"
import { AprContextProvider } from "./AcceleratedPayoutRegulated.context"
import { AcceleratedPayoutDetails } from "./components/AcceleratedPayoutDetails"
import { AcceleratedPayoutsSection } from "./components/AcceleratedPayoutsSection"
import { MarketplaceTransfersOverview } from "./components/MarketplaceTransfersOverview"
import { EmptyScreen } from "./Empty.screen"
import { useGetIsApActivated } from "./hooks/getIsApActivated"
import { apRegulatedBreakpoints as breakpoints } from "./utils"
import { KPIs } from "./components/KPIs"

export const HomeScreen = () => {
  const { t } = useDashboardTranslation()
  const [detailsContext, setDetailsContext] = useState({
    modalOpen: false,
    aprId: "",
  })

  const { currentUser } = useAuthContext()
  const { data, error, loading } = useGetIsApActivated({ merchantId: currentUser.merchantId })

  const dislayedMarkets = [
    {
      id: 0,
      name: t("apRegulated.home.allMarketplaces"),
      logoUrl: null,
    },
    // {
    //   id: 1,
    //   name: "Amazon",
    //   logoUrl: "https://amazon.com/favicon.ico",
    // },
    // {
    //   id: 2,
    //   name: "BackMarket",
    //   logoUrl: "https://backmarket.com/favicon.ico",
    // },
    // {
    //   id: 3,
    //   name: "Cdiscount",
    //   logoUrl: "https://cdiscount.com/favicon.ico",
    // },
  ]

  // state for selected marketplace
  const [selectedMarketplaceId, setSelectedMarketplaceId] = useState(0)

  return (
    <AprContextProvider>
      <PageWrapper>
        <Header>
          <Typography $variant="title-3-inter-bold">{t("apRegulated.home.title")}</Typography>

          <HorizontalOverflow>
            <DeprecatedTabs>
              {dislayedMarkets.map(({ id, name, logoUrl }) => (
                <DeprecatedTab $isSelected={id === selectedMarketplaceId} onClick={() => setSelectedMarketplaceId(id)}>
                  {logoUrl && <LogoMarketplace src={logoUrl} alt={name} />}
                  {!logoUrl && id !== 0 && <NoLogoMarketplaceLogoIcon />}
                  {name}
                </DeprecatedTab>
              ))}
            </DeprecatedTabs>
          </HorizontalOverflow>
        </Header>

        <PageContent>
          <PageContentWrapper>
            {loading && <CenteredLoading />}

            {error && <DeprecatedErrorBlock $margin="0">{error.translatedMessage}</DeprecatedErrorBlock>}

            {data?.hasCreatedAp && (
              <>
                <Wrapper>
                  <KPIs />
                  {/* <AcceleratedPayoutsGraphSection /> */}
                  <Row>
                    <AcceleratedPayoutsColumn>
                      <AcceleratedPayoutsSection setDetailsContext={setDetailsContext} />
                    </AcceleratedPayoutsColumn>
                    <MarketplaceTransfersColumn>
                      <MarketplaceTransfersOverview />
                    </MarketplaceTransfersColumn>
                  </Row>
                </Wrapper>
              </>
            )}

            <AcceleratedPayoutDetails
              detailsContext={detailsContext}
              close={() =>
                setDetailsContext((old) => ({
                  ...old,
                  modalOpen: false,
                }))
              }
            />
          </PageContentWrapper>

          {data && data.hasCreatedAp === false && <EmptyScreen isApActivated={data.isApActivated} />}
        </PageContent>
      </PageWrapper>
    </AprContextProvider>
  )
}

const HorizontalOverflow = styled.div`
  margin-left: -0.75rem;
  overflow-x: auto;
`

const NoLogoMarketplaceLogoIcon = styled(AppsIcon)`
  width: 1.55rem;
  height: 1.55rem;

  margin-right: 0.2rem;

  padding: 0.1rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
`

const LogoMarketplace = styled.img`
  object-fit: cover;
  width: 100%;
  height: 1.5rem;
  max-width: 1.5rem;

  margin-right: 0.2rem;

  padding: 0.1rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
`

const Header = styled.header`
  padding: 1.5rem 3rem 0 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem;
    padding-bottom: 0;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    padding: 1rem;
    padding-bottom: 0;
  }
`

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
`

const PageContent = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const PageContentWrapper = styled.main`
  padding: 1.5rem 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    padding: 0rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;

  & > *:first-child {
    grid-column: span 2;
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    gap: 0rem;
  }
`

const AcceleratedPayoutsColumn = styled.div`
  flex: 2;

  @media (max-width: ${breakpoints.tablet}) {
    flex: 1;
  }
`

const MarketplaceTransfersColumn = styled.div`
  flex: 1;
`
