import styled from "styled-components"
import { AccountHeader } from "./AccountHeader"
import { AccountSidebar } from "./AccountSidebar/AccountSidebar"

const LayoutContainer = styled.div`
  @media (min-width: 768px) {
    height: calc(100vh - 0.125rem);
  }
  min-height: 0;

  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex: 1;
`

const SidebarWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    display: none;
  }
`

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 10rem;
  overflow: auto;
  flex: 1;

  @media (min-width: 768px) {
    padding: 1.5rem 3rem;
  }
`

export const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutContainer>
      <SidebarWrapper>
        <AccountSidebar />
      </SidebarWrapper>

      <div style={{ flex: 1, display: "flex", flexDirection: "column", minWidth: 0 }}>
        <AccountHeader />
        <MainContent id="mainContent">{children}</MainContent>
      </div>
    </LayoutContainer>
  )
}
