import { DeprecatedButton, LinkOutIcon, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import ApiImg from "../../../assets/collection/api.png"
import MagentoImg from "../../../assets/collection/magento.png"
import prestashopImg from "../../../assets/collection/prestashop.png"
import WooImg from "../../../assets/collection/woo.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 1rem;
  flex: 1;
`

const plugins: Array<{
  imgSrc: string
  imgHeight: string
  imgWidth: string
  pluginName: string
  descriptionKey: string
  redirectUrl: string
}> = [
  {
    imgSrc: WooImg,
    pluginName: "WooCommerce",
    descriptionKey: "collection.settings.plugins.woocommerce",
    redirectUrl: "https://docs.heropay.eu/docs/Checkout/plugin/woocommerce",
    imgHeight: "20px",
    imgWidth: "34px",
  },
  {
    imgSrc: MagentoImg,
    pluginName: "Magento",
    descriptionKey: "collection.settings.plugins.magento",
    redirectUrl: "https://docs.heropay.eu/docs/Checkout/plugin/magento",
    imgHeight: "48px",
    imgWidth: "48px",
  },
  {
    imgSrc: prestashopImg,
    pluginName: "PrestaShop",
    descriptionKey: "collection.settings.plugins.prestashop",
    redirectUrl: "https://docs.heropay.eu/docs/Checkout/plugin/prestashop",
    imgHeight: "32px",
    imgWidth: "32px",
  },
  {
    imgSrc: ApiImg,
    pluginName: "API",
    descriptionKey: "collection.settings.plugins.api",
    redirectUrl: "https://docs.heropay.eu/docs/Checkout/api/intro",
    imgHeight: "24px",
    imgWidth: "24px",
  },
]

const PluginContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 1rem;
  max-width: 20rem;
  min-height: 15rem;
`

const PluginImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const PluginHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const PluginDescription = styled(Typography)`
  flex: 1;
`

const PluginAction = styled(DeprecatedButton)`
  width: fit-content;
  padding: 0.25rem;
`

const Plugin = ({ imgSrc, pluginName, descriptionKey, redirectUrl, imgHeight, imgWidth }: (typeof plugins)[0]) => {
  const { t } = useDashboardTranslation()

  return (
    <PluginContainer>
      <PluginHeader>
        <PluginImgContainer>
          <img src={imgSrc} alt={pluginName} width={imgWidth} height={imgHeight} />
        </PluginImgContainer>
        <Typography $variant="body-3-medium">{pluginName}</Typography>
      </PluginHeader>
      <PluginDescription $variant="body-4-regular">{t(descriptionKey)}</PluginDescription>
      <PluginAction onClick={() => window.open(redirectUrl, "_blank")} leftIcon={LinkOutIcon}>
        {t("collection.settings.plugins.install")}
      </PluginAction>
    </PluginContainer>
  )
}

const Title = styled(Typography)`
  margin-bottom: 1rem;
`

const Description = styled(Typography)`
  margin-bottom: 1rem;
`

const PluginsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
`

export const Plugins = () => {
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <Title $variant="title-2-bold">{t("collection.settings.plugins.title")}</Title>
      <Description $variant="body-4-regular">{t("collection.settings.plugins.description")}</Description>
      <PluginsContainer>
        {plugins.map((plugin) => (
          <Plugin key={plugin.pluginName} {...plugin} />
        ))}
      </PluginsContainer>
    </Container>
  )
}
