import { useAuthContext } from "../../../Auth/auth.context"
import { useIsCreditWireTransferActive } from "../../merchant.menu.requests"
import { useMREFinancingEnabled } from "../00_shared/hooks/useMREFinancingEnabled.hook"
import { LegacyCwtListScreen } from "./legacy/LegacyCWT.screen"
import { CwtNotActivatedScreen } from "./views/CWTNotActivated.screen"
import { CwtListScreen } from "./views/List.screen"

export const CreditWireTransferLandingScreen: React.FC = () => {
  const { currentUser } = useAuthContext()
  const { data: isCreditWireTransferActive, loading } = useIsCreditWireTransferActive({
    merchantId: currentUser.merchantId,
  })

  const mreFinancingEnabled = useMREFinancingEnabled()

  if (loading) {
    return null
  }

  if (!mreFinancingEnabled) {
    return <LegacyCwtListScreen />
  } else {
    return !isCreditWireTransferActive?.isActivated ? <CwtNotActivatedScreen /> : <CwtListScreen />
  }
}
