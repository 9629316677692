import { DeprecatedModal, Typography } from "@hero/krypton"
import styled from "styled-components"
import { Button } from "../../../../00_shared/components/krypton2/Button"
import { useDashboardTranslation } from "../../../../01_technical/translations"

const StyledModal = styled(DeprecatedModal)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  max-width: 25rem;
`

const Title = styled(Typography)`
  margin-top: -3.5rem;
  width: calc(100% - 3.5rem);
`

export const SendIncreaseExposureLimitRequestModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void
  isOpen: boolean
}) => {
  const { t } = useDashboardTranslation()
  return (
    <StyledModal onClose={onClose} isOpen={isOpen}>
      <Title as="h2" $variant="title-3-inter-bold">
        {t("creditWireTransfer.increaseExposureLimitRequest.modal.title")}
      </Title>
      <Typography $variant="body-4-regular">
        {t("creditWireTransfer.increaseExposureLimitRequest.modal.subtitle")}
      </Typography>
      <Button onClick={onClose}>{t("creditWireTransfer.increaseExposureLimitRequest.modal.button")}</Button>
    </StyledModal>
  )
}
