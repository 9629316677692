import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedBreadcrumb,
  DeprecatedBreadcrumbItem,
  DeprecatedErrorBlock,
  RefreshIcon,
  Separator,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { useBreadcrumbs } from "../../../../00_shared/hooks/useBreadcrumbs.hook"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { formatAsPercentage } from "../../../../00_shared/utils/percentage.converter"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { APMovementCategory, APStatus } from "../../../../business/enums/AP.enum"
import {
  TransferDetailsHistory,
  TransferDetailsHistoryItem,
} from "../../Balance/TransferDetails/components/TransferDetailsHistory.component"
import { PaymentInfo } from "../../Balance/TransferDetails/components/TransferDetailsPaymentInfo.component"
import { TransferDetailsSection } from "../../Balance/TransferDetails/components/TransferDetailsSection.component"
import { LogoMarketplace } from "../components/LogoMarketplace.component"
import { NoMarketplaceLogoIcon } from "../components/NoMarketplaceLogoIcon"
import { GET_ACCELERATED_PAYOUT, GetAcceleratedPayoutResult } from "./PaymentDetails.requests"
import { getPaymentDemoData } from "./paymentDemoData.utils"

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.grey.$300};
  min-height: 100vh;
`

const DeprecatedHeader = styled.header`
  padding: 1.5rem 3rem 1rem 3rem;
`

const HeaderMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.5rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

const TransferDetailsCard = styled.div`
  margin: 2rem 3rem;
  width: 42.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const TransferDetailsCardContent = styled.div`
  padding: 1.5rem;
`

const TransferDetailsHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
`

const TransferDetailsTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.7rem;
`

const Order = styled(Typography)`
  height: 2rem;
  display: inline-flex;
  padding: 0.125rem 0.5rem;
  margin: 0.5rem;
  border-radius: 8.125rem;
  font-weight: 600;

  white-space: nowrap;

  color: ${({ theme }) => theme.colors.grey.$500};
  background: ${({ theme }) => theme.colors.grey.$100};
`

const ErrorWrapper = styled.div`
  padding: 0rem 1.5rem;
`

const humanFriendlyDate = (date: string, lang: string) =>
  `${toInternationalDate({ date: date, language: lang })} ${DateTime.fromISO(date)
    .setLocale(lang)
    .toLocaleString(DateTime.TIME_24_SIMPLE)}`

const PaymentStatus: React.FC<{ status: APStatus; statusTranslations: Record<APStatus, string> }> = ({
  status,
  statusTranslations,
}) => {
  switch (status) {
    case APStatus.FINISHED:
      return (
        <DeprecatedBadge $variant="primary" icon={<ValidIcon />}>
          {statusTranslations[status]}
        </DeprecatedBadge>
      )
    case APStatus.IN_PROGRESS:
    case APStatus.PARTIALLY_REPAID:
      return (
        <DeprecatedBadge $variant="warning" icon={<RefreshIcon />}>
          {statusTranslations[status]}
        </DeprecatedBadge>
      )
    case APStatus.CANCELED:
      return (
        <DeprecatedBadge $variant="danger" icon={<CloseStrokeIcon />}>
          {statusTranslations[status]}
        </DeprecatedBadge>
      )
    default:
      return null
  }
}

const useGetAcceleratedPayout = (args: { acceleratedPayoutId?: string }, config: { useDemoData: boolean }) => {
  const { data, error, loading } = useHeroQuery<GetAcceleratedPayoutResult>({
    gqlQuerySchema: GET_ACCELERATED_PAYOUT,
    variables: { acceleratedPayoutId: args.acceleratedPayoutId || "" },
    skip: config.useDemoData || !args.acceleratedPayoutId,
  })

  if (config.useDemoData) {
    return {
      loading: false,
      error: undefined,
      data: getPaymentDemoData(args.acceleratedPayoutId || ""),
    }
  }

  return {
    loading,
    error,
    data,
  }
}

export const APPaymentDetails = () => {
  const { t, i18n } = useDashboardTranslation()
  const { id: acceleratedPayoutId } = useParams()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  useEffect(() => {
    if (!acceleratedPayoutId) {
      navigate("/ap/payments?status=all&page=1")
    }
  }, [acceleratedPayoutId, navigate])

  const { loading, error, data } = useGetAcceleratedPayout({ acceleratedPayoutId }, { useDemoData: isDemoMerchant })

  if (loading) {
    return <CenteredLoading height="screen" />
  }

  if (error || !data) {
    return (
      <ErrorWrapper>
        <DeprecatedErrorBlock>{error?.translatedMessage || "Error"}</DeprecatedErrorBlock>
      </ErrorWrapper>
    )
  }

  const {
    account,
    status,
    marketplace,
    id,
    CA,
    periodeDuCAEnd,
    periodeDuCAStart,
    fundedAmount,
    financingRate,
    feesAmount,
    vatAmount,
    createdAt,
    closingDate,
    movements,
    orders,
  } = data

  const caPeriodLabel = [
    DateTime.fromISO(periodeDuCAStart).setLocale(i18n.language).toLocaleString(),
    DateTime.fromISO(periodeDuCAEnd).setLocale(i18n.language).toLocaleString(),
  ].join(" - ")

  const paymentStatusTranslations: Record<APStatus, string> = {
    IN_PROGRESS: t("ap.payment.status.inProgress"),
    PARTIALLY_REPAID: t("ap.payment.status.partiallyRepaid"),
    FINISHED: t("ap.payment.status.finished"),
    CANCELED: t("ap.payment.status.canceled"),
  }

  const movementCategoryTranslations: Record<APMovementCategory, string> = {
    CREDIT_GRANTED: t("ap.payment.category.creditGranted"),
    CREDIT_CANCELED: t("ap.payment.category.creditCanceled"),
    CREDIT_FEES: t("ap.payment.category.creditFees"),
    CREDIT_FEES_CANCELED: t("ap.payment.category.creditFeesCanceled"),
    CREDIT_REPAYMENT: t("ap.payment.category.creditRepayment"),
    CREDIT_REPAYMENT_CANCELED: t("ap.payment.category.creditRepaymentCanceled"),
    CREDIT_VAT: t("ap.payment.category.creditVat"),
  }

  return (
    <Container>
      <DeprecatedHeader>
        <HeaderMain>
          <Typography $variant="body-1-medium">{t("ap.payment.title")}</Typography>
        </HeaderMain>
        <DeprecatedBreadcrumb>
          <DeprecatedBreadcrumbItem<typeof Link> as={Link} to={breadcrumbs[0].to}>
            {t("ap.payment.title")}
          </DeprecatedBreadcrumbItem>
          <DeprecatedBreadcrumbItem<"p"> as={"p"}>{breadcrumbs[1].label}</DeprecatedBreadcrumbItem>
        </DeprecatedBreadcrumb>
      </DeprecatedHeader>
      <Divider />
      <TransferDetailsCard>
        <TransferDetailsCardContent>
          <TransferDetailsHeader $variant="title-1-bold">
            {marketplace.logoUrl ? (
              <LogoMarketplace src={marketplace.logoUrl} alt={marketplace.name} />
            ) : (
              <NoMarketplaceLogoIcon />
            )}
            <TransferDetailsTitle>
              {marketplace.name}
              <PaymentStatus status={status} statusTranslations={paymentStatusTranslations} />
            </TransferDetailsTitle>
          </TransferDetailsHeader>
          <TransferDetailsSection title={t("ap.payment.detailsTitle")} testId="ap-payment-details-informations">
            <PaymentInfo label={t("ap.payment.details.paymentId")} value={id} />
            {CA && <PaymentInfo label={t("ap.payment.details.grossSales")} value={toEuros(CA)} />}
            <PaymentInfo label={t("ap.payment.details.grossSalesPeriod")} value={caPeriodLabel} />
            <PaymentInfo label={t("ap.payment.details.fundedAmount")} value={toEuros(fundedAmount)} />
            {financingRate && (
              <PaymentInfo label={t("ap.payment.details.financingRate")} value={formatAsPercentage(financingRate)} />
            )}
            <PaymentInfo label={t("ap.payment.details.date")} value={humanFriendlyDate(createdAt, i18n.language)} />
            {closingDate && (
              <PaymentInfo
                label={t("ap.payment.details.closingDate")}
                value={humanFriendlyDate(closingDate, i18n.language)}
              />
            )}
            <PaymentInfo label={t("ap.payment.details.fees")} value={toEuros(feesAmount)} />
            <PaymentInfo label={t("ap.payment.details.vat")} value={toEuros(vatAmount)} />
            {account && (
              <PaymentInfo
                label={t("ap.payment.details.account")}
                value={`Account - ${account.name} - ${account.number}`}
              />
            )}
          </TransferDetailsSection>
          {movements.length > 0 && (
            <TransferDetailsSection title={t("ap.payment.movementsTitle")} testId="ap-payment-details-operations">
              <TransferDetailsHistory>
                {movements.map((movement) => (
                  <TransferDetailsHistoryItem
                    key={movement.id}
                    id={movement.id}
                    createdAt={movement.createdAt}
                    amount={movement.amount}
                    label={movementCategoryTranslations[movement.category]}
                    balanceAfter={movement.balanceAfter}
                    isMovement
                  />
                ))}
              </TransferDetailsHistory>
            </TransferDetailsSection>
          )}
          <TransferDetailsSection title={t("ap.payment.ordersTitle")} testId="ap-payment-details-orders">
            {orders.map((order, index) => (
              <Order key={index}>{order.orderNumber}</Order>
            ))}
          </TransferDetailsSection>
        </TransferDetailsCardContent>
      </TransferDetailsCard>
    </Container>
  )
}
