import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { CHANGE_MERCHANT_USER_ROLE } from "../../../Tools/Settings/MerchantUser/listMerchantUser.requests"

type MerchantUserOutput = {
  id: string
}

type UpdateMerchantUserRoleInput = {
  userId: string
  role: string
}

type IUpdateMerchantUserRoleArgs = UpdateMerchantUserRoleInput

export const useUpdateMerchantUserRole = () => {
  const {
    mutationWith2fa: updateMerchantUserRole,
    mutationState: { data: mutationData, error, loading },
    isModalOpen,
  } = use2faChallenge<{ changeMerchantUserRole: MerchantUserOutput }, IUpdateMerchantUserRoleArgs>(
    CHANGE_MERCHANT_USER_ROLE,
  )

  const mutate = async (input: UpdateMerchantUserRoleInput) => {
    const result = await updateMerchantUserRole(input)
    return result.data?.changeMerchantUserRole
  }

  return { mutate, loading, error, mutationData, is2faModalOpen: isModalOpen }
}
