import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"

interface KpisData {
  exposure: number
  cumulativePayments: number
  cumulativeAdvances: number
  firstApCreatedAt: string
}

const query = gql`
  query getKpis($merchantId: String!) {
    getKpis(merchantId: $merchantId) {
      ... on GetKpisOutput {
        exposure
        cumulativePayments
        cumulativeAdvances
        firstApCreatedAt
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

const fakeData: KpisData = {
  exposure: 1987432,
  cumulativePayments: 19876543,
  cumulativeAdvances: 29765432,
  firstApCreatedAt: "2021-01-01T00:00:00Z",
}

export const useGetKpis = ({ merchantId }: { merchantId: string }, useFakeData = false) => {
  const queryResult = useHeroQuery<KpisData>({
    gqlQuerySchema: query,
    variables: { merchantId },
  })

  if (useFakeData) {
    return {
      data: fakeData,
      loading: false,
      error: null,
    }
  }

  return queryResult
}
