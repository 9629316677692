import {
  AddIcon,
  DeprecatedButton,
  DeprecatedTable,
  DeprecatedTBody,
  DeprecatedTHead,
  deprecatedToaster,
  GraphIcon,
  SwitchIcon,
  Typography,
} from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import useLocalStorage from "../../../../../00_shared/hooks/useLocalStorage.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAskProductActivation } from "./CwtNotActivated.request"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  padding: 2rem 3rem;
`

const Title = styled(Typography)`
  max-width: 48rem;
  margin-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  max-width: 48rem;
  margin-bottom: 1rem;
`

const TableStyled = styled(DeprecatedTable)`
  width: fit-content;
`

const Th = styled.th`
  text-transform: none !important;
`

const FooterText = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`

const ActivateButton = styled(DeprecatedButton)`
  width: fit-content;
`

const AlignedTypography = styled(Typography)`
  display: flex;
  align-items: left;

  svg:first-child {
    margin-right: 1rem;
  }
`

export const CwtNotActivatedScreen = () => {
  const { t } = useDashboardTranslation()
  const [heroOnboardingState, setHeroOnboardingState] = useLocalStorage("heroCwtOnboardingState", "not_started")
  const [loadingOnboarding, setLoadingOnboarding] = useState(false)
  const [askProductActivation, { loading }] = useAskProductActivation()

  return (
    <Container>
      <Title $variant="title-2-bold">{t("creditWireTransfer.notActivatedScreen.title")}</Title>
      <Subtitle $color="grey.$400" $variant="body-4-medium">
        {t("creditWireTransfer.notActivatedScreen.subtitle")}
      </Subtitle>

      <TableStyled type="card">
        <DeprecatedTHead>
          <tr>
            <Th>
              <Typography $variant="body-3-regular">
                {t("creditWireTransfer.notActivatedScreen.table.headers.name")}
              </Typography>
            </Th>
          </tr>
        </DeprecatedTHead>
        <DeprecatedTBody>
          <tr>
            <td>
              <AlignedTypography $variant="body-4-regular">
                <SwitchIcon />
                {t("creditWireTransfer.notActivatedScreen.table.rows.1")}
              </AlignedTypography>
            </td>
          </tr>
          <tr>
            <td>
              <AlignedTypography $variant="body-4-regular">
                <GraphIcon />
                {t("creditWireTransfer.notActivatedScreen.table.rows.2")}
              </AlignedTypography>
            </td>
          </tr>
        </DeprecatedTBody>
      </TableStyled>

      <FooterText>
        <Typography $variant="body-4-medium">{t("creditWireTransfer.notActivatedScreen.criteria.title")}</Typography>
        <Typography $color="grey.$400" $variant="body-4-medium">
          {t("creditWireTransfer.notActivatedScreen.criteria.subtitle")}
        </Typography>
      </FooterText>
      <ActivateButton
        disabled={heroOnboardingState === "started" || loading}
        leftIcon={AddIcon}
        onClick={async () => {
          if (loadingOnboarding) return

          setLoadingOnboarding(true)

          if (heroOnboardingState === "not_started") {
            setHeroOnboardingState("started")
          }

          await askProductActivation({})

          deprecatedToaster.success(t("creditWireTransfer.notActivatedScreen.askForProduct.toastSuccess"))

          setLoadingOnboarding(false)
        }}
      >
        {heroOnboardingState === "not_started"
          ? t("creditWireTransfer.notActivatedScreen.button.contact")
          : t("creditWireTransfer.notActivatedScreen.button.contactDone")}
      </ActivateButton>
    </Container>
  )
}
