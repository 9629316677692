import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { INVITE_USER_MERCHANT_MUTATION_NEW_USER_MANAGEMENT } from "../../Merchant.requests"

type MerchantUser = {
  email: string
  firstname: string
  lastname: string
  phone: string
  role: string
}

type Capability = {
  action: string
  target: string
  isAllowed: boolean
}

type MerchantUserOutput = {
  id: string
  firstname: string
  lastname: string
  email: string
  phone: string
}

type CreateBeneficiaryInput = {
  email: string
  firstname: string
  lastname: string
  phone: string
  role: string
  capabilities: Capability[]
}

type InviteMerchantUserArgs = CreateBeneficiaryInput

export const useInviteMerchantUser = () => {
  const {
    mutationWith2fa: inviteMerchantUser,
    mutationState: { data: mutationData, error, loading },
    isModalOpen,
  } = use2faChallenge<{ merchantOwnerInviteUser: MerchantUserOutput }, InviteMerchantUserArgs>(
    INVITE_USER_MERCHANT_MUTATION_NEW_USER_MANAGEMENT,
  )

  const mutate = async (input: MerchantUser & { capabilities: Capability[] }) => {
    const result = await inviteMerchantUser({ ...input })
    return result.data?.merchantOwnerInviteUser
  }

  return { mutate, loading, error, mutationData, is2faModalOpen: isModalOpen }
}
