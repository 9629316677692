import { ApolloError } from "@apollo/client"
import { DeprecatedButton, DeprecatedErrorBlock, DeprecatedField, deprecatedToaster } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { PinSchema } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { Panel } from "../component/Pannel"
import { UPDATE_2FA_PIN, UpdateArgs, UpdateResponse } from "./UpdateUserPin.requests"

const useUpdateUserPinForm = () => {
  const { t } = useCommonTranslation()
  const formSchema = getUpdateUserPinSchema(t)
  type SchemaType = z.infer<typeof formSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(formSchema),
  })
}

const getUpdateUserPinSchema = (t: CommonTFunction) => {
  return z.object({
    pin: PinSchema(t),
  })
}

export const UpdateUserPinForm = () => {
  const { t } = useTranslation()
  const { t: commonT } = useCommonTranslation()
  const { t: dashboardTranslations } = useDashboardTranslation()
  const [error, setError] = useState<string | null>(null)

  const getTranslations = (errorCode: string) =>
    ({
      PIN_EMPTY: commonT("auth.error.pinEmpty"),
      PIN_WEAK: commonT("auth.error.pinWeak"),
      PIN_WRONG_FORMAT: commonT("auth.error.pinWrongFormat"),
      PIN_REQUIRED: commonT("auth.error.pinEmpty"),
      DEVICE_NOT_FOUND: dashboardTranslations("merchant.tools.setting.updateUser.deviceNotFound"),
      UNAUTHORIZED: dashboardTranslations("merchant.tools.setting.updateUser.unauthorized"),
      "2FA_ABORTED": commonT("auth.login.error.2faAborted"),
      "2FA_FAILED": commonT("auth.login.error.2faFailed"),
    })[errorCode] || commonT("queryError.unknownError")

  const {
    mutationWith2fa: updatePin,
    mutationState: { loading },
  } = use2faChallenge<UpdateResponse, UpdateArgs>(UPDATE_2FA_PIN)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useUpdateUserPinForm()

  const onSubmit = handleSubmit(async (data) => {
    try {
      setError(null)
      const response = await updatePin({ pin: data.pin })
      console.log(response)
      if (response.data && response.data.update2FAPin && "errorCode" in response.data.update2FAPin) {
        setError(getTranslations(response.data.update2FAPin.errorCode))
        reset()
        return
      }
      deprecatedToaster.success(t("merchant.tools.setting.resetPin.success"))
      reset()
    } catch (err) {
      if (err === "2FA_ABORTED" || err === "2FA_FAILED") {
        setError(getTranslations(err))
      }
      if (err instanceof ApolloError && err.message === "NOT_FOUND") {
        setError(getTranslations("DEVICE_NOT_FOUND"))
      }
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <Panel>
        <DeprecatedField
          type="password"
          fieldLabel={t("merchant.tools.setting.resetPin.pinField")}
          placeholder={t("merchant.tools.setting.resetPin.placeHolder")}
          {...register("pin")}
          aria-invalid={!!errors.pin}
          errorMessage={errors.pin?.message}
          inputMode="numeric"
        />

        <DeprecatedButton
          data-test-id="update-pin-button"
          disabled={loading || !isValid || isSubmitting}
          isLoading={loading}
          size="medium"
          style={{ alignSelf: "center" }}
          type="submit"
        >
          {t("merchant.tools.setting.resetPin.submit")}
        </DeprecatedButton>

        {error && <DeprecatedErrorBlock>{error}</DeprecatedErrorBlock>}
      </Panel>
    </form>
  )
}
