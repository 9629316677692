import { deprecatedToaster } from "@hero/krypton"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useDownloadWireProof, WireProof } from "../pdf/wireProof"
import { useGetWireProofData } from "./getWireProofData.hook"

const useWireProofDownloadPdf = (transactionId: string) => {
  const [skip, setSkip] = useState(true)
  const getWireProofData = useGetWireProofData(transactionId, skip)

  const wireProofProps = {
    generationDate: formatGenerationDate(getWireProofData.data?.generationDate),
    debtorName: getWireProofData.data?.debtorName || "ERROR_NOT_FOUND",
    debtorIBAN: getWireProofData.data?.debtorIBAN || "ERROR_NOT_FOUND",
    debtorBIC: getWireProofData.data?.debtorBIC || "ERROR_NOT_FOUND",
    creditorName: getWireProofData.data?.creditorName || "ERROR_NOT_FOUND",
    creditorIBAN: getWireProofData.data?.creditorIBAN || "ERROR_NOT_FOUND",
    creditorBIC: getWireProofData.data?.creditorBIC || null,
    createdAtDate: formatCreatedAtDate(getWireProofData.data?.createdAt),
    createdAtHour: formatCreatedAtHour(getWireProofData.data?.createdAt),
    amount: (getWireProofData.data && -getWireProofData.data.amount) || 0,
    reference: getWireProofData.data?.reference || null,
    transactionId: getWireProofData.data?.transactionId || "ERROR_NOT_FOUND",
  }

  const [instance, updateInstance] = useDownloadWireProof({
    ...wireProofProps,
  })

  const handleDownloadWireProofClicked = () => {
    if (!skip) {
      openWireProofPDF()
      return
    }
    setSkip(false)
  }

  const openWireProofPDF = () => {
    const url = instance.url
    if (!url) {
      deprecatedToaster.error("Erreur lors du téléchargement du PDF")
      return
    }
    openDocument(url, `${transactionId}_wire_proof.pdf`)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getWireProofData.data) {
      updateInstance(<WireProof {...wireProofProps} />)
    }
  }, [getWireProofData.data])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getWireProofData.data) {
      openWireProofPDF()
    }
  }, [instance])

  return {
    handleDownloadWireProofClicked,
    loading: getWireProofData.loading,
    error: getWireProofData.error,
  }
}

const formatGenerationDate = (dateString: string | undefined): string => {
  return dateString ? DateTime.fromISO(dateString).toFormat("dd MMMM yyyy") : "ERROR_NOT_FOUND"
}

const formatCreatedAtDate = (dateString: string | undefined): string => {
  return dateString ? DateTime.fromISO(dateString).toFormat("dd/MM/yyyy") : "ERROR_NOT_FOUND"
}

const formatCreatedAtHour = (dateString: string | undefined): string => {
  return dateString ? DateTime.fromISO(dateString).toFormat("HH:mm") : "ERROR_NOT_FOUND"
}

const openDocument = (url: string, filename: string) => {
  const a = document.createElement("a")
  a.href = url
  a.download = filename
  a.target = "_blank"
  a.click()
}

export default useWireProofDownloadPdf
