import { useQuery } from "@apollo/client"
import { DeprecatedButton, deprecatedToaster, Separator, Typography } from "@hero/krypton"
import { useCallback, useEffect, useState } from "react"
import { Spacer } from "../../../00_shared/components/Spacer"
import { useCommonTranslation, useDashboardTranslation } from "../../../01_technical/translations"
import { useBusinessAccountContext } from "../../BusinessAccount/01_context/businessAccount.context"
import { Capability } from "../00_shared/Capability"
import { getTranslationKey } from "../00_shared/i18n"
import { byPreset } from "../00_shared/rules/ProductCapabilities.rules"
import { LIST_CAPABILITIES } from "../Capabilities.requests"
import { ProductCapabilities } from "./UserManagementProductCapabilities"
import { useUpdateCapability } from "./hooks/useUpdateCapability"

type MerchantUserCapability = Omit<Capability, "translationKey"> & {
  actor: string
}

export const SidePanelCapabilitiesStrategy = ({ actor }: { actor: string }) => {
  const { t } = useDashboardTranslation()
  const { t: ComonT } = useCommonTranslation()
  const { merchantMainAccountId } = useBusinessAccountContext()
  const [updates, setUpdates] = useState<MerchantUserCapability[]>([])
  const [capabilityForAccounts, setCapabilityForAccounts] = useState<Capability[]>([])
  const [otherCapabilities, setOtherCapabilities] = useState<Capability[]>([])

  const { mutate: updateCapability } = useUpdateCapability()
  const { loading, error, data, refetch } = useQuery(LIST_CAPABILITIES, {
    variables: { actor },
    fetchPolicy: "network-only",
  })

  const updateCapabilityGroup = (
    capabilities: Capability[],
    updatedCapabilities: MerchantUserCapability[],
    actions: string[],
  ) => {
    return capabilities.map((cap) => {
      if (actions.includes(cap.action)) {
        return {
          ...(capabilities.find((c) => c.action === cap.action && c.target === cap.target) ?? cap),
          ...updatedCapabilities.find((c) => c.action === cap.action && c.target === cap.target),
        }
      }

      return cap
    })
  }

  const updateCapabilitiesAndToaster = async (updatedCapabilities: MerchantUserCapability[]) => {
    const capabilityActions = updatedCapabilities.map((cap) => cap.action)
    const newCapabilities: MerchantUserCapability[] = [...updates, ...updatedCapabilities].filter(
      (cap, index, self) => index === self.findIndex((c) => c.action === cap.action && c.target === cap.target),
    )

    setUpdates(newCapabilities)
    setCapabilityForAccounts(updateCapabilityGroup(capabilityForAccounts, updatedCapabilities, capabilityActions))
    setOtherCapabilities(updateCapabilityGroup(otherCapabilities, updatedCapabilities, capabilityActions))
  }

  useEffect(() => {
    let fetchedCapabilities: Capability[] = []
    if (!loading && !error) {
      fetchedCapabilities = data.listCapabilities.capabilities.map(
        ({ action, target, isAllowed, friendlyTargetName }: Omit<Capability, "translationKey">) => ({
          action,
          target,
          friendlyTargetName:
            target === merchantMainAccountId ? ComonT("onboarding.businessAccount.name") : friendlyTargetName,
          isAllowed,
          translationKey: getTranslationKey({ action, target }),
        }),
      )
    }
    setCapabilityForAccounts(byPreset(["view-account"], fetchedCapabilities))
    /* Display only one capability for transfer */
    setOtherCapabilities(byPreset(["create-card", "add-beneficiary", "send-internal-transfer"], fetchedCapabilities))
  }, [ComonT, data, error, loading, merchantMainAccountId])

  const saveCapabilities = useCallback(async () => {
    deprecatedToaster.promise(
      updateCapability(updates).then(() => {
        setUpdates([])
        refetch()
      }),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserCapabilities.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserCapabilities.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserCapabilities.error"),
      },
      { position: "top-center" },
    )
  }, [refetch, t, updateCapability, updates])

  return (
    <>
      {capabilityForAccounts.length > 0 && (
        <>
          <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.accounts.title")}</Typography>
          <Typography $variant="caption-2" $color="grey.$500">
            {t("userManagement.productStrategy.accounts.description")}
          </Typography>
          <Spacer />

          <ProductCapabilities
            capabilities={capabilityForAccounts.map((cap) => ({
              ...cap,
            }))}
            onChange={async ({ action, target, isAllowed }) => {
              await updateCapabilitiesAndToaster([{ actor, action, target, isAllowed }])
            }}
          />

          <Separator />
          <Spacer />
        </>
      )}

      <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.permissions.title")}</Typography>
      <Spacer />
      <ProductCapabilities
        capabilities={otherCapabilities}
        onChange={async ({ action, target, isAllowed }) => {
          if (action === "send-internal-transfer") {
            // If the user is allowed to send external transfers, they should also be allowed to send internal transfers
            return updateCapabilitiesAndToaster([
              { actor, action, target, isAllowed },
              { actor, action: "send-external-transfer", target, isAllowed },
            ])
          }

          await updateCapabilitiesAndToaster([{ actor, action, target, isAllowed }])
        }}
      />

      <DeprecatedButton onClick={saveCapabilities} disabled={updates.length === 0}>
        {t("userManagement.productStrategy.permissions.save")}
      </DeprecatedButton>
    </>
  )
}
