import {
  DeprecatedBadge,
  DeprecatedErrorBlock,
  DeprecatedSpinner,
  DeprecatedTable,
  DeprecatedTBody,
  DeprecatedTHead,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import i18n from "i18next"
import { DateTime } from "luxon"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import {
  AccountBox,
  CustomDiv,
  CustomPagination,
  CustomTdImg,
  EmptyTable,
  NoLogoUrl,
} from "../BalanceTables.components"
import { BalanceOutgoingFilters } from "./BalanceOutgoingFilters"
import { useGetApBankTransfers } from "./useGetApBankTransfers"

const PAGE_SIZE_LIMIT = 20

export type SearchParams = {
  transaction: "outgoing"
  page: string
  accounts: string[]
  amountUnder?: string
  amountEqual?: string
  amountOver?: string
  dateFrom?: string
  dateTo?: string
}

const Th = styled.th`
  text-transform: uppercase;
`

export const BalanceOutgoingTable = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const searchParams: SearchParams = {
    transaction: "outgoing",
    page: getSearchParam("page"),
    accounts: getSearchParam("accounts") ? getSearchParam("accounts").split(",") : [],
    amountUnder: getSearchParam("amountUnder") || undefined,
    amountEqual: getSearchParam("amountEqual") || undefined,
    amountOver: getSearchParam("amountOver") || undefined,
    dateFrom: getSearchParam("dateFrom") || undefined,
    dateTo: getSearchParam("dateTo") || undefined,
  }

  const { page, accounts, amountUnder, amountEqual, amountOver, dateFrom, dateTo } = searchParams

  const pageNumber = page ? Number(page) : 1

  const { data, error, loading } = useGetApBankTransfers(
    {
      accounts: accounts,
      amountUnder: isNaN(Number(amountUnder)) ? undefined : eurosToCents(Number(amountUnder)),
      amountEqual: isNaN(Number(amountEqual)) ? undefined : eurosToCents(Number(amountEqual)),
      amountOver: isNaN(Number(amountOver)) ? undefined : eurosToCents(Number(amountOver)),
      dateFrom: dateFrom && dateTo ? DateTime.fromISO(dateFrom).toJSDate() : undefined,
      dateTo: dateFrom && dateTo ? DateTime.fromISO(dateTo).toJSDate() : undefined,
    },
    {
      pageNumber,
      pageSize: PAGE_SIZE_LIMIT,
    },
    {
      useDemoData: isDemoMerchant,
    },
  )

  const totalPages = data?.pagination ? data.pagination.totalPage : 1
  const apBankTransfers = data?.apBankTransfers ? data.apBankTransfers : []
  const accountLabels = data?.accountLabels ? data.accountLabels : []

  const columns = [
    t("ap.balance.columns.account"),
    t("ap.balance.columns.amount"),
    t("ap.balance.columns.status"),
    t("ap.balance.columns.date"),
    t("ap.balance.columns.iban"),
    t("ap.balance.columns.type"),
  ]

  return (
    <>
      <BalanceOutgoingFilters
        setSearchParam={setSearchParam}
        searchParams={searchParams}
        accountLabels={accountLabels}
      />

      {error && <DeprecatedErrorBlock $margin="1.5rem 3rem">{error?.translatedMessage}</DeprecatedErrorBlock>}

      {loading && (
        <LoadingWrapper>
          <DeprecatedSpinner />
          <Title>{t("loading")}...</Title>
        </LoadingWrapper>
      )}

      {data && (
        <>
          <DeprecatedTable data-test-id="merchant-ap-balance-outgoing-table">
            <DeprecatedTHead>
              <tr>
                {columns.map((label) => (
                  <Th key={label}>{label}</Th>
                ))}
              </tr>
            </DeprecatedTHead>

            <DeprecatedTBody $clickable={!!apBankTransfers.length}>
              {!apBankTransfers.length && (
                <tr>
                  <EmptyTable colSpan={6}>{t("ap.balance.outgoing.empty")}</EmptyTable>
                </tr>
              )}

              {apBankTransfers.map(({ id, amount, date, iban, wasInstant, logoUrl, accountLabel, accountNumber }) => {
                const transactionDetailsUrl = `/ap/transfers/outgoing/${id}`
                return (
                  <tr key={id}>
                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      <CustomDiv>
                        {logoUrl ? <CustomTdImg src={logoUrl} alt={t("ap.balance.logoAlt")} /> : <NoLogoUrl />}

                        <AccountBox>
                          {/* eslint-disable-next-line i18next/no-literal-string */}
                          <Typography>Account - {titleCase(accountLabel)}</Typography>
                          <Typography $variant="caption-2">{accountNumber}</Typography>
                        </AccountBox>
                      </CustomDiv>
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {toEuros(amount)}
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      <DeprecatedBadge $variant="primary" icon={<ValidIcon />}>
                        {t("ap.balance.outgoing.done")}
                      </DeprecatedBadge>
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {toInternationalDate({ date: date, language: i18n.language })}
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      ... {iban}
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      <DeprecatedBadge $variant="secondary" icon={<ValidIcon />}>
                        {wasInstant ? t("ap.balance.outgoing.type.instant") : t("ap.balance.outgoing.type.normal")}
                      </DeprecatedBadge>
                    </td>
                  </tr>
                )
              })}
            </DeprecatedTBody>
          </DeprecatedTable>

          <CustomPagination
            currentPage={pageNumber}
            totalPages={totalPages}
            onPageChange={(pageNumber) => setSearchParam({ ...searchParams, page: pageNumber.toString() })}
          />
        </>
      )}
    </>
  )
}

const Title = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
`
