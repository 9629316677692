import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const ASK_PRODUCT_ACTIVATION = gql`
  mutation askProductActivationCwt {
    askProductActivationCwt {
      ... on CwtAskProductActivationOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

export const useAskProductActivation = () => {
  return useHeroMutation({
    gqlQuerySchema: ASK_PRODUCT_ACTIVATION,
  })
}
