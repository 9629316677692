import { DeprecatedToggle, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { Capability } from "../00_shared/Capability"

const SRPBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProductCapabilities = ({
  capabilities,
  onChange,
}: {
  capabilities: Capability[]
  onChange: (capability: Capability) => void
}) => {
  const { t } = useDashboardTranslation()

  return (
    <div>
      {capabilities.map((cap, i) => (
        <SRPBlock key={cap.action} style={{ marginBottom: "1em" }}>
          <Typography $variant="body-4-medium">
            {t(cap.translationKey, { target: cap.friendlyTargetName ?? cap.target })}
          </Typography>
          <DeprecatedToggle
            checked={cap.isAllowed}
            aria-labelledby="capability"
            $variant="primary"
            onClick={() => {
              onChange({
                action: cap.action,
                target: cap.target,
                isAllowed: !cap.isAllowed,
                translationKey: cap.translationKey,
              })
            }}
          />
        </SRPBlock>
      ))}
    </div>
  )
}
