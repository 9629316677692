import { DeprecatedSpinner, DeprecatedTable, DeprecatedTBody, DeprecatedTHead, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingCard } from "../../00_shared/business/IssuingCard"
import { IssuingCardBadge } from "../../00_shared/components/IssuingCardBadge"
import { IssuingCardSmallSpending } from "./IssuingCardSmallPending"
import { IssuingCardSmallVisual } from "./IssuingCardSmallVisual"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-top: none;
  display: grid;
  grid-template-rows: minmax(0, max-content) min-content;
  overflow: hidden;
`

const StaticTable = styled(DeprecatedTable)`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: unset;
  border: none;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }

  thead tr th:first-child {
    border-top-left-radius: 1rem;
  }

  thead tr th:last-child {
    border-top-right-radius: 1rem;
  }
`

const CenteringTD = styled.td`
  text-align: center;
`

interface IssuingCardsTableProps {
  cards: IssuingCard[]
  isLoading: boolean
}

export const IssuingCardsTable = ({ cards, isLoading }: IssuingCardsTableProps) => {
  const navigate = useNavigate()
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()

  const goToCard = (cardId: string) => {
    track("select_card_from_card_list", { cardId })
    navigate(`./informations/${cardId}`)
  }

  return (
    <TableContainer>
      <ScrollArea height="100%">
        <StaticTable>
          <DeprecatedTHead>
            <tr>
              <th>{t("issuing.cards.table.issuanceType", { defaultValue: "Type" })}</th>
              <th>{t("issuing.cards.table.cardholder", { defaultValue: "Titulaire" })}</th>
              <th>{t("issuing.cards.table.monthlyUsage", { defaultValue: "Dépensé ce mois-ci" })}</th>
              <th>{t("issuing.cards.table.paymentAccount", { defaultValue: "Compte associé" })}</th>
              <th>{t("issuing.cards.table.status", { defaultValue: "Statut" })}</th>
            </tr>
          </DeprecatedTHead>
          <DeprecatedTBody $clickable={!!cards.length}>
            {isLoading && (
              <tr>
                <CenteringTD colSpan={5}>
                  <DeprecatedSpinner />
                </CenteringTD>
              </tr>
            )}
            {!cards.length && !isLoading && (
              <tr>
                <CenteringTD colSpan={5}>{t("issuing.cards.table.noCard")}</CenteringTD>
              </tr>
            )}
            {cards.map((card) => (
              <tr key={card.id} onClick={() => goToCard(card.id)}>
                <td>
                  <IssuingCardSmallVisual card={card} />
                </td>
                <td>
                  <Typography $variant="body-4-regular">{card.cardholderName}</Typography>
                </td>
                <td>
                  <IssuingCardSmallSpending card={card} />
                </td>
                <td>
                  <Typography $variant="body-4-regular">{card.paymentAccountName}</Typography>
                </td>
                <td>
                  <IssuingCardBadge card={card} />
                </td>
              </tr>
            ))}
          </DeprecatedTBody>
        </StaticTable>
      </ScrollArea>
    </TableContainer>
  )
}
