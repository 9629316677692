import { ContentCopyIcon, deprecatedToaster, theme, Typography } from "@hero/krypton"
import { FC, useMemo } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../../01_technical/translations"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.primary.$200};
  }

  &:disabled {
    cursor: not-allowed;
  }
`

const ValueContainer = styled(Typography).attrs(() => ({ as: "div", $variant: "body-3-regular" }))<{
  $isBlur?: boolean
}>`
  filter: ${(props) => (props.$isBlur ? "blur(6px)" : "none")};
  user-select: ${(props) => props.$isBlur && "none"};
  -webkit-user-select: ${(props) => props.$isBlur && "none"};
  color: ${({ theme }) => theme.colors.grey.$600};
`

export const AccountInformation: FC<{
  label: string
  value: string | JSX.Element
  disabled?: boolean
  canCopy?: boolean
  isBlur?: boolean
  onCopy?: () => void
}> = ({ label, value, canCopy, disabled, isBlur, onCopy }) => {
  const { t } = useCommonTranslation()

  const content = useMemo(
    () => (
      <Container>
        <Typography $variant="label-1" style={{ color: theme.colors.primary.$200, textTransform: "uppercase" }}>
          {label}
        </Typography>
        <Content>
          <Typography as="div" $variant="body-3-regular" style={{ color: theme.colors.grey.$600 }}>
            {value}
          </Typography>
        </Content>
      </Container>
    ),

    [label, value],
  )

  const handleOnCopy = (_: string, result: boolean) => {
    onCopy !== undefined && onCopy()

    if (result) {
      deprecatedToaster.success(`${label} ${t("accounts.copy.success")}`, { autoClose: 1500 })
    } else {
      deprecatedToaster.error(t("accounts.copy.error"), { autoClose: 1500 })
    }
  }

  if (canCopy && typeof value === "string") {
    return (
      <>
        <Container>
          <Typography $variant="label-1" style={{ color: theme.colors.primary.$200, textTransform: "uppercase" }}>
            {label}
          </Typography>
          {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          <Content>
            {/** @ts-expect-error CopyToClipboardType is wrong... */}
            <CopyToClipboard text={value} onCopy={handleOnCopy}>
              <CopyButton disabled={disabled}>
                <ContentCopyIcon />
              </CopyButton>
            </CopyToClipboard>
            <ValueContainer $isBlur={isBlur}>{value}</ValueContainer>
          </Content>
        </Container>
      </>
    )
  }

  return content
}
