import { BadgeVariant, DeprecatedBadge, Typography } from "@hero/krypton"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { DateStyle, toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { MarketplaceReserveFundingInstallmentStatus } from "../../../../business/enums/MarketplaceReserveFundingInstallmentStatus.enum"
import { MarketplaceReserveFundingStatus } from "../../../../business/enums/MarketplaceReserveFundingStatus.enum"
import { GetMrfDetailsResponseData } from "./ActiveMRF.request"

interface ActiveMRFDetailsOverviewCardProps {
  details: GetMrfDetailsResponseData
}

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding: 1.5rem;
  width: 100%;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ProgressInfo = styled(Typography)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.5rem;
  align-items: center;
`

const CardItemRight = styled(Typography)`
  text-align: right;
  font-weight: 500;
`

const MRFAmount = styled.div`
  margin: 1rem 0;
`

const ProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 0.5rem;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black.$1000};
  }
}
`

function getBadgeVariant(status: MarketplaceReserveFundingStatus): BadgeVariant {
  return {
    [MarketplaceReserveFundingStatus.PENDING]: "warning" as BadgeVariant,
    [MarketplaceReserveFundingStatus.IN_PROGRESS]: "primary" as BadgeVariant,
    [MarketplaceReserveFundingStatus.FINISHED]: "secondary" as BadgeVariant,
    [MarketplaceReserveFundingStatus.REFUSED]: "danger" as BadgeVariant,
  }[status]
}

function getMrfStatusTranslation(
  status: MarketplaceReserveFundingStatus,
  t: ReturnType<typeof useDashboardTranslation>["t"],
): string {
  return {
    [MarketplaceReserveFundingStatus.PENDING]: t("mrf.status.pending"),
    [MarketplaceReserveFundingStatus.IN_PROGRESS]: t("mrf.status.inProgress"),
    [MarketplaceReserveFundingStatus.FINISHED]: t("mrf.status.finished"),
    [MarketplaceReserveFundingStatus.REFUSED]: t("mrf.status.refused"),
  }[status]
}

const ActiveMRFDetailsOverviewCard = ({ details }: ActiveMRFDetailsOverviewCardProps) => {
  const { t, i18n } = useDashboardTranslation()

  const finishedInstallments = details.installments.filter(
    ({ status }) => status === MarketplaceReserveFundingInstallmentStatus.FINISHED,
  ).length

  return (
    <>
      <Card>
        <CardHeader>
          <Typography $variant="body-1-medium">{t("mrf.details.financing")}</Typography>
          <DeprecatedBadge $variant={getBadgeVariant(details.status)}>
            {getMrfStatusTranslation(details.status, t)}
          </DeprecatedBadge>
        </CardHeader>
        {details.periodStart && details.periodEnd && (
          <Typography>
            {toInternationalDate({ date: details.periodStart, language: i18n.language, style: DateStyle.TEXTUAL })} -{" "}
            {toInternationalDate({ date: details.periodEnd, language: i18n.language, style: DateStyle.TEXTUAL })}
          </Typography>
        )}

        <MRFAmount>
          <Typography $variant="title-2-bold">{toEuros(details.amount)}</Typography>
        </MRFAmount>

        <ProgressInfo>
          <ProgressBar progress={(finishedInstallments / 12) * 100} />
          <CardItemRight>
            {finishedInstallments} / {t("mrf.details.twelveInstallments")}
          </CardItemRight>
        </ProgressInfo>
      </Card>
    </>
  )
}

export default ActiveMRFDetailsOverviewCard
