import { ArrowRightIcon, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Button } from "../../../../../00_shared/components/krypton2/Button"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { SendIncreaseExposureLimitRequestButton } from "../../../00_shared/components/SendIncreaseExposureLimitResquestButton"
import { useMerchantExposure } from "../../../00_shared/hooks/useMerchantExposure.hook"
import { useMREFinancingEnabled } from "../../../00_shared/hooks/useMREFinancingEnabled.hook"
import { OutstandingPanel } from "../../../Application/components/OutstandingPanel"
import CreditOutstandingIllustration from "./images/credit-outstanding.png"

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 3rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  & > * {
    flex: 1;
  }
`

const LandingProductPresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding-bottom: 0.875rem;
`

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderSubtitle = styled(Typography)`
  max-width: 21.25rem;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  line-height: 1.3rem;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
`

export const LandingHeader: React.FC = () => {
  const mreFinancingEnabled = useMREFinancingEnabled()
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()

  const {
    data: merchantExposureData,
    loading: isMerchantExposureLoading,
    error: merchantExposureError,
  } = useMerchantExposure({
    skip: !mreFinancingEnabled,
  })

  if (!mreFinancingEnabled) return null

  return (
    <Container>
      <LandingProductPresentationContainer>
        <HeaderContainer>
          <HeaderTitleContainer>
            <Typography $variant="title-4-semibold">{t("creditWireTransfer.landingHeader.title")}</Typography>
            <HeaderSubtitle $variant="caption-2" $color="grey.$500">
              {t("creditWireTransfer.landingHeader.subtitle")}
            </HeaderSubtitle>
          </HeaderTitleContainer>
          <img src={CreditOutstandingIllustration} width={70} height={70} alt="Credit outstanding" />
        </HeaderContainer>
        <ActionsContainer>
          <Typography $variant="caption-2" $color="grey.$500">
            {t("creditWireTransfer.landingHeader.actionsTitle")}
          </Typography>
          <Button
            disabled={
              isMerchantExposureLoading ||
              !merchantExposureData ||
              !merchantExposureData.totalAllowedExposure ||
              merchantExposureData.totalInternalDebt > merchantExposureData.totalAllowedExposure
            }
            onClick={() => {
              navigate("/cwt/application")
            }}
            size="small"
            RightIcon={ArrowRightIcon}
          >
            <Typography $variant="caption-1" $color="white">
              {t("creditWireTransfer.landingHeader.actionsButton")}
            </Typography>
          </Button>
        </ActionsContainer>
      </LandingProductPresentationContainer>
      <OutstandingPanel
        variant="full"
        isLoading={isMerchantExposureLoading}
        isError={!!merchantExposureError}
        totalAllowedExposure={merchantExposureData?.totalAllowedExposure ?? 0}
        totalUsedExposure={merchantExposureData?.totalInternalDebt ?? 0}
        title={t("creditWireTransfer.applicationForm.outstandingTitle")}
        subtitle={t("creditWireTransfer.applicationForm.outstandingSubtitle.active")}
        actions={
          <>
            <Typography $variant="caption-2" $color="grey.$500">
              {t("creditWireTransfer.applicationForm.outstandingActionsTitle")}
            </Typography>
            <SendIncreaseExposureLimitRequestButton />
          </>
        }
      />
    </Container>
  )
}
