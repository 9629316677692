import {
  BadgeVariant,
  DeprecatedBadge,
  DeprecatedButton,
  DeprecatedCheckbox,
  DeprecatedChip,
  DeprecatedField,
  DeprecatedFieldSelect,
  DeprecatedFloatingBox,
  DeprecatedRadio,
  Typography,
} from "@hero/krypton"
import { Dispatch, MouseEvent, ReactNode, SetStateAction, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { centsToEuros, eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { Filter } from "../../../00_shared/components/Filter"

const ChipBox = styled.span`
  margin-right: 0.5rem;
`

const FiltersBox = styled.div`
  padding: 1rem 2rem;
  display: flex;
`

const StyledButton = styled(DeprecatedButton)`
  width: 100%;
  min-width: 22rem;
`

type Anchor = "amount" | "date"

type AmountForm = {
  amountOption: "under" | "equal" | "over"
  amount?: number
}

type DateForm = {
  dateFrom?: string
  dateTo?: string
}

export type SearchParams = {
  page: number
  amountUnder?: number
  amountOver?: number
  amountEqual?: number
  dateFrom?: string
  dateTo?: string
  customerEmail?: string
  paymentStatuses: string[]
  paymentTypes: string[]
  kind?: string
  productType?: string
}

const resetAnchors = (...setStates: Dispatch<SetStateAction<HTMLElement | undefined>>[]) => {
  for (const setState of setStates) {
    setState(undefined)
  }
}

type PaymentStatuses = {
  status: string
  icon: ReactNode
  label: string
  $variant: BadgeVariant
}[]

type PaymentTypes = {
  id: string
  label: string
  isBold: boolean
}[]

export const CollectionListingFilters = ({
  setSearchParam,
  searchParams,
  availablePaymentStatuses,
  availablePaymentTypes,
  hideSupportFilter,
}: {
  setSearchParam: (filters: Partial<SearchParams>) => void
  searchParams: SearchParams
  availablePaymentStatuses: PaymentStatuses
  availablePaymentTypes: PaymentTypes
  hideSupportFilter: boolean
}) => {
  const { amountUnder, amountEqual, amountOver, customerEmail, paymentStatuses, paymentTypes, dateFrom, dateTo } =
    searchParams

  const { t } = useDashboardTranslation()
  const [anchorAmountElement, setAnchorAmountElement] = useState<HTMLElement | undefined>(undefined)
  const [anchorDateElement, setAnchorDateElement] = useState<HTMLElement | undefined>(undefined)

  const {
    handleSubmit: handleAmountSubmit,
    register: registerAmount,
    setValue: setAmountFormValue,
  } = useForm<AmountForm>({
    defaultValues: {
      amountOption: amountUnder ? "under" : amountEqual ? "equal" : amountOver ? "over" : undefined,
      amount: centsToEuros(amountUnder ?? amountEqual ?? amountOver ?? 0),
    },
  })
  const {
    handleSubmit: handleDateSubmit,
    register: registerDate,
    setValue: setDateFormValue,
  } = useForm<DateForm>({
    defaultValues: { dateFrom, dateTo },
  })

  const handleAnchors = (event: MouseEvent<HTMLElement>, anchor: Anchor) => {
    resetAnchors(setAnchorAmountElement, setAnchorDateElement)

    if (anchor === "amount") {
      setAnchorAmountElement(anchorAmountElement ? undefined : event.currentTarget)
    }

    if (anchor === "date") {
      setAnchorDateElement(anchorDateElement ? undefined : event.currentTarget)
    }
  }

  // Amount Filter

  const amountChipValue = amountUnder
    ? `< ${toEuros(Number(amountUnder))}`
    : amountEqual
      ? `= ${toEuros(Number(amountEqual))}`
      : amountOver
        ? `> ${toEuros(Number(amountOver))}`
        : ""

  const isAmountOpen = Boolean(anchorAmountElement)

  const submitAmount = ({ amountOption, amount }: AmountForm) => {
    setSearchParam({
      ...searchParams,
      amountUnder: amountOption === "under" && amount ? eurosToCents(Number(amount)) : undefined,
      amountEqual: amountOption === "equal" && amount ? eurosToCents(Number(amount)) : undefined,
      amountOver: amountOption === "over" && amount ? eurosToCents(Number(amount)) : undefined,
    })
    setAnchorAmountElement(undefined)
    setAmountFormValue("amount", amount)
    setAmountFormValue("amountOption", amountOption)
  }

  // Status Filter

  const submitPaymentStatuses = ({ paymentStatuses }: Record<string, string[]>) => {
    setSearchParam({
      ...searchParams,
      paymentStatuses,
    })
  }

  // Type filter

  const submitPaymentTypes = ({ paymentTypes }: Record<string, string[]>) => {
    setSearchParam({
      ...searchParams,
      paymentTypes,
    })
  }

  // Email filter

  const submitCustomerEmail = ({ customerEmail }: Record<string, string>) => {
    setSearchParam({
      ...searchParams,
      customerEmail,
    })
  }

  // ProductType Filter

  const submitProductType = ({ productType }: Record<string, string>) => {
    setSearchParam({
      ...searchParams,
      productType,
    })
  }

  // Date Filter

  const dateChipValue = dateFrom && dateTo ? `Du ${dateFrom} au ${dateTo}` : ""
  const isDateOpen = Boolean(anchorDateElement)

  const submitDate = ({ dateFrom, dateTo }: DateForm) => {
    setSearchParam({
      ...searchParams,
      dateFrom: dateFrom && dateTo ? dateFrom : undefined,
      dateTo: dateFrom && dateTo ? dateTo : undefined,
    })
    setAnchorDateElement(undefined)
    setDateFormValue("dateFrom", dateFrom)
    setDateFormValue("dateTo", dateTo)
  }

  return (
    <FiltersBox>
      {/* Amount Filter */}
      <ChipBox>
        <DeprecatedChip
          label={t("collection.list.filters.chip.amount")}
          onClear={() => {
            submitAmount({ amountOption: "equal" })
          }}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "amount")}
          value={amountChipValue}
        />
      </ChipBox>
      <DeprecatedFloatingBox
        anchorElement={anchorAmountElement}
        onClose={() => setAnchorAmountElement(undefined)}
        isOpen={isAmountOpen}
      >
        <form method="dialog" onSubmit={handleAmountSubmit(submitAmount)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("collection.list.filters.amountLabel")}
          </Typography>
          <DeprecatedFieldSelect {...registerAmount("amountOption")}>
            <option value="under">{t("collection.list.filters.amount.lessThan")}</option>
            <option value="equal">{t("collection.list.filters.amount.equal")}</option>
            <option value="over">{t("collection.list.filters.amount.greaterThan")}</option>
          </DeprecatedFieldSelect>
          <DeprecatedField type="number" placeholder="10" step="0.01" aria-invalid {...registerAmount("amount")} />
          <StyledButton<"button"> type="submit">{t("collection.list.filters.apply")}</StyledButton>
        </form>
      </DeprecatedFloatingBox>

      {/* Status Filter */}
      <Filter
        label={t("collection.list.filters.chip.status")}
        onSubmit={submitPaymentStatuses}
        defaultValues={{ paymentStatuses }}
        getChipValue={({ paymentStatuses }) =>
          paymentStatuses
            .map(
              (status) =>
                availablePaymentStatuses.find(({ status: availableStatus }) => availableStatus === status)?.label,
            )
            .filter(Boolean)
            .join(", ")
        }
      >
        {availablePaymentStatuses.map(({ status, label, icon, $variant }) => (
          <DeprecatedCheckbox
            key={status}
            id={status}
            value={status}
            text={
              <DeprecatedBadge $variant={$variant} icon={icon}>
                {label}
              </DeprecatedBadge>
            }
            name="paymentStatuses"
          />
        ))}
      </Filter>

      {/* Type Filter */}
      <Filter
        label={t("collection.list.filters.chip.type")}
        defaultValues={{ paymentTypes }}
        onSubmit={submitPaymentTypes}
        getChipValue={({ paymentTypes }) =>
          paymentTypes
            .map((type) => availablePaymentTypes.find(({ id }) => id === type)?.label)
            .filter(Boolean)
            .join(", ")
        }
      >
        {availablePaymentTypes.map(({ id, label, isBold }) => {
          return (
            <DeprecatedCheckbox
              key={id}
              id={id}
              value={id}
              text={<Typography $variant={isBold ? "caption-1-bold" : "caption-1"}>{label}</Typography>}
              name="paymentTypes"
            />
          )
        })}
      </Filter>

      {/* Client Filter */}
      <Filter
        label={t("collection.list.filters.chip.customerEmail")}
        onSubmit={submitCustomerEmail}
        defaultValues={{ customerEmail }}
        getChipValue={({ customerEmail }) => customerEmail}
      >
        <DeprecatedField fieldLabel={t("collection.list.filters.customerEmailLabel")} name="customerEmail" />
      </Filter>

      {/* Date Filter */}
      <ChipBox>
        <DeprecatedChip
          label={t("collection.list.filters.chip.date")}
          onClear={() => {
            submitDate({})
          }}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "date")}
          value={dateChipValue}
        />
      </ChipBox>

      <DeprecatedFloatingBox
        anchorElement={anchorDateElement}
        onClose={() => setAnchorDateElement(undefined)}
        isOpen={isDateOpen}
      >
        <form method="dialog" onSubmit={handleDateSubmit(submitDate)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("collection.list.filters.dateLabel")}
          </Typography>
          <DeprecatedField
            type="date"
            fieldLabel={t("collection.list.filters.date.from")}
            {...registerDate("dateFrom")}
          />
          <DeprecatedField type="date" fieldLabel={t("collection.list.filters.date.to")} {...registerDate("dateTo")} />
          <StyledButton<"button"> type="submit">{t("collection.list.filters.apply")}</StyledButton>
        </form>
      </DeprecatedFloatingBox>

      {/* ProductType Filter */}
      {!hideSupportFilter && (
        <Filter
          label={t("collection.list.filters.chip.productType")}
          onSubmit={submitProductType}
          defaultValues={{ productType: searchParams.productType }}
          getChipValue={({ productType }) => {
            if (!productType) return ""

            return t(`collection.list.filters.productType.${productType.toLowerCase()}`)
          }}
        >
          <DeprecatedRadio
            id="LINK"
            value="LINK"
            text={<Typography $variant="caption-1">{t("collection.list.filters.productType.link")}</Typography>}
            name="productType"
          />
          <DeprecatedRadio
            id="CHECKOUT"
            value="CHECKOUT"
            text={<Typography $variant="caption-1">{t("collection.list.filters.productType.checkout")}</Typography>}
            name="productType"
          />
        </Filter>
      )}
    </FiltersBox>
  )
}
