import { ChevronDownIcon, ChevronUpIcon, DeprecatedFieldError, Typography, ValidIcon } from "@hero/krypton"
import * as SelectPrimitive from "@radix-ui/react-select"
import React, { createContext, useContext } from "react"
import styled, { keyframes } from "styled-components"

interface SelectContextProps {
  error: boolean
}

const SelectContext = createContext<SelectContextProps | undefined>(undefined)

const useSelectContext = () => {
  const context = useContext(SelectContext)
  if (!context) {
    throw new Error("useSelectContext must be used within a SelectProvider")
  }
  return context
}

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`

const StyledTrigger = styled(SelectPrimitive.Trigger)<{ $error: boolean }>`
  display: flex;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey.$600};

  border: 1px solid ${({ theme, $error }) => ($error ? theme.colors.danger.$200 : theme.colors.grey.$200)};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;

  padding: 0 0.5rem 0 0.5rem;

  transition: all 0.2s ease;
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey.$400};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.colors.grey.$600};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.grey.$400};
    border: 1px solid ${({ theme, $error }) => ($error ? theme.colors.danger.$200 : theme.colors.grey.$200)};
  }

  &[aria-expanded="true"] {
    outline: 2px solid ${({ theme }) => theme.colors.grey.$400};
    border: 1px solid ${({ theme, $error }) => ($error ? theme.colors.danger.$200 : theme.colors.grey.$200)};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.grey.$200};
    background-color: ${({ theme }) => theme.colors.grey.$200};
  }

  &:disabled::after {
    background-color: ${({ theme }) => theme.colors.grey.$200};
  }

  & > span {
    line-clamp: 1;
  }
`

const StyledContent = styled(SelectPrimitive.Content)`
  position: absolute;
  top: 0.25rem;
  z-index: 150;
  padding: 0.75rem 0;
  width: var(--radix-select-trigger-width);
  max-height: 24rem;
  min-width: 8rem;
  overflow: hidden;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black.$900};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 0.75rem;
  animation: ${slideDown} 0.2s ease-out;

  &[data-state="closed"] {
    animation: ${slideUp} 0.2s ease-in;
  }
`

const StyledItem = styled(SelectPrimitive.Item)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.75rem;
  padding-left: 1.5rem;
  outline: none;
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$200};
    color: ${({ theme }) => theme.colors.grey.$600};
  }
  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`

const StyledLabel = styled(SelectPrimitive.Label)`
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;
`

const StyledSeparator = styled(SelectPrimitive.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.$200};
`

const ScrollButtonTop = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
`

const ScrollButtonBottom = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  width: 100%;
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
`

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  height: 1rem;
  width: 1rem;
`

const StyledDiv = styled.div`
  position: absolute;
  left: 0.2rem;
  line-height: 0.1;
`

const StyledValidIcon = styled(ValidIcon)`
  height: 1.2rem;
  width: 1.2rem;
`

const Select = SelectPrimitive.Root
// const SelectGroup = SelectPrimitive.Group
const SelectValue = SelectPrimitive.Value

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const { error } = useSelectContext()
  return (
    <StyledTrigger ref={ref} className={className} $error={error} {...props}>
      {children}
      <SelectPrimitive.Icon asChild>
        <StyledChevronDownIcon />
      </SelectPrimitive.Icon>
    </StyledTrigger>
  )
})
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <ScrollButtonTop>
    <SelectPrimitive.ScrollUpButton ref={ref} className={className} {...props}>
      <StyledChevronUpIcon />
    </SelectPrimitive.ScrollUpButton>
  </ScrollButtonTop>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <ScrollButtonBottom>
    <SelectPrimitive.ScrollDownButton ref={ref} className={className} {...props}>
      <StyledChevronDownIcon />
    </SelectPrimitive.ScrollDownButton>
  </ScrollButtonBottom>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", onScroll, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <StyledContent ref={ref} className={className} position={position} {...props}>
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport onScroll={onScroll}>{children}</SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </StyledContent>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => <StyledLabel ref={ref} className={className} {...props} />)
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <StyledItem ref={ref} className={className} {...props}>
    <StyledDiv>
      <SelectPrimitive.ItemIndicator>
        <StyledValidIcon />
      </SelectPrimitive.ItemIndicator>
    </StyledDiv>
    <SelectPrimitive.ItemText>
      <Typography $variant="body-4-regular" $color="grey.$600">
        {children}
      </Typography>
    </SelectPrimitive.ItemText>
  </StyledItem>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => <StyledSeparator ref={ref} className={className} {...props} />)
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

type SelectComponentProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
  error?: boolean
  errorMessage?: string
}

const SelectComponent: React.FC<SelectComponentProps> = ({ error = false, errorMessage, children, ...props }) => {
  return (
    <SelectContext.Provider value={{ error }}>
      <Select {...props}>
        {children}
        {errorMessage && <DeprecatedFieldError>{errorMessage}</DeprecatedFieldError>}
      </Select>
    </SelectContext.Provider>
  )
}

export {
  SelectComponent as Select,
  SelectContent,
  // SelectGroup,
  SelectItem,
  // SelectLabel,
  // SelectScrollDownButton,
  // SelectScrollUpButton,
  // SelectSeparator,
  SelectTrigger,
  SelectValue,
}
