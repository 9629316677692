import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { CHANGE_MERCHANT_USER_STATUS } from "../../../Tools/Settings/MerchantUser/listMerchantUser.requests"

type MerchantUserOutput = {
  id: string
}

type UpdateMerchantUserStatusInput = {
  userId: string
  status: string
}

type InviteMerchantUserArgs = UpdateMerchantUserStatusInput

export const useUpdateMerchantUserStatus = () => {
  const {
    mutationWith2fa: updateMerchantUserStatus,
    mutationState: { data: mutationData, error, loading },
    isModalOpen,
  } = use2faChallenge<{ changeMerchantUserStatus: MerchantUserOutput }, InviteMerchantUserArgs>(
    CHANGE_MERCHANT_USER_STATUS,
  )

  const mutate = async (input: InviteMerchantUserArgs) => {
    const result = await updateMerchantUserStatus(input)
    return result.data?.changeMerchantUserStatus
  }

  return { mutate, loading, error, mutationData, is2faModalOpen: isModalOpen }
}
