import { useMutation, useQuery } from "@apollo/client"
import { DeprecatedButton, DeprecatedHeader, Typography, deprecatedToaster } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useDelayRedirect } from "../../00_shared/hooks/useDelayRedirect.hook"
import { useQuerySearchParams } from "../../00_shared/hooks/useQuerySearchParams.hook"
import { HeroErrorDisplayer, unwrapGraphQLResponse } from "../../01_technical/requesting/DEPRECATED_graphql.errors"
import { HEADER_COLORS, SWAN_AUTH_URL, SWAN_CLIENT_ID, SWAN_SETUP_REDIRECT_URL } from "../../env_variables"
import {
  GET_GET_SETUP_STANDING_ORDER_LINK,
  GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE,
  GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE_SUCCESS,
  GET_SWAN_ACCOUNT,
  GET_SWAN_ACCOUNT_RESPONSE,
  GET_SWAN_ACCOUNT_RESPONSE_SUCCESS,
  RECEIVE_STANDING_ORDER_CONFIRMATION,
  RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS,
  RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE,
  RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS,
  SCHEDULE_STANDING_ORDER,
  SCHEDULE_STANDING_ORDER_ARGS,
  SCHEDULE_STANDING_ORDER_ERROR_RESPONSE,
  SCHEDULE_STANDING_ORDER_RESPONSE,
  SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS,
  SWAN_AUTH_ARGS,
  SWAN_AUTH_MUTATION,
  SWAN_AUTH_MUTATION_RESPONSE,
  SWAN_AUTH_MUTATION_RESPONSE_SUCCESS,
  SWAN_COMPLETE_SETUP_LINK_MUTATION,
  SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE,
  SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE_SUCCESS,
  isReceiveStandingOrderConfirmationSuccess,
  isScheduleStandingOrderSuccess,
} from "./SetupStandingOrder.request"
import { SetupStandingOrderInfoScreen } from "./components/SetupStandingOrderInfoScreen"

const CustomHeader = styled(DeprecatedHeader)`
  min-height: auto;
  border-bottom: 0;
  &::before {
    margin-bottom: 0;
  }
`

const EmptyBox = styled.div`
  height: 43.3rem;
  margin: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftChild = styled.div`
  margin-left: 6.25rem;
`

const StyledTypography = styled(Typography)`
  margin-top: 2.25rem;
  width: 23.75rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonBox = styled.div`
  margin-top: 2.25rem;
`

const startExternalAuth = (token: string) => {
  window.location.href = `${SWAN_AUTH_URL}?response_type=code&client_id=${SWAN_CLIENT_ID}&redirect_uri=${SWAN_SETUP_REDIRECT_URL}&scope=openid%20offline&state=${token}`
}

const startScheduleStandingOrder = (consentUrl: string) => {
  window.location.href = consentUrl
}

const useSwanAuthMutation = () => {
  const [swanAuth, { loading, error, data }] = useMutation<SWAN_AUTH_MUTATION_RESPONSE, SWAN_AUTH_ARGS>(
    SWAN_AUTH_MUTATION,
  )

  const { error: swanAuthFunctionalError, data: swauAuthResult } =
    unwrapGraphQLResponse<SWAN_AUTH_MUTATION_RESPONSE_SUCCESS>(data?.merchantSetupSwanAuth)

  return {
    swanAuth,
    swanAuthLoading: loading,
    swanAuthError: error,
    swanAuthFunctionalError,
    swauAuthResult,
  }
}

const useCompleteSwanStandingOrderLinkMutation = () => {
  const [completeSwanStandingOrderLink, { loading, error, data }] =
    useMutation<SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE>(SWAN_COMPLETE_SETUP_LINK_MUTATION)

  const { error: completeSwanStandingOrderLinkFunctionalError } =
    unwrapGraphQLResponse<SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE_SUCCESS>(
      data?.merchantCompleteSwanSetupStandingOrderLink,
    )

  return {
    completeSwanStandingOrderLink,
    completeSwanStandingOrderLinkLoading: loading,
    completeSwanStandingOrderLinkError: error,
    completeSwanStandingOrderLinkFunctionalError,
  }
}

const useGetSwanAccount = (skip: boolean) => {
  const { data, loading, error } = useQuery<GET_SWAN_ACCOUNT_RESPONSE>(GET_SWAN_ACCOUNT, {
    fetchPolicy: "cache-and-network",
    skip,
  })

  const { error: getSwanAccountFunctionalError, data: swanAccount } =
    unwrapGraphQLResponse<GET_SWAN_ACCOUNT_RESPONSE_SUCCESS>(data?.merchantGetMerchantSwanAccount)

  return {
    swanAccount,
    getSwanAccountLoading: loading,
    getSwanAccountTechnicalError: error,
    getSwanAccountFunctionalError,
  }
}

const useGetSwanSetupStandingOrderLink = (skip: boolean) => {
  const { data, loading, error } = useQuery<GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE>(
    GET_GET_SETUP_STANDING_ORDER_LINK,
    {
      fetchPolicy: "cache-and-network",
      skip,
    },
  )

  const { error: getSetupStandingOrderLinkFunctionalError, data: setupStandingOrderLink } =
    unwrapGraphQLResponse<GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE_SUCCESS>(data?.getSwanSetupStandingOrderLink)

  return {
    setupStandingOrderLink,
    getSetupStandingOrderLinkLoading: loading,
    getSetupStandingOrderLinkTechnicalError: error,
    getSetupStandingOrderLinkFunctionalError,
  }
}

const useSchedulStandingOrder = () => {
  const [scheduleStandingOrder, { loading, error, data }] = useMutation<
    SCHEDULE_STANDING_ORDER_RESPONSE,
    SCHEDULE_STANDING_ORDER_ARGS
  >(SCHEDULE_STANDING_ORDER)

  const { error: functionalError, data: standingOrderResult } = unwrapGraphQLResponse<
    SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE
  >(data?.merchantScheduleStandingOrder)

  return {
    scheduleStandingOrder,
    scheduleStandingOrderLoading: loading,
    scheduleStandingOrderError: error,
    scheduleStandingOrderFunctionalError: functionalError,
    standingOrderResult,
  }
}

const useReceiveStandingOrderConfirmation = () => {
  const [receiveStandingOrderConfirmation, { loading, error, data }] = useMutation<
    RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE,
    RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS
  >(RECEIVE_STANDING_ORDER_CONFIRMATION)

  const { error: functionalError, data: standingOrderResult } =
    unwrapGraphQLResponse<RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS>(
      data?.merchantReceiveStandingOrderConfirmation,
    )

  return {
    receiveStandingOrderConfirmation,
    receiveStandingOrderConfirmationLoading: loading,
    receiveStandingOrderConfirmationError: error,
    receiveStandingOrderConfirmationFunctionalError: functionalError,
    standingOrderResult,
  }
}

export const SetupStandingOrder = () => {
  const { getSearchParam } = useQuerySearchParams<{ token: string; code: string; resourceId: string; state: string }>()

  const state = getSearchParam("state")

  const token = getSearchParam("token")

  const localStorageToken = localStorage.getItem("token")

  const [isTokenSaved, setIsTokenSaved] = useState(false)

  const code = getSearchParam("code")

  const standingOrderSwanId = getSearchParam("resourceId")

  const { swanAuth, swanAuthLoading, swanAuthError, swauAuthResult, swanAuthFunctionalError } = useSwanAuthMutation()

  const { swanAccount, getSwanAccountLoading } = useGetSwanAccount(!isTokenSaved)

  const { setupStandingOrderLink, getSetupStandingOrderLinkLoading } = useGetSwanSetupStandingOrderLink(!isTokenSaved)

  const { redirecting, startRedirect } = useDelayRedirect("https://google.com", 3000)

  const { completeSwanStandingOrderLink } = useCompleteSwanStandingOrderLinkMutation()

  const merchantId = swanAccount?.merchantId

  const navigate = useNavigate()

  const {
    scheduleStandingOrder,
    scheduleStandingOrderError,
    standingOrderResult,
    scheduleStandingOrderFunctionalError,
    scheduleStandingOrderLoading,
  } = useSchedulStandingOrder()

  const {
    receiveStandingOrderConfirmation,
    receiveStandingOrderConfirmationError,
    receiveStandingOrderConfirmationFunctionalError,
    receiveStandingOrderConfirmationLoading,
    standingOrderResult: receiveStandingOrderConfirmationResult,
  } = useReceiveStandingOrderConfirmation()

  useEffect(() => {
    if (token && !isTokenSaved) {
      localStorage.setItem("token", token)
      setIsTokenSaved(true)

      return
    }

    const localStorageToken = localStorage.getItem("token")

    if (state && localStorageToken !== state) {
      deprecatedToaster.error("Invalid state")
      navigate("/login")
    } else if (localStorageToken) {
      setIsTokenSaved(true)
    } else {
      navigate("/login")
    }
  }, [token, isTokenSaved, state, navigate])

  useEffect(() => {
    if (code) {
      swanAuth({ variables: { code } })
    }
  }, [code, swanAuth])

  useEffect(() => {
    if (!swauAuthResult || !merchantId) {
      return
    }

    if (swauAuthResult.success) {
      scheduleStandingOrder({ variables: { merchantId } })
    }
  }, [swauAuthResult, scheduleStandingOrder, merchantId])

  useEffect(() => {
    if (!standingOrderResult) {
      return
    }

    if (!isScheduleStandingOrderSuccess(standingOrderResult)) {
      deprecatedToaster.error(standingOrderResult.reason)
    }
  }, [standingOrderResult])

  useEffect(() => {
    if (!standingOrderSwanId) {
      return
    }

    receiveStandingOrderConfirmation({ variables: { standingOrderId: standingOrderSwanId } })
    completeSwanStandingOrderLink()
  }, [receiveStandingOrderConfirmation, completeSwanStandingOrderLink, standingOrderSwanId])

  useEffect(() => {
    if (!receiveStandingOrderConfirmationResult) {
      return
    }

    if (isReceiveStandingOrderConfirmationSuccess(receiveStandingOrderConfirmationResult)) {
      startRedirect()
    }
  }, [receiveStandingOrderConfirmationResult, startRedirect])

  const finalConsentUrl =
    standingOrderResult && isScheduleStandingOrderSuccess(standingOrderResult) ? standingOrderResult.consentUrl : null

  if (getSwanAccountLoading || !swanAccount) {
    return null
  }

  if (getSetupStandingOrderLinkLoading || !setupStandingOrderLink) {
    return null
  }

  if (swanAccount.hasSwanStandingOrderEnabled) {
    return <SetupStandingOrderInfoScreen message="Standing order is already activated." />
  }

  if (!swanAccount.hasSwanAcccountFullyOnboarded) {
    return <SetupStandingOrderInfoScreen message="Please onboard your swan account first." />
  }

  if (setupStandingOrderLink.status === "COMPLETED") {
    return <SetupStandingOrderInfoScreen message="Standing order link has already been used." />
  }

  return (
    <>
      <CustomHeader $colors={HEADER_COLORS} />
      <EmptyBox>
        <LeftChild>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Typography as="h1" $variant="title-2-bold">
            Coruscant - Standing Order
          </Typography>
          <StyledTypography as="h2" $variant="body-4-regular">
            {receiveStandingOrderConfirmationResult || receiveStandingOrderConfirmationLoading
              ? ""
              : finalConsentUrl
                ? "Congratulations, you are now authenticated. One more step : activate the daily transfer of your account balance to the Coruscant account"
                : "This operation will activate the daily transfer of your account balance to the Coruscant account."}
          </StyledTypography>
          <StyledTypography as="h2" $variant="body-4-regular">
            {receiveStandingOrderConfirmationResult || receiveStandingOrderConfirmationLoading
              ? "Everything is set ! You are being redirected…"
              : finalConsentUrl
                ? "You will receive a last SMS to authorize this standing order. Please click on the following button to receive it"
                : "Please click on the following button to receive an authentication SMS from our partner"}
          </StyledTypography>
          {!(receiveStandingOrderConfirmationResult || receiveStandingOrderConfirmationLoading) && !finalConsentUrl && (
            // eslint-disable-next-line i18next/no-literal-string
            <StyledTypography as="h2" $variant="body-4-regular">
              Time needed : 5 minutes to complete the operation
            </StyledTypography>
          )}
          <ButtonBox>
            <DeprecatedButton<"a">
              onClick={() =>
                receiveStandingOrderConfirmationResult
                  ? navigate(0)
                  : finalConsentUrl
                    ? startScheduleStandingOrder(finalConsentUrl)
                    : startExternalAuth(token || state || localStorageToken || "")
              }
              isLoading={
                swanAuthLoading ||
                scheduleStandingOrderLoading ||
                receiveStandingOrderConfirmationLoading ||
                redirecting
              }
              size="medium"
              as="a"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {receiveStandingOrderConfirmationResult || receiveStandingOrderConfirmationLoading
                ? "3. Redirecting…"
                : finalConsentUrl
                  ? "2. Activate the standing order"
                  : "1. Connect to Partner"}
            </DeprecatedButton>
          </ButtonBox>
          <HeroErrorDisplayer
            err={scheduleStandingOrderError}
            data={scheduleStandingOrderFunctionalError}
            messages={{ UNAUTHORIZED: "You're not allowed to access this functionality" }}
          />

          <HeroErrorDisplayer
            err={swanAuthError}
            data={swanAuthFunctionalError}
            messages={{ UNAUTHORIZED: "You're not allowed to access this functionality" }}
          />

          <HeroErrorDisplayer
            err={receiveStandingOrderConfirmationError}
            data={receiveStandingOrderConfirmationFunctionalError}
            messages={{
              UNAUTHORIZED: "You're not allowed to access this functionality",
              NOT_FOUND: "The standing order was not found",
              INTERNAL_SERVER_ERROR: (message) => message.error?.message ?? "An error occured",
            }}
          />
        </LeftChild>
      </EmptyBox>
    </>
  )
}
