import { DeprecatedBadge, DeprecatedButton, DeprecatedHeader, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useLocalizedImage } from "../../../../01_technical/localizedImages"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { HEADER_COLORS } from "../../../../env_variables"

const CustomHeader = styled(DeprecatedHeader)`
  min-height: auto;
  border-bottom: 0;

  &::before {
    margin-bottom: 0;
  }
`

const BadgeBox = styled.div`
  margin-bottom: 0.875rem;
`

const EmptyBox = styled.div`
  height: 43.3rem;
  margin: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftChild = styled.div`
  margin-left: 6.25rem;
`

const StyledTypography = styled(Typography)`
  margin-top: 0.25rem;
  width: 23.75rem;
  height: 9rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Sentence = styled.span``

const ButtonBox = styled.div`
  margin-top: 2rem;
`

const RightChild = styled.div`
  margin-right: 6.25rem;
`

const Image = styled.img``

export const EmptyCockpit = () => {
  const { t } = useDashboardTranslation()
  const sideImage = useLocalizedImage("checkoutAndCash")
  return (
    <>
      <CustomHeader data-test-id="Checkout_cash_empty_cokpit" $colors={HEADER_COLORS}></CustomHeader>
      <EmptyBox>
        <LeftChild>
          <BadgeBox>
            <DeprecatedBadge $variant="marketing">{t("checkoutAndCash.cockpit.emptyCockpit.badge")}</DeprecatedBadge>
          </BadgeBox>
          <Typography as="h1" $variant="title-2-bold">
            {t("checkoutAndCash.cockpit.emptyCockpit.title")}
          </Typography>
          <StyledTypography as="h2" $variant="body-4-regular">
            <Sentence>{t("checkoutAndCash.cockpit.emptyCockpit.sentence1")}</Sentence>
            <Sentence>{t("checkoutAndCash.cockpit.emptyCockpit.sentence2")}</Sentence>
          </StyledTypography>
          <ButtonBox>
            <DeprecatedButton<"a">
              isLoading={false}
              size="medium"
              as="a"
              href="https://cal.mixmax.com/pierremarion/herocheckoutcash"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {t("checkoutAndCash.cockpit.emptyCockpit.button")}
            </DeprecatedButton>
          </ButtonBox>
        </LeftChild>
        <RightChild>
          <Image src={sideImage} alt="Checkout and Cash" />
        </RightChild>
      </EmptyBox>
    </>
  )
}
