import { useDashboardTranslation } from "../../../../../01_technical/translations"
import french_pls_application_preview from "../../assets/french_pls_application_preview.png"
import english_pls_application_preview from "../../assets/english_pls_application_preview.png"
import italian_pls_application_preview from "../../assets/italian_pls_application_preview.png"
import spanish_pls_application_preview from "../../assets/spanish_pls_application_preview.png"
export const CWTApplicationPreview: React.FC = () => {
  const { i18n } = useDashboardTranslation()

  const getSrc = () => {
    switch (i18n.language) {
      case "fr":
        return french_pls_application_preview
      case "it":
        return italian_pls_application_preview
      case "es":
        return spanish_pls_application_preview
      case "en":
      case "de":
      default:
        return english_pls_application_preview
    }
  }

  return (
    <div style={{ width: "50%", height: "100%", overflow: "hidden", position: "relative" }}>
      <img
        src={getSrc()}
        style={{
          position: "absolute",
          height: "30.8125rem",
          overflow: "hidden",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        alt="pls_application_preview"
      />
    </div>
  )
}
