import { DeprecatedToggle, Separator, theme, Typography } from "@hero/krypton"
import React, { useState } from "react"
import styled from "styled-components"
import { Card, CardHeader as CardHeaderDefault } from "./Card"

const CollapsibleWrapper = styled(Card)`
  position: relative;
`

const CardHeader = styled(CardHeaderDefault)`
  padding: 0.5rem 0.75rem;
`

const CollapsibleTogglerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CollapsibleTogglerTitle = styled(Typography)`
  flex: 1;
`

const CollapsibleContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  background-color: ${theme.colors.grey.$100};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  transition:
    max-height 0.4s ease,
    margin-top 0.4s ease,
    opacity 0.4s ease;
`

const SeparatorWrapper = styled(Separator)<{ isOpen: boolean }>`
  width: 100%;
  position: absolute;
  left: 0;
  border-color: ${theme.colors.grey.$200};
  max-height: ${({ isOpen }) => (isOpen ? "1px" : "0")};
  border-width: ${({ isOpen }) => (isOpen ? "1px" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition:
    opacity 0.4s ease,
    max-height 0.4s ease,
    margin-top 0.4s ease,
    border-width 0.4s ease;
`

const ContentWrapper = styled.div`
  margin: 1.25rem 0.75rem;
  background-color: ${theme.colors.white};
  border-radius: 0.5rem;
`

export const CollapsibleToggler = ({
  title,
  disabled,
  onToggle,
  children,
}: {
  title: string
  disabled?: boolean
  onToggle?: (isChecked: boolean) => void
  children: React.ReactNode
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleToggle = () => {
    setIsChecked(!isChecked)
    onToggle?.(!isChecked)
  }

  return (
    <CollapsibleWrapper>
      <CardHeader>
        <CollapsibleTogglerHeader>
          <CollapsibleTogglerTitle $variant="body-4-medium">{title}</CollapsibleTogglerTitle>
          <DeprecatedToggle checked={isChecked} onClick={handleToggle} disabled={!!disabled} aria-label="toggle" />
        </CollapsibleTogglerHeader>
      </CardHeader>
      <CollapsibleContent isOpen={isChecked}>
        <SeparatorWrapper isOpen={isChecked} />
        <ContentWrapper>{children}</ContentWrapper>
      </CollapsibleContent>
    </CollapsibleWrapper>
  )
}
