import { DeprecatedModal as DefaultModal, DeprecatedButton, deprecatedToaster, Typography } from "@hero/krypton"
import { useEffect } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useIssuingCardInformationHook } from "../../IssuingCardList/IssuingCardInformations.hook"
import { useTerminateCard } from "../hooks/useTerminateCard.hook"

const DeprecatedModal = styled(DefaultModal)`
  width: unset;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

interface TerminateCardModalProps {
  cardId: string
  isModalOpen: boolean
  onClose: () => void
}

export const TerminateCardModal = ({ cardId, isModalOpen, onClose }: TerminateCardModalProps) => {
  const { t } = useDashboardTranslation()
  const { terminateCard, loading: deactivating, error: deactivateError } = useTerminateCard()
  const { refetch } = useIssuingCardInformationHook(cardId)

  const handleDeactivateCard = async () => {
    if (cardId) {
      const terminated = await terminateCard(cardId)
      refetch()
      if (terminated?.success) {
        deprecatedToaster.success(t("issuing.cards.lostCardModal.success"))
      }
      onClose()
    }
  }

  useEffect(() => {
    if (deactivateError) {
      deprecatedToaster.error(deactivateError.message)
    }
  }, [deactivateError])

  return (
    <DeprecatedModal isOpen={isModalOpen} onClose={() => onClose()}>
      <Container>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.lostCardModal.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.lostCardModal.description")}</Typography>
        </div>
        <DeprecatedButton size="medium" onClick={handleDeactivateCard} $variant="danger" isLoading={deactivating}>
          {t("issuing.cards.lostCardModal.confirm")}
        </DeprecatedButton>
      </Container>
    </DeprecatedModal>
  )
}
