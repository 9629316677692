import { gql, useQuery } from "@apollo/client"
import { DeprecatedField, DeprecatedSpinner, Typography } from "@hero/krypton"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { HeroErrorDisplayer } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"

const GET_BANK_ACCOUNT = gql`
  query {
    defaultBankAccount {
      iban
      name
      bicswift
    }
  }
`

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 5em;
  margin: 0 0rem;
`

const Slot = styled.div`
  width: 300px;
`

const Footer = styled.div`
  margin: 2em 1em;
`

const IbanChangeBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 8px;
  padding: 1.25rem;
  height: 18.25rem;
`

const TitleBox = styled.div`
  margin-bottom: 1.25rem;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
`

const TextItem = styled.div`
  margin-left: 0.75rem;
`

const Circle = styled.span`
  height: 2rem;
  width: 2rem;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey.$600};
  justify-content: center;
  display: flex;
  align-items: center;
`

const InlineBlock = styled.div`
  display: inline-flex;
  gap: 0.4rem;
`

export const UpdateBankAccount = () => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<{
    defaultBankAccount: {
      iban: string
      name: string
      bicswift: string | null
    }
  }>(GET_BANK_ACCOUNT, {
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <DeprecatedSpinner />
  }

  if (!data) {
    return <div>{t("merchant.tools.setting.updateBankAccount.errorNoData")}</div>
  }

  return (
    <>
      <Panel>
        {(
          [
            { field: "name", fieldLabel: t("merchant.tools.setting.updateBankAccount.name.label") },
            { field: "iban", fieldLabel: t("merchant.tools.setting.updateBankAccount.iban.label") },
            { field: "bicswift", fieldLabel: t("merchant.tools.setting.updateBankAccount.bicswift.label") },
          ] as const
        ).map(({ field, fieldLabel }) => (
          <Slot key={field}>
            <DeprecatedField disabled={true} fieldLabel={fieldLabel} value={data.defaultBankAccount[field]} />
          </Slot>
        ))}
      </Panel>
      <IbanChangeBox>
        <TitleBox>
          <Typography $variant="body-3-medium">
            {t("merchant.tools.setting.updateBankAccount.changeRequest.title")}
          </Typography>
        </TitleBox>
        {(
          [
            {
              number: "1",
              subtitle: t("merchant.tools.setting.updateBankAccount.changeRequest.subtitle1"),
              sentence: t("merchant.tools.setting.updateBankAccount.changeRequest.sentence1"),
              sentencebis: t("merchant.tools.setting.updateBankAccount.changeRequest.sentence1bis"),
            },
            {
              number: "2",
              subtitle: t("merchant.tools.setting.updateBankAccount.changeRequest.subtitle2"),
              sentence: t("merchant.tools.setting.updateBankAccount.changeRequest.sentence2"),
              sentencebis: null,
            },
            {
              number: "3",
              subtitle: t("merchant.tools.setting.updateBankAccount.changeRequest.subtitle3"),
              sentence: t("merchant.tools.setting.updateBankAccount.changeRequest.sentence3"),
              sentencebis: null,
            },
          ] as const
        ).map(({ number, subtitle, sentence, sentencebis }) => (
          <Item key={number}>
            <Circle>{number}</Circle>
            <TextItem>
              <Typography $variant="body-4-medium">{subtitle}</Typography>
              {sentencebis ? (
                <>
                  <InlineBlock>
                    <Typography $variant="body-4-regular">{sentence}</Typography>
                    <span>
                      <Typography
                        as="a"
                        $variant="body-4-link"
                        href={`mailto:${t("merchant.tools.setting.updateBankAccount.changeRequest.email")}`}
                      >
                        {t("merchant.tools.setting.updateBankAccount.changeRequest.email")}
                      </Typography>
                    </span>
                  </InlineBlock>
                  <Typography $variant="body-4-regular">{sentencebis}</Typography>
                </>
              ) : (
                <Typography $variant="body-4-regular">{sentence}</Typography>
              )}
            </TextItem>
          </Item>
        ))}
      </IbanChangeBox>
      <Footer>
        {error && (
          <HeroErrorDisplayer
            data={data}
            err={error}
            messages={{
              UNAUTHORIZED: t("merchant.tools.setting.updateBankAccount.unauthorized"),
              NOTFOUND: t("merchant.tools.setting.updateBankAccount.errorNoData"),
            }}
          />
        )}
      </Footer>
    </>
  )
}
