import { DeprecatedButton, Separator, Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceType } from "../../00_shared/business/IssuingCard"
import { DownloadAppAdvice } from "./DownloadAppAdvice"

interface Step {
  label: string
  description: string
  icon?: JSX.Element
  date?: string
}

interface CardProgressScreenProps {
  cardType: IssuanceType
  cardIcon: string
  title: string
  description: string
  steps: Step[]
  onClickNext: () => void
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 12px;
  color: #fff;
`

const ProgressWrapper = styled.div`
  width: 160%;
  margin-top: 2rem;
`

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: linear-gradient(353.12deg, #e9eaf0 -3.89%, #f3f3f3 95.82%);
  border-radius: 340px;
  padding: 0.2rem 0.3rem;
`

const ProgressCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  box-shadow: ${({ theme }) => theme.shadows.mid};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`

const StepLabel = styled.div`
  text-align: center;
`

const Footer = styled.div`
  margin-top: 2rem;
  width: 100%;
`

export const CardOrderedScreen: React.FC<CardProgressScreenProps> = ({
  cardType,
  cardIcon,
  title,
  description,
  steps,
  onClickNext,
}) => {
  const { t } = useDashboardTranslation()
  return (
    <CardWrapper>
      <img src={cardIcon} width={80} alt={`${cardType} card`} />
      <Typography $variant="title-1-bold">{title}</Typography>
      <Typography style={{ textAlign: "center" }} $variant="body-3-regular">
        {description}
      </Typography>

      {/* Progress bar */}
      <ProgressWrapper>
        <ProgressBar>
          {steps.map((step, index) => (
            <ProgressCircle key={index}>{step.icon ? step.icon : null}</ProgressCircle>
          ))}
        </ProgressBar>

        {/* Step Descriptions */}
        <StepWrapper>
          {steps.map((step, index) => (
            <StepLabel key={index}>
              <Typography $variant="body-4-medium">{step.label}</Typography>
              <Typography $variant="caption-2">{step.description}</Typography>
              {step.date && <Typography $variant="caption-2">{step.date}</Typography>}
            </StepLabel>
          ))}
        </StepWrapper>
      </ProgressWrapper>
      <Separator style={{ marginTop: "1rem", marginBottom: "1rem", width: "160%" }} />
      <DownloadAppAdvice />

      {/* Footer */}
      <Footer>
        <DeprecatedButton size="medium" $fullWidth onClick={onClickNext}>
          {t("issuing.wizard.next")}
        </DeprecatedButton>
      </Footer>
    </CardWrapper>
  )
}
