import { DeprecatedModal, deprecatedToaster, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { FileUploadPreview, PreviewType } from "../../../../00_shared/components/FileUploadPreview"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useOperationDetails } from "../../pro-account/00_shared/hooks/useOperationDetails"
import { useOperationJustificativePresignedUrl } from "../../pro-account/00_shared/hooks/useOperationJustificativePresignedUrl"
import { useUpdateOperationMetadata } from "../../pro-account/00_shared/hooks/useUpdateOperationMetadata"
import { LedgerMovementFlow } from "../business"
import { useUploadFile } from "../hooks/useUploadFile"

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const TransactionReceipt = ({
  title,
  operationId,
  fileUrl,
  flow,
  ledgerMovementId,
  ledgerId,
}: {
  title: string
  operationId: string
  ledgerMovementId: string
  flow: LedgerMovementFlow
  ledgerId: string
  fileUrl?: string | null
}) => {
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const { refetch, loading: operationDetailsLoading } = useOperationDetails({
    operationId,
    flow,
    ledgerMovementId,
    businessAccountId: ledgerId,
  })
  const { fetchSignedUrl, loading: signedUrlLoading } = useOperationJustificativePresignedUrl()
  const { uploadFile, isUploading } = useUploadFile({
    onUploadSuccess: async (fp: string) => {
      const justificativeId = fp.split("/").pop()
      await updateOperationMetadata({ operationId, metadata: { justificativeId }, businessAccountId: ledgerId })
      deprecatedToaster.success(t("fileUpload.success.uploaded"))
    },
  })
  const { updateOperationMetadata, loading: metadataLoading } = useUpdateOperationMetadata()

  const isLoading = operationDetailsLoading || signedUrlLoading || isUploading || metadataLoading || isRefreshing

  const handleFileChange = async (file: File) => {
    track("business_account_list_all_accounts_add_a_receipt_clicked")
    setIsRefreshing(true)
    try {
      const { signedUrl, path } = await fetchSignedUrl(operationId, ledgerId)

      if (!signedUrl) {
        deprecatedToaster.error(t("fileUpload.error.failedUpload"))
        setIsRefreshing(false)
        return
      }

      await uploadFile(signedUrl, file, path)
      await refetch()
    } catch (error) {
      deprecatedToaster.error(t("fileUpload.error.failedUpload"))
    } finally {
      setIsRefreshing(false)
    }
  }

  const handleFileDelete = async () => {
    try {
      setIsRefreshing(true)
      await updateOperationMetadata({ operationId, metadata: { justificativeId: null }, businessAccountId: ledgerId })
      deprecatedToaster.success(t("fileUpload.success.deleted"))
      await refetch()
    } catch (error) {
      deprecatedToaster.error(t("fileUpload.error.failedDeletion"))
    } finally {
      setIsRefreshing(false)
    }
  }

  const handlePreviewClick = (previewType: PreviewType) => {
    if (previewType === PreviewType.IMAGE) {
      setModalOpen(true)
    } else {
      window.open(fileUrl ?? undefined, "_blank")
    }
  }

  return (
    <ReceiptContainer>
      <Typography $variant="body-4-medium">{title}</Typography>
      <FileUploadPreview
        style={{ maxWidth: "7.5rem" }}
        fileUrl={fileUrl}
        isLoading={isLoading}
        onFileChange={handleFileChange}
        onDelete={handleFileDelete}
        onPreview={handlePreviewClick}
        allowedFileTypes={["image/*", "application/pdf"]}
      />
      {isModalOpen && (
        <DeprecatedModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <FlexContainer style={{ width: "100%" }}>
            <img src={fileUrl ?? undefined} alt="Full-size preview" style={{ width: "100%", borderRadius: "0.5rem" }} />
          </FlexContainer>
        </DeprecatedModal>
      )}
    </ReceiptContainer>
  )
}
