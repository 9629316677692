import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_WIRE_PROOF_DATA = gql`
  query getExternalWireProof($operationId: String!) {
    getExternalWireProof(operationId: $operationId) {
      ... on GetExternalWireProofOutput {
        generationDate
        debtorName
        debtorIBAN
        debtorBIC
        creditorName
        creditorIBAN
        creditorBIC
        createdAt
        amount
        reference
        transactionId
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

type ExternalWireProofData = {
  generationDate: string
  debtorName: string
  debtorIBAN: string
  debtorBIC: string
  creditorName: string
  creditorIBAN: string
  creditorBIC: string | null
  createdAt: string
  amount: number
  reference: string | null
  transactionId: string
}

export const useGetWireProofData = (operationId: string, skip: boolean) => {
  const { data, loading, error } = useHeroQuery<ExternalWireProofData>({
    gqlQuerySchema: GET_WIRE_PROOF_DATA,
    variables: { operationId },
    skip,
  })

  return { data, loading, error }
}
