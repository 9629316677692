import { ApolloQueryResult, gql, useQuery } from "@apollo/client"
import { Typography } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import CenteredLoading from "../00_shared/components/CenteredLoading"
import { useAmplitude } from "../00_shared/hooks/useAmplitude.hook"
import { useRouteListener } from "../00_shared/hooks/useRouteListener"
import { checkIfIsDemoMerchant } from "../00_shared/utils/demo"
import { TranslationNamespaces } from "../01_technical/translations"
import { MerchantOnboardingFlowStatus, MerchantStatus } from "../business/enums/Merchant.enum"
import { UserLocale } from "../business/enums/User.enum"
import IntercomProvider, { useIntercomContext } from "../IntercomProvider"

export interface CurrentUser {
  id: string
  intercomUserHash: string
  email: string
  merchantId: string
  firstname: string
  lastname: string
  phone: string
  merchantTradingName: string
  payOfflineAvailable: boolean
  merchantStatus: MerchantStatus
  canShowAPPages: boolean
  canShowCockpit: boolean
  isDemoMerchant: boolean
  role: "owner" | "member"
  locale: UserLocale
  onboardingFlowStatuses: {
    BA: MerchantOnboardingFlowStatus
    P1X: MerchantOnboardingFlowStatus
    CWT: MerchantOnboardingFlowStatus
    AP: MerchantOnboardingFlowStatus
    BNPL: MerchantOnboardingFlowStatus
  }
  isUserImpersonated: boolean
  address?: {
    line1?: string
    line2?: string
    zipCode: string
    city: string
    region?: string
    countryCode?: string
  }
  isUserAllowedToSendWire: boolean
  isUserAllowedToViewBusinessAccounts: boolean
  isUserAllowedToAddBeneficiary: boolean
  isUserAllowedToSendInternalTransfer: boolean
  isUserAllowedToSendExternalTransfer: boolean
}

type UserTypeBackend = {
  getmerchant: {
    id: string
    tradingName: string
    status: MerchantStatus
    canShowAPCockpit: boolean
    canShowCockpit: boolean
    onboardingFlowStatuses: {
      BA: MerchantOnboardingFlowStatus
      P1X: MerchantOnboardingFlowStatus
      CWT: MerchantOnboardingFlowStatus
      AP: MerchantOnboardingFlowStatus
      BNPL: MerchantOnboardingFlowStatus
    }
    address?: {
      line1?: string
      line2?: string
      zipCode: string
      city: string
      region?: string
      countryCode?: string
    }
  }
  user: {
    id: string
    intercomUserHash: string
    phone: string
    firstname: string
    lastname: string
    email: string
    locale: UserLocale
    role: "owner" | "member"
  }
  me: {
    merchant: {
      payOfflineAvailable: boolean
      isUserImpersonated: boolean
    }
  }

  getMerchantBusinessAccounts: {
    businessAccounts?: {
      ledgerId: string
    }[]
  }

  canISendExternalTransfer: {
    result: boolean
  }
  canISendInternalTransfer: {
    result: boolean
  }
  canIAddBeneficiary: {
    result: boolean
  }
}

const ME_QUERY = gql`
  query {
    getmerchant {
      id
      tradingName
      status
      canShowAPCockpit
      canShowCockpit
      onboardingFlowStatuses {
        BA
        P1X
        CWT
        AP
        BNPL
      }
      address {
        city
        zipCode
        line1
        line2
        region
        countryCode
      }
    }

    user {
      id
      intercomUserHash
      firstname
      lastname
      phone
      email
      locale
      role
    }

    me {
      merchant {
        payOfflineAvailable
        isUserImpersonated
      }
    }

    getMerchantBusinessAccounts {
      ... on GetMerchantBusinessAccountsOutput {
        businessAccounts {
          ledgerId
        }
      }
    }

    canISendExternalTransfer: canI(action: "send-external-transfer", target: "any") {
      ... on CanI {
        result
      }
    }

    canISendInternalTransfer: canI(action: "send-internal-transfer", target: "any") {
      ... on CanI {
        result
      }
    }

    canIAddBeneficiary: canI(action: "add-beneficiary", target: "any") {
      ... on CanI {
        result
      }
    }
  }
`

/**
 * @public
 */
export type AuthContextType = {
  currentUser: CurrentUser
  refetch: () => Promise<ApolloQueryResult<UserTypeBackend>>
  setImpersonateBannerHeight: (height: number) => void
  impersonateBannerHeight: number
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const { data, loading, error, refetch } = useQuery<UserTypeBackend>(ME_QUERY)
  const { t, i18n } = useTranslation(TranslationNamespaces.DASHBOARD)
  const isLocaleLoaded = useRef(false)
  const { bootIntercom } = useIntercomContext()
  const amplitude = useAmplitude()
  const navigate = useNavigate()

  const [impersonateBannerHeight, setImpersonateBannerHeight] = useState<number>(0)

  const handleRouteChange = (pathname: string) => {
    if (pathname === "/") {
      localStorage.removeItem("BO_V3")
    }
  }

  useEffect(() => {
    if (error?.message === "MEMBERSHIP_NOT_ACTIVE") {
      navigate("/logout")
    }
  }, [error?.message, navigate])

  useRouteListener(handleRouteChange)

  useEffect(() => {
    bootIntercom()
  }, [bootIntercom])

  useEffect(() => {
    if (data) {
      Sentry.addBreadcrumb({
        category: "auth",
        message: `merchant: ${data.user.id}`,
        level: "info",
      })
    }

    if (data?.user.locale && !isLocaleLoaded.current) {
      i18n.changeLanguage(data.user.locale.toLocaleLowerCase())
      isLocaleLoaded.current = true
    }
  }, [data, i18n])

  if (loading) {
    return <CenteredLoading />
  }

  if (error) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          lineHeight: "1.5",
        }}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography $variant="body-3-medium">Auth provider error</Typography>
        {error.message}
      </div>
    )
  }

  if (!data) {
    console.error("AuthProvider - data is empty")
    return <>{t("auth.cannotAccessToUser")}</>
  }

  const currentUser: CurrentUser = {
    id: data.user.id,
    locale: data.user.locale,
    merchantId: data.getmerchant.id,
    firstname: data.user.firstname,
    lastname: data.user.lastname,
    payOfflineAvailable: data.me.merchant.payOfflineAvailable,
    merchantTradingName: data.getmerchant.tradingName,
    merchantStatus: data.getmerchant.status,
    canShowAPPages: data.getmerchant.canShowAPCockpit,
    canShowCockpit: data.getmerchant.canShowCockpit,
    isDemoMerchant: checkIfIsDemoMerchant(data.getmerchant.id),
    intercomUserHash: data.user.intercomUserHash,
    email: data.user.email,
    phone: data.user.phone,
    onboardingFlowStatuses: data.getmerchant.onboardingFlowStatuses,
    isUserImpersonated: data.me.merchant.isUserImpersonated,
    role: data.user.role,
    address: data.getmerchant?.address && {
      line1: data.getmerchant.address?.line1,
      line2: data.getmerchant.address?.line2,
      zipCode: data.getmerchant.address.zipCode,
      city: data.getmerchant.address.city,
      region: data.getmerchant.address?.region,
      countryCode: data.getmerchant.address?.countryCode,
    },
    isUserAllowedToSendWire: data.canISendExternalTransfer.result && data.canISendInternalTransfer.result,
    isUserAllowedToAddBeneficiary: data.canIAddBeneficiary.result,
    isUserAllowedToViewBusinessAccounts:
      data.getmerchant.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.VALIDATED &&
      data.getMerchantBusinessAccounts.businessAccounts?.length
        ? data.getMerchantBusinessAccounts.businessAccounts?.length > 0
        : false,
    isUserAllowedToSendInternalTransfer: data.canISendInternalTransfer.result,
    isUserAllowedToSendExternalTransfer: data.canISendExternalTransfer.result,
  }

  amplitude.setUserId(currentUser.id)
  amplitude.setUserProperties([
    { email: currentUser.email },
    { merchantId: currentUser.merchantId },
    { merchantTradingName: currentUser.merchantTradingName },
    { merchantStatus: currentUser.merchantStatus },
  ])

  return (
    <AuthContext.Provider value={{ currentUser, refetch, setImpersonateBannerHeight, impersonateBannerHeight }}>
      <IntercomProvider>{children}</IntercomProvider>
    </AuthContext.Provider>
  )
}

const CAN_I = gql`
  query canI($action: String!, $target: String!) {
    canI(action: $action, target: $target) {
      ... on CanI {
        result
      }

      ... on GqlHeroError {
        message
        errorCode
        detail
      }

      ... on ValidationErrors {
        validationErrors {
          validationError
        }
      }
    }
  }
`

export const useCapability = (action: string, target: string) => {
  const { data } = useQuery(CAN_I, {
    variables: {
      action,
      target,
    },
    fetchPolicy: "cache-and-network",
  })

  return !!data?.canI?.result
}
