import {
  ArrowLeftIcon,
  BankIcon,
  CreditCardIcon,
  DeprecatedField,
  deprecatedToaster,
  PermIdentityIcon,
  theme,
  Typography,
} from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import styled from "styled-components"
import { z } from "zod"
import { Spacer } from "../../../00_shared/components/Spacer"
import { Button } from "../../../00_shared/components/krypton2/Button"
import { AddMemberIcon } from "../../../00_shared/components/krypton2/icons/icons"
import { PhoneSchema } from "../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation, useDashboardTranslation } from "../../../01_technical/translations"
import { InvitationCapabilitiesStrategy } from "../ProductCapabilities/UserManagementInvitationCapabilitiesStrategy"
import { useInviteMerchantUser } from "./hooks/useInviteMerchantUser"

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const InviteUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InviteUserWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 48rem;
  margin: auto;
  padding: 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const InviteUserLogoWrapper = styled.span`
  padding: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.secondary.$100};
  display: inline-block;
`

const DeprecatedCheckbox = styled.input`
  margin-top: 0.3125rem;
  height: 0.875rem;
  width: 0.875rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};

  accent-color: ${theme.colors.majorelle};
`

const CheckboxGroup = styled.label<{ $isChecked?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  border: 1px solid ${({ theme, $isChecked }) => ($isChecked ? theme.colors.grey.$600 : theme.colors.grey.$200)};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;

  transition: all 0.2s;

  & * {
    transition: all 0.2s;
  }
`

const CheckboxGroupWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`

const CheckboxGroupColumn = styled(CheckboxGroup)`
  flex: 0 0 50%;
`

const CheckboxGroupDetailItem = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }
`

const getInviteFormSchema = (t: CommonTFunction) => {
  return z.object({
    email: z.string().email(),
    firstname: z.string().trim().min(1),
    lastname: z.string().trim().min(1),
    role: z.enum(["OWNER", "MEMBER"]),
    phone: PhoneSchema(t),
    capabilities: z.array(
      z.object({
        action: z.string().min(1),
        target: z.string().min(1),
        isAllowed: z.boolean(),
      }),
    ),
  })
}

const useInviteForm = () => {
  const { t } = useCommonTranslation()
  const exportInviteSchema = getInviteFormSchema(t)
  type SchemaType = z.infer<typeof exportInviteSchema>

  return useForm<SchemaType>({
    resolver: zodResolver(exportInviteSchema),
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      role: "MEMBER",
      phone: "",
      capabilities: [],
    },
  })
}

export const InviteUser = ({
  strawmanUserId,
  onInviteSuccess,
}: {
  strawmanUserId: string
  onInviteSuccess: () => void
}) => {
  const { mutate } = useInviteMerchantUser()
  type InviteUserStep = "EMAIL_AND_NAMES_SELECTION" | "ROLE_SELECTION" | "CAPABILITIES_SELECTION"
  const [formStep, setFormStep] = useState<InviteUserStep>("EMAIL_AND_NAMES_SELECTION")
  const { t } = useDashboardTranslation()

  const form = useInviteForm()
  const onSubmit = form.handleSubmit((formData) => {
    deprecatedToaster.promise(
      mutate({
        email: formData.email,
        firstname: formData.firstname,
        lastname: formData.lastname,
        phone: formData.phone,
        role: formData.role,
        capabilities: formData.capabilities,
      }).then(() => {
        onInviteSuccess()
      }),
      {
        pending: t("merchant.tools.setting.merchantUser.invited.pending"),
        success: t("merchant.tools.setting.merchantUser.invited.success"),
        error: t("merchant.tools.setting.merchantUser.invited.error"),
      },
      { position: "top-center" },
    )
  })

  return (
    <InviteUserWrapper>
      <StyledForm onSubmit={onSubmit}>
        {formStep === "EMAIL_AND_NAMES_SELECTION" && (
          <>
            <InviteUserContainer>
              <InviteUserLogoWrapper>
                <AddMemberIcon />
              </InviteUserLogoWrapper>
            </InviteUserContainer>

            <Spacer />

            <Typography $variant="title-3-semibold">{t("userManagement.invite.addMemberToYourTeam")}</Typography>

            <Spacer />

            <Controller
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <DeprecatedField
                  placeholder={t("userManagement.invite.form.emailPlaceholder")}
                  fieldLabel={t("userManagement.invite.form.email")}
                  type="email"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={form.control}
              name="firstname"
              render={({ field, fieldState }) => (
                <DeprecatedField
                  placeholder={t("userManagement.invite.form.firstnamePlaceholder")}
                  fieldLabel={t("userManagement.invite.form.firstname")}
                  type="text"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={form.control}
              name="lastname"
              render={({ field, fieldState }) => (
                <DeprecatedField
                  placeholder={t("userManagement.invite.form.lastnamePlaceholder")}
                  fieldLabel={t("userManagement.invite.form.lastname")}
                  type="text"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Controller
              name="phone"
              control={form.control}
              rules={{
                validate: (value = "") => isValidPhoneNumber(value.toString()) || t("formValidation.common.invalid"),
              }}
              render={({ field, fieldState }) => (
                <DeprecatedField
                  {...field}
                  fieldLabel={t("bnpl.link.wizard.steps.client.phoneNumber")}
                  type="tel"
                  $fullWidth
                  defaultCountry="FR"
                  errorMessage={fieldState.error?.message}
                />
              )}
            />

            <Button
              onClick={() => setFormStep("ROLE_SELECTION")}
              disabled={!form.formState.isValid}
              type="button"
              $variant="primary"
              size="medium"
            >
              {t("userManagement.invite.continue")}
            </Button>
          </>
        )}

        {formStep === "ROLE_SELECTION" && (
          <>
            <div>
              <Button
                onClick={() => setFormStep("EMAIL_AND_NAMES_SELECTION")}
                type="button"
                $variant="secondary"
                $plain={true}
                LeftIcon={ArrowLeftIcon}
              >
                {t("userManagement.invite.backToMemberInformations")}
              </Button>
            </div>

            <Spacer />

            <Typography $variant="title-3-semibold">{t("userManagement.invite.selectRole")}</Typography>

            <Spacer />

            <Controller
              control={form.control}
              name="role"
              render={({ field }) => (
                <>
                  <CheckboxGroupWrapper>
                    <CheckboxGroupColumn $isChecked={field.value === "MEMBER"}>
                      <div>
                        <DeprecatedCheckbox
                          type="radio"
                          checked={field.value === "MEMBER"}
                          name="selected-role"
                          onChange={(e) => {
                            field.onChange("MEMBER")
                          }}
                        />
                      </div>
                      <div>
                        <Typography $variant="body-4-medium">
                          {t("userManagement.sidePanel.roleToggle.collaborator.title")}
                        </Typography>
                        <Typography $variant="caption-2">
                          {t("userManagement.sidePanel.roleToggle.collaborator.description")}
                        </Typography>
                        <CheckboxGroupDetailItem>
                          <CreditCardIcon />
                          <Typography $variant="caption-2" as="span">
                            {t("userManagement.sidePanel.roleToggle.collaborator.benefits.card")}
                          </Typography>
                        </CheckboxGroupDetailItem>
                        <CheckboxGroupDetailItem>
                          <BankIcon />
                          <Typography $variant="caption-2" as="span">
                            {t("userManagement.sidePanel.roleToggle.collaborator.benefits.account")}
                          </Typography>
                        </CheckboxGroupDetailItem>
                      </div>
                    </CheckboxGroupColumn>

                    <CheckboxGroupColumn $isChecked={field.value === "OWNER"}>
                      <div>
                        <DeprecatedCheckbox
                          type="radio"
                          checked={field.value === "OWNER"}
                          name="selected-role"
                          onChange={(e) => {
                            field.onChange("OWNER")
                          }}
                        />
                      </div>
                      <div>
                        <Typography $variant="body-4-medium">
                          {t("userManagement.sidePanel.roleToggle.admin.title")}
                        </Typography>
                        <Typography $variant="caption-2">
                          {t("userManagement.sidePanel.roleToggle.admin.description")}
                        </Typography>
                        <CheckboxGroupDetailItem>
                          <PermIdentityIcon />
                          <Typography $variant="caption-2" as="span">
                            {t("userManagement.sidePanel.roleToggle.admin.benefits.roles")}
                          </Typography>
                        </CheckboxGroupDetailItem>
                        <CheckboxGroupDetailItem>
                          <CreditCardIcon />
                          <Typography $variant="caption-2" as="span">
                            {t("userManagement.sidePanel.roleToggle.admin.benefits.cards")}
                          </Typography>
                        </CheckboxGroupDetailItem>
                        <CheckboxGroupDetailItem>
                          <BankIcon />
                          <Typography $variant="caption-2" as="span">
                            {t("userManagement.sidePanel.roleToggle.admin.benefits.access")}
                          </Typography>
                        </CheckboxGroupDetailItem>
                      </div>
                    </CheckboxGroupColumn>

                    <Spacer />
                  </CheckboxGroupWrapper>
                  {form.getValues().role === "OWNER" ? (
                    <Button type="submit" $fullWidth size="medium" isLoading={false}>
                      {t("userManagement.invite.submit")}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setFormStep("CAPABILITIES_SELECTION")}
                      disabled={!form.formState.isValid}
                      type="button"
                      $variant="primary"
                      size="medium"
                    >
                      {t("userManagement.invite.continue")}
                    </Button>
                  )}
                </>
              )}
            />
          </>
        )}

        {formStep === "CAPABILITIES_SELECTION" && (
          <>
            <div>
              <Button
                onClick={() => setFormStep("ROLE_SELECTION")}
                type="button"
                $variant="secondary"
                $plain={true}
                LeftIcon={ArrowLeftIcon}
              >
                {t("userManagement.invite.backToRole")}
              </Button>
            </div>

            <Spacer />

            <InvitationCapabilitiesStrategy
              actor={strawmanUserId}
              onChange={(capabilities) => {
                form.setValue("capabilities", capabilities)
              }}
            />

            <Button type="submit" $fullWidth size="medium" isLoading={false}>
              {t("userManagement.invite.submit")}
            </Button>
          </>
        )}
      </StyledForm>
    </InviteUserWrapper>
  )
}
