import { track } from "@amplitude/analytics-browser"
import {
  ArrowLeftIcon,
  DeprecatedModal as DefaultModal,
  DeprecatedButton,
  DeprecatedField,
  deprecatedToaster,
  Typography,
} from "@hero/krypton"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { eurosToCents } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingCard } from "../business/IssuingCard"
import { useCardSetSpendingAmountLimitHook } from "../hooks/useCardSetSpendingAmountLimit.hook"

const DeprecatedModal = styled(DefaultModal)`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 30%;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.form`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
`

const CurrencyFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: "€";
    position: absolute;
    left: 10px;
    top: 49%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.grey.$600};
    font-size: 1rem;
  }

  input {
    padding-left: 1.5rem;
  }
`

interface IssuingCardViewDetailsIframeProps {
  card: IssuingCard
  onClose: () => void
}

type SpendingLimitFormData = {
  monthlyLimit: number
  dailyLimit: number
  perTransactionLimit: number
}

const MAX_MONTHLY_SPENDING_LIMIT_AMOUNT = 50_000
const MAX_DAILY_SPENDING_LIMIT_AMOUNT = 20_000
const MAX_PER_TRANSACTION_LIMIT_AMOUNT = 10_000

export const IssuingCardEditMonthlySpendingLimitModal = ({ card, onClose }: IssuingCardViewDetailsIframeProps) => {
  const { t } = useDashboardTranslation()
  const { setSpendingLimits, loading } = useCardSetSpendingAmountLimitHook()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<SpendingLimitFormData>({
    mode: "onChange",
    defaultValues: {
      monthlyLimit: card.monthlySpendingLimitAmount / 100 || 0,
      dailyLimit: card.dailySpendingLimitAmount / 100 || 0,
      perTransactionLimit: card.perTransactionLimitAmount / 100 || 0,
    },
  })

  const onSubmit = handleSubmit(async (formData: SpendingLimitFormData) => {
    track("card_validate_monthly_limit_clicked")

    await setSpendingLimits({
      variables: {
        cardId: card.id,
        monthlySpendingLimitAmount: eurosToCents(formData.monthlyLimit),
        perTransactionLimitAmount: eurosToCents(formData.perTransactionLimit),
        dailySpendingLimitAmount: eurosToCents(formData.dailyLimit),
      },
      onCompleted: (data) => {
        if ("errorCode" in data.setIssuingCardSpendingLimits) {
          deprecatedToaster.error(t("issuing.cards.editSpendingLimit.error"))
          return
        }

        deprecatedToaster.success(t("issuing.cards.editSpendingLimit.success"))
        onClose()
      },
      onError: () => {
        deprecatedToaster.error(t("issuing.cards.editSpendingLimit.error"))
      },
    })
  })

  return (
    <DeprecatedModal isOpen={true} onClose={onClose}>
      <Container onSubmit={onSubmit}>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.editSpendingLimit.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.editSpendingLimit.description")}</Typography>
        </div>
        <ModalContent>
          <CurrencyFieldWrapper>
            <DeprecatedField
              type="text"
              fieldLabel={t("issuing.cards.editMonthlySpendingLimit.inputFieldName")}
              placeholder={t("issuing.cards.editSpendingLimit.inputPlaceholder", {
                amount: MAX_MONTHLY_SPENDING_LIMIT_AMOUNT.toLocaleString(),
              })}
              inputMode="decimal"
              $fullWidth
              {...register("monthlyLimit", {
                max: {
                  value: MAX_MONTHLY_SPENDING_LIMIT_AMOUNT,
                  message: t("issuing.cards.editSpendingLimit.maxLimit", {
                    amount: MAX_MONTHLY_SPENDING_LIMIT_AMOUNT.toLocaleString(),
                  }),
                },
                pattern: {
                  value: /^[0-9]+([.,][0-9]{1,})?$/,
                  message: t("issuing.cards.editSpendingLimit.invalidAmount"),
                },
              })}
              errorMessage={errors.monthlyLimit?.message}
            />
          </CurrencyFieldWrapper>
          <CurrencyFieldWrapper>
            <DeprecatedField
              type="text"
              fieldLabel={t("issuing.cards.editDailySpendingLimit.inputFieldName")}
              placeholder={t("issuing.cards.editSpendingLimit.inputPlaceholder", {
                amount: MAX_DAILY_SPENDING_LIMIT_AMOUNT.toLocaleString(),
              })}
              inputMode="decimal"
              $fullWidth
              {...register("dailyLimit", {
                max: {
                  value: MAX_DAILY_SPENDING_LIMIT_AMOUNT,
                  message: t("issuing.cards.editSpendingLimit.maxLimit", {
                    amount: MAX_DAILY_SPENDING_LIMIT_AMOUNT.toLocaleString(),
                  }),
                },
                pattern: {
                  value: /^[0-9]+([.,][0-9]{1,})?$/,
                  message: t("issuing.cards.editSpendingLimit.invalidAmount"),
                },
              })}
              errorMessage={errors.dailyLimit?.message}
            />
          </CurrencyFieldWrapper>
          <CurrencyFieldWrapper>
            <DeprecatedField
              type="text"
              fieldLabel={t("issuing.cards.editPerTransactionSpendingLimit.inputFieldName")}
              placeholder={t("issuing.cards.editSpendingLimit.inputPlaceholder", {
                amount: MAX_PER_TRANSACTION_LIMIT_AMOUNT.toLocaleString(),
              })}
              inputMode="decimal"
              $fullWidth
              {...register("perTransactionLimit", {
                max: {
                  value: MAX_PER_TRANSACTION_LIMIT_AMOUNT,
                  message: t("issuing.cards.editSpendingLimit.maxLimit", {
                    amount: MAX_PER_TRANSACTION_LIMIT_AMOUNT.toLocaleString(),
                  }),
                },
                pattern: {
                  value: /^[0-9]+([.,][0-9]{1,})?$/,
                  message: t("issuing.cards.editSpendingLimit.invalidAmount"),
                },
              })}
              errorMessage={errors.perTransactionLimit?.message}
            />
          </CurrencyFieldWrapper>
        </ModalContent>
        <ModalFooter>
          <DeprecatedButton type="button" size="medium" $variant="underline" onClick={onClose}>
            <ArrowLeftIcon />
            {t("issuing.cards.editSpendingLimit.back")}
          </DeprecatedButton>
          <DeprecatedButton
            $variant="primary"
            size="medium"
            isLoading={loading}
            disabled={!isDirty || !isValid || loading}
          >
            {t("issuing.cards.editSpendingLimit.confirm")}
          </DeprecatedButton>
        </ModalFooter>
      </Container>
    </DeprecatedModal>
  )
}
