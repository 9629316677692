import { DeprecatedMenuItem, DeprecatedRadio, Typography } from "@hero/krypton"
import { ElementType } from "react"
import styled from "styled-components"
import { AvailableLanguage, useCommonTranslation } from "../../01_technical/translations"

const CountrySelectContainer = styled.div`
  display: inline-flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  width: 100%;
`

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
`

const Flag = styled.img`
  width: 21px;
  height: 15px;
`

const LanguageName = styled(Typography)`
  margin-left: 0.5rem;
  flex-grow: 2;
  margin-top: 0.75rem;
`

const WhiteMenuItem = styled<ElementType>(DeprecatedMenuItem)`
  button {
    background-color: white !important;
    border: none;
  }
`

export const languageKeyByCountryCode = {
  [AvailableLanguage.EN]: "languages.english",
  [AvailableLanguage.FR]: "languages.french",
  [AvailableLanguage.ES]: "languages.spanish",
  [AvailableLanguage.DE]: "languages.german",
  [AvailableLanguage.IT]: "languages.italian",
} as const

export const CountryFlag = ({ countryCode }: { countryCode: AvailableLanguage }) => (
  <FlagContainer>
    <Flag src={`/flags/${countryCode}.png`} alt={countryCode} />
  </FlagContainer>
)

const LanguageRadioButton = ({
  countryCode,
  selected,
  displayLanguageName,
}: {
  countryCode: AvailableLanguage
  selected: boolean
  displayLanguageName: boolean
}) => {
  const { t } = useCommonTranslation()

  return (
    <CountrySelectContainer>
      {displayLanguageName && <LanguageName>{t(languageKeyByCountryCode[countryCode])}</LanguageName>}
      <DeprecatedRadio
        readOnly
        value={countryCode.toString()}
        checked={selected}
        id={`select-lang-${countryCode}`}
        text=""
      />
    </CountrySelectContainer>
  )
}

export const LanguageSelectMenuEntry = ({
  onClick,
  countryCode,
  selected,
  mode,
  displayLanguageName = true,
}: {
  onClick: () => void
  countryCode: AvailableLanguage
  selected: boolean
  mode: "light" | "dark"
  displayLanguageName?: boolean
}) => {
  return (
    <WhiteMenuItem icon={<CountryFlag countryCode={countryCode} />} onClick={onClick} mode={mode}>
      <LanguageRadioButton displayLanguageName={displayLanguageName} countryCode={countryCode} selected={selected} />
    </WhiteMenuItem>
  )
}
