import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { UPDATE_CAPABILITY } from "../../Capabilities.requests"

type Capability = {
  action: string
  target: string
  isAllowed: boolean
}

type UpdateCapabilityOutput = {
  success: boolean
}

type UpdateCapabilityInput = {
  capabilities: Capability[]
}

export const useUpdateCapability = () => {
  const {
    mutationWith2fa: updateCapability,
    mutationState: { data: mutationData, error, loading },
    isModalOpen,
  } = use2faChallenge<{ updateCapability: UpdateCapabilityOutput }, UpdateCapabilityInput>(UPDATE_CAPABILITY)

  const mutate = async (input: Capability[]) => {
    const result = await updateCapability({ capabilities: input })
    return result.data?.updateCapability.success
  }

  return { mutate, loading, error, mutationData, is2faModalOpen: isModalOpen }
}
