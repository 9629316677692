import {
  ArrowLeftIcon,
  DeprecatedButton,
  DeprecatedErrorBlock,
  DeprecatedField,
  DeprecatedFieldSelect,
  DeprecatedSpinner,
  Typography,
} from "@hero/krypton"
import { FC, useCallback, useEffect } from "react"
import { Controller } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { useCreateLinkFormContext, useNavigateWithScrolling } from "../../CreateLink.utils"
import { useCreateLinkContext } from "../../CreateLinkContext"
import { useCreateDraftPaymentLink, usePaymentCustomers } from "../ClientStep.requests"

const ClientFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 2rem;
  width: 40rem;
  margin: auto;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`

const StyledField = styled(DeprecatedField)`
  width: unset;
`

export const ClientForm: FC<{ isIndividual: boolean }> = ({ isIndividual }) => {
  const { t } = useDashboardTranslation()
  const { register, formState, control, reset, trigger, setValue, getValues, watch } = useCreateLinkFormContext()
  const { customerInfos, loading: isPaymentCustomersLoading } = usePaymentCustomers(isIndividual)
  const navigate = useNavigateWithScrolling()
  const { setPaymentLink } = useCreateLinkContext()

  const [createDraftPaymentLink, { loading: isCreateDraftPaymentLinkLoading, error: createDraftPaymentLinkError }] =
    useCreateDraftPaymentLink()

  const customerId = watch("customer.id")

  useEffect(() => {
    if (customerInfos && customerId) {
      if (customerId === "") {
        reset()
        return
      }

      const customer = customerInfos.find((customer) => customer.id === customerId)

      if (customer) {
        setValue("customer.name", customer.name)
        setValue("customer.email", customer.email)
        setValue("customer.phone", customer.phone)
        setValue("customer.siret", customer.siret ?? undefined)
        setValue("address.line1", customer.billingAddress.line1)
        setValue("address.city", customer.billingAddress.city)
        setValue("address.zipCode", customer.billingAddress.zipCode)
      }
    }
  }, [customerId, customerInfos, setValue, reset])

  const onSubmit = useCallback(async () => {
    await trigger([
      "customer.name",
      "customer.email",
      "customer.phone",
      "customer.siret",
      "address.line1",
      "address.city",
      "address.zipCode",
    ])

    if (Object.keys(formState.errors).length > 0) {
      return
    }

    const values = getValues()
    const isIndividual = Boolean(values.customer.isIndividual)

    const response = await createDraftPaymentLink({
      variables: {
        input: {
          customer: {
            name: values.customer.name,
            email: values.customer.email,
            isIndividual,
            phone: values.customer.phone,
            siret: !isIndividual ? values.customer.siret ?? null : null,
            address: {
              line1: values.address.line1,
              city: values.address.city,
              zipCode: values.address.zipCode,
            },
          },
        },
      },
    })

    setPaymentLink({
      id: response.paymentLinkId,
      isDraft: true,
    })

    navigate(`/collection/new-link/${response.paymentLinkId}/demand`)
  }, [createDraftPaymentLink, formState.errors, getValues, navigate, setPaymentLink, trigger])

  const resetClient = useCallback(() => {
    reset()
  }, [reset])

  if (isCreateDraftPaymentLinkLoading || isPaymentCustomersLoading || !customerInfos) {
    return <DeprecatedSpinner />
  }

  return (
    <ClientFormContainer>
      <Typography $variant="title-2-bold">{t("bnpl.link.wizard.steps.client")}</Typography>

      {createDraftPaymentLinkError && (
        <DeprecatedErrorBlock>{createDraftPaymentLinkError.message}</DeprecatedErrorBlock>
      )}

      <Controller
        control={control}
        name="customer.id"
        defaultValue=""
        render={() => (
          <DeprecatedFieldSelect
            $fullWidth
            fieldLabel={t("bnpl.link.wizard.steps.client.selectClient")}
            placeholder={t("bnpl.link.wizard.steps.client.selectClientPlaceholder")}
            {...register("customer.id")}
          >
            <option value="" disabled>
              {t("bnpl.link.wizard.steps.client.selectClientPlaceholder")}
            </option>
            {customerInfos.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </DeprecatedFieldSelect>
        )}
      />

      {(!customerId || customerId.length === 0) && (
        <>
          <Row>
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.name")}
              type="text"
              {...register("customer.name")}
              errorMessage={formState.errors?.customer?.name?.message}
            />
          </Row>

          <Row>
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.email")}
              type="email"
              {...register("customer.email")}
              errorMessage={formState.errors?.customer?.email?.message}
            />

            <Controller
              name="customer.phone"
              control={control}
              rules={{
                validate: (value = "") => isValidPhoneNumber(value.toString()) || t("formValidation.common.invalid"),
              }}
              render={({ field }) => (
                <StyledField
                  {...field}
                  fieldLabel={t("bnpl.link.wizard.steps.client.phoneNumber")}
                  type="tel"
                  defaultCountry="FR"
                  errorMessage={formState.errors?.customer?.phone?.message}
                />
              )}
            />
          </Row>

          {!isIndividual && (
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.siret")}
              type="text"
              {...register("customer.siret")}
              errorMessage={formState.errors?.customer?.siret?.message}
            />
          )}

          <Row>
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.address.line1")}
              type="text"
              {...register("address.line1")}
              errorMessage={formState.errors?.address?.line1?.message}
            />
          </Row>

          <Row>
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.address.city")}
              type="text"
              {...register("address.city")}
              errorMessage={formState.errors?.address?.city?.message}
            />
            <DeprecatedField
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.client.address.zipCode")}
              type="text"
              {...register("address.zipCode")}
              errorMessage={formState.errors?.address?.zipCode?.message}
            />
          </Row>
        </>
      )}

      <ButtonsContainer>
        <DeprecatedButton onClick={onSubmit} isLoading={isCreateDraftPaymentLinkLoading} type="submit">
          {t("bnpl.link.wizard.navigation.next")}
        </DeprecatedButton>

        <BackButtonContainer>
          <DeprecatedButton $variant="underline" onClick={resetClient}>
            <ArrowLeftIcon />
            {t("bnpl.link.wizard.navigation.previous")}
          </DeprecatedButton>
        </BackButtonContainer>
      </ButtonsContainer>
    </ClientFormContainer>
  )
}
