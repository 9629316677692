import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { getDemoData } from "./useGetCwtList.demo-data"

export interface CwtItem {
  id: string
  amount: number
  feeRate: number
  feeAmount: {
    totalAmount: number
    vatAmount: number
    amountWithoutVAT: number
  }
  appliedAt: Date
  document: URL
  installmentCount: number
  installments:
    | {
        amount: {
          totalAmount: number
          feeAmount: {
            totalAmount: number
            vatAmount: number
            amountWithoutVAT: number
          }
        }
        dueDate: Date
        position: number
        status: string
      }[]
    | null
  label: string
  merchantId: string
  recipient: {
    beneficiaryId: string
    name: string
    email: string
    siret: string
    status: string
  }
  status: CwtItemStatus
  businessAccountId: string | null
}

export enum CwtItemStatus {
  "WAITING_FOR_REVIEW" = "WAITING_FOR_REVIEW",
  "APPROVED" = "APPROVED",
  "REJECTED" = "REJECTED",
  "COMPLETED" = "COMPLETED",
}

export type CwtItemStatusFilters = "ONGOING" | "COMPLETED" | "REJECTED"

export const useGetCwtList = (
  filters: {
    merchantId: string
    status: CwtItemStatusFilters | null
  },
  config: { useDemoData?: boolean },
) => {
  const { data, loading, error } = useHeroQuery<{ creditWireTransfers: CwtItem[] }>({
    gqlQuerySchema: GET_CREDIT_WIRE_TRANSFERS_LIST,
    variables: {
      filters: {
        merchantId: filters.merchantId,
      },
    },
    skip: config.useDemoData || !filters.merchantId,
    fetchPolicy: "network-only",
  })

  if (config.useDemoData) {
    return {
      data: getDemoData(filters),
      loading: false,
      error: null,
    }
  }

  return { data: data?.creditWireTransfers?.filter((i) => statusFilter(i, filters.status)), loading, error }
}

const statusFilter = (item: CwtItem, status: CwtItemStatusFilters | null) => {
  switch (status) {
    case "ONGOING":
      return item.status === CwtItemStatus.APPROVED || item.status === CwtItemStatus.WAITING_FOR_REVIEW
    case "REJECTED":
      return item.status === CwtItemStatus.REJECTED
    case "COMPLETED":
      return item.status === CwtItemStatus.COMPLETED
    default:
      return true
  }
}

const GET_CREDIT_WIRE_TRANSFERS_LIST = gql`
  query listMerchantCreditWireTransfers($filters: CWTFiltersInput!) {
    listMerchantCreditWireTransfers(filters: $filters) {
      ... on CWTListOutput {
        creditWireTransfers {
          amount
          feeRate
          feeAmount {
            totalAmount
            vatAmount
            amountWithoutVAT
          }
          appliedAt
          document
          id
          installmentCount
          installments {
            amount {
              totalAmount
              feeAmount {
                totalAmount
                vatAmount
                amountWithoutVAT
              }
            }
            status
            dueDate
            position
          }
          label
          merchantId
          recipient {
            beneficiaryId
            email
            name
            siret
            status
          }
          status
          businessAccountId
        }
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`
