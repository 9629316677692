import { DeprecatedModal as DefaultModal, Typography } from "@hero/krypton"
import { useCallback, useEffect, useRef } from "react"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { ISSUING_PAN_KEY } from "../../../../env_variables"

const DeprecatedModal = styled(DefaultModal)`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 30%;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

const IframeContainer = styled.div`
  display: flex;
  width: 100%;
`

interface IssuingCardViewDetailsIframeProps {
  url?: string | null
  token?: string | null
  isOpen: boolean
  onClose: () => void
}

const CLOSE_ISSUING_CARD_INFOS = 30000

export const IssuingCardViewDetailsIframeModal = ({
  url,
  token,
  isOpen,
  onClose,
}: IssuingCardViewDetailsIframeProps) => {
  const { t } = useDashboardTranslation()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const onIframeLoad = useCallback(() => {
    url && iframeRef?.current?.contentWindow?.postMessage({ operation: "view-pan", token }, url)
  }, [iframeRef, url, token])

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current
      iframe.addEventListener("load", onIframeLoad, { once: true })

      return () => iframe.removeEventListener("load", onIframeLoad)
    }
  }, [iframeRef, onIframeLoad])

  useEffect(() => {
    const itx = setTimeout(onClose, CLOSE_ISSUING_CARD_INFOS)

    return () => clearTimeout(itx)
  })

  return (
    <DeprecatedModal isOpen={isOpen} onClose={onClose}>
      <Container>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.viewPan.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.viewPan.description")}</Typography>
        </div>
        {(!url || !token) && <CenteredLoading />}
        {url && token && (
          <IframeContainer>
            <iframe
              ref={iframeRef}
              allow="same-origin"
              src={`${url}?key=${ISSUING_PAN_KEY}`}
              width="100%"
              height="100%"
              title="card-details"
            />
          </IframeContainer>
        )}
      </Container>
    </DeprecatedModal>
  )
}
