import { useMutation } from "@apollo/client"
import { DeprecatedButton, deprecatedToaster, Separator, Typography, TypographyVariant } from "@hero/krypton"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { TransactionType } from "../../../../../business/enums/Transaction.enum"
import * as TransactionRules from "../../../../../business/rules/Transaction.rules"
import {
  MERCHANT_SEND_PAYMENT_LINK_BY_SMS_REQUEST,
  MERCHANT_SEND_PAYMENT_LINK_BY_WHATSAPP_REQUEST,
  MerchantSendPaymentLinkByMessageParameters,
} from "./PaymentLinkDetails.requests"

const Container = styled.div`
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  padding: 2rem;
`
const URL = styled(Typography)`
  width: 100%;
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  margin: 0.25rem 0 1.25rem 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

const DividerGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`

const Divider = styled(Separator)`
  height: 1px;
`

const ActionsTextSeparator = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin: 0 0.5rem;
`

const ButtonActionGroup = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1.5rem;

  button {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const Description = styled(Typography)<{ $variant?: TypographyVariant }>`
  color: ${(props) => props.theme.colors.grey.$500};
  margin: 0.25rem auto 2rem auto;
`

export const PaymentLinkDetails: React.FC<{ url: string; transactionType: TransactionType; transactionId: string }> = ({
  url,
  transactionType,
  transactionId,
}) => {
  const { t } = useDashboardTranslation()

  const isPayGenerator = TransactionRules.isPayGenerator(transactionType)

  const [sendSms, { loading: sendSmsLoading }] = useMutation<void, MerchantSendPaymentLinkByMessageParameters>(
    MERCHANT_SEND_PAYMENT_LINK_BY_SMS_REQUEST,
  )
  const [sendWhatsapp, { loading: sendWhatsappLoading }] = useMutation<
    void,
    MerchantSendPaymentLinkByMessageParameters
  >(MERCHANT_SEND_PAYMENT_LINK_BY_WHATSAPP_REQUEST)

  const handleSendSms = () => {
    sendSms({ variables: { paymentLinkId: transactionId } })
      .then(() =>
        deprecatedToaster.success(t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.sendSMS.toasterSuccess")),
      )
      .catch(() =>
        deprecatedToaster.error(t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.sendSMS.toasterError")),
      )
  }

  const handleSendWhatsapp = () => {
    sendWhatsapp({ variables: { paymentLinkId: transactionId } })
      .then(() =>
        deprecatedToaster.success(
          t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.sendWhatsApp.toasterSuccess"),
        ),
      )
      .catch(() =>
        deprecatedToaster.error(t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.sendWhatsApp.toasterError")),
      )
  }

  return (
    <Container data-test-id="payment-link-detail-page">
      <Typography $variant="body-2-semibold">
        {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.title")}
      </Typography>
      <Description $variant="body-4-regular">
        {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.subtitle")}
      </Description>
      <Description $variant="body-4-regular">
        {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.description")}
      </Description>
      <Typography $variant="body-4-medium">
        {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlTitle")}
      </Typography>
      <URL as="pre" $variant="body-4-regular" data-test-id="payment-link-url">
        <code>{url}</code>
      </URL>
      {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/** @ts-expect-error CopyToClipboardType is wrong... */}
      <CopyToClipboard
        text={url}
        onCopy={(_: unknown, result?: unknown) => {
          if (result) {
            deprecatedToaster.success(
              t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlCopy.toasterSuccess"),
              {
                autoClose: 1500,
              },
            )
          } else {
            deprecatedToaster.error(t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlCopy.toasterError"))
          }
        }}
      >
        <DeprecatedButton isLoading={false} style={{ width: "100%" }}>
          {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlCopyButton")}
        </DeprecatedButton>
      </CopyToClipboard>
      {isPayGenerator && (
        <Actions>
          <DividerGroup>
            <Divider />
            <ActionsTextSeparator $variant="label-1">
              {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlDivider").toUpperCase()}
            </ActionsTextSeparator>
            <Divider />
          </DividerGroup>
          <ButtonActionGroup>
            <DeprecatedButton $variant="secondary" onClick={handleSendSms} isLoading={sendSmsLoading}>
              {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlSendSMSButton")}
            </DeprecatedButton>
            <DeprecatedButton $variant="secondary" onClick={handleSendWhatsapp} isLoading={sendWhatsappLoading}>
              {t("checkoutAndCash.cockpit.transaction.paymentLinkDetails.urlSendWhatsAppButton")}
            </DeprecatedButton>
          </ButtonActionGroup>
        </Actions>
      )}
    </Container>
  )
}
