import { DeprecatedButton, DeprecatedField, DeprecatedFieldSelect, Typography, deprecatedToaster } from "@hero/krypton"
import type { TFunction } from "i18next"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { useFileDownload } from "../../../../../00_shared/hooks/useFileDownload.hook"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"
import { API_URI } from "../../../../../env_variables"
import type { MerchantAccount } from "../export.requests"
import { DateFormatted, formatDateToInputDate } from "./date-input.utils"

const AccountingSection = styled.section`
  padding-left: 1.5rem;
`

const Description = styled(Typography)`
  padding-right: 1rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-bottom: 1.5rem;
`

const Option = styled.option``

const Form = styled.form`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

const FieldStyled = styled(DeprecatedField)`
  margin-top: 0.25rem;
  margin-left: 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 1.75rem;
`

const ExportErrorMapper = (t: TFunction): Record<string, string> => ({
  MERCHANT_ACCOUNT_NOT_FOUND: t("accounting.export.errors.merchantAccountNotFound"),
  MERCHANT_ACCOUNT_INVALID: t("accounting.export.errors.merchantAccountInvalid"),
  FROM_DATE_HIGHER_THAN_TO_DATE: t("accounting.export.errors.fromDateHigherThanToDate"),
  INVALID_DATE_RANGE: t("accounting.export.errors.fromDateHigherThanToDate"),
  DATE_IN_THE_FUTURE: t("accounting.export.errors.dateInTheFuture"),
  RANGE_TOO_LONG: t("accounting.export.errors.rangeTooLong"),
})

type TimingFilter = {
  from: DateFormatted
  to: DateFormatted
  accountId?: string
}

export const AccountingSummaryExport: React.FC<{ merchantAccounts: MerchantAccount[] }> = ({ merchantAccounts }) => {
  const [triggerFileDownload, { loading, error }] = useFileDownload()
  const { t, unsafeT } = useDashboardTranslation()
  const { t: tcommon } = useCommonTranslation()
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (error) {
      deprecatedToaster.error(ExportErrorMapper(unsafeT)[error.message])
    }
  }, [error, unsafeT])

  const now = new Date()

  const firstDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  const lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0)

  const { handleSubmit, formState, register } = useForm<TimingFilter>({
    defaultValues: {
      from: formatDateToInputDate(firstDayOfPreviousMonth),
      to: formatDateToInputDate(lastDayOfPreviousMonth),
    },
  })

  const isGenerateDisabled = loading || !formState.isValid

  const handleExportPayment = handleSubmit(({ from, to, accountId }) => {
    const merchantAccount = merchantAccounts.find(({ id }) => id === accountId)

    const fromIsoString = new Date(from).toISOString()
    const toIsoString = new Date(to).toISOString()

    const exportPaymentURL =
      merchantAccount?.accountType === "AP"
        ? `${API_URI}/accounting/export-ap-summary?from=${fromIsoString}&to=${toIsoString}&accountId=${accountId}`
        : `${API_URI}/accounting/export-bnpl-summary?from=${fromIsoString}&to=${toIsoString}&accountId=${accountId}`

    return triggerFileDownload(exportPaymentURL)
  })

  return (
    <AccountingSection>
      <Typography as="h2" $variant="title-3-inter-bold" data-test-id="accounting-export-title">
        {t("accounting.export.summary.title")}
      </Typography>

      <Description $variant="body-4-regular">{t("accounting.export.summary.subtitle")}</Description>

      <Form onSubmit={handleExportPayment}>
        <FieldStyled
          fieldLabel={t("accounting.export.summary.form.from.label")}
          type="date"
          aria-invalid={!!formState.errors.from}
          {...register("from", { required: tcommon("requiredField") })}
          errorMessage={formState.errors.from?.message}
          $inline
        />

        <FieldStyled
          fieldLabel={t("accounting.export.summary.form.to.label")}
          type="date"
          aria-invalid={!!formState.errors.to}
          {...register("to", { required: tcommon("requiredField") })}
          errorMessage={formState.errors.to?.message}
          $inline
        />

        <DeprecatedFieldSelect
          fieldLabel={t("accounting.export.summary.form.account")}
          {...register("accountId", { value: "" })}
        >
          <Option value="" disabled>
            {t("accounting.export.summary.form.selectDefault")}
          </Option>
          {merchantAccounts.map(({ id, name, balance }) => (
            <Option key={id} value={id}>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {name} - ({toEuros(balance)})
            </Option>
          ))}
        </DeprecatedFieldSelect>

        {currentUser.isDemoMerchant && (
          <a href="/demo/demo-account-export-summary.pdf" target="_blank">
            <ButtonContainer>
              <DeprecatedButton type="button" size="medium" isLoading={false}>
                {t("accounting.export.summary.form.generateButton")}
              </DeprecatedButton>
            </ButtonContainer>
          </a>
        )}

        {!currentUser.isDemoMerchant && (
          <ButtonContainer>
            <DeprecatedButton type="submit" size="medium" disabled={isGenerateDisabled} isLoading={loading}>
              {t("accounting.export.summary.form.generateButton")}
            </DeprecatedButton>
          </ButtonContainer>
        )}
      </Form>
    </AccountingSection>
  )
}
