import { Badge, ValidIcon } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { ApRegulatedStatusEnum } from "../../AcceleratedPayout/00_shared/types/types"

export const StatusBadge = ({ status }: { status: ApRegulatedStatusEnum }) => {
  const { t } = useDashboardTranslation()

  if ([ApRegulatedStatusEnum.OPENED, ApRegulatedStatusEnum.PARTIALLY_REPAID].includes(status)) {
    return <Badge $variant="info">{t("apRefunded.dashboard.apListSection.status.opened")}</Badge>
  }

  if ([ApRegulatedStatusEnum.OPENING_CANCELLED, ApRegulatedStatusEnum.OPENING_CANCEL_IN_PROGRESS].includes(status)) {
    return <Badge $variant="danger">{t("apRefunded.dashboard.apListSection.status.cancelled")}</Badge>
  }

  if (status === ApRegulatedStatusEnum.TOTALLY_REPAID) {
    return (
      <Badge $variant="primary" rightIcon={CheckIcon}>
        {t("apRefunded.dashboard.apListSection.status.totallyRepaid")}
      </Badge>
    )
  }
}

const CheckIcon = styled(ValidIcon)`
  color: ${({ theme }) => theme.colors.primary.$300};
  height: 1rem;
  width: 1rem;
`
