import { Typography } from "@hero/krypton"
import { useEffect, useRef } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useBusinessAccountContext } from "../../BusinessAccount/01_context/businessAccount.context"

const Banner = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  position: sticky;
  top: 0;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
`

export const AccountVerificationBanner = () => {
  const { bannerAccountInVerification } = useBusinessAccountContext()
  const { t } = useDashboardTranslation()

  const bannerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!bannerRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      const height = bannerAccountInVerification.isActive ? bannerRef.current?.offsetHeight || 0 : 0
      bannerAccountInVerification.setBannerHeight(height)
    })

    resizeObserver.observe(bannerRef.current)
    return () => resizeObserver.disconnect()
  }, [bannerAccountInVerification])

  return (
    <>
      {bannerAccountInVerification.isActive && (
        <Banner ref={bannerRef}>
          <Typography $color="grey.$300" $variant="body-3-regular">
            {t("businessAccount.inactiveBanner")}
          </Typography>
        </Banner>
      )}
    </>
  )
}
