import { useQuery } from "@apollo/client"
import { DeprecatedButton, DeprecatedSpinner, deprecatedToaster, Typography } from "@hero/krypton"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { APIKEYRespone, GET_API_KEY } from "./Integration.requests"

const Panel = styled.div`
  display: flex;
  align-items: center;
`

const URL = styled(Typography).attrs(() => ({ as: "pre" }))`
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  & > code {
    font-weight: 900;
    color: #b18aff;
  }
`

export const ApiKey = () => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<APIKEYRespone>(GET_API_KEY)
  if (loading) {
    return <DeprecatedSpinner />
  }

  if (error) {
    return <div>{error.message}</div>
  }

  if (!data) {
    return <div>{t("merchant.tools.setting.integration.noData")}</div>
  }

  const apiKey = data.apiKey

  return (
    <Panel>
      <URL>
        <code>{apiKey}</code>
      </URL>
      {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/** @ts-expect-error CopyToClipboardType is wrong... */}
      <CopyToClipboard
        text={apiKey}
        onCopy={(_, result) => {
          if (result) {
            deprecatedToaster.success(t("merchant.tools.setting.integration.copyApiKey.success"), { autoClose: 1500 })
          } else {
            deprecatedToaster.error(t("merchant.tools.setting.integration.copyApiKey.error"))
          }
        }}
      >
        <DeprecatedButton isLoading={false} size="medium">
          {t("merchant.tools.setting.integration.copyApiKey.label")}
        </DeprecatedButton>
      </CopyToClipboard>
    </Panel>
  )
}
