import { DeprecatedBadge, Typography } from "@hero/krypton"
import styled from "styled-components"
import { Card } from "../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import QrCodeIcon from "../../00_shared/icons/qr-code.png"

const DiscoverBadge = styled(DeprecatedBadge)`
  position: absolute;
  padding: 0.1rem 0.5rem;
  color: ${({ theme }) => theme.colors.black.$1000};
  top: -1.7rem;
  height: unset;
  text-transform: uppercase;
`

export const DownloadAppAdvice = () => {
  const { t } = useDashboardTranslation()
  return (
    <FlexContainer $direction="column" $align="center" $gap="1.2rem" style={{ position: "relative" }}>
      <DiscoverBadge $variant="info">{t("issuing.downloadAppAdvice.badge")}</DiscoverBadge>
      <Card style={{ width: "min-content", padding: ".2rem" }}>
        <img src={QrCodeIcon} width={80} alt="Download the app" />
      </Card>
      <FlexContainer $direction="column" $align="center">
        <Typography $variant="title-3-inter-bold" $color="grey.$600">
          {t("issuing.downloadAppAdvice.title")}
        </Typography>
        <Typography $variant="body-3-regular" $color="grey.$500">
          {t("issuing.downloadAppAdvice.subtitle")}
        </Typography>
      </FlexContainer>
    </FlexContainer>
  )
}
