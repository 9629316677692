import { Badge, Typography } from "@hero/krypton"
import { useMemo } from "react"
import styled from "styled-components"
import { Skeleton } from "../../../../00_shared/components/Skeleton"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import waveBkg from "../images/wave.svg"

const Container = styled.div<{ $variant: "compact" | "full" }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $variant }) => ($variant === "compact" ? "1rem" : "2rem")};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  height: ${({ $variant }) => ($variant === "compact" ? "14rem" : "15.5rem")};
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionsContainer = styled.div<{ $variant: "compact" | "full" }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding-top: ${({ $variant }) => ($variant === "compact" ? "1.5rem" : "2rem")};
  height: ${({ $variant }) => ($variant === "compact" ? "4rem" : "5rem")};
`

const OutstandingContainer = styled.div<{ $variant: "compact" | "full" }>`
  display: flex;
  margin: 1.5rem 0;
  justify-content: space-between;
  align-items: center;
  height: ${({ $variant }) => ($variant === "compact" ? "2.5rem" : "3.5rem")};
`

const StyledProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border-radius: 0.625rem;
  overflow: hidden;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  flex: 1;
  margin-right: 1.5rem;
`

const Progress = styled.div<{ $percent: number }>`
  width: ${({ $percent }) => Math.min(Math.max($percent, 0), 100)}%;
  height: 100%;
  position: relative;
  transition: width 0.3s ease-in-out;
  background-image: url(${waveBkg});
  background-size: contain;
  background-position: 0 0;
  background-repeat: repeat;
  background-color: #624aff;
  border-radius: 0.625rem;
`

const OutstandingSkeletonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
`

type OutstandingPanelProps = {
  variant: "compact" | "full"
  isLoading: boolean
  isError: boolean
  totalAllowedExposure: number
  totalUsedExposure: number
  actions?: React.ReactNode
  title: string
  subtitle: string
}

export const OutstandingPanel = (props: OutstandingPanelProps) => {
  const { isLoading, isError, totalAllowedExposure, totalUsedExposure, actions, title, subtitle, variant } = props

  const { t } = useDashboardTranslation()

  const outstanding = useMemo(() => {
    if (isError) {
      return {
        badge: {
          text: t("creditWireTransfer.outstandingPanel.error"),
          variant: "danger" as const,
        },
        outstanding: {
          available: 0,
          remaining: 0,
          percentAvailable: 0,
        },
      }
    }

    const remaining = Math.max(0, totalAllowedExposure - totalUsedExposure)
    const percentAvailable = (remaining / totalAllowedExposure) * 100

    return {
      badge: {
        text:
          remaining > 0
            ? t("creditWireTransfer.outstandingPanel.available")
            : t("creditWireTransfer.outstandingPanel.allUsed"),
        variant: remaining > 0 ? ("info" as const) : ("danger" as const),
      },
      outstanding: {
        available: totalAllowedExposure,
        remaining,
        percentAvailable,
      },
    }
  }, [totalAllowedExposure, totalUsedExposure, t, isError])

  return (
    <Container $variant={variant}>
      <HeaderContainer>
        <HeaderTitleContainer>
          <Typography $variant="title-4-semibold">{title}</Typography>
          <Typography $variant="caption-2" $color="grey.$500">
            {isError ? t("creditWireTransfer.outstandingPanel.errorSubtitle") : subtitle}
          </Typography>
        </HeaderTitleContainer>
        {!isLoading && <Badge $variant={outstanding.badge.variant}>{t(outstanding.badge.text)}</Badge>}
        {isLoading && <Skeleton width="3.125rem" height="1.5rem" />}
      </HeaderContainer>
      <OutstandingContainer $variant={variant}>
        {!isLoading && !isError && (
          <>
            <StyledProgressBar>
              <Progress $percent={outstanding.outstanding.percentAvailable} />
            </StyledProgressBar>
            <Typography $variant="caption-2" $color="grey.$500">
              {toEuros(outstanding.outstanding.remaining, 0)} / {toEuros(outstanding.outstanding.available, 0)}
            </Typography>
          </>
        )}
        {(isLoading || isError) && (
          <OutstandingSkeletonContainer>
            <Skeleton width="100%" height="10px" />
            <Skeleton width="5.5rem" height="10px" />
          </OutstandingSkeletonContainer>
        )}
      </OutstandingContainer>
      <ActionsContainer $variant={variant}>{isError ? <ErrorActions /> : actions}</ActionsContainer>
    </Container>
  )
}

const ErrorActions = () => {
  const { t } = useDashboardTranslation()
  return (
    <Typography $variant="caption-2" $color="grey.$500">
      {t("creditWireTransfer.applicationForm.outstandingActionsTitleError")}
    </Typography>
  )
}
