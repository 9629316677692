import { useMutation } from "@apollo/client"
import { DeprecatedButton, DeprecatedField, deprecatedToaster } from "@hero/krypton"
import { MouseEvent } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ApiErrors } from "../../../../Legacy/components/ApiErrors"
import { Footer } from "../component/Footer"
import { Panel } from "../component/Pannel"
import { Slot } from "../component/Slot"
import { UPDATE_PASSWORD, UPDATE_PASSWORD_ERROR_MAPPINGS, UpdatePasswordType } from "./UpdateUserPassword.requests"

type UpdatePasswordForm = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const UpdateUserPasswordForm = () => {
  const { handleSubmit, register, formState, getValues } = useForm<UpdatePasswordForm>({ mode: "onChange" })
  const [updatePassword, updatePasswordStatus] = useMutation<unknown, UpdatePasswordType>(UPDATE_PASSWORD)
  const updatePasswordFormId = "updatePasswordFormId"

  const savePassword = async ({ oldPassword, newPassword }: UpdatePasswordForm) => {
    try {
      await updatePassword({ variables: { oldPassword, newPassword } })
      deprecatedToaster.success(t("merchant.tools.setting.resetPassword.successToaster"))
    } catch (err) {
      // Handled in render
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <form id={updatePasswordFormId}>
        <Panel>
          <Slot>
            <DeprecatedField
              fieldLabel={t("merchant.tools.setting.resetPassword.oldPassword.label")}
              placeholder={t("merchant.tools.setting.resetPassword.oldPassword.placeholder")}
              type="password"
              $fullWidth
              {...register("oldPassword", { required: true })}
            />
          </Slot>
          <Slot>
            <DeprecatedField
              fieldLabel={t("merchant.tools.setting.resetPassword.newPassword.label")}
              placeholder={t("merchant.tools.setting.resetPassword.newPassword.placeholder")}
              type="password"
              $fullWidth
              {...register("newPassword", { required: true })}
            />
          </Slot>
          <Slot>
            <DeprecatedField
              fieldLabel={t("merchant.tools.setting.resetPassword.confirmNewPassword.label")}
              placeholder={t("merchant.tools.setting.resetPassword.confirmNewPassword.placeholder")}
              type="password"
              errorMessage={formState.errors.confirmNewPassword?.message}
              $fullWidth
              {...register("confirmNewPassword", {
                required: t("merchant.tools.setting.resetPassword.confirmNewPassword.error.required"),
                validate: (value) => {
                  return (
                    getValues("newPassword") === value ||
                    t("merchant.tools.setting.resetPassword.confirmNewPassword.error.matching")
                  )
                },
              })}
            />
          </Slot>
        </Panel>
      </form>

      <Footer>
        {updatePasswordStatus.error && (
          <ApiErrors err={updatePasswordStatus.error} strategies={UPDATE_PASSWORD_ERROR_MAPPINGS} />
        )}
        <DeprecatedButton<"button">
          disabled={!formState.isValid}
          isLoading={updatePasswordStatus.loading}
          onClick={(e: MouseEvent) => {
            e.preventDefault()
            handleSubmit(savePassword)(e)
          }}
          form={updatePasswordFormId}
        >
          {t("merchant.tools.setting.resetPassword.submit")}
        </DeprecatedButton>
      </Footer>
    </>
  )
}
