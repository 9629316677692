import { gql } from "@apollo/client"
import { GqlHeroError } from "../01_technical/requesting/request-error.type"
import { useHeroQuery } from "../01_technical/requesting/useHeroQuery/useHeroQuery"
import { UserLocale } from "../business/enums/User.enum"

export type UPDATE_USER_LOCALE_ARGS = {
  locale: UserLocale
}

export type UPDATE_USER_LOCALE_RESPONSE_SUCCESS = {
  success: boolean
}

export type UPDATE_USER_LOCALE_RESPONSE = {
  updateUserLocale: UPDATE_USER_LOCALE_RESPONSE_SUCCESS | GqlHeroError
}

export const UPDATE_USER_LOCALE = gql`
  mutation updateUserLocale($locale: UserLocale!) {
    updateUserLocale(locale: $locale) {
      ... on UpdateUserLocalePayoutSuccess {
        success
      }

      ... on GqlHeroError {
        errorCode
        detail
        message
      }
    }
  }
`

type IS_CREDIT_WIRE_TRANSFER_ENABLED_PARAMS = {
  merchantId: string
}

type IS_CREDIT_WIRE_TRANSFER_ENABLED_RESPONSE = {
  isActivated: boolean
  useBusinessAccount: boolean
}

const IS_CREDIT_WIRE_TRANSFER_ENABLED = gql`
  query isCreditWireTransferActive($merchantId: String!) {
    isCreditWireTransferActive(merchantId: $merchantId) {
      ... on CWTConfigOutput {
        isActivated
        useBusinessAccount
      }
      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

export function useIsCreditWireTransferActive(params: IS_CREDIT_WIRE_TRANSFER_ENABLED_PARAMS) {
  return useHeroQuery<IS_CREDIT_WIRE_TRANSFER_ENABLED_RESPONSE>({
    gqlQuerySchema: IS_CREDIT_WIRE_TRANSFER_ENABLED,
    variables: params,
  })
}
