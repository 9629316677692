import { useMutation } from "@apollo/client"
import {
  BankIcon,
  CreditCardIcon,
  DeprecatedAccordion,
  DeprecatedFloatingBox,
  DeprecatedMenuButtonEntry,
  DeprecatedMenuListButton,
  DeprecatedMenuProfileButton,
  GetPaidIcon,
  Invoices2Icon,
  DeprecatedMenu as KryptonMenu,
  MenuOpenIcon,
  MenuReduceIcon,
  PaidIcon,
  PermIdentityIcon,
  Typography,
} from "@hero/krypton"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { LanguageSelectMenuEntry } from "../00_shared/components/LanguageSelector"
import { ScrollArea } from "../00_shared/components/ScrollArea"
import { Sheet, SheetContent } from "../00_shared/components/Sheet"
import { useAmplitude } from "../00_shared/hooks/useAmplitude.hook"
import { useIsApRegulatedActive, useIsFFCWTActive } from "../00_shared/hooks/useFeatureFlag.hook"
import useMediaQuery from "../00_shared/hooks/useMediaQuery.hook"
import { unwrapGraphQLResponse } from "../01_technical/requesting/DEPRECATED_graphql.errors"
import { AvailableLanguage, EnabledLanguages, useDashboardTranslation } from "../01_technical/translations"
import heroLogo from "../assets/hero.svg"
import { useAuthContext } from "../Auth/auth.context"
import { UserLocale } from "../business/enums/User.enum"
import { BoSwitcher } from "./00_shared/components/BoSwitcher"
import { useMenuLayoutContext } from "./00_shared/components/MenuLayoutContext"
import { ApListMenuEntry } from "./AcceleratedPayoutRegulated/AP.menuEntry"
import { useBusinessAccountContext } from "./BusinessAccount/01_context/businessAccount.context"
import { ProAccountMenuEntry, TransfersMenuEntry } from "./BusinessAccount/routes/listMenuEntry"
import { CollectionMenuEntry } from "./Collection/Collection.menuEntry"
import { CwtListMenuEntry } from "./CreditWireTransfer/Landing/List.menuEntry"
import { IssuingCardsMenuEntry } from "./IssuingCards/routes/listMenuEntry"
import {
  UPDATE_USER_LOCALE,
  UPDATE_USER_LOCALE_ARGS,
  UPDATE_USER_LOCALE_RESPONSE,
  UPDATE_USER_LOCALE_RESPONSE_SUCCESS,
} from "./merchant.menu.requests"
import { MerchantMenuEntry } from "./merchant.menu.type"
import { UserManagementMenuEntry } from "./UserManagement/UserManagement.menuEntry"

interface CollapsibleProps {
  $isMenuReduced: boolean
}

const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  & img {
    width: 4rem;
    height: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const MenuContainer = styled(KryptonMenu)<CollapsibleProps>`
  overflow-y: auto;
  left: 0;
  width: ${({ $isMenuReduced }) => ($isMenuReduced ? "4.25rem" : "15rem")};
  min-width: ${({ $isMenuReduced }) => ($isMenuReduced ? "4.25rem" : "15rem")};
  transition: width 0.3s;
  align-items: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "inherit")};

  @media (max-width: 768px) {
    position: relative;
  }
`

const MenuListButtonTitle = styled.div<CollapsibleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;

  & .text {
    margin-left: 0.25rem;
    display: ${({ $isMenuReduced }) => ($isMenuReduced ? "none" : "inline")};
  }
`

const ScaleDownIcon = styled.div`
  display: flex;
  transform: scale(0.9);
`

const MenuListButtonWrapper = styled.div`
  margin: 0.25rem 0;
`

const fromCodeLangToUserLocale = (code: string): UserLocale => {
  switch (code) {
    case AvailableLanguage.EN:
      return UserLocale.EN
    case AvailableLanguage.DE:
      return UserLocale.DE
    case AvailableLanguage.FR:
      return UserLocale.FR
    case AvailableLanguage.ES:
      return UserLocale.ES
    case AvailableLanguage.IT:
      return UserLocale.IT
    default:
      throw new Error(`Unknown language code ${code}`)
  }
}

const useUpdateUserLocale = () => {
  const [updateUserLocale, { error, loading, data }] = useMutation<
    UPDATE_USER_LOCALE_RESPONSE,
    UPDATE_USER_LOCALE_ARGS
  >(UPDATE_USER_LOCALE)

  const { error: updateUserLocaleFunctionalError } = unwrapGraphQLResponse<UPDATE_USER_LOCALE_RESPONSE_SUCCESS>(
    data?.updateUserLocale,
  )

  return {
    updateUserLocale,
    updateUserLocaleLoading: loading,
    updateUserLocaleFunctionalError,
    updateUserLocaleTechnicalError: error,
  }
}

const ToggleButton = styled.button<CollapsibleProps>`
  display: flex;
  background-color: unset;
  align-items: center;
  margin: ${({ $isMenuReduced }) => ($isMenuReduced ? "auto" : "0")};
  border: none;
  cursor: pointer;
`

const StyledMenuListButton = styled(DeprecatedMenuListButton)<CollapsibleProps>`
  border-radius: ${({ $isMenuReduced }) => ($isMenuReduced ? "50%" : "0.5rem")};
  width: ${({ $isMenuReduced }) => ($isMenuReduced ? "2.25rem" : "auto")};
  height: ${({ $isMenuReduced }) => ($isMenuReduced ? "2.25rem" : "auto")};
  padding: ${({ $isMenuReduced }) => ($isMenuReduced ? "0" : "0 0.5rem 0 0.3rem")};
  display: flex;
  white-space: nowrap;
  justify-content: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "unset")};
  align-items: ${({ $isMenuReduced }) => ($isMenuReduced ? "center" : "unset")};

  & .text {
    margin-left: 0.25rem;
    display: ${({ $isMenuReduced }) => ($isMenuReduced ? "none" : "inline")};
  }
`

const StyledMenuListEntryButton = styled(StyledMenuListButton)<CollapsibleProps>`
  margin-top: 0.5rem;
`

const ReduceMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`

const LogoAndToggleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const LogoImageContainer = styled.div`
  transition: all 0.6s ease;
`

const MenuAccordionContainer = styled.div`
  margin: 0.25rem 0;

  .hero-accordion {
    padding: 0px;
    margin: 0.25rem 0;
    padding: 0 0.5rem 0 0.3rem;
    background-color: transparent;
    border: none;
  }

  .hero-accordion-content {
    margin-left: 0rem;
    padding: 0rem 0.2rem 0rem 0.2rem;
    overflow: hidden;
  }

  .hero-accordion-chevron-container {
    margin-right: 0rem;

    svg {
      color: ${({ theme }) => theme.colors.grey.$500};
      width: 1rem;
      height: 1rem;
    }
  }
`

const MenuAccordionTitle = styled.div`
  display: flex;
  align-items: center;

  .accordion-title-typography {
    margin-left: 0.25rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.grey.$500};
  }
`

export const DeprecatedMenu = () => {
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useMenuLayoutContext()
  const { bannerAccountInVerification } = useBusinessAccountContext()

  useEffect(() => {
    setIsMobileMenuOpen(false)
  }, [location, setIsMobileMenuOpen])

  if (!isMobile) {
    return <MenuContent />
  }

  return (
    <Sheet open={isMobileMenuOpen} onOpenChange={(open) => setIsMobileMenuOpen(open)}>
      <ScrollArea>
        <SheetContent
          side="left"
          maxWidth="min-content"
          style={{ padding: 0, marginTop: bannerAccountInVerification.bannerHeight }}
        >
          <MenuContent />
        </SheetContent>
      </ScrollArea>
    </Sheet>
  )
}

const MenuContent = () => {
  const { t, unsafeT, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const location = useLocation()
  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement>()
  const isOpen = Boolean(anchorElement)
  const isCWTEnabled = useIsFFCWTActive()
  const apRegulatedEnabled = useIsApRegulatedActive()
  const { updateUserLocale } = useUpdateUserLocale()
  const { isMenuReduced, setIsMenuReduced } = useMenuLayoutContext()
  const { track } = useAmplitude()
  const { isUserAllowedToViewAccounts } = useBusinessAccountContext()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(anchorElement ? undefined : e.currentTarget)
  }

  const isActive = (path: string) => location.pathname.startsWith(path)

  const financingProductMenuEntries: MerchantMenuEntry[] = []

  if (isCWTEnabled) {
    financingProductMenuEntries.push(CwtListMenuEntry)
  }

  if (apRegulatedEnabled) {
    financingProductMenuEntries.push(ApListMenuEntry)
  }

  const setLang = (code: string) => {
    updateUserLocale({ variables: { locale: fromCodeLangToUserLocale(code) } })
    i18n.changeLanguage(code)
  }

  return (
    <>
      <MenuContainer $isMenuReduced={isMenuReduced} mode="light">
        {!isMenuReduced && (
          <DeprecatedMenuProfileButton onClick={handleClick} isOpen={isOpen} mode="light">
            {currentUser.merchantTradingName}
          </DeprecatedMenuProfileButton>
        )}

        <DeprecatedFloatingBox
          anchorElement={anchorElement}
          onClose={() => setAnchorEl(undefined)}
          isOpen={isOpen}
          // eslint-disable-next-line i18next/no-literal-string
          style={{ width: "14rem", zIndex: 9999 }}
        >
          {EnabledLanguages.map((code) => (
            <LanguageSelectMenuEntry
              key={code}
              mode="light"
              countryCode={code}
              onClick={() => setLang(code)}
              selected={i18n.language === code}
            />
          ))}
          <DeprecatedMenuButtonEntry<typeof Link> as={Link} to="/logout" color="danger" mode="light">
            {t("menu.logout")}
          </DeprecatedMenuButtonEntry>
        </DeprecatedFloatingBox>

        {isUserAllowedToViewAccounts && (
          <MenuListButtonWrapper>
            <StyledMenuListButton
              $isMenuReduced={isMenuReduced}
              $variant="label-1"
              mode="light"
              selected={isActive(ProAccountMenuEntry.to)}
              key={ProAccountMenuEntry.to}
              as={Link}
              to={ProAccountMenuEntry.to}
            >
              <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
                <ScaleDownIcon>
                  <BankIcon />
                </ScaleDownIcon>
                <span className="text">{unsafeT(ProAccountMenuEntry.key)}</span>
              </MenuListButtonTitle>
            </StyledMenuListButton>
          </MenuListButtonWrapper>
        )}

        <MenuListButtonWrapper>
          <StyledMenuListButton
            $isMenuReduced={isMenuReduced}
            $variant="label-1"
            mode="light"
            selected={isActive(IssuingCardsMenuEntry.to)}
            key={IssuingCardsMenuEntry.to}
            as={Link}
            to={IssuingCardsMenuEntry.to}
          >
            <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
              <ScaleDownIcon>
                <CreditCardIcon />
              </ScaleDownIcon>
              <span className="text">{unsafeT(IssuingCardsMenuEntry.key)}</span>
            </MenuListButtonTitle>
          </StyledMenuListButton>
        </MenuListButtonWrapper>

        {financingProductMenuEntries.length > 0 && (
          <>
            {isMenuReduced ? (
              <>
                {financingProductMenuEntries.map((entry) => (
                  <MenuListButtonWrapper key={entry.key}>
                    <StyledMenuListButton
                      $isMenuReduced={isMenuReduced}
                      mode="light"
                      selected={isActive(entry.to)}
                      $variant="label-1"
                      as={Link}
                      to={entry.to}
                    >
                      <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
                        <ScaleDownIcon>
                          <Invoices2Icon />
                        </ScaleDownIcon>
                        <span className="text">{unsafeT(entry.key)}</span>
                      </MenuListButtonTitle>
                    </StyledMenuListButton>
                  </MenuListButtonWrapper>
                ))}
              </>
            ) : (
              <MenuAccordionContainer>
                <DeprecatedAccordion
                  chevronPosition="right"
                  isDefaultOpen={isActive(financingProductMenuEntries[0].to)}
                  title={
                    <MenuAccordionTitle>
                      <ScaleDownIcon>
                        <Invoices2Icon />
                      </ScaleDownIcon>
                      <Typography $color="grey.$500" className="accordion-title-typography">
                        {t("menu.financing.title")}
                      </Typography>
                    </MenuAccordionTitle>
                  }
                >
                  {financingProductMenuEntries.map((entry) => (
                    <MenuListButtonWrapper key={entry.key}>
                      <StyledMenuListEntryButton
                        $isMenuReduced={isMenuReduced}
                        mode="light"
                        selected={isActive(entry.to)}
                        $variant="label-1"
                        as={Link}
                        to={entry.to}
                      >
                        <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
                          <span className="text">{unsafeT(entry.key)}</span>
                        </MenuListButtonTitle>
                      </StyledMenuListEntryButton>
                    </MenuListButtonWrapper>
                  ))}
                </DeprecatedAccordion>
              </MenuAccordionContainer>
            )}
          </>
        )}

        {isUserAllowedToViewAccounts && (
          <MenuListButtonWrapper>
            <StyledMenuListButton
              $isMenuReduced={isMenuReduced}
              $variant="label-1"
              mode="light"
              selected={isActive(TransfersMenuEntry.to)}
              key={TransfersMenuEntry.to}
              as={Link}
              to={TransfersMenuEntry.to}
              onClick={() => track("transfer_transactions_clicked")}
            >
              <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
                <ScaleDownIcon>
                  <PaidIcon />
                </ScaleDownIcon>
                <span className="text">{unsafeT(TransfersMenuEntry.key)}</span>
              </MenuListButtonTitle>
            </StyledMenuListButton>
          </MenuListButtonWrapper>
        )}

        <MenuListButtonWrapper>
          <StyledMenuListButton
            $isMenuReduced={isMenuReduced}
            $variant="label-1"
            mode="light"
            selected={isActive(CollectionMenuEntry.to)}
            key={CollectionMenuEntry.to}
            as={Link}
            to={CollectionMenuEntry.to}
          >
            <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
              <ScaleDownIcon>
                <GetPaidIcon />
              </ScaleDownIcon>
              <span className="text">{unsafeT(CollectionMenuEntry.key)}</span>
            </MenuListButtonTitle>
          </StyledMenuListButton>
        </MenuListButtonWrapper>

        {UserManagementMenuEntry.visible && (
          <MenuListButtonWrapper>
            <StyledMenuListButton
              $isMenuReduced={isMenuReduced}
              $variant="label-1"
              mode="light"
              data-test-id={UserManagementMenuEntry["data-test-id"]}
              selected={isActive(UserManagementMenuEntry.to)}
              key={UserManagementMenuEntry.to}
              as={Link}
              to={UserManagementMenuEntry.to}
            >
              <MenuListButtonTitle $isMenuReduced={isMenuReduced}>
                <ScaleDownIcon>
                  <PermIdentityIcon />
                </ScaleDownIcon>
                <span className="text">{unsafeT(UserManagementMenuEntry.key)}</span>
              </MenuListButtonTitle>
            </StyledMenuListButton>
          </MenuListButtonWrapper>
        )}

        <LogoContainer>
          <BoSwitcher isReduced={isMenuReduced} />
          <LogoAndToggleContainer>
            <ToggleButton $isMenuReduced={isMenuReduced} onClick={() => setIsMenuReduced((isReduced) => !isReduced)}>
              {isMenuReduced && <MenuOpenIcon width={30} height={30} />}
              {!isMenuReduced && (
                <ReduceMenuContainer>
                  <MenuReduceIcon width={30} height={30} />{" "}
                  <Typography $variant="body-4-regular">{t("menu.reduce")}</Typography>
                </ReduceMenuContainer>
              )}
            </ToggleButton>
            {!isMenuReduced && (
              <LogoImageContainer>
                <img src={heroLogo} alt="" />
              </LogoImageContainer>
            )}
          </LogoAndToggleContainer>
        </LogoContainer>
      </MenuContainer>
    </>
  )
}
