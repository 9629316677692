import { DeprecatedSpinner, DeprecatedWizard } from "@hero/krypton"
import { useCallback, useEffect, useMemo } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useMenuLayoutContext } from "../../../00_shared/components/MenuLayoutContext"
import { LinkCreateClientStep } from "./ClientStep/ClientStep"
import { LinkCreateConfirmationStep } from "./ConfirmationStep/ConfirmationStep"
import { useGetPaymentLinkFeeConfigurations } from "./CreateLink.requests"
import { useNavigateWithScrolling } from "./CreateLink.utils"
import { CreateLinkProvider } from "./CreateLinkContext"
import { LinkCreateDemandStep } from "./DemandStep/DemandStep"
import { LinkCreatePreviewStep } from "./PreviewStep/PreviewStep"

const CreateLink = () => {
  const { setIsMenuOpen } = useMenuLayoutContext()
  const { t } = useDashboardTranslation()
  const navigate = useNavigateWithScrolling()
  const { pathname } = useLocation()

  useEffect(() => {
    setIsMenuOpen(false)
    return () => {
      setIsMenuOpen(true)
    }
  }, [setIsMenuOpen])

  const onQuit = useCallback(() => {
    navigate("/collection")
  }, [navigate])

  const linkCreationSteps = useMemo(
    () => [
      {
        name: t("bnpl.link.wizard.steps.client"),
        path: "/client",
        component: LinkCreateClientStep,
      },
      {
        name: t("bnpl.link.wizard.steps.demand"),
        path: "/:id/demand",
        component: LinkCreateDemandStep,
      },
      {
        name: t("bnpl.link.wizard.steps.confirmation"),
        path: "/:id/confirmation",
        component: LinkCreateConfirmationStep,
      },
      {
        name: t("bnpl.link.wizard.steps.preview"),
        path: "/:id/preview",
        component: LinkCreatePreviewStep,
      },
    ],
    [t],
  )

  const wizardStepsWithStatus = useMemo(() => {
    return linkCreationSteps.map(({ name }, index) => {
      const currentPath = pathname.split("/").at(-1)
      if (!currentPath) {
        return {
          name,
          active: false,
          completed: false,
        }
      }

      const currentStep = linkCreationSteps.findIndex(({ path }) => path.endsWith(currentPath))

      return {
        name,
        active: index === currentStep,
        completed: index < currentStep,
      }
    })
  }, [linkCreationSteps, pathname])

  return (
    <DeprecatedWizard onQuit={onQuit} steps={wizardStepsWithStatus}>
      <Routes>
        {linkCreationSteps.map(({ name, path, component: Component }) => (
          <Route key={name} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<LinkCreateClientStep />} />
      </Routes>
    </DeprecatedWizard>
  )
}

export const CreateLinkScreen = () => {
  const { feeConfigurations, loading: isPaymentLinkFeeConfigurationsLoading } = useGetPaymentLinkFeeConfigurations()

  if (isPaymentLinkFeeConfigurationsLoading) {
    return <DeprecatedSpinner />
  }

  return (
    <CreateLinkProvider feeConfigurations={feeConfigurations}>
      <CreateLink />
    </CreateLinkProvider>
  )
}
