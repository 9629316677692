import { deprecatedToaster } from "@hero/krypton"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { CurrentUser, useAuthContext } from "../../Auth/auth.context"

export const FeatureGuard: React.FC<{ check: (user: CurrentUser) => boolean; element: JSX.Element }> = ({
  check,
  element,
}) => {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  const allowed = currentUser && check(currentUser)

  useEffect(() => {
    if (!allowed) {
      deprecatedToaster.error("Vous n'avez pas accès a cette page")
      navigate("/")
    }
  }, [allowed, navigate])

  if (currentUser && check(currentUser)) {
    return element
  } else {
    return null
  }
}
