import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedButton,
  DeprecatedFieldInput,
  DeprecatedHeader,
  DeprecatedPagination,
  DeprecatedSpinner,
  DeprecatedTable,
  DeprecatedTableSectionHeader,
  DeprecatedTBody,
  DeprecatedTHead,
  RefreshIcon,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import { ChangeEvent, ReactNode } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { toFrenchDate } from "../../../../00_shared/utils/date.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import {
  getTransactionWordingByFlowType,
  getTransactionWordingByStatus,
  isTransactionReceivableGenerator,
} from "../../../../business/rules/Transaction.rules"
import { HEADER_COLORS } from "../../../../env_variables"
import { ApiErrors } from "../../../../Legacy/components/ApiErrors"
import { Transaction, useGetMerchantTransactions } from "../cockpit.requests"
import { useHandleSearchParams } from "./FilledCockpit.utils"
import { KPIHeader } from "./KPIHeader.screen"

const TableContainer = styled.div`
  overflow-x: auto;
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CustomPagination = styled(DeprecatedPagination)`
  margin: 1.5rem 0;
`

const TotalTransactionContainer = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenteringTD = styled.td`
  text-align: center;
`

const CustomTableSectionHeader = styled(DeprecatedTableSectionHeader)`
  background-color: ${({ theme }) => theme.colors.white};
`

const Form = styled.form`
  & input {
    margin-bottom: 0;
  }
`

const BadgeVariantIconMapping: Record<string, ReactNode> = {
  primary: <ValidIcon />,
  secondary: <ValidIcon />,
  warning: <RefreshIcon />,
  danger: <CloseStrokeIcon />,
}

export type FormType = {
  search: string
}

export type SearchParams = {
  pageNumber: string
  search?: string
}

const pageSize = 50

export const FilledCockpit = () => {
  const { t } = useDashboardTranslation()
  const { unsafeT: unsafeTCommon } = useCommonTranslation()
  const { currentUser } = useAuthContext()
  // const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const { onSearch, onPageChange, pageNumber, search } = useHandleSearchParams()

  const { register, handleSubmit } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      search,
    },
  })

  const searchRegister = register("search")

  // prettier-ignore
  const {
    merchantTransactions,
    totalMerchantTransactions,
    totalPages,
    previousData,
    error,
    loading,
  } = useGetMerchantTransactions(
    { pageSize, pageNumber },
    { search: search || "" },
    { useDemoData: false },
  )

  return (
    <div data-test-id="dashboard-screen">
      <DeprecatedHeader data-test-id="Checkout_cash_filled_cokpit" $colors={HEADER_COLORS}>
        <HeaderContent>
          <Typography as="h1" $variant="title-2-bold">
            {t("checkoutAndCash.cockpit.filledCockpit.title")}
          </Typography>
          {currentUser?.payOfflineAvailable ? (
            <DeprecatedButton<typeof Link>
              as={Link}
              to="/create_payment"
              data-test-id="new-payment-link"
              isLoading={false}
            >
              {t("checkoutAndCash.cockpit.filledCockpit.paymentLinkbutton")}
            </DeprecatedButton>
          ) : null}
        </HeaderContent>
        <Typography $variant="body-3-medium"> {t("checkoutAndCash.cockpit.filledCockpit.subtitle")}</Typography>
      </DeprecatedHeader>
      <KPIHeader />
      <TableContainer>
        <CustomTableSectionHeader>
          <TotalTransactionContainer $variant="body-2-semibold">
            {t("checkoutAndCash.cockpit.filledCockpit.result.count", { count: totalMerchantTransactions })}
          </TotalTransactionContainer>
          <Form name="search" noValidate onSubmit={handleSubmit(onSearch)}>
            <DeprecatedFieldInput
              data-test-id="transaction-search"
              type="search"
              placeholder={t("checkoutAndCash.cockpit.filledCockpit.searchPlaceholder")}
              {...searchRegister}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                searchRegister.onChange(e)
                onSearch({ search: e.target.value })
              }}
            />
          </Form>
        </CustomTableSectionHeader>
        <DeprecatedTable>
          <DeprecatedTHead>
            <tr>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.typeHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.orderNumberHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.clientHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.paymentIdHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.paymentDateHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.amountHeader")}</th>
              <th>{t("checkoutAndCash.cockpit.filledCockpit.table.statusHeader")}</th>
            </tr>
          </DeprecatedTHead>
          <DeprecatedTBody $clickable={!!merchantTransactions.length}>
            {loading && !previousData && (
              <tr>
                <CenteringTD colSpan={7}>
                  <DeprecatedSpinner />
                </CenteringTD>
              </tr>
            )}
            {!merchantTransactions.length && (
              <tr>
                <CenteringTD colSpan={7}>{t("checkoutAndCash.cockpit.filledCockpit.table.noPayment")}</CenteringTD>
              </tr>
            )}
            {merchantTransactions.map((transaction: Transaction) => {
              const isReceivableGenerator = isTransactionReceivableGenerator(transaction)
              const detailLink = `/transactions/${transaction.id}?origin=${encodeURIComponent(
                `/checkout_cash/cockpit?pageNumber=${pageNumber}${search ? `&search=${search}` : ""}`,
              )}`

              const displayedType = getTransactionWordingByFlowType(unsafeTCommon, transaction)
              const transactionStatusWording = getTransactionWordingByStatus(unsafeTCommon, transaction)
              return (
                <tr key={transaction.id}>
                  <td>
                    <TableCellLink to={detailLink} />
                    {displayedType}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    {transaction.orderID}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    {transaction.customerEmail}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    {!isReceivableGenerator && transaction.id}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    {!isReceivableGenerator && toFrenchDate(transaction.createdAt)}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    {toEuros(transaction.amount)}
                  </td>
                  <td>
                    <TableCellLink to={detailLink} />
                    <DeprecatedBadge
                      $variant={transactionStatusWording.color}
                      title={transactionStatusWording.title}
                      icon={BadgeVariantIconMapping[transactionStatusWording.color] ?? <ValidIcon />}
                    >
                      {transactionStatusWording.text}
                    </DeprecatedBadge>
                  </td>
                </tr>
              )
            })}
          </DeprecatedTBody>
        </DeprecatedTable>
        <CustomPagination
          currentPage={pageNumber}
          totalPages={totalPages || 1}
          onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
        />
        <ApiErrors err={error} />
      </TableContainer>
    </div>
  )
}
