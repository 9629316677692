import { DeprecatedErrorBlock, SwitchIcon, Typography } from "@hero/krypton"
import styled from "styled-components"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import {
  DashboardSectionHeader as DeprecatedHeader,
  DashboardSectionHeaderIconWrapper as HeaderIconWrapper,
  DashboardSectionTitle as HeaderTitle,
  DashboardSection as Section,
} from "../../../00_shared/components/krypton2/DashboardSection"
import { Spacer } from "../../../00_shared/components/Spacer"
import { centsToEuros, i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import { toLocalizedDate } from "../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import {
  MarketplaceTransaction,
  useGetMarketplaceTransactionOverview,
} from "../hooks/getMarketplaceTransactionOverview"

export const MarketplaceTransfersOverview = () => {
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const { data, loading, error } = useGetMarketplaceTransactionOverview({
    merchantId: currentUser.merchantId,
    fakeData: false,
  })

  const transactionWithMarketplace = data?.aprTransactions.map((transaction) => {
    const marketplace = data?.marketplaces.find((marketplace) => marketplace.id === transaction.marketplaceId)
    return {
      ...transaction,
      marketplace,
    }
  })

  return (
    <Section $isSticky>
      <DeprecatedHeader>
        <HeaderTitle>
          <HeaderIconWrapper>
            <SwitchIcon />
          </HeaderIconWrapper>
          {t("apRegulated.dashboard.marketplaceTransferOverview.title")}
        </HeaderTitle>

        <Spacer $height=".25rem" />

        <Typography>{t("apRegulated.dashboard.marketplaceTransferOverview.subtitle")}</Typography>
      </DeprecatedHeader>
      <HiddenWrapper>
        <Spacer $height="1rem" />

        {loading && (
          <>
            <Spacer $height="1.5rem" />
            <CenteredLoading />
            <Spacer $height="1.5rem" />
          </>
        )}

        {error && <DeprecatedErrorBlock $margin="0">{error.translatedMessage}</DeprecatedErrorBlock>}

        {transactionWithMarketplace && (
          <>
            <CardList>
              {transactionWithMarketplace.map((item) => (
                <Card key={item.id} {...item} />
              ))}
            </CardList>

            {/* <Spacer $height="1rem" />

            <DeprecatedButton
              $fullWidth
              $variant="secondary"
              size="small"
              onClick={() => {
                alert("not implemented yet")
              }}
            >
              {t("apRegulated.dashboard.marketplaceTransferOverview.seeAll")}
            </DeprecatedButton> */}
          </>
        )}
      </HiddenWrapper>
    </Section>
  )
}

const Card = (
  props: MarketplaceTransaction & {
    marketplace?: { name: string; logoUrl: string | null }
  },
) => {
  const { lang } = useDashboardTranslation()

  return (
    <CardRelative>
      <CardWrapper>
        {props.marketplace?.logoUrl && <Icon src={props.marketplace.logoUrl}></Icon>}
        <CardText>
          <Typography $variant="caption-2" style={{ color: "black" }}>
            {props.marketplace?.name}
          </Typography>
          <Typography $variant="caption-2" style={{ color: "#696969" }}>
            {toLocalizedDate(props.createdAt, lang)}
          </Typography>
          {/* {props.nbOfReconciledAp > 0 && (
            <Typography $variant="caption-2" style={{ color: "#696969" }}>
              {props.nbOfReconciledAp === 1 && (
                <>
                  {t("apRegulated.dashboard.marketplaceTransferOverview.oneApClosed", {
                    nbOfReconciledAp: props.nbOfReconciledAp,
                  })}
                </>
              )}

              {props.nbOfReconciledAp > 1 && (
                <>
                  {t("apRegulated.dashboard.marketplaceTransferOverview.multipleApsClosed", {
                    nbOfReconciledAp: props.nbOfReconciledAp,
                  })}
                </>
              )}
            </Typography>
          )} */}
        </CardText>
        <Typography $variant="body-4-semibold">{i18nCurrencyFormatter(centsToEuros(props.amount), lang)}</Typography>
      </CardWrapper>
      <MultiCardEffect $isActivated={false} />
    </CardRelative>
  )
}

const HiddenWrapper = styled.div`
  position: relative;
  z-index: 10;
`

const CardText = styled.div`
  flex: 1;
`

const CardRelative = styled.div`
  position: relative;
`

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
`

const MultiCardEffect = styled.div<{ $isActivated: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${({ $isActivated: isActivated }) => (isActivated ? "-.25rem" : "0")};
  z-index: 1;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
`
