import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { Spacer } from "../../00_shared/components/Spacer"
import { Button } from "../../00_shared/components/krypton2/Button"
import Preview from "./assets/accelerated-payout-preview.png"
import { useDashboardTranslation } from "../../01_technical/translations"

export const EmptyScreen = ({ isApActivated }: { isApActivated: boolean }) => {
  const { t } = useDashboardTranslation()

  const title = isApActivated ? t("apRegulated.home.noAp.title") : t("apRegulated.home.notActivated.title")
  const subtitle = isApActivated ? t("apRegulated.home.noAp.subtitle") : t("apRegulated.home.notActivated.subtitle")
  const cta = t("apRegulated.home.notActivated.cta")

  const activateAp = null

  return (
    <Wrapper>
      <Content>
        <Typography $variant="title-1-bold">{title}</Typography>
        <Spacer $height="1.5rem" />
        <Typography
          $variant="body-3-regular"
          style={{
            maxWidth: "32rem",
          }}
        >
          {subtitle}
        </Typography>
        <Spacer $height="1.5rem" />
        {!isApActivated && activateAp && (
          <AlignLeft>
            <Button size="small">{cta}</Button>
          </AlignLeft>
        )}
      </Content>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  gap: 5.5rem;
`

const Content = styled.div`
  flex: 1;
  min-width: 32rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
`

const ImageWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4rem 0;

  // trick to not crop the image shadow
  padding-left: 4rem;
  margin-left: -4rem;
`

const Image = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.strong};
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 34rem;
  border-radius: 0.625rem 0 0 0.625rem;
  aspect-ratio: 52.875 / 34;
  background-position: left;
  background-image: url(${Preview});
  background-size: cover;
`

const AlignLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`
