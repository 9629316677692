import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedButton,
  RefreshIcon,
  Separator,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import { TFunction } from "i18next"
import { ReactNode, useState } from "react"
import styled from "styled-components"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../../00_shared/utils/date.converter"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { PaymentType } from "../../../../../business/enums/Payment.enum"
import { TransactionFlowType, TransactionStatus } from "../../../../../business/enums/Transaction.enum"
import {
  getTransactionWordingByStatus,
  isTransactionReceivableGenerator,
} from "../../../../../business/rules/Transaction.rules"
import { Refunds, Transaction } from "../transaction.hooks"
import { RefundModalV2 } from "./refundV2/askForRefund.modal"

const MainContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  padding: 2rem;
  margin-bottom: 2rem;
`

const MainSection = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

const InfoSection = styled.article``

const InfoContainer = styled(Typography)`
  margin-top: 0.125rem;
`

const Info = styled.li`
  width: 100%;
  color: ${({ theme }) => theme.colors.grey.$500};
`
const InfoName = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`
const InfoValue = styled.span`
  display: inline-block;
  width: 50%;
  text-align: right;
`

const InfoInstallment = styled.span`
  display: inline-block;
  width: 100%;
  text-align: right;
`

const ActionsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
`
const PaymentTypeMapper = (t: TFunction): Record<PaymentType, string> => ({
  Pay1X: t("checkoutAndCash.cockpit.transaction.mainInfos.paymentTypePay1X"),
  Pay3X: t("checkoutAndCash.cockpit.transaction.mainInfos.paymentTypePay3X"),
  Pay4X: t("checkoutAndCash.cockpit.transaction.mainInfos.paymentTypePay4X"),
  Pay30D: t("checkoutAndCash.cockpit.transaction.mainInfos.paymentTypePay30D"),
  Pay60D: t("checkoutAndCash.cockpit.transaction.mainInfos.paymentTypePay60D"),
})

const FlowTypeMapper = (t: TFunction): Record<TransactionFlowType, string> => ({
  [TransactionFlowType.ONLINE]: t("checkoutAndCash.cockpit.transaction.mainInfos.flowTypeOnline"),
  [TransactionFlowType.OFFLINE]: t("checkoutAndCash.cockpit.transaction.mainInfos.flowTypeOffline"),
  [TransactionFlowType.RECEIVABLE]: t("checkoutAndCash.cockpit.transaction.mainInfos.flowTypeReceivable"),
})

const BadgeVariantIconMapping: Record<string, ReactNode> = {
  primary: <ValidIcon />,
  secondary: <ValidIcon />,
  warning: <RefreshIcon />,
  danger: <CloseStrokeIcon />,
}

export const TransactionMainInfo: React.FC<{ transaction: Transaction; refunds: Refunds; refetch: () => void }> = ({
  transaction,
  refunds,
  refetch,
}) => {
  const { unsafeT: commonUnsafeT } = useCommonTranslation()
  const { t, unsafeT: dashboardUnsafeT, i18n } = useDashboardTranslation()

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)

  const isPayment = transaction.paymentId
  const isReceivable = isTransactionReceivableGenerator(transaction)

  const statusInfo = getTransactionWordingByStatus(commonUnsafeT, transaction)

  return (
    <MainContent>
      <MainSection>
        <Typography as="h2" $variant="title-3-inter-bold">
          {toEuros(transaction.amount)}
        </Typography>
        <DeprecatedBadge
          $variant={statusInfo.color}
          title={statusInfo.title}
          icon={BadgeVariantIconMapping[statusInfo.color] ?? <ValidIcon />}
        >
          {statusInfo.text}
        </DeprecatedBadge>
      </MainSection>

      {transaction.customer && (
        <InfoSection>
          <Typography as="h3" $variant="body-4-medium">
            {t("checkoutAndCash.cockpit.transaction.mainInfos.clientTitle")}
          </Typography>
          <InfoContainer as="ul" $variant="body-4-regular">
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.clientEmailLabel")}</InfoName>
              <InfoValue>{transaction.customer.contactEmail}</InfoValue>
            </Info>
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.clientSiretLabel")}</InfoName>
              <InfoValue>{transaction.customer.siret}</InfoValue>
            </Info>
          </InfoContainer>
        </InfoSection>
      )}

      <Separator />

      <InfoSection>
        <Typography as="h3" $variant="body-4-medium">
          {t("checkoutAndCash.cockpit.transaction.mainInfos.detailsTitle")}
        </Typography>
        <InfoContainer as="ul" $variant="body-4-regular">
          <Info>
            <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.detailsTypeLabel")}</InfoName>
            <InfoValue>{FlowTypeMapper(dashboardUnsafeT)[transaction.flowType]}</InfoValue>
          </Info>
          <Info>
            <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.detailsOrderLabel")}</InfoName>
            <InfoValue>{transaction.orderId}</InfoValue>
          </Info>
          {!isReceivable && (
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.detailsPaymentLabel")}</InfoName>
              <InfoValue>{transaction.id}</InfoValue>
            </Info>
          )}
          {transaction.initiatedAt && (
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.detailsPaidBy")}</InfoName>
              <InfoValue>{toInternationalDate({ date: transaction.initiatedAt, language: i18n.language })}</InfoValue>
            </Info>
          )}
        </InfoContainer>
      </InfoSection>

      <Separator />

      {transaction.installments.length > 0 || isReceivable ? (
        <>
          <InfoSection>
            <Typography as="h3" $variant="body-4-medium">
              {t("checkoutAndCash.cockpit.transaction.mainInfos.timelineTitle")}
            </Typography>
            <InfoContainer as="ul" $variant="body-4-regular">
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.timelineMethodLabel")}</InfoName>
                <InfoValue>{transaction.type ? PaymentTypeMapper(dashboardUnsafeT)[transaction.type] : ""}</InfoValue>
              </Info>
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.timelineDueDateLabel")}</InfoName>
                <InfoValue>
                  {transaction.installments
                    .map((installment) => toInternationalDate({ date: installment.dueDate, language: i18n.language }))
                    .join(", ")}
                </InfoValue>
              </Info>
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.timelinePaidAmountLabel")}</InfoName>
                <InfoValue>{toEuros(transaction.paidAmount)}</InfoValue>
              </Info>
            </InfoContainer>
          </InfoSection>
          <Separator />
        </>
      ) : null}

      {isPayment && (
        <InfoSection>
          <Typography as="h3" $variant="body-4-medium">
            {t("checkoutAndCash.cockpit.transaction.mainInfos.summaryTitle")}
          </Typography>
          <InfoContainer as="ul" $variant="body-4-regular">
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryAmountLabel")}</InfoName>
              <InfoValue>
                {toEuros(transaction.status !== TransactionStatus.REFUSED ? transaction.amount : 0)}
              </InfoValue>
            </Info>
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryRefundsLabel")}</InfoName>
              <InfoValue>{toEuros(refunds.total)}</InfoValue>
            </Info>
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryRefundsProcessingLabel")}</InfoName>
              <InfoValue>{toEuros(refunds.totalProcessing)}</InfoValue>
            </Info>
            {!!refunds.refunds.length && (
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryRefundsListLabel")}</InfoName>
                <InfoValue>
                  {refunds.refunds.length ? (
                    <ul>
                      {" "}
                      {refunds.refunds.map((refund) => (
                        <InfoInstallment as="li" key={refund.id}>
                          {toInternationalDate({ date: refund.createdAt, language: i18n.language })} :{" "}
                          {toEuros(refund.amount)}
                        </InfoInstallment>
                      ))}{" "}
                    </ul>
                  ) : (
                    t("checkoutAndCash.cockpit.transaction.mainInfos.summaryNoRefunds")
                  )}
                </InfoValue>
              </Info>
            )}
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryMerchantFeeLabel")}</InfoName>
              <InfoValue>{toEuros(transaction.merchantFee ?? 0)}</InfoValue>
            </Info>
            {!!refunds.feesHTRefunded && (
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryFeesRefundLabel")}</InfoName>
                <InfoValue>{toEuros(refunds.feesHTRefunded)}</InfoValue>
              </Info>
            )}
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryMerchantVATLabel")}</InfoName>
              <InfoValue>{toEuros(transaction.merchantVAT ?? 0)}</InfoValue>
            </Info>
            {!!refunds.feesVatRefunded && (
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryFeesVATRefundLabel")}</InfoName>
                <InfoValue>{toEuros(refunds.feesVatRefunded)}</InfoValue>
              </Info>
            )}
            <Info>
              <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryAmountAddedToBalanceLabel")}</InfoName>
              <InfoValue>{toEuros(transaction.amountAddedToBalance ?? 0)}</InfoValue>
            </Info>
            {transaction.reserve ? (
              <Info>
                <InfoName>{t("checkoutAndCash.cockpit.transaction.mainInfos.summaryReserveLabel")}</InfoName>
                <InfoValue>{toEuros(transaction.reserve)}</InfoValue>
              </Info>
            ) : null}
          </InfoContainer>
        </InfoSection>
      )}

      {
        <>
          <Separator />
          <ActionsSection>
            <DeprecatedButton
              isLoading={false}
              $variant="tertiary"
              size="medium"
              onClick={() => setIsRefundModalOpen(true)}
            >
              {t("checkoutAndCash.cockpit.transaction.mainInfos.refundButton")}
            </DeprecatedButton>
          </ActionsSection>
          <RefundModalV2
            isOpen={isRefundModalOpen}
            transaction={transaction}
            refundsData={refunds}
            onClose={() => {
              setIsRefundModalOpen(false)
            }}
            onRefunded={() => {
              setIsRefundModalOpen(false)
            }}
            refetch={refetch}
          />
        </>
      }
    </MainContent>
  )
}
