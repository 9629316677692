import { DeprecatedSpinner } from "@hero/krypton"
import LinkEmpty from "../../../assets/collection/link_empty.png"
import { EmptyScreen } from "../00_shared/components/EmptyScreen"
import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"
import { NewLinkButton } from "./NewLinkButton"

export const EmptyScreenLink = () => {
  const productContext = useProductContext()

  if (!productContext) {
    return <DeprecatedSpinner />
  }

  const isProductActivated = productContext.linkEnabled && productContext.linkScope !== PaymentConfigurationScope.NONE

  if (!isProductActivated) {
    return (
      <EmptyScreen
        imgSrc={LinkEmpty}
        titleKey="collection.link.notActivated.title"
        descriptionKey="collection.link.notActivated.description"
      />
    )
  }

  return (
    <EmptyScreen
      imgSrc={LinkEmpty}
      titleKey="collection.link.empty.title"
      descriptionKey="collection.link.empty.description"
      action={<NewLinkButton />}
    />
  )
}
