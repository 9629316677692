import {
  ArrowLeftIcon,
  CheckIcon,
  DeprecatedButton,
  deprecatedToaster,
  RefreshIcon,
  Separator,
  theme,
  Typography,
} from "@hero/krypton"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent, CardHeader } from "../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceStatus, IssuanceType } from "../../00_shared/business/IssuingCard"
import CardPhysicalOrderedIcon from "../../00_shared/icons/card-debit.png"
import CardVirtualIcon from "../../00_shared/icons/card-virtual-give.png"
import { CreateCardMutationResult, useCreateCard } from "../00_shared/hooks/useCreateCard.hook"
import { useIssuingCardCreationContext } from "../IssuingCardCreationContext"
import { CardOrderedScreen } from "./CardOrdered.screen"

const CardHeaderContainer = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey.$200};

  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.grey.$100} 100%
  );
`

const DeprecatedLabel = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const ConfirmCardCreation: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()
  const { state, goToPreviousStep } = useIssuingCardCreationContext()
  const { createCard, error, loading } = useCreateCard()

  const [isCardCreated, setIsCardCreated] = useState(false) // New local state to track card creation
  const [createdCardData, setCreatedCardData] = useState<CreateCardMutationResult | null>(null) // Store the created card data

  const cardTypeLabel = state.cardDetails.cardType === IssuanceType.PHYSICAL ? "Physique" : "Virtuelle"
  const cardIcon = state.cardDetails.cardType === IssuanceType.PHYSICAL ? CardPhysicalOrderedIcon : CardVirtualIcon

  const handleCreateCard = async () => {
    track("card_summary_validation_clicked", { cardType: state.cardDetails.cardType })
    const result = await createCard(state.businessAccountId, state.cardholderId, state.cardDetails)
    if (result?.id) {
      deprecatedToaster.success(t("issuing.cardOrdered.success"))
      if (result.status === IssuanceStatus.ORDERED) {
        setIsCardCreated(true)
        setCreatedCardData(result)
      } else {
        handleRedirect()
      }
    } else if (error) {
      deprecatedToaster.error(error.translatedMessage)
    }
  }

  const handleRedirect = () => {
    navigate("/cards/list")
  }

  const handleGoBack = () => {
    track("card_previous_card_summary_selection_clicked", { cardType: state.cardDetails.cardType })
    goToPreviousStep()
  }

  useEffect(() => {
    if (error) {
      deprecatedToaster.error(error.translatedMessage)
    }
  }, [error])

  // Switch to CardOrderedScreen after card creation
  if (isCardCreated && createdCardData) {
    const steps =
      state.cardDetails.cardType === IssuanceType.PHYSICAL
        ? [
            {
              label: t("issuing.cardOrdered.step1.label"),
              description: t("issuing.cardOrdered.step1.description"),
              icon: <CheckIcon color={theme.colors.success.$300} />,
              date: createdCardData.formattedOrderedDate,
            },
            {
              label: t("issuing.cardOrdered.step2.label"),
              description: t("issuing.cardOrdered.step2.description"),
              icon: <RefreshIcon color={theme.colors.black.$700} />,
              date: createdCardData.formattedProductionDate,
            },
            {
              label: t("issuing.cardOrdered.step3.label"),
              description: t("issuing.cardOrdered.step3.description"),
              date: createdCardData.formattedReceptionDate,
            },
          ]
        : [
            {
              label: t("issuing.cardOrdered.step1.label"),
              description: t("issuing.cardOrdered.step1.description"),
              icon: <RefreshIcon color={theme.colors.black.$700} />,
            },
            {
              label: t("issuing.cardOrdered.step2.label"),
              description: t("issuing.cardOrdered.step2.description"),
            },
          ]

    return (
      <CardOrderedScreen
        cardType={state.cardDetails.cardType}
        cardIcon={cardIcon}
        title={t("issuing.cardOrdered.title", { cardType: cardTypeLabel })}
        description={t("issuing.cardOrdered.description")}
        steps={steps}
        onClickNext={handleRedirect}
      />
    )
  }

  return (
    <FlexContainer $direction="column" $gap="2rem">
      <Typography $variant="title-1-bold">{t("issuing.wizard.confirm.title")}</Typography>
      <Card>
        <CardHeaderContainer>
          <FlexContainer $direction="column" style={{ minWidth: 0, overflowX: "hidden" }}>
            <Typography $variant="body-4-medium" $color="grey.$500">
              {t("issuing.wizard.confirm.card.label")}
            </Typography>
            {state.cardDetails.cardType === IssuanceType.PHYSICAL && (
              <Typography $variant="title-1-bold">{t("issuing.wizard.confirm.card.title.physical")}</Typography>
            )}
            {state.cardDetails.cardType === IssuanceType.VIRTUAL && (
              <Typography $variant="title-1-bold">{t("issuing.wizard.confirm.card.title.virtual")}</Typography>
            )}
          </FlexContainer>
          <div>
            <img width={80} src={cardIcon} alt={cardTypeLabel} />
          </div>
        </CardHeaderContainer>
        <CardContent style={{ padding: "2rem" }}>
          <FlexContainer $direction="column" $gap="1rem">
            <FlexContainer $justify="space-between">
              <DeprecatedLabel>{t("issuing.wizard.confirm.cardholder.label")}</DeprecatedLabel>
              <Typography $variant="body-4-medium">{state.cardholderFullname}</Typography>
            </FlexContainer>
            <Separator />
            <FlexContainer $justify="space-between">
              <DeprecatedLabel>{t("issuing.wizard.confirm.associatedAccount.label")}</DeprecatedLabel>
              <Typography $variant="body-4-medium">{state.businessAccountName}</Typography>
            </FlexContainer>
            <Separator />
            {state.cardDetails.cardType === IssuanceType.PHYSICAL && state.cardDetails.address && (
              <>
                <FlexContainer $justify="space-between">
                  <DeprecatedLabel>{t("issuing.wizard.confirm.deliveryAddress.label")}</DeprecatedLabel>
                  <FlexContainer $direction="column">
                    <Typography $variant="body-4-medium" style={{ textAlign: "end" }}>
                      {state.cardDetails.address.address1}
                    </Typography>
                    <Typography $variant="body-4-medium" style={{ textAlign: "end" }}>
                      {`${state.cardDetails.address.zipCode} ${state.cardDetails.address.city}, ${state.cardDetails.address.country}`}
                    </Typography>
                  </FlexContainer>
                </FlexContainer>
                <Separator />
              </>
            )}
            <FlexContainer $justify="space-between">
              <DeprecatedLabel>{t("issuing.wizard.confirm.monthlyCost.label")}</DeprecatedLabel>
              <Typography $variant="body-4-medium" style={{ textAlign: "end", textWrap: "balance" }}>
                {t("issuing.wizard.confirm.monthlyCost.info")}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </CardContent>
      </Card>

      <FlexContainer $direction="column" $align="center" $gap="2rem">
        <DeprecatedButton isLoading={loading} $variant="primary" size="medium" $fullWidth onClick={handleCreateCard}>
          {t("issuing.wizard.confirm.validate")}
        </DeprecatedButton>
        <DeprecatedButton disabled={loading} type="button" size="medium" $variant="underline" onClick={handleGoBack}>
          <ArrowLeftIcon />
          {t("issuing.wizard.confirm.back")}
        </DeprecatedButton>
      </FlexContainer>
    </FlexContainer>
  )
}
