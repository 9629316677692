import {
  DeprecatedFloatingBox,
  DeprecatedPagination,
  DeprecatedSpinner,
  DeprecatedTable,
  DeprecatedTBody,
  DeprecatedTHead,
  LinkIcon,
  MoreIcon,
  RefreshIcon,
  Typography,
  ValidIcon,
  WarningIcon,
  WatchLaterIcon,
} from "@hero/krypton"
import { DateTime } from "luxon"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../00_shared/hooks/useQuerySearchParams.hook"
import { toEuros } from "../../../00_shared/utils/currency.converter"
import { toFrenchDate } from "../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import CheckoutEmpty from "../../../assets/collection/checkout_empty.png"
import LinkEmpty from "../../../assets/collection/link_empty.png"
import { ApiErrors } from "../../../Legacy/components/ApiErrors"
import { useHandleSearchParams } from "../../CheckoutAndCash/Cockpit/components/FilledCockpit.utils"
import { DiscoverNewSolution } from "../00_shared/components/DiscoverNewSolution"
import { LinkModal } from "../00_shared/components/LinkModal"
import { PaymentStatusBadge } from "../00_shared/components/PaymentStatusBadge/PaymentStatusBadge"
import { RefundModal } from "../00_shared/components/Refund/RefundModal"
import {
  BNPLProductType,
  PaymentConfigurationScope,
  PaymentStatus,
  PaymentType,
} from "../00_shared/enums/PaymentCore.enum"
import { EmptyScreenAll } from "../components/EmptyScreenAll"
import { EmptyScreenCheckout } from "../components/EmptyScreenCheckout"
import { EmptyScreenLink } from "../components/EmptyScreenLink"
import { useGetPaymentLinkFeeConfigurations } from "../Link/Create/CreateLink.requests"
import { useProductContext } from "../ProductScopeContext"
import { CollectionKPIs } from "./components/CollectionKPIs"
import { CollectionListingFilters, SearchParams } from "./components/ListFilters"
import { isPaymentRefundable } from "./List.business"
import { Payment, useGetPaymentsBnpl } from "./List.requests"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const CustomPagination = styled(DeprecatedPagination)`
  margin: 1rem 0;
`

const CenteringTD = styled.td`
  text-align: center;
`

const IconButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.grey.$400};
  transition: all 0.3s ease;
  background: none;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.grey.$500};
  }
`

const Dialog = styled(DeprecatedFloatingBox)`
  top: 2rem !important;
  left: -2rem !important;
`

const DialogOption = styled.span`
  display: flex;
  flex: 1;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$200};
  }
`

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 3rem;
  flex: 1;
`

const RefundAction = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger.$200};
`

const pageSize = 50

export const CollectionListScreen = () => {
  const { t } = useDashboardTranslation()
  const { onPageChange, pageNumber } = useHandleSearchParams()
  const { feeConfigurations } = useGetPaymentLinkFeeConfigurations()
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>(undefined)

  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const [anchorEl, setAnchorEl] = useState<{ [index: string]: HTMLElement | undefined }>({})

  const navigate = useNavigate()

  const paymentStatuses = useMemo(
    () => ({
      [PaymentStatus.NOT_STARTED]: {
        label: t("collections.payment.statuses.notStarted"),
        $variant: "disabled" as const,
        icon: <WatchLaterIcon />,
      },
      [PaymentStatus.FINISHED]: {
        label: t("collections.payment.statuses.finished"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.IN_PROGRESS]: {
        label: t("collections.payment.statuses.inProgress"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.REFUNDED]: {
        label: t("collections.payment.statuses.refunded"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.PARTIALLY_REFUNDED]: {
        label: t("collections.payment.statuses.partiallyRefunded"),
        $variant: "warning" as const,
        icon: <RefreshIcon />,
      },
      [PaymentStatus.LATE]: {
        label: t("collections.payment.statuses.late"),
        $variant: "danger" as const,
        icon: <WarningIcon />,
      },
      [PaymentStatus.DEFAULT]: {
        label: t("collections.payment.statuses.default"),
        $variant: "danger" as const,
        icon: <WarningIcon />,
      },
    }),
    [t],
  )

  const paymentTypes = useMemo(
    () =>
      feeConfigurations.map((feeConfiguration) => ({
        id: feeConfiguration.id,
        isBold: feeConfiguration.type !== PaymentType.PAY_1X,
        paymentType: feeConfiguration.type,
        label:
          feeConfiguration.type === PaymentType.PAY_1X
            ? t("collection.list.types.1x")
            : feeConfiguration.type === PaymentType.PAY_ND
              ? t("collection.list.types.nd", { days: feeConfiguration.daysBeforeDueDate })
              : t("collection.list.types.nx", { installmentCount: feeConfiguration.installmentCount }),
      })),
    [feeConfigurations, t],
  )

  const searchParams = {
    page: getSearchParam("page") ? parseInt(getSearchParam("page")) : 1,
    amountUnder: getSearchParam("amountUnder") ? parseInt(getSearchParam("amountUnder")) : undefined,
    amountOver: getSearchParam("amountOver") ? parseInt(getSearchParam("amountOver")) : undefined,
    amountEqual: getSearchParam("amountEqual") ? parseInt(getSearchParam("amountEqual")) : undefined,
    customerEmail: getSearchParam("customerEmail") ? getSearchParam("customerEmail") : undefined,
    paymentStatuses: getSearchParam("paymentStatuses").length > 0 ? getSearchParam("paymentStatuses").split(",") : [],
    paymentTypes: getSearchParam("paymentTypes").length > 0 ? getSearchParam("paymentTypes").split(",") : [],
    dateFrom: getSearchParam("dateFrom") ? DateTime.fromISO(getSearchParam("dateFrom")).toMillis() : undefined,
    dateTo: getSearchParam("dateTo") ? DateTime.fromISO(getSearchParam("dateTo")).toMillis() : undefined,
    productType:
      getSearchParam("productType") === BNPLProductType.LINK
        ? BNPLProductType.LINK
        : getSearchParam("productType") === BNPLProductType.CHECKOUT
          ? BNPLProductType.CHECKOUT
          : undefined,
  }

  const onOpenLink = useCallback((payment: Payment) => {
    setIsLinkModalOpen(true)
    setSelectedPayment(payment)
  }, [])

  const onOpenRefund = useCallback((payment: Payment) => {
    setIsRefundModalOpen(true)
    setSelectedPayment(payment)
  }, [])

  const kind = getSearchParam("kind")

  const productType =
    kind === "all" || kind === undefined
      ? searchParams.productType
      : kind === "link"
        ? BNPLProductType.LINK
        : BNPLProductType.CHECKOUT

  const { payments, error, loading, refetch } = useGetPaymentsBnpl({
    filters: {
      amountUnder: searchParams.amountUnder,
      amountOver: searchParams.amountOver,
      amountEqual: searchParams.amountEqual,
      customerEmail: searchParams.customerEmail,
      paymentStatuses: searchParams.paymentStatuses,
      paymentTypes: paymentTypes
        .filter((paymentType) => searchParams.paymentTypes.includes(paymentType.id))
        .map((paymentType) => paymentType.paymentType),
      dateFrom: searchParams.dateFrom,
      dateTo: searchParams.dateTo,
      productType: productType,
    },
    pagination: {
      pageNumber,
      pageSize,
    },
  })

  const productContext = useProductContext()

  if (!productContext) {
    return null
  }

  if (loading) {
    return <DeprecatedSpinner />
  }

  if (kind === "all" && payments.length === 0) {
    return <EmptyScreenAll />
  }

  if (
    kind === "link" &&
    (payments.length === 0 ||
      !productContext.linkEnabled ||
      productContext.linkScope === PaymentConfigurationScope.NONE)
  ) {
    return <EmptyScreenLink />
  }

  if (
    kind === "checkout" &&
    (payments.length === 0 ||
      !productContext.checkoutEnabled ||
      productContext.checkoutScope === PaymentConfigurationScope.NONE)
  ) {
    return <EmptyScreenCheckout />
  }

  return (
    <PageContent>
      {kind === "checkout" && (
        <DiscoverNewSolution
          titleKey={`collection.list.discoverNewSolution.link.title`}
          descriptionKey={`collection.list.discoverNewSolution.link.description`}
          imgSrc={LinkEmpty}
          redirectUri={`/collection/settings/link`}
        />
      )}

      {kind === "link" && (
        <DiscoverNewSolution
          titleKey={`collection.list.discoverNewSolution.checkout.title`}
          descriptionKey={`collection.list.discoverNewSolution.checkout.description`}
          imgSrc={CheckoutEmpty}
          redirectUri={`/collection/settings/checkout`}
        />
      )}

      {kind === "all" && <CollectionKPIs />}

      <TableContainer>
        <CollectionListingFilters
          setSearchParam={(filters: Partial<SearchParams>) =>
            setSearchParam({
              kind,
              ...filters,
            })
          }
          searchParams={{
            ...searchParams,
            dateFrom: searchParams.dateFrom
              ? DateTime.fromMillis(searchParams.dateFrom).toISODate() ?? undefined
              : undefined,
            dateTo: searchParams.dateTo ? DateTime.fromMillis(searchParams.dateTo).toISODate() ?? undefined : undefined,
          }}
          availablePaymentStatuses={Object.entries(paymentStatuses).map(([key, value]) => ({
            status: key,
            ...value,
          }))}
          availablePaymentTypes={paymentTypes}
          hideSupportFilter={kind === "link" || kind === "checkout"}
        />

        <LinkModal
          isOpen={isLinkModalOpen}
          paymentLinkId={selectedPayment?.id}
          onClose={() => setIsLinkModalOpen(false)}
        />

        <RefundModal
          isOpen={isRefundModalOpen}
          payment={selectedPayment}
          onClose={() => setIsRefundModalOpen(false)}
          refetch={refetch}
        />

        <DeprecatedTable hideOverflow>
          <DeprecatedTHead>
            <tr>
              <th>{t("collection.list.table.amountHeader")}</th>
              <th>{t("collection.list.table.statusHeader")}</th>
              <th>{t("collection.list.table.typeHeader")}</th>
              <th>{t("collection.list.table.clientHeader")}</th>
              <th>{t("collection.list.table.dateHeader")}</th>
              <th>{t("collection.list.table.supportHeader")}</th>
              <th></th>
            </tr>
          </DeprecatedTHead>
          <DeprecatedTBody $clickable={!!payments.length}>
            {loading && (
              <tr>
                <CenteringTD colSpan={7}>
                  <DeprecatedSpinner />
                </CenteringTD>
              </tr>
            )}

            {!payments.length && !loading && (
              <tr>
                <CenteringTD colSpan={7}>{t("checkoutAndCash.cockpit.filledCockpit.table.noPayment")}</CenteringTD>
              </tr>
            )}

            {payments.map((payment) => {
              const paymentTypeLabel =
                payment.paymentType === PaymentType.PAY_1X
                  ? t("collection.list.types.1x")
                  : payment.paymentType === PaymentType.PAY_ND
                    ? t("collection.list.types.nd", { days: payment.daysBeforeDueDate })
                    : t("collection.list.types.nx", { installmentCount: payment.installmentCount })

              const hasActionOptions = payment.productType === "LINK" || isPaymentRefundable(payment)

              return (
                <tr
                  key={payment.id}
                  onClick={() => {
                    navigate(`/collection/detail/${payment.id}`)
                  }}
                >
                  <td>{toEuros(payment.amount)}</td>
                  <td>
                    <PaymentStatusBadge paymentStatus={payment.paymentStatus} />
                  </td>
                  <td>{payment.paymentType ? paymentTypeLabel : "-"}</td>
                  <td>{payment.customerEmail}</td>
                  <td>{toFrenchDate(payment.createdAt)}</td>
                  <td>
                    {payment.productType === "CHECKOUT"
                      ? t("collection.list.productCheckout")
                      : t("collection.list.productLink")}
                  </td>
                  <td>
                    {hasActionOptions && (
                      <>
                        <IconButton
                          onClick={(event) => {
                            setAnchorEl({
                              ...anchorEl,
                              [payment.id]: anchorEl[payment.id] ? undefined : event.currentTarget,
                            })
                            event.stopPropagation()
                          }}
                        >
                          <MoreIcon />
                        </IconButton>

                        <Dialog
                          isOpen={Boolean(anchorEl[payment.id])}
                          anchorElement={anchorEl[payment.id]}
                          onClose={() =>
                            setAnchorEl({
                              [payment.id]: undefined,
                            })
                          }
                        >
                          {payment.productType === "LINK" && (
                            <DialogOption
                              onClick={(e) => {
                                onOpenLink(payment)
                                e.stopPropagation()
                              }}
                            >
                              <Typography $variant="body-4-regular">{t("collection.list.actions.link")}</Typography>
                              <LinkIcon />
                            </DialogOption>
                          )}
                          {isPaymentRefundable(payment) && (
                            <DialogOption
                              onClick={(e) => {
                                onOpenRefund(payment)
                                e.stopPropagation()
                              }}
                            >
                              <RefundAction $variant="body-4-regular">
                                {t("collection.list.actions.refund")}
                              </RefundAction>
                            </DialogOption>
                          )}
                        </Dialog>
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </DeprecatedTBody>
        </DeprecatedTable>
        <CustomPagination
          currentPage={pageNumber}
          totalPages={1}
          onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
        />
        <ApiErrors err={error} />
      </TableContainer>
    </PageContent>
  )
}
