import { createContext, useCallback, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAmplitude } from "../../../00_shared/hooks/useAmplitude.hook"

type CreateTransferNavigationContextType = {
  navigateToCreateTransfer: (options: { updateTransferOrigin: boolean }) => void
  returnToCreateTransferOrigin: () => void
  createTransferOrigin: string | null
}

const CreateTransferNavigationContext = createContext<CreateTransferNavigationContextType>(
  {} as CreateTransferNavigationContextType,
)

export const useCreateTransferNavigationContext = () => useContext(CreateTransferNavigationContext)

export const CreateTransferNavigationProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [createTransferOrigin, setCreateTransferOrigin] = useState<null | string>(null)

  const { track } = useAmplitude()

  const logAmplitude = useCallback((): void => {
    const pathEventMapping = [
      {
        path: "/accounts/transactions",
        event: "business_account_send_a_transfer_from_list_all_ba_clicked",
      },
      {
        path: "/accounts/account-",
        event: "business_account_select_an_account_send_a_transfer_clicked",
      },
      {
        path: "/transfers/transactions",
        event: "transfer_transactions.send_a_transfer",
      },
    ]

    const matchingPath = pathEventMapping.find(({ path }) => location.pathname.startsWith(path))

    if (matchingPath) {
      track(matchingPath.event)
    }
  }, [location.pathname, track])

  const navigateToCreateTransfer = useCallback(
    (options: { updateTransferOrigin: boolean }) => {
      if (options.updateTransferOrigin) {
        setCreateTransferOrigin(location.pathname)
      }

      logAmplitude()

      navigate("/transfers/create")
    },
    [location.pathname, navigate, logAmplitude],
  )

  const returnToCreateTransferOrigin = useCallback(() => {
    navigate(createTransferOrigin ?? "/transfers")
  }, [navigate, createTransferOrigin])

  return (
    <CreateTransferNavigationContext.Provider
      value={{
        navigateToCreateTransfer,
        returnToCreateTransferOrigin,
        createTransferOrigin,
      }}
    >
      {children}
    </CreateTransferNavigationContext.Provider>
  )
}
