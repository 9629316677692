import {
  DeprecatedBreadcrumb,
  DeprecatedBreadcrumbItem,
  DeprecatedErrorBlock,
  DeprecatedHeader,
  DeprecatedSpinner,
  Typography,
} from "@hero/krypton"
import { TOptions } from "i18next"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useBreadcrumbs } from "../../../../../00_shared/hooks/useBreadcrumbs.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"
import { HEADER_COLORS } from "../../../../../env_variables"
import { TransferStatus } from "../../../00_shared/business/enum/IncomingTransferStatus.enum"
import { TransferDetails, humanFriendlyDate } from "../TransferDetails.component"
import {
  APHistoryMovementType,
  GetAPBankTransferDetailsSuccessResponse,
  HistoryMovement,
} from "./getMerchantBankTransfer.requests"
import { useGetMerchantBankTransfer } from "./useGetMerchantBankTransfer"

export const OutgoingDetails = () => {
  const { t, unsafeT, i18n } = useDashboardTranslation()
  const { id: bankTransferId } = useParams()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (!bankTransferId) {
      navigate("/ap/balance?transaction=outgoing&page=1")
    }
  }, [bankTransferId, navigate])

  if (!bankTransferId) {
    throw new Error("Missing merchantBankTransferId")
  }

  const { data, error, loading } = useGetMerchantBankTransfer(
    { bankTransferId },
    { useDemoData: currentUser.isDemoMerchant },
  )

  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <Typography $variant="body-1-medium" as="h1">
          {t("ap.balance.title")}
        </Typography>
        <DeprecatedBreadcrumb>
          <DeprecatedBreadcrumbItem<typeof Link> as={Link} to={breadcrumbs[0].to}>
            {unsafeT(breadcrumbs[0].label)}
          </DeprecatedBreadcrumbItem>
          <DeprecatedBreadcrumbItem<"p"> as={"p"}>{breadcrumbs[1].label}</DeprecatedBreadcrumbItem>
        </DeprecatedBreadcrumb>
      </DeprecatedHeader>

      {error && <DeprecatedErrorBlock $margin="2rem 3rem">{error.translatedMessage}</DeprecatedErrorBlock>}

      {loading && (
        <LoaderWrapper>
          <DeprecatedSpinner />
          <LoaderTitle>{t("loading")}...</LoaderTitle>
        </LoaderWrapper>
      )}

      {data && (
        <TransferDetails
          referenceId={bankTransferId}
          createdAt={humanFriendlyDate(data.createdAt, i18n.language)}
          iban={data.iban}
          accountName={data.account.name}
          accountNumber={data.account.number}
          marketplaceName={data.marketplaceName}
          amount={data.amount}
          items={data.historyMovements.map((historyMovement) => ({
            ...historyMovement,
            isHighlight: historyMovement.id === data.id,
            label: getMovementLabel(data, historyMovement, t),
            isMovement: ![APHistoryMovementType.BALANCE_BEFORE, APHistoryMovementType.BALANCE_AFTER].includes(
              historyMovement.type,
            ),
          }))}
          status={TransferStatus.SENT}
        />
      )}
    </>
  )
}

const getMovementLabel = (
  bankTransferDetails: GetAPBankTransferDetailsSuccessResponse,
  historyMovement: HistoryMovement,
  t: (key: string, options?: TOptions | undefined) => string,
) => {
  const mapper: Record<HistoryMovement["type"], string> = {
    [APHistoryMovementType.BALANCE_BEFORE]: t("ap.balance.movement.beforeOperation", {
      accountNumber: bankTransferDetails.account.number,
    }),
    [APHistoryMovementType.BANK_TRANSFER]: t("ap.balance.movement.bankTransfer", {
      accountNumber: bankTransferDetails.account.number,
    }),
    [APHistoryMovementType.BANK_TRANSFER_CANCELED]: t("ap.balance.movement.bankTransferCanceled", {
      accountNumber: bankTransferDetails.account.number,
    }),
    [APHistoryMovementType.BANK_TRANSFER_RETURNED]: t("ap.balance.movement.bankTransferReturned", {
      accountNumber: bankTransferDetails.account.number,
    }),
    [APHistoryMovementType.BALANCE_AFTER]: t("ap.balance.movement.afterOperation", {
      accountNumber: bankTransferDetails.account.number,
    }),
    [APHistoryMovementType.AP_CREDIT_GRANTED]: t("ap.balance.movement.apCreditGranted"),
    [APHistoryMovementType.AP_CREDIT_CANCELED]: t("ap.balance.movement.apCreditCanceled"),
    [APHistoryMovementType.AP_CREDIT_FEES]: t("ap.balance.movement.apCreditFees"),
    [APHistoryMovementType.AP_CREDIT_FEES_CANCELED]: t("ap.balance.movement.apCreditFeesCanceled"),
    [APHistoryMovementType.AP_CREDIT_REPAYMENT]: t("ap.balance.movement.apCreditRepayment"),
    [APHistoryMovementType.SWAN_MOVEMENT_ALLOCATED]: t("ap.balance.movement.swanMovementAllocated"),
    [APHistoryMovementType.SWAN_MOVEMENT_REFUND]: t("ap.balance.movement.swanMovementRefund"),
    [APHistoryMovementType.MANUAL_BULK_FEES_COMMISSION]: t("ap.balance.movement.MANUAL_BULK_FEES_COMMISSION"),
    [APHistoryMovementType.MANUAL_BULK_FEES_RETROCOMMISSION]: t("ap.balance.movement.MANUAL_BULK_FEES_RETROCOMMISSION"),
    [APHistoryMovementType.MANUAL_MOVEMENT_ARBITRARY]: t("ap.balance.movement.MANUAL_MOVEMENT_ARBITRARY"),
    [APHistoryMovementType.ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_FROM]: t(
      "ap.balance.movement.ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_FROM",
    ),
    [APHistoryMovementType.ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_TO]: t(
      "ap.balance.movement.ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_TO",
    ),
    [APHistoryMovementType.MARKETPLACE_RESERVE_GRANTED]: t("ap.balance.movement.MARKETPLACE_RESERVE_GRANTED"),
    [APHistoryMovementType.MARKETPLACE_RESERVE_FEE]: t("ap.balance.movement.MARKETPLACE_RESERVE_FEE"),
    [APHistoryMovementType.MARKETPLACE_RESERVE_REPAYMENT]: t("ap.balance.movement.MARKETPLACE_RESERVE_REPAYMENT"),
    [APHistoryMovementType.MARKETPLACE_RESERVE_MANUAL_FEE]: t("ap.balance.movement.MARKETPLACE_RESERVE_MANUAL_FEE"),
    [APHistoryMovementType.MARKETPLACE_RESERVE_MANUAL_REPAYMENT]: t(
      "ap.balance.movement.MARKETPLACE_RESERVE_MANUAL_REPAYMENT",
    ),
  }

  const result = mapper[historyMovement.type]

  if (!result) {
    console.error(`An history movement ${historyMovement.type} is not handled properly. @devs update the front-end`)
    return historyMovement.label
  }

  return result
}

const LoaderWrapper = styled.div<{ $screenHeight?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 2rem 3rem;
`

const LoaderTitle = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`
