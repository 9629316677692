/* eslint-disable i18next/no-literal-string */
import { DeprecatedButton, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { Beneficiary, useGetBeneficiaries } from "../requests/useGetBeneficiaries"
import { AddBeneficiaryModal } from "./AddBeneficiaryModal"
import { AmountFormSection } from "./AmountFormSection"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"
import { BeneficiaryFormSection } from "./BeneficiaryFormSection"
import { FinancingFormSection } from "./FinancingFormSection"

const ComponentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 4rem;
`

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const FullWidthButton = styled(DeprecatedButton)`
  width: 100%;
`

interface ApplicationFormProps {
  onSubmit: () => void
  beneficiaries: Beneficiary[]
  refetchBeneficiaries: ReturnType<typeof useGetBeneficiaries>["refetch"]
}

export const ApplicationForm = ({ onSubmit, beneficiaries, refetchBeneficiaries }: ApplicationFormProps) => {
  const form = useCwtApplicationFormContext()
  const { t } = useDashboardTranslation()
  const [createBeneficiaryModalIsOpen, setCreateBeneficiaryModalIsOpen] = useState(false)

  const isDev = process.env.NODE_ENV === "development"

  return (
    <ComponentContainer>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {isDev && (
          <div
            style={{
              position: "fixed",
              top: "7rem",
              right: "1rem",
              backgroundColor: "white",
              padding: ".25rem",
              border: "1px solid black",
              zIndex: 100,
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              borderRadius: "0.5rem",
            }}
          >
            <p
              style={{
                marginLeft: ".75rem",
              }}
            >
              devtools
            </p>
            <DeprecatedButton
              isLoading={false}
              type="button"
              onClick={() => {
                form.setValue("beneficiary.id", "beneficiary-72a52eb2-20e0-4b93-895b-617edd2d892d")
                form.setValue("beneficiary.email", "supplier@coca-cola.com")
                form.setValue("beneficiary.phone", "+33782510606")
                form.setValue("beneficiary.siret", "12345322345433")
                form.setValue("debtorIBAN", "DE91100000001234400020")
                form.setValue("amount", 1000)
                form.setValue("installmentsCount", 3)
                form.setValue("label", "Boissons soiree")
                form.setValue(
                  "filePath",
                  "cwt-documents/merc-aff7b04b-4a9d-40dd-88b0-df812fd0de01/uploaded/2bfb854b-fe7f-48ee-96fa-d0da684eaf11",
                )
                form.setValue("beneficiary.headquartersCountry", "France")
              }}
            >
              populate form with data
            </DeprecatedButton>
          </div>
        )}

        <AmountFormSection />

        <Spacer $h={"1.5rem"} />

        <FinancingFormSection />

        <Spacer $h={"1.5rem"} />

        <BeneficiaryFormSection
          setCreateBeneficiaryModalIsOpen={setCreateBeneficiaryModalIsOpen}
          beneficiaries={beneficiaries}
        />

        <Spacer $h={"3rem"} />

        <FullWidthButton isLoading={false} type="submit" size="medium">
          {t("creditWireTransfer.applicationForm.next")}
        </FullWidthButton>

        <Spacer $h={".5rem"} />

        <Typography $variant="caption-2">{t("creditWireTransfer.applicationForm.disclaimer")}</Typography>
      </form>

      <AddBeneficiaryModal
        isOpen={createBeneficiaryModalIsOpen}
        closeModal={(newBeneficiaryId?: string) => {
          setCreateBeneficiaryModalIsOpen(false)

          if (newBeneficiaryId) {
            refetchBeneficiaries()
            form.setValue("beneficiary.id", newBeneficiaryId)
          }
        }}
      />
    </ComponentContainer>
  )
}
