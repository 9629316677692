import { useApolloClient } from "@apollo/client"
import { deprecatedToaster } from "@hero/krypton"
import { useState } from "react"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { useCreateLinkFormContext } from "../CreateLink.utils"
import { useCreateLinkContext } from "../CreateLinkContext"
import { PAYMENT_LINK_DOCUMENT_UPLOAD_URL, PaymentLinkDocumentUploadUrlResponse } from "./DemandStep.requests"

export const useCreateLinkUploadDocument = () => {
  const client = useApolloClient()
  const { t } = useDashboardTranslation()

  const { setValue } = useCreateLinkFormContext()
  const { documentFile, setDocumentFile } = useCreateLinkContext()

  const [isUploading, setIsUploading] = useState<boolean>(false)

  const uploadFile = async (file: File) => {
    try {
      setIsUploading(true)
      setDocumentFile(undefined)

      const { signedUrl, filePath } = await getUploadUrl()
      if (!signedUrl || !filePath) {
        throw new Error("Missing signedUrl or filePath")
      }

      const uploadResult = await uploadRequest(signedUrl, file)
      if (!uploadResult.ok) {
        throw new Error("Cannot upload file")
      }

      deprecatedToaster.success(t("bnpl.link.wizard.steps.demand.upload.success"))
      setDocumentFile(file)
      setValue("documentPath", filePath)
    } catch (err: unknown) {
      deprecatedToaster.error(t("bnpl.link.wizard.steps.demand.upload.error"))
      throw err
    } finally {
      setIsUploading(false)
    }
  }

  const clearFile = () => {
    setValue("documentPath", null)
    setDocumentFile(undefined)
  }

  const getUploadUrl = async () => {
    const { data } = await client.query<PaymentLinkDocumentUploadUrlResponse>({
      query: PAYMENT_LINK_DOCUMENT_UPLOAD_URL,
      fetchPolicy: "network-only",
    })

    return {
      signedUrl: data?.paymentLinkDocumentUploadUrl?.signedUrl,
      filePath: data?.paymentLinkDocumentUploadUrl?.path,
    }
  }

  const uploadRequest = (signedUrl: string, file: File) => {
    return fetch(signedUrl, {
      method: "PUT",
      headers: { "Content-Type": file.type, "Content-Length": file.size.toString() },
      body: file,
    })
  }

  return { isUploading, uploadFile, documentFile, clearFile }
}
