import { DeprecatedErrorBlock, DeprecatedTable, DeprecatedTBody, DeprecatedTHead, Typography } from "@hero/krypton"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import styled from "styled-components"
import CenteredLoading from "../../../../../00_shared/components/CenteredLoading"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../../00_shared/utils/date.converter"
import { DashboardTFunction, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"
import { useBusinessAccounts } from "../../../../BusinessAccount/pro-account/00_shared/hooks/useBusinessAccounts"
import { useIsCreditWireTransferActive } from "../../../../merchant.menu.requests"
import { CwtItem, CwtItemStatus, CwtItemStatusFilters, useGetCwtList } from "./useGetCwtList"

const getPaidInstallments = (installments: CwtItem["installments"]) =>
  installments === null ? 0 : installments.filter((installment) => installment.status === "PAID").length

const getNextInstallmentDate = (installments: CwtItem["installments"], language: string) => {
  if (!installments) return
  const nextInstallment = installments.find((installment) => installment.status === "PENDING")
  return nextInstallment ? toInternationalDate({ date: nextInstallment.dueDate, language }) : undefined
}

const getNextInstallmentAmount = (installments: CwtItem["installments"]) => {
  if (!installments) return "N/A"
  const nextInstallment = installments.find((installment) => installment.status === "PENDING")
  return nextInstallment ? toEuros(nextInstallment.amount.totalAmount) : "N/A"
}

const getStatusTranslation = (status: CwtItemStatus, t: DashboardTFunction) => {
  switch (status) {
    case CwtItemStatus.APPROVED:
      return t("creditWireTransfer.list.table.installmentState.approved")
    case CwtItemStatus.WAITING_FOR_REVIEW:
      return t("creditWireTransfer.list.table.installmentState.waitingForReview")
    case CwtItemStatus.REJECTED:
      return t("creditWireTransfer.list.table.installmentState.rejected")
    case CwtItemStatus.COMPLETED:
      return t("creditWireTransfer.list.table.installmentState.completed")
    default:
      return status
  }
}

const statusFilter = (status: string | null): CwtItemStatusFilters => {
  if (status === "ONGOING" || status === "COMPLETED" || status === "REJECTED") {
    return status
  }

  return "ONGOING"
}

export const CwtListTable = () => {
  const { t, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const status = statusFilter(searchParams.get("status"))
  const { data, loading, error } = useGetCwtList(
    { merchantId: currentUser.merchantId, status },
    {
      useDemoData: currentUser.isDemoMerchant,
    },
  )
  const { businessAccounts } = useBusinessAccounts()
  const { data: isCreditWireTransferActive } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })

  const handleAccountClick = (businessAccountId: string | null) => {
    if (!businessAccountId) return
    navigate(`/accounts/${businessAccountId}/transactions`)
  }

  if (error) return <DeprecatedErrorBlock $margin="0">{error.translatedMessage}</DeprecatedErrorBlock>

  const colsName = [
    t("creditWireTransfer.list.table.supplier"),
    t("creditWireTransfer.list.table.financedAmount"),
    t("creditWireTransfer.list.table.fees"),
    t("creditWireTransfer.list.table.paymentExecutionDate"),
    t("creditWireTransfer.list.table.installmentState"),
    t("creditWireTransfer.list.table.nextInstallment"),
  ]

  if (isCreditWireTransferActive?.useBusinessAccount && businessAccounts.length !== 0) {
    colsName.push(t("creditWireTransfer.list.table.businessAccount"))
  }

  return (
    <DeprecatedTable type="card">
      <DeprecatedTHead>
        <tr>
          {colsName.map((field, index) => (
            <th key={index}>{field}</th>
          ))}
        </tr>
      </DeprecatedTHead>

      {loading && (
        <DeprecatedTBody>
          <tr>
            <td colSpan={colsName.length}>
              <CenteredLoading />
            </td>
          </tr>
        </DeprecatedTBody>
      )}

      {data && data.length <= 0 && (
        <DeprecatedTBody>
          <tr>
            <td colSpan={colsName.length}>
              <Center>
                <Typography $variant="body-4-medium">{t("creditWireTransfer.list.table.noResults")}</Typography>
              </Center>
            </td>
          </tr>
        </DeprecatedTBody>
      )}

      <DeprecatedTBody>
        {data &&
          data.length > 0 &&
          data.map((cwt) => (
            <Fragment key={cwt.id}>
              <tr>
                <td>{cwt.recipient.name}</td>
                <td>
                  <Typography $variant="body-4-medium">
                    {toEuros(cwt.amount)}{" "}
                    {cwt.status === CwtItemStatus.WAITING_FOR_REVIEW &&
                      `(${t("creditWireTransfer.list.table.installmentState.waitingForReview").toLowerCase()})`}
                  </Typography>
                </td>
                <td>
                  <Typography $variant="body-4-medium">{toEuros(cwt.feeAmount.amountWithoutVAT)}</Typography>
                </td>
                <td>
                  {cwt.installments?.[0].dueDate
                    ? toInternationalDate({ date: cwt.installments?.[0].dueDate, language: i18n.language })
                    : "N/A"}
                </td>
                <td>
                  <Typography $variant="body-4-medium">{getStatusTranslation(cwt.status, t)}</Typography>
                  {[CwtItemStatus.APPROVED, CwtItemStatus.COMPLETED].includes(cwt.status) && (
                    <Typography $variant="caption-2">
                      {t("creditWireTransfer.list.table.installmentStateDescription", {
                        count: getPaidInstallments(cwt.installments),
                        total: cwt.installmentCount,
                      })}
                    </Typography>
                  )}
                </td>
                <td>
                  <Typography $variant="body-4-medium">{getNextInstallmentAmount(cwt.installments)}</Typography>
                  <Typography $variant="caption-2">
                    {getNextInstallmentDate(cwt.installments, i18n.language)}
                  </Typography>
                </td>
                {isCreditWireTransferActive?.useBusinessAccount &&
                  businessAccounts.length !== 0 &&
                  businessAccounts.find((ba) => ba.ledgerId === cwt.businessAccountId) && (
                    <td>
                      <AccountLink onClick={() => handleAccountClick(cwt.businessAccountId)} $variant="body-4-medium">
                        {businessAccounts.find((ba) => ba.ledgerId === cwt.businessAccountId)?.name}
                      </AccountLink>
                      <Typography $variant="caption-2">
                        {toEuros(businessAccounts.find((ba) => ba.ledgerId === cwt.businessAccountId)?.balance || 0)}
                      </Typography>
                    </td>
                  )}
              </tr>
            </Fragment>
          ))}
      </DeprecatedTBody>
    </DeprecatedTable>
  )
}

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const AccountLink = styled(Typography)`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
