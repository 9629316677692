import { DeprecatedButton, Typography } from "@hero/krypton"
import styled from "styled-components"
import useMediaQuery from "../hooks/useMediaQuery.hook"

type EmptyStateProps = {
  illustration: React.ReactNode
  title: string
  text: string
  buttonIcon?: React.ReactNode
  buttonText: string
  onButtonClick?: () => void
  disabledButton?: boolean
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  illustration,
  title,
  text,
  buttonIcon,
  buttonText,
  onButtonClick,
  disabledButton,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const btnSize = isMobile ? "large" : "medium"
  return (
    <EmptyStateContainer id="EmptyStateContainer">
      <EmptyStateContent>
        {isMobile && <div style={{ flex: 1 }} />}
        <CardImageWrapper>{illustration}</CardImageWrapper>
        <EmptyStateTitle>{title}</EmptyStateTitle>
        <EmptyStateText>{text}</EmptyStateText>
        {isMobile && <div style={{ flex: 1 }} />}
        <EmptyStateButton size={btnSize} onClick={onButtonClick} disabled={disabledButton}>
          {buttonIcon ? buttonIcon : null}
          {buttonText}
        </EmptyStateButton>
      </EmptyStateContent>
    </EmptyStateContainer>
  )
}

const EmptyStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  padding: 5rem;
  flex: 1;

  @media (max-width: 768px) {
    padding: 1rem;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

const EmptyStateContent = styled.div<{ width?: string }>`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: start;
  text-align: start;

  @media (max-width: 768px) {
    flex: 1;
    max-width: 100%;
    text-align: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    max-width: 22rem;
  }
`

const CardImageWrapper = styled.div`
  width: 6.25rem;
  height: 6.25rem;
`

const EmptyStateTitle = styled(Typography).attrs(() => ({
  $variant: "title-2-bold",
}))``

const EmptyStateText = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  padding-bottom: 1rem;
`

const EmptyStateButton = styled(DeprecatedButton).attrs(({ size }) => ({
  variant: "primary",
  size: size || "medium",
}))`
  @media (max-width: 768px) {
    width: 100%;
  }
`
