import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

export const CwtListCards = () => {
  const { t } = useDashboardTranslation()

  return (
    <CardsContainer>
      <Card>
        <Typography $variant="caption-2">{t("creditWireTransfer.list.cards.toRepay.label")}</Typography>
        <Typography $variant="body-3-semibold">{toEuros(0)}</Typography>
      </Card>
      <Card>
        <Typography $variant="caption-2">{t("creditWireTransfer.list.cards.totalFinanced.label")}</Typography>
        <Typography $variant="body-3-semibold">{toEuros(0)}</Typography>
      </Card>
      <Card>
        <Typography $variant="caption-2">{t("creditWireTransfer.list.cards.maxOutstandings.label")}</Typography>
        <Typography $variant="body-3-semibold">{toEuros(0)}</Typography>
      </Card>
      <Card>
        <Typography $variant="caption-2">{t("creditWireTransfer.list.cards.availableOutstandings.label")}</Typography>
        <Typography $variant="body-3-semibold">{toEuros(0)}</Typography>
      </Card>
    </CardsContainer>
  )
}
const Card = styled.article`
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 0.75rem;
`

const CardsContainer = styled.div`
  display: flex;
  gap: 1rem;

  ${Card} {
    flex: 1;
  }
`
