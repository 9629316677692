import { DeprecatedWizard } from "@hero/krypton"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Dialog, DialogContent } from "../../../00_shared/components/Dialog"
import { ScrollArea } from "../../../00_shared/components/ScrollArea"
import { useAuthContext } from "../../../Auth/auth.context"
import { IssuingCardCreationProvider, useIssuingCardCreationContext } from "./IssuingCardCreationContext"

const Container = styled.div`
  height: 100%;
  width: 40rem;
  margin: auto;
  padding: 2rem 0;
`

const WizardWrapper: React.FC<{ onQuit: () => void }> = ({ onQuit }) => {
  const { steps, clearState } = useIssuingCardCreationContext()

  const handleQuit = () => {
    clearState()
    onQuit()
  }

  return (
    <DeprecatedWizard onQuit={handleQuit} steps={steps}>
      <ScrollArea height="calc(100% - 6rem)">
        <Container>
          <Outlet />
        </Container>
      </ScrollArea>
    </DeprecatedWizard>
  )
}

export const IssuingCardCreation: React.FC = () => {
  const navigate = useNavigate()
  const { impersonateBannerHeight } = useAuthContext()

  const onQuit = () => {
    navigate("/cards/list")
  }

  return (
    <Dialog open={true}>
      <DialogContent
        disableCloseButton={true}
        $disableAnimations={true}
        width="100%"
        height="100%"
        topOffset={impersonateBannerHeight}
      >
        <IssuingCardCreationProvider>
          <WizardWrapper onQuit={onQuit} />
        </IssuingCardCreationProvider>
      </DialogContent>
    </Dialog>
  )
}
