import { DeprecatedWizardHeader } from "@hero/krypton"
import { useState } from "react"
import { FormProvider } from "react-hook-form"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useHideMenu } from "../../00_shared/components/MenuLayoutContext"
import { useNavigateWithScrolling } from "../../Collection/Link/Create/CreateLink.utils"
import { CwtApplicationValidation } from "./ApplicationValidation.screen"
import { ApplicationForm } from "./components/ApplicationForm"
import { useCwtApplicationForm } from "./components/ApplicationForm.utils"
import { UploadDocument } from "./components/UploadDocument"
import { useGetBeneficiaries } from "./requests/useGetBeneficiaries"

const ScreenContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`

const BodyChildrenWrapper = styled.div`
  position: relative;
  flex: 1;
  max-height: 100%;
`

export const CwtApplication = () => {
  const form = useCwtApplicationForm()
  const [showValidationScreen, setShowValidationScreen] = useState(false)
  const navigate = useNavigateWithScrolling()
  const { t } = useDashboardTranslation()

  const { data: dataBeneficiary, refetch: refetchBeneficiaries } = useGetBeneficiaries({ pageSize: 50, pageNumber: 1 })

  useHideMenu()

  if (showValidationScreen) {
    return (
      <CwtApplicationValidation
        formValues={form.getValues()}
        goBack={() => setShowValidationScreen(false)}
        beneficiaries={dataBeneficiary?.beneficiaries || []}
      />
    )
  }

  return (
    <FormProvider {...form}>
      <ScreenContainer>
        <DeprecatedWizardHeader
          onQuit={() => {
            navigate("/cwt")
          }}
          steps={[
            { name: t("creditWireTransfer.applicationForm.wizardStep1"), active: true, completed: false },
            { name: t("creditWireTransfer.applicationForm.wizardStep2"), active: false, completed: false },
          ]}
        />
        <BodyWrapper>
          <BodyChildrenWrapper>
            <UploadDocument />
          </BodyChildrenWrapper>
          <BodyChildrenWrapper>
            <ApplicationForm
              onSubmit={() => setShowValidationScreen(true)}
              beneficiaries={dataBeneficiary?.beneficiaries || []}
              refetchBeneficiaries={refetchBeneficiaries}
            />
          </BodyChildrenWrapper>
        </BodyWrapper>
      </ScreenContainer>
    </FormProvider>
  )
}
