import { DeprecatedHeader, DeprecatedTab, DeprecatedTabs, Typography } from "@hero/krypton"
import { Link, Outlet, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { HEADER_COLORS } from "../../../env_variables"
import { billingRoute } from "./billing/billing.route"
import { exportRoute } from "./export/export.route"

const Container = styled.main`
  width: calc(100% - 6rem);
  margin: 1.5rem 3rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const Nav = styled.nav`
  margin-top: 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`

const RouteContainer = styled.section`
  padding: 2rem 0;
  background-color: rgba(255, 255, 255, 0.72);
  border-radius: 0.75rem;
`

export const AccountingScreen = () => {
  const location = useLocation()
  const { t } = useDashboardTranslation()
  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <Typography as="h1" $variant="title-2-bold">
          {t("accounting.title")}
        </Typography>
        <Typography as="strong" $variant="body-3-medium">
          {t("accounting.subtitle")}
        </Typography>
        <Nav>
          <DeprecatedTabs>
            <DeprecatedTab<typeof Link>
              $isSelected={location.pathname.includes(billingRoute.path)}
              as={Link}
              to={billingRoute.path}
              data-test-id="tab-factures"
            >
              {t("accounting.billingTab")}
            </DeprecatedTab>

            <DeprecatedTab<typeof Link>
              $isSelected={location.pathname.includes(exportRoute.path)}
              as={Link}
              to={exportRoute.path}
              data-test-id="tab-exports"
            >
              {t("accounting.exportTab")}
            </DeprecatedTab>
          </DeprecatedTabs>
        </Nav>
      </DeprecatedHeader>

      <Container>
        <RouteContainer>
          <Outlet />
        </RouteContainer>
      </Container>
    </>
  )
}
