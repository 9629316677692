import {
  DeprecatedCheckbox,
  DeprecatedChip,
  DeprecatedField,
  DeprecatedFieldSelect,
  DeprecatedFloatingBox,
  Typography,
} from "@hero/krypton"
import { Dispatch, MouseEvent, SetStateAction, useState } from "react"
import { useForm } from "react-hook-form"
import { eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { ChipBox, FiltersBox, StyledButton } from "../BalanceTables.components"
import { SearchParams } from "./BalanceAllTable"

type Anchor = "accounts" | "amount" | "date" | "ibans"

type AccountsForm = {
  accounts: string[]
}

type AmountForm = {
  amountOption: "under" | "equal" | "over"
  amount?: string
}

type DateForm = {
  dateFrom?: string
  dateTo?: string
}

const resetAnchors = (...setStates: Dispatch<SetStateAction<HTMLElement | undefined>>[]) => {
  for (const setState of setStates) {
    setState(undefined)
  }
}

export const AllTransfersFilters = ({
  setSearchParam,
  searchParams,
  accountLabels,
}: {
  setSearchParam: (filters: Partial<SearchParams>) => void
  searchParams: SearchParams
  accountLabels: string[]
}) => {
  const { accounts = [], amountUnder, amountEqual, amountOver, dateFrom, dateTo } = searchParams

  const { t } = useDashboardTranslation()
  const [anchorAccountsElement, setAnchorAccountsElement] = useState<HTMLElement | undefined>(undefined)
  const [anchorAmountElement, setAnchorAmountElement] = useState<HTMLElement | undefined>(undefined)
  const [anchorDateElement, setAnchorDateElement] = useState<HTMLElement | undefined>(undefined)

  const {
    handleSubmit: handleAccountsSubmit,
    register: registerAccounts,
    setValue: setAccountsFormValue,
  } = useForm<AccountsForm>({
    defaultValues: { accounts },
  })
  const {
    handleSubmit: handleAmountSubmit,
    register: registerAmount,
    setValue: setAmountFormValue,
  } = useForm<AmountForm>({
    defaultValues: {
      amountOption: amountUnder ? "under" : amountEqual ? "equal" : amountOver ? "over" : undefined,
      amount: amountUnder ?? amountEqual ?? amountOver,
    },
  })
  const {
    handleSubmit: handleDateSubmit,
    register: registerDate,
    setValue: setDateFormValue,
  } = useForm<DateForm>({
    defaultValues: { dateFrom, dateTo },
  })

  const handleAnchors = (event: MouseEvent<HTMLElement>, anchor: Anchor) => {
    resetAnchors(setAnchorAccountsElement, setAnchorAmountElement, setAnchorDateElement)

    if (anchor === "accounts") {
      setAnchorAccountsElement(anchorAccountsElement ? undefined : event.currentTarget)
    }

    if (anchor === "amount") {
      setAnchorAmountElement(anchorAmountElement ? undefined : event.currentTarget)
    }

    if (anchor === "date") {
      setAnchorDateElement(anchorDateElement ? undefined : event.currentTarget)
    }
  }

  // Accounts Filter

  const accountsChipValue = !accounts.length
    ? ""
    : accounts.length === 1
      ? titleCase(accounts[0])
      : `${titleCase(accounts[0])}, +${accounts.length - 1}`

  const isAccountsOpen = Boolean(anchorAccountsElement)

  const submitAccounts = ({ accounts }: AccountsForm) => {
    setSearchParam({ ...searchParams, accounts })
    setAnchorAccountsElement(undefined)
    setAccountsFormValue("accounts", accounts)
  }

  // Amount Filter

  const amountChipValue = amountUnder
    ? `< ${toEuros(eurosToCents(Number(amountUnder)))}`
    : amountEqual
      ? `= ${toEuros(eurosToCents(Number(amountEqual)))}`
      : amountOver
        ? `> ${toEuros(eurosToCents(Number(amountOver)))}`
        : ""

  const isAmountOpen = Boolean(anchorAmountElement)

  const submitAmount = ({ amountOption, amount }: AmountForm) => {
    setSearchParam({
      ...searchParams,
      amountUnder: amountOption === "under" && amount ? amount : undefined,
      amountEqual: amountOption === "equal" && amount ? amount : undefined,
      amountOver: amountOption === "over" && amount ? amount : undefined,
    })
    setAnchorAmountElement(undefined)
    setAmountFormValue("amount", amount)
    setAmountFormValue("amountOption", amountOption)
  }

  // Date Filter

  const dateChipValue = dateFrom && dateTo ? `Du ${dateFrom} au ${dateTo}` : ""

  const isDateOpen = Boolean(anchorDateElement)

  const submitDate = ({ dateFrom, dateTo }: DateForm) => {
    setSearchParam({
      ...searchParams,
      dateFrom: dateFrom && dateTo ? dateFrom : undefined,
      dateTo: dateFrom && dateTo ? dateTo : undefined,
    })
    setAnchorDateElement(undefined)
    setDateFormValue("dateFrom", dateFrom)
    setDateFormValue("dateTo", dateTo)
  }

  return (
    <FiltersBox>
      {/* Accounts Filter */}
      <ChipBox>
        <DeprecatedChip
          label={t("ap.balance.filters.chip.marketplace")}
          onClear={() => {
            submitAccounts({ accounts: [] })
          }}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "accounts")}
          value={accountsChipValue}
        />
      </ChipBox>
      <DeprecatedFloatingBox
        anchorElement={anchorAccountsElement}
        onClose={() => setAnchorAccountsElement(undefined)}
        isOpen={isAccountsOpen}
      >
        <form method="dialog" onSubmit={handleAccountsSubmit(submitAccounts)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.accountLabel")}
          </Typography>
          {accountLabels.map((accountLabel) => (
            <DeprecatedCheckbox
              {...registerAccounts("accounts")}
              key={accountLabel}
              id={accountLabel}
              text={titleCase(accountLabel)}
              value={accountLabel}
            />
          ))}
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </DeprecatedFloatingBox>

      {/* Amount Filter */}
      <ChipBox>
        <DeprecatedChip
          label={t("ap.balance.filters.chip.amount")}
          onClear={() => {
            submitAmount({ amountOption: "equal" })
          }}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "amount")}
          value={amountChipValue}
        />
      </ChipBox>
      <DeprecatedFloatingBox
        anchorElement={anchorAmountElement}
        onClose={() => setAnchorAmountElement(undefined)}
        isOpen={isAmountOpen}
      >
        <form method="dialog" onSubmit={handleAmountSubmit(submitAmount)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.amountLabel")}
          </Typography>
          <DeprecatedFieldSelect {...registerAmount("amountOption")}>
            <option value="under">{t("ap.balance.filters.amount.lessThan")}</option>
            <option value="equal">{t("ap.balance.filters.amount.equal")}</option>
            <option value="over">{t("ap.balance.filters.amount.greaterThan")}</option>
          </DeprecatedFieldSelect>
          <DeprecatedField type="number" placeholder="10" step="0.01" aria-invalid {...registerAmount("amount")} />
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </DeprecatedFloatingBox>

      {/* Date Filter */}
      <ChipBox>
        <DeprecatedChip
          label={t("ap.balance.filters.chip.date")}
          onClear={() => {
            submitDate({})
          }}
          onClick={(event: MouseEvent<HTMLElement>) => handleAnchors(event, "date")}
          value={dateChipValue}
        />
      </ChipBox>
      <DeprecatedFloatingBox
        anchorElement={anchorDateElement}
        onClose={() => setAnchorDateElement(undefined)}
        isOpen={isDateOpen}
      >
        <form method="dialog" onSubmit={handleDateSubmit(submitDate)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.dateLabel")}
          </Typography>
          <DeprecatedField type="date" fieldLabel={t("ap.balance.filters.date.from")} {...registerDate("dateFrom")} />
          <DeprecatedField type="date" fieldLabel={t("ap.balance.filters.date.to")} {...registerDate("dateTo")} />
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </DeprecatedFloatingBox>
    </FiltersBox>
  )
}
