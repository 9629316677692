import { CloseStrokeIcon, DeprecatedButton, LinkOutIcon, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import useLocalStorage from "../../../../00_shared/hooks/useLocalStorage.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"

type DiscoverNewSolutionProps = {
  imgSrc: string
  titleKey: string
  descriptionKey: string
  redirectUri: string
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 1rem;
  height: fit-content;
  position: relative;
`

const LeftSection = styled.div`
  width: 3.125rem;
  margin-right: 3rem;
  display: flex;
  align-items: center;
`

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  max-width: 40rem;
`

const CloseButton = styled.div`
  button {
    width: fit-content;
    padding: 0.25rem;
    border: none;
    outline: none;
    box-shadow: none;

    &:hover {
      color: inherit;
      background-color: transparent;
    }
  }
  position: absolute;
  right: 1rem;
`

const ButtonAction = styled(DeprecatedButton)`
  width: fit-content;
  padding: 0.25rem;
  margin-top: 1rem;
`

export const DiscoverNewSolution = ({ imgSrc, titleKey, descriptionKey, redirectUri }: DiscoverNewSolutionProps) => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()
  const [stayClose, setStayClose] = useLocalStorage(`discover-new-solution-${titleKey}`, "false")

  if (stayClose === "true") {
    return null
  }

  return (
    <Container>
      <LeftSection>
        <img src={imgSrc} alt={titleKey} width="75px" height="50px" />
      </LeftSection>
      <RightSection>
        <Typography $variant="body-3-medium">{t(titleKey)}</Typography>
        <Typography $variant="body-4-regular">{t(descriptionKey)}</Typography>
        <ButtonAction size="small" onClick={() => navigate(redirectUri)} leftIcon={LinkOutIcon}>
          {t("collection.discover.button")}
        </ButtonAction>
      </RightSection>
      <CloseButton>
        <DeprecatedButton
          size="small"
          centerIcon={CloseStrokeIcon}
          $variant="round"
          onClick={() => {
            setStayClose("true")
          }}
        />
      </CloseButton>
    </Container>
  )
}
