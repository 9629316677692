import { ArrowLeftIcon, DeprecatedButton, deprecatedToaster, FileIcon, Typography } from "@hero/krypton"
import { useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { currencyFormatter, eurosToCents } from "../../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { PaymentType } from "../../../00_shared/enums/PaymentCore.enum"
import { findP1XFeeConfigurations } from "../CreateLink.business"
import { useCreateLinkFormContext, useNavigateWithScrolling } from "../CreateLink.utils"
import { useCreateLinkContext } from "../CreateLinkContext"
import { useCompleteDraftPaymentLink } from "./ConfirmationStep.requests"

const RowLeft = styled.div`
  flex: 1;
`

const RowRight = styled.div`
  flex: 1;
  text-align: right;
`

const ScreenContainer = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
`

const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 32rem;
  margin: 3rem auto;
`

const Card = styled.section`
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`

const CardHeader = styled.header`
  padding: 1.5rem;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey.$200};
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.grey.$100} 100%
  );
`

const CardBody = styled.div`
  padding: 0.5rem 1.5rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding: 1rem 0;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const LinkCreateConfirmationStep = () => {
  const { t } = useDashboardTranslation()
  const { getValues } = useCreateLinkFormContext()
  const { feeConfigurations, paymentLink, fetchPaymentLink, setPaymentLink } = useCreateLinkContext()
  const { id: paymentLinkId } = useParams()
  const navigate = useNavigateWithScrolling()
  const [completeDraftPayment, { loading, error }] = useCompleteDraftPaymentLink()
  const values = getValues()

  const getFeeConfigurationLabel = useCallback(
    (feeConfigurationId: string) => {
      const feeConfiguration = feeConfigurations.find((fc) => fc.id === feeConfigurationId)
      if (!feeConfiguration) {
        return null
      }

      switch (feeConfiguration.type) {
        case PaymentType.PAY_1X:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.p1x")
        case PaymentType.PAY_NX:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.pnx", { n: feeConfiguration.installmentCount })
        case PaymentType.PAY_ND:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.pnd", { d: feeConfiguration.daysBeforeDueDate })
      }
    },
    [feeConfigurations, t],
  )

  const handleSubmit = useCallback(async () => {
    const values = getValues()

    if (!paymentLinkId) {
      return
    }

    const paymentFeeConfigurationsIds = values.customer.isIndividual
      ? findP1XFeeConfigurations(feeConfigurations).map(({ id }) => id)
      : values.paymentFeeConfigurationsIds

    await completeDraftPayment({
      variables: {
        input: {
          paymentLinkId,
          amount: eurosToCents(values.amount),
          reference: values.reference,
          documentPath: values.documentPath,
          comment: values.note,
          paymentFeeConfigurationsIds,
        },
      },
    })

    setPaymentLink({ id: paymentLinkId, isDraft: false })

    navigate(`/collection/new-link/${paymentLinkId}/preview`, { replace: true })
  }, [completeDraftPayment, feeConfigurations, getValues, navigate, paymentLinkId, setPaymentLink])

  const goToPreviousStep = useCallback(() => {
    navigate(`/collection/new-link/${paymentLinkId}/demand`)
  }, [navigate, paymentLinkId])

  useEffect(() => {
    if (error) {
      deprecatedToaster.error(error.message)
    }
  }, [error])

  useEffect(() => {
    if (!paymentLink && paymentLinkId) {
      navigate(`/collection/new-link/${paymentLinkId}/demand`, { replace: true })
    }
  }, [fetchPaymentLink, navigate, paymentLink, paymentLinkId])

  useEffect(() => {
    if (paymentLink?.isDraft === false) {
      navigate(`/collection/new-link/${paymentLinkId}/preview`, { replace: true })
    }
  }, [navigate, paymentLink, paymentLinkId])

  return (
    <ScreenContainer>
      <PageWrapper>
        <Typography $variant="title-1-bold">{t("bnpl.link.wizard.steps.confirmation.title")}</Typography>

        <Card>
          <CardHeader>
            <Typography $variant="body-4-medium">{t("bnpl.link.wizard.steps.confirmation.subtitle")}</Typography>
            <Typography $variant="title-2-bold">{currencyFormatter(values.amount)}</Typography>
          </CardHeader>
          <CardBody>
            <Row>
              <Typography $variant="body-4-regular">{t("bnpl.link.wizard.steps.confirmation.client")}</Typography>
              <Typography $variant="body-4-medium">{values.customer.name}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">{t("bnpl.link.wizard.steps.confirmation.contact")}</Typography>
              <Typography $variant="body-4-medium">{values.informClientEmail ?? "-"}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">{t("bnpl.link.wizard.steps.confirmation.reference")}</Typography>
              <Typography $variant="body-4-medium">{values.reference}</Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">
                {t("bnpl.link.wizard.steps.confirmation.creditorAccount")}
              </Typography>
              <Typography $variant="body-4-medium">
                {t("bnpl.link.wizard.steps.confirmation.creditorAccountName")}
              </Typography>
            </Row>
            <Row>
              <Typography $variant="body-4-regular">{t("bnpl.link.wizard.steps.confirmation.attachment")}</Typography>
              {values.documentPath ? <FileIcon /> : "-"}
            </Row>
            <Row>
              <Typography $variant="body-4-regular">{t("bnpl.link.wizard.steps.confirmation.note")}</Typography>
              <Typography $variant="body-4-medium">
                {values.note && values.note.length > 0 ? values.note : "-"}
              </Typography>
            </Row>
            {values.customer.isIndividual === false && (
              <Row>
                <RowLeft>
                  <Typography $variant="body-4-regular">
                    {t("bnpl.link.wizard.steps.confirmation.paymentTypes")}
                  </Typography>
                </RowLeft>
                <RowRight>
                  {values.paymentFeeConfigurationsIds.map((id) => (
                    <Typography key={id} $variant="body-4-medium">
                      {getFeeConfigurationLabel(id)}
                    </Typography>
                  ))}
                </RowRight>
              </Row>
            )}
          </CardBody>
        </Card>

        <DeprecatedButton type="button" size="medium" isLoading={loading} disabled={loading} onClick={handleSubmit}>
          {t("bnpl.link.wizard.steps.confirmation.confirmButton")}
        </DeprecatedButton>

        <Center>
          <DeprecatedButton
            type="button"
            size="medium"
            $variant="underline"
            disabled={loading}
            onClick={goToPreviousStep}
          >
            <ArrowLeftIcon />
            {t("bnpl.link.wizard.navigation.previous")}
          </DeprecatedButton>
        </Center>
      </PageWrapper>
    </ScreenContainer>
  )
}
