import { DeprecatedTab, DeprecatedTabs } from "@hero/krypton"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { useIsCreditWireTransferActive } from "../../../merchant.menu.requests"
import { CreditWireTransferHeader } from "../components/Header/CreditWireTransferHeader"
import { LandingHeader } from "../components/LandingHeader/LandingHeader"
import { CwtListTable } from "../components/ListTable/ListTable"

export const CwtListScreen = () => {
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const { data: isCreditWireTransferActive } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })

  const [searchParams, setSearchParams] = useSearchParams()

  const status = searchParams.get("status")

  useEffect(() => {
    if (!status) {
      setSearchParams({ status: "ONGOING" })
    }
  }, [status, setSearchParams])

  if (!isCreditWireTransferActive) {
    return null
  }

  return (
    <>
      <CreditWireTransferHeader />
      <LandingHeader />
      <PageWrapper>
        <PageHeader>
          <DeprecatedTabs>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={status === "ONGOING"}
              to="/cwt?status=ONGOING&page=1"
              data-test-id="merchant-ap-balance-ONGOING-tab"
            >
              {t("creditWireTransfer.list.tabs.ongoing")}
            </DeprecatedTab>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={status === "COMPLETED"}
              to="/cwt?status=COMPLETED&page=1"
              data-test-id="merchant-ap-balance-COMPLETED-tab"
            >
              {t("creditWireTransfer.list.tabs.closed")}
            </DeprecatedTab>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={status === "REJECTED"}
              to="/cwt?status=REJECTED&page=1"
              data-test-id="merchant-ap-balance-REJECTED-tab"
            >
              {t("creditWireTransfer.list.tabs.refused")}
            </DeprecatedTab>
          </DeprecatedTabs>
        </PageHeader>

        <PageContent>
          <CwtListTable />
        </PageContent>
      </PageWrapper>
    </>
  )
}

const PageHeader = styled.header`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding: 0 3rem;
  padding-left: 2.25rem;
`

const PageWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  padding: 1.5rem 3rem;
`
