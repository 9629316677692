import { ApolloError } from "@apollo/client"
import {
  DeprecatedButton,
  DeprecatedErrorBlock,
  DeprecatedField,
  DeprecatedSpinner,
  deprecatedToaster,
} from "@hero/krypton"
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"
import { CenteringContainer } from "../component/CenteringContainer"
import { Footer } from "../component/Footer"
import { Panel } from "../component/Pannel"
import { Slot } from "../component/Slot"
import { User, useGetMerchantUserQuery } from "./useGetMerchantUserQuery.hooks"
import {
  UPDATE_MERCHANT_RESPONSE,
  UPDATE_MERCHANT_USER,
  UPDATE_MERCHANT_VARIABLES,
} from "./useUpdateMerchantUserMutation.requests"

export const UpdateUserForm = () => {
  const { data, loading, error } = useGetMerchantUserQuery()
  const { t: commonTranslations } = useCommonTranslation()
  const { t: dashboardTranslations } = useDashboardTranslation()
  const [error2fa, setError2fa] = useState<string | null>(null)

  const getTranslations = (errorCode: string) =>
    ({
      WRONG_FORMAT: commonTranslations("queryError.validationError"),
      EMAIL_ALREADY_TAKEN: dashboardTranslations("merchant.tools.setting.updateUser.emailAlreadyTaken"),
      DEVICE_NOT_FOUND: dashboardTranslations("merchant.tools.setting.updateUser.deviceNotFound"),
      NOT_FOUND: dashboardTranslations("merchant.tools.setting.updateUser.userNotFound"),
      UNAUTHORIZED: dashboardTranslations("merchant.tools.setting.updateUser.unauthorized"),
      "2FA_ABORTED": commonTranslations("auth.login.error.2faAborted"),
      "2FA_FAILED": commonTranslations("auth.login.error.2faFailed"),
    })[errorCode] || commonTranslations("queryError.unknownError")

  const { mutationWith2fa: updateUser, mutationState: updateUserStatus } = use2faChallenge<
    UPDATE_MERCHANT_RESPONSE,
    UPDATE_MERCHANT_VARIABLES
  >(UPDATE_MERCHANT_USER)

  const [formState, setFormState] = useState<User>({
    id: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  })
  const [phoneError, setPhoneError] = useState<string | null>(null)
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false)

  useEffect(() => {
    if (data) {
      setFormState(data)
    }
  }, [data])

  const saveUser = async (user: User) => {
    try {
      const response = await updateUser({ ...user })
      if (response.data && response.data.updateMerchantUser && "errorCode" in response.data.updateMerchantUser) {
        setError2fa(getTranslations(response.data.updateMerchantUser.errorCode))
        return
      }
      deprecatedToaster.success(dashboardTranslations("merchant.tools.setting.updateUser.success"))
    } catch (err) {
      if (err === "2FA_ABORTED" || err === "2FA_FAILED") {
        setError2fa(getTranslations(err))
      }
      if (err instanceof ApolloError && err.message === "NOT_FOUND") {
        setError2fa(getTranslations("DEVICE_NOT_FOUND"))
      }
    }
  }

  const { t } = useTranslation()

  if (loading) {
    return (
      <CenteringContainer>
        <DeprecatedSpinner />
      </CenteringContainer>
    )
  }

  if (error) {
    return <CenteringContainer>{error.message}</CenteringContainer>
  }

  if (!data) {
    return <div>{t("merchant.tools.setting.updateUser.noUserError")}</div>
  }

  return (
    <>
      <Panel>
        {(
          [
            { field: "firstname", functional: t("merchant.tools.setting.updateUser.firstname.label"), type: "text" },
            { field: "lastname", functional: t("merchant.tools.setting.updateUser.lastname.label"), type: "text" },
            { field: "phone", functional: t("merchant.tools.setting.updateUser.phone.label"), type: "tel" },
            { field: "email", functional: t("merchant.tools.setting.updateUser.email.label"), type: "email" },
          ] as const
        ).map(({ field, functional, type }) => (
          <Slot key={field}>
            <DeprecatedField
              type={type}
              fieldLabel={functional}
              placeholder={functional}
              value={formState[field]}
              $fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement> | string) => {
                if (field === "phone") {
                  const value = e ? (e as string) : ""
                  setFormState({ ...formState, [field]: value })
                  const parsedPhoneNumber = parsePhoneNumberFromString(value)
                  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    setFormState({ ...formState, [field]: parsedPhoneNumber.number })
                    setPhoneError(null)
                    setIsPhoneValid(true)
                  } else if (value.length > 0) {
                    setPhoneError(t("merchant.tools.setting.updateUser.phone.error.mobileRequired"))
                    setIsPhoneValid(false)
                  } else {
                    setPhoneError(t("merchant.tools.setting.updateUser.phone.error.required"))
                    setIsPhoneValid(false)
                  }
                } else {
                  const value = (e as ChangeEvent<HTMLInputElement>).target.value
                  setFormState({ ...formState, [field]: value })
                }
              }}
              errorMessage={field === "phone" ? phoneError : undefined}
              defaultCountry={field === "phone" ? "FR" : undefined}
              aria-invalid={field === "phone" && isPhoneValid}
            />
          </Slot>
        ))}
      </Panel>

      <Footer>
        {error2fa && <DeprecatedErrorBlock>{error2fa}</DeprecatedErrorBlock>}

        <DeprecatedButton
          onClick={() => saveUser(formState)}
          isLoading={updateUserStatus.loading}
          disabled={!!phoneError}
        >
          {t("merchant.tools.setting.updateUser.submit")}
        </DeprecatedButton>
      </Footer>
    </>
  )
}
