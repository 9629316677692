import { useMutation } from "@apollo/client"
import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedButton,
  DeprecatedField,
  DeprecatedModal,
  deprecatedToaster,
  Typography,
} from "@hero/krypton"
import { TFunction } from "i18next"
import { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { centsToEuros, eurosToCents, toEuros } from "../../../../../../00_shared/utils/currency.converter"
import { HeroErrorDisplayer } from "../../../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { isApiError } from "../../../../../../01_technical/requesting/request-error.utils"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { Refunds, Transaction } from "../../transaction.hooks"
import { REFUND, RefundArgs, RefundResponse } from "./askForRefund.request"

const StyledModal = styled(DeprecatedModal)`
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalTitle = styled(Typography)`
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const MainInfo = styled(Typography)`
  margin: 2rem 0;
`

const Address = styled(Typography)`
  margin-left: 0.25rem;
  display: inline;
`

const Actions = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 1rem;
  }
`

type FormState = {
  eurosToRefund: number
}

const GenericErrorMessage = ({ t, errorCode }: { t: TFunction; errorCode: string }) => {
  return (
    <>
      {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.genericErrorMessage.line1")}
      <br />
      {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.genericErrorMessage.line2.part1")}{" "}
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <a href="mailto:support@hero.fr">support@hero.fr</a>{" "}
      {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.genericErrorMessage.line2.part2")}
      <span> {errorCode}</span>
    </>
  )
}

export const RefundModalV2: React.FC<{
  isOpen: boolean
  onClose: () => void
  onRefunded: () => void
  transaction: Transaction
  refundsData: Refunds
  refetch: () => void
}> = ({ isOpen, onClose, onRefunded, transaction, refundsData, refetch }) => {
  const { t, unsafeT: dashboardUnsafeT } = useDashboardTranslation()

  const [mode, setMode] = useState<"Partial" | "Total">("Total")

  const [RefundMutation, refundStatus] = useMutation<RefundResponse, RefundArgs>(REFUND)

  const { register, handleSubmit, formState } = useForm<FormState>({
    defaultValues: {
      eurosToRefund: centsToEuros(transaction.amount),
    },
  })

  const closeModal = () => {
    refundStatus.reset()
    onClose()
  }

  const refund = async (variables: RefundArgs) => {
    try {
      const response = await RefundMutation({ variables })

      if (isApiError(response.data?.askForRefund)) {
        throw new Error("GraphqlError" + response.data?.askForRefund.errorCode)
      }
      onRefunded()
      refetch()
      deprecatedToaster.success(
        `${t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.refund.toasterSuccess.part1")}.`,
        {
          autoClose: 2000,
        },
      )
    } catch (err: unknown) {
      deprecatedToaster.error(t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.refund.toasterError"), {
        autoClose: 2000,
      })
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    refund({
      paymentId: transaction.id,
      amount: eurosToCents(data.eurosToRefund),
    })
  })

  const isLoading = refundStatus.loading

  return (
    <StyledModal isOpen={isOpen} onClose={closeModal}>
      <ModalTitle as="h2" $variant="title-2-bold">
        {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.title")}
      </ModalTitle>

      <Form onSubmit={onSubmit} noValidate>
        {mode === "Total" ? (
          <MainInfo as="div">
            {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.subtitle.part1")}
            <Typography as="span" $variant="title-3-inter-bold">
              &nbsp;
              {toEuros(refundsData.maxRefundableAmount)}
            </Typography>
            {transaction.customer && (
              <Address as="address">
                {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.subtitle.part2")}{" "}
                {transaction.customer.name},&nbsp;
                {transaction.customer.contactEmail},&nbsp;
                {transaction.customer.siret}
              </Address>
            )}
          </MainInfo>
        ) : (
          <MainInfo as="div">
            <DeprecatedField
              fieldLabel={t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.partialRefundFieldLabel")}
              type="number"
              step="0.01"
              aria-invalid={!!formState.errors.eurosToRefund}
              {...register("eurosToRefund", {
                required: t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.partialRefundFieldRequired"),
                min: {
                  message: t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.partialRefundFieldMin"),
                  value: 0.01,
                },
                valueAsNumber: true,
              })}
              errorMessage={formState.errors.eurosToRefund?.message}
              inline
            />
          </MainInfo>
        )}

        <DeprecatedBadge as="div" $variant="danger" icon={<CloseStrokeIcon />}>
          {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.warning")}
        </DeprecatedBadge>

        <Actions>
          <DeprecatedButton
            type={mode === "Partial" ? "submit" : "button"}
            $variant={mode === "Partial" ? "primary" : "secondary"}
            disabled={isLoading || formState.isSubmitting}
            isLoading={isLoading || formState.isSubmitting}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              if (mode !== "Partial") {
                e.preventDefault()
                setMode("Partial")
              }
            }}
          >
            {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.partialRefundButton")}
          </DeprecatedButton>
          <DeprecatedButton
            type={mode === "Total" ? "submit" : "button"}
            $variant={mode === "Total" ? "primary" : "secondary"}
            disabled={isLoading || formState.isSubmitting}
            isLoading={isLoading || formState.isSubmitting}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              if (mode !== "Total") {
                e.preventDefault()
                setMode("Total")
              }
            }}
          >
            {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.fullRefundButton")}
          </DeprecatedButton>
        </Actions>

        <HeroErrorDisplayer
          err={refundStatus.error}
          data={refundStatus.data?.askForRefund}
          messages={{
            R_420: <GenericErrorMessage errorCode="R_420" t={dashboardUnsafeT} />,
            R_421: <GenericErrorMessage errorCode="R_421" t={dashboardUnsafeT} />,
            R_422: <GenericErrorMessage errorCode="R_422" t={dashboardUnsafeT} />,
            R_423: <GenericErrorMessage errorCode="R_423" t={dashboardUnsafeT} />,
            R_424: <GenericErrorMessage errorCode="R_424" t={dashboardUnsafeT} />,
            R_425: <GenericErrorMessage errorCode="R_425" t={dashboardUnsafeT} />,
            R_426: <GenericErrorMessage errorCode="R_426" t={dashboardUnsafeT} />,
            R_427: <GenericErrorMessage errorCode="R_427" t={dashboardUnsafeT} />,
            R_430: <GenericErrorMessage errorCode="R_430" t={dashboardUnsafeT} />,
            R_432: <GenericErrorMessage errorCode="R_432" t={dashboardUnsafeT} />,
            R_435: (
              <>
                {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.refundUnavailable.line1")}
                <br />
                {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.refundUnavailable.line2")}
                <br />
                {t("checkoutAndCash.cockpit.transaction.mainInfos.refundModal.refundUnavailable.line3")}{" "}
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <a href="mailto:support@hero.fr">support@hero.fr</a>.
              </>
            ),
            R_436: <GenericErrorMessage errorCode="R_436" t={dashboardUnsafeT} />,
            R_437: <GenericErrorMessage errorCode="R_437" t={dashboardUnsafeT} />,
            R_438: <GenericErrorMessage errorCode="R_438" t={dashboardUnsafeT} />,
            R_439: <GenericErrorMessage errorCode="R_439" t={dashboardUnsafeT} />,
          }}
        />
      </Form>
    </StyledModal>
  )
}
