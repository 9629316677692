import { DeleteIcon, DeprecatedSpinner, FileIcon, Typography } from "@hero/krypton"
import { Accept, useDropzone } from "react-dropzone"
import styled from "styled-components"
import { formatBytes } from "./formatBytes.utils"

const DnDBox = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.grey.$100};
  border: 1px dashed ${({ theme }) => theme.colors.grey.$600};
  box-sizing: border-box;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
`

const DnDBoxTitle = styled(Typography)`
  font-weight: bold;
`

const DnDBoxDescription = styled(Typography)`
  color: #616172;
`

const DocumentList = styled.ul`
  margin-top: 1.5rem;
`

const DocumentListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
`

const DocumentListItemDescription = styled.div`
  flex-grow: 1;
`

const DocumentListItemFileName = styled(Typography)`
  text-decoration: underline;
  font-weight: bold;
`
const DocumentListItemFileSize = styled(Typography).attrs(() => ({ $variant: "caption-2" }))``

const DocumentListItemActionList = styled.div``

const DocumentListItemActionListItem = styled.button`
  display: inline-block;
  margin-left: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
`

const DocumentIcon = styled(FileIcon)`
  font-size: 1.4rem;
  margin-right: 0.5rem;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 1.4rem;
`

const StyledSpinner = styled(DeprecatedSpinner)`
  margin: 1rem auto;
`

type UploadFileComponentProps = {
  name: string
  loading: boolean
  onChange: (arg: File | undefined) => void
  value: File | undefined
  maxSize?: number
  title?: string
  cancel?: string
  acceptedFiles?: Accept
  children: JSX.Element
}

export const UploadFileComponent: React.FC<UploadFileComponentProps> = ({
  onChange,
  value,
  loading,
  name,
  maxSize = 52428800.0, // 50 Mo maximum for a file
  title = "Déposez votre fichier ici",
  cancel = "Supprimer le document",
  children,
  acceptedFiles,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    maxSize,
    onDrop: (acceptedFiles, fileRejections, event) => {
      const firstAcceptedFiles = acceptedFiles[0]
      onChange(firstAcceptedFiles)
    },
    accept: acceptedFiles,
  })

  if (loading) {
    return <StyledSpinner />
  }

  if (!value) {
    return (
      <DnDBox
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(getRootProps() as any)}
        data-test-id="file-input-wrapper"
        onClick={(e) => {
          // See https://stackoverflow.com/questions/49671325/react-dropzone-opens-files-chooser-twice
          e.preventDefault()
          e.stopPropagation()
          open()
        }}
      >
        <input
          {...getInputProps({
            name,
            onChange: (e) => {
              e.stopPropagation()
              onChange(e.target.files?.[0])
            },
          })}
        />
        <DnDBoxTitle>{title}</DnDBoxTitle>
        <DnDBoxDescription>{children}</DnDBoxDescription>
      </DnDBox>
    )
  }

  return (
    <>
      <DocumentList
        onClick={(e) => {
          // See https://stackoverflow.com/questions/49671325/react-dropzone-opens-files-chooser-twice
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <DocumentListItem>
          <DocumentIcon />
          <DocumentListItemDescription>
            <DocumentListItemFileName>{value.name}</DocumentListItemFileName>
            <DocumentListItemFileSize>{formatBytes(`${value.size}`)}</DocumentListItemFileSize>
          </DocumentListItemDescription>
          <DocumentListItemActionList>
            <DocumentListItemActionListItem
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onChange(undefined)
              }}
              title={cancel}
            >
              <StyledDeleteIcon />
            </DocumentListItemActionListItem>
          </DocumentListItemActionList>
        </DocumentListItem>
      </DocumentList>
    </>
  )
}
