import { DeprecatedButton } from "@hero/krypton"
import styled from "styled-components"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../../../00_shared/components/Accordion"
import { ScrollArea } from "../../../../../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../../../../../00_shared/components/Sheet"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { SearchParams, useTransactionFilters } from "../../../hooks/filters/useTransactionFilters"
import { AmountFilter } from "./AmountFilter"
import { DateFilter } from "./DateFilter"
import { MethodFilter } from "./MethodFilter"
import { TypeFilter } from "./TypeFilter"

const FilterSheetContent = styled(SheetContent)`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) 6rem;
`

const AccordionContainer = styled(Accordion)`
  padding-right: 0.25rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: auto;
`

const AccordionItemContainer = styled(AccordionItem)``

type FiltersSheetProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onFiltersChange: (params: SearchParams) => void
}

export const FiltersSheet: React.FC<FiltersSheetProps> = ({ isOpen, setIsOpen, onFiltersChange }) => {
  const { t } = useDashboardTranslation()
  const { amountState, dateState, methodState, typeState, clearFilters, applyAllFilters } = useTransactionFilters(
    onFiltersChange,
    {
      amount: { applyImmediately: false },
      date: { applyImmediately: false },
      method: { applyImmediately: false },
      type: { applyImmediately: false },
    },
  )

  const filterCount = [
    amountState.amountUnder,
    amountState.amountEqual,
    amountState.amountOver,
    dateState.dateFrom,
    dateState.dateTo,
    methodState.methods.length,
    typeState.types.length,
  ].filter(Boolean).length

  const handleApply = () => {
    applyAllFilters()
    setIsOpen(false)
  }

  const handleClear = () => {
    clearFilters()
    setIsOpen(false)
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <FilterSheetContent>
        <SheetHeader>
          <SheetTitle>{t("accounts.transaction.filters.title")}</SheetTitle>
        </SheetHeader>
        <ScrollArea>
          <AccordionContainer type="single" collapsible>
            <AccordionItemContainer value="method">
              <AccordionTrigger>
                {t("accounts.transaction.list.filters.chip.method")}{" "}
                {methodState.methods.length > 0 && `(${methodState.methods.length})`}
              </AccordionTrigger>
              <AccordionContent>
                <MethodFilter
                  selectedMethods={methodState.methods}
                  onMethodChange={methodState.toggleMethod}
                  hideHeader={true}
                />
              </AccordionContent>
            </AccordionItemContainer>
            <AccordionItemContainer value="amount">
              <AccordionTrigger>
                {t("accounts.transaction.list.filters.amountLabel")}
                {(amountState.amountUnder || amountState.amountEqual || amountState.amountOver) && "(1)"}
              </AccordionTrigger>
              <AccordionContent>
                <AmountFilter
                  amountUnder={amountState.amountUnder}
                  amountEqual={amountState.amountEqual}
                  amountOver={amountState.amountOver}
                  setAmountUnder={amountState.setAmountUnder}
                  setAmountEqual={amountState.setAmountEqual}
                  setAmountOver={amountState.setAmountOver}
                  onApply={amountState.apply}
                  showApplyButton={false}
                  hideHeader={true}
                />
              </AccordionContent>
            </AccordionItemContainer>
            <AccordionItemContainer value="date">
              <AccordionTrigger>
                {t("accounts.transaction.list.filters.dateLabel")} {(dateState.dateFrom || dateState.dateTo) && "(1)"}
              </AccordionTrigger>
              <AccordionContent>
                <DateFilter
                  dateFrom={dateState.dateFrom}
                  dateTo={dateState.dateTo}
                  setDateFrom={dateState.setDateFrom}
                  setDateTo={dateState.setDateTo}
                  onApply={dateState.apply}
                  hideHeader={true}
                  autoApply={false}
                  showApplyButton={false}
                />
              </AccordionContent>
            </AccordionItemContainer>
            <AccordionItemContainer value="type">
              <AccordionTrigger>
                {t("accounts.transaction.list.filters.chip.type")}{" "}
                {typeState.types.length > 0 && `(${typeState.types.length})`}
              </AccordionTrigger>
              <AccordionContent>
                <TypeFilter selectedTypes={typeState.types} onTypeChange={typeState.toggleType} hideHeader={true} />
              </AccordionContent>
            </AccordionItemContainer>
          </AccordionContainer>
        </ScrollArea>
        <ButtonContainer>
          <DeprecatedButton isLoading={false} $variant="primary" size="medium" onClick={handleApply}>
            {t("accounts.transaction.filters.apply")}
          </DeprecatedButton>
          <DeprecatedButton isLoading={false} $variant="secondary" size="medium" onClick={handleClear}>
            {t("accounts.transaction.filters.clear")} {filterCount > 0 && `(${filterCount})`}
          </DeprecatedButton>
        </ButtonContainer>
      </FilterSheetContent>
    </Sheet>
  )
}
