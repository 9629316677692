import { Typography } from "@hero/krypton"
import { DashboardSection } from "../../../00_shared/components/krypton2/DashboardSection"
import styled from "styled-components"
import { Spacer } from "../../../00_shared/components/Spacer"
import { Skeleton } from "../../../00_shared/components/Skeleton"
import { centsToEuros, i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { DateStyle, toInternationalDate } from "../../../00_shared/utils/date.converter"
import { apRegulatedBreakpoints as breakpoints } from "../utils"
import { useGetKpis } from "../hooks/getKpis"
import { useAuthContext } from "../../../Auth/auth.context"

export const KPIs = () => {
  const { t, i18n } = useDashboardTranslation()
  const auth = useAuthContext()
  const { data, loading } = useGetKpis({ merchantId: auth.currentUser.merchantId })

  return (
    <Wrapper>
      <KPI
        title={t("apRegulated.home.kpis.exposure")}
        subtitle={t("apRegulated.home.kpis.exposureSubtitle")}
        amount={data?.exposure}
        isLoading={loading}
      />
      <KPI
        title={t("apRegulated.home.kpis.cumulativePayments")}
        subtitle={t("apRegulated.home.kpis.cumulativePaymentsSubtitle", {
          date: data?.firstApCreatedAt
            ? toInternationalDate({
                date: data?.firstApCreatedAt,
                language: i18n.language,
                style: DateStyle.TEXTUAL,
              })
            : "...",
        })}
        amount={data?.cumulativePayments}
        isLoading={loading}
      />
      <KPI
        title={t("apRegulated.home.kpis.cumulativeAdvances")}
        subtitle={t("apRegulated.home.kpis.cumulativeAdvancesSubtitle", {
          date: data?.firstApCreatedAt
            ? toInternationalDate({
                date: data?.firstApCreatedAt,
                language: i18n.language,
                style: DateStyle.TEXTUAL,
              })
            : "...",
        })}
        amount={data?.cumulativeAdvances}
        isLoading={loading}
      />
    </Wrapper>
  )
}

const KPI = ({
  title,
  subtitle,
  amount,
  isLoading,
}: {
  title: string
  subtitle: string
  amount: number | undefined
  isLoading: boolean
}) => {
  const { lang } = useDashboardTranslation()

  return (
    <DashboardSection>
      <SecondaryTypo>{title}</SecondaryTypo>
      <Spacer />
      {!isLoading && amount && <MainTypo>{i18nCurrencyFormatter(centsToEuros(amount), lang)}</MainTypo>}
      {(isLoading || !amount) && <MainTypoSkeleton />}
      <Spacer $height="0.25rem" />
      <SecondaryTypo>{subtitle}</SecondaryTypo>
    </DashboardSection>
  )
}

const MainTypo = styled(Typography).attrs({ $variant: "body-2-semibold", $color: "secondary.$200" })``
const MainTypoSkeleton = styled(Skeleton).attrs({ height: "1.6875rem", width: "6rem" })``

const SecondaryTypo = styled(Typography).attrs({ $variant: "caption-2", $color: "grey.$500" })``

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  gap: 0.75rem;

  & > * {
    flex: 1;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`
