import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"

export interface MarketplaceTransaction {
  id: string
  createdAt: string
  merchantId: string
  marketplaceId: string
  amount: number
}

interface Marketplace {
  id: string
  name: string
  logoUrl: string | null
}

const query = gql`
  query getMarketplaceTransactions($merchantId: String!) {
    getMarketplaceTransactions(merchantId: $merchantId) {
      ... on GetMarketplaceTransactionsOutput {
        aprTransactions {
          id
          createdAt
          merchantId
          marketplaceId
          amount
        }
        marketplaces {
          id
          name
          logoUrl
        }
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

const fakeData = {
  aprTransactions: Array.from({ length: 5 }).map((_, index) => ({
    id: `transaction-${index}`,
    createdAt: new Date(2024, 0, index + 1).toISOString(),
    merchantId: "merchant-1",
    marketplaceId: "marketplace-1",
    amount: Math.floor(Math.random() * 100000) + 10000,
  })),
  marketplaces: [
    {
      id: "marketplace-1",
      name: "Amazon",
      logoUrl: "https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png",
    },
  ],
}

export const useGetMarketplaceTransactionOverview = ({
  merchantId,
  fakeData: useFakeData = false,
}: {
  merchantId: string
  fakeData?: boolean
}) => {
  const queryResult = useHeroQuery<{
    aprTransactions: MarketplaceTransaction[]
    marketplaces: Marketplace[]
  }>({
    gqlQuerySchema: query,
    variables: { merchantId },
  })

  if (useFakeData) {
    return {
      data: fakeData as {
        aprTransactions: MarketplaceTransaction[]
        marketplaces: Marketplace[]
      },
      loading: false,
      error: null,
    }
  }

  return queryResult
}
