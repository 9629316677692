import { DeprecatedButton, DeprecatedModal, Separator, Typography, deprecatedToaster } from "@hero/krypton"
import { FC, useCallback } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { PAY_URI } from "../../../../env_variables"
import { useSendPaymentLink } from "../../Link/Create/CreateLink.requests"
import { useSendPaymentLinkByEmail } from "./PaymentDetails/SendPaymentLinkByEmail.request"

const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const URL = styled(Typography)`
  width: 100%;
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  margin: 0.25rem 0 1.25rem 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

const DividerGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`

const Divider = styled(Separator)`
  height: 1px;
`

const ActionsTextSeparator = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin: 0 0.5rem;
`

const ButtonActionGroup = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1.5rem;

  button {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

export const LinkModal: FC<{
  paymentLinkId?: string
  isOpen: boolean
  onClose: () => void
}> = ({ paymentLinkId, isOpen, onClose }) => {
  const { t } = useDashboardTranslation()
  const [sendPaymentLink, { loading }] = useSendPaymentLink()

  const [sendPaymentLinkByEmail, { loading: isEmailSenderLoading }] = useSendPaymentLinkByEmail()

  const sendPaymentLinkWithMethod = useCallback(
    (method: "SMS" | "WHATSAPP") => async () => {
      if (!paymentLinkId) {
        return
      }

      try {
        await sendPaymentLink({ variables: { input: { paymentLinkId, method } } })
        deprecatedToaster.success(t(`bnpl.link.wizard.steps.preview.deprecatedToaster.success.${method}`))
      } catch {
        deprecatedToaster.error(t(`bnpl.link.wizard.steps.preview.deprecatedToaster.error.${method}`))
      }
    },
    [paymentLinkId, sendPaymentLink, t],
  )

  const sendLinkByEmail = () => {
    if (!paymentLinkId) {
      return
    }

    sendPaymentLinkByEmail({ variables: { input: { paymentLinkId } } })
      .then(() => {
        deprecatedToaster.success(t("bnpl.link.wizard.steps.preview.deprecatedToaster.success.email"))
      })
      .catch(() => {
        deprecatedToaster.error(t("bnpl.link.wizard.steps.preview.deprecatedToaster.error.email"))
      })
  }

  const url = `${PAY_URI}/link/${paymentLinkId}`

  return (
    <DeprecatedModal isOpen={isOpen} onClose={onClose}>
      <Content>
        <Typography $variant="title-1-bold">{t("bnpl.link.wizard.steps.preview.title")}</Typography>
        <Description $variant="body-4-regular">{t("bnpl.link.wizard.steps.preview.subtitle")}</Description>
        <Description $variant="body-4-regular">{t("bnpl.link.wizard.steps.preview.description")}</Description>
        <Typography $variant="body-4-medium">{t("bnpl.link.wizard.steps.preview.urlTitle")}</Typography>
        <URL as="pre" $variant="body-4-regular" data-test-id="payment-link-url">
          <code>{url}</code>
        </URL>

        {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/** @ts-expect-error CopyToClipboardType is wrong... */}
        <CopyToClipboard
          text={url}
          onCopy={(_: unknown, result?: unknown) => {
            if (result) {
              deprecatedToaster.success(t("bnpl.link.wizard.steps.preview.urlCopy.toasterSuccess"), {
                autoClose: 1500,
              })
            } else {
              deprecatedToaster.error(t("bnpl.link.wizard.steps.preview.urlCopy.toasterError"))
            }
          }}
        >
          <DeprecatedButton isLoading={false} style={{ width: "100%" }}>
            {t("bnpl.link.wizard.steps.preview.urlCopyButton")}
          </DeprecatedButton>
        </CopyToClipboard>
        <Actions>
          <DividerGroup>
            <Divider />
            <ActionsTextSeparator $variant="label-1">
              {t("bnpl.link.wizard.steps.preview.urlDivider").toUpperCase()}
            </ActionsTextSeparator>
            <Divider />
          </DividerGroup>

          <ButtonActionGroup>
            <DeprecatedButton
              $variant="secondary"
              size="large"
              onClick={sendPaymentLinkWithMethod("SMS")}
              isLoading={loading}
            >
              {t("bnpl.link.wizard.steps.preview.urlSendSMSButton")}
            </DeprecatedButton>
            <DeprecatedButton
              $variant="secondary"
              size="large"
              onClick={sendLinkByEmail}
              isLoading={isEmailSenderLoading}
            >
              {t("bnpl.link.wizard.steps.preview.urlSendMailButton")}
            </DeprecatedButton>
            {/*
            <DeprecatedButton
              $variant="secondary"
              size="large"
              onClick={sendPaymentLinkWithMethod("WHATSAPP")}
              isLoading={loading}
            >
              {t("bnpl.link.wizard.steps.preview.urlSendWhatsAppButton")}
            </DeprecatedButton>
            */}
          </ButtonActionGroup>
        </Actions>
      </Content>
    </DeprecatedModal>
  )
}
