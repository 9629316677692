/* eslint-disable i18next/no-literal-string */
import { DeprecatedErrorBlock, DeprecatedField, DeprecatedFieldSelect, Typography } from "@hero/krypton"
import React, { useEffect } from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import CenteredLoading, { CenteredBasicError } from "../../../../00_shared/components/CenteredLoading"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { useBusinessAccounts } from "../../../BusinessAccount/pro-account/00_shared/hooks/useBusinessAccounts"
import { useIsCreditWireTransferActive } from "../../../merchant.menu.requests"
import { SendIncreaseExposureLimitRequestButton } from "../../00_shared/components/SendIncreaseExposureLimitResquestButton"
import { useMerchantExposure } from "../../00_shared/hooks/useMerchantExposure.hook"
import { useMREFinancingEnabled } from "../../00_shared/hooks/useMREFinancingEnabled.hook"
import HeroIcon from "../../00_shared/icons/hero-icon.png"
import { useGetInstallments } from "../requests/useGetFinancingInstallments"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"
import { OutstandingPanel } from "./OutstandingPanel"
import { useGetAvailablePaymentOptions } from "./useGetAvailablePaymentOptions.request"

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s ease;
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.grey.$300};
  margin-bottom: 2rem;
`

const AccountIcon = styled.img`
  width: 1rem;
  height: 1rem;
  object-fit: contain;
`

const AccountName = styled(Typography)`
  flex: 1;
`

const FinancingDetailsContainer = styled.div<{ $loading: boolean }>`
  padding: 1.25rem;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  margin-top: 1rem;
  position: relative;

  ${({ $loading }) =>
    $loading &&
    `
    & > * {
      opacity: 0;
    }

    & .loader {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  `}
`

const FinancingDetailsRow = styled.div`
  margin: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InstallmentAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const InstallmentFee = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const FinancingFormSection = () => {
  const { t } = useDashboardTranslation()
  const { register, control, formState, setValue } = useCwtApplicationFormContext()
  const { businessAccounts, loading: isLoadingAccounts, error: accountsError } = useBusinessAccounts()
  const { currentUser } = useAuthContext()
  const { data: isCreditWireTransferActive } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })

  const { data, error, loading } = useGetAvailablePaymentOptions()

  const mainAccount = businessAccounts?.find((account) => account.isMainAccount)

  const mreFinancingEnabled = useMREFinancingEnabled()

  const {
    data: merchantExposureData,
    loading: isMerchantExposureLoading,
    error: merchantExposureError,
  } = useMerchantExposure({
    skip: !mreFinancingEnabled,
  })

  useEffect(() => {
    if (data?.numberOfInstallmentsAllowed.length) {
      setValue("installmentsCount", data.numberOfInstallmentsAllowed[0])
    }
    if (mainAccount) {
      setValue("businessAccountId", mainAccount.ledgerId)
    }
  }, [data, mainAccount, setValue])

  if (isLoadingAccounts) {
    return <CenteredLoading />
  }

  if (accountsError) {
    return <DeprecatedErrorBlock $margin="1rem 0 0 0">{accountsError.message}</DeprecatedErrorBlock>
  }

  if (!mainAccount) {
    return (
      <DeprecatedErrorBlock $margin="1rem 0 0 0">
        {t("creditWireTransfer.applicationForm.noMainAccount")}
      </DeprecatedErrorBlock>
    )
  }

  return (
    <>
      <Typography $variant="title-2-bold">{t("creditWireTransfer.applicationForm.financingSectionTitle")}</Typography>
      {mreFinancingEnabled && (
        <>
          <Spacer $h={"1.5rem"} />
          <OutstandingPanel
            variant="compact"
            isLoading={isMerchantExposureLoading}
            isError={!!merchantExposureError}
            totalAllowedExposure={merchantExposureData?.totalAllowedExposure ?? 0}
            totalUsedExposure={merchantExposureData?.totalInternalDebt ?? 0}
            title={t("creditWireTransfer.applicationForm.outstandingTitle")}
            subtitle={t("creditWireTransfer.applicationForm.outstandingSubtitle.active")}
            actions={
              <>
                <Typography $variant="caption-2" $color="grey.$500">
                  {t("creditWireTransfer.applicationForm.outstandingActionsTitle")}
                </Typography>
                <SendIncreaseExposureLimitRequestButton />
              </>
            }
          />
        </>
      )}
      <Spacer $h={"1rem"} />
      {!isCreditWireTransferActive?.useBusinessAccount ? (
        <DeprecatedField
          $fullWidth
          fieldLabel={t("creditWireTransfer.applicationForm.debtorIBAN")}
          type="text"
          {...register("debtorIBAN")}
          errorMessage={formState.errors?.debtorIBAN?.message}
        />
      ) : (
        <>
          <Typography $variant="body-4-medium">{t("creditWireTransfer.list.table.businessAccount")}</Typography>
          <AccountContainer>
            <AccountIcon src={HeroIcon} alt={mainAccount.name} />
            <AccountName>{mainAccount.name}</AccountName>
            <Typography>{toEuros(mainAccount.balance)}</Typography>
          </AccountContainer>
        </>
      )}{" "}
      <div
        style={{
          position: "relative",
        }}
      >
        {(loading || error) && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffffbf",
              zIndex: 1,
              borderRadius: "0.5rem",
            }}
          >
            {loading && <CenteredLoading />}
            {error && <CenteredBasicError />}
          </div>
        )}
        <Controller
          control={control}
          name="installmentsCount"
          render={({ field: { value, onBlur, onChange } }) => (
            <DeprecatedFieldSelect
              value={value}
              $fullWidth
              noErrorMessage
              fieldLabel={t("creditWireTransfer.applicationForm.installmentCount")}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(parseInt(e.target.value, 10))}
              onBlur={onBlur}
              disabled={loading || error}
            >
              {data?.numberOfInstallmentsAllowed.map((installmentsCount) => (
                <option key={installmentsCount} value={installmentsCount}>
                  {installmentsCount}x
                </option>
              ))}
            </DeprecatedFieldSelect>
          )}
        />
      </div>
      <FinancingDetails />
    </>
  )
}

const FinancingDetails = () => {
  const { t } = useDashboardTranslation()
  const { watch } = useCwtApplicationFormContext()
  const installmentsCount = watch("installmentsCount")
  const amount = watch("amount")

  const { data, loading, error } = useGetInstallments({
    amount: Number(amount) * 100,
    installmentsCount: Number(installmentsCount),
  })

  if (Boolean(Number(amount)) && error !== null) {
    console.error(error)
    return <DeprecatedErrorBlock $margin="1rem 0 0 0">{error?.translatedMessage}</DeprecatedErrorBlock>
  }

  const installments: {
    position: number
    totalAmount: number
    feeAmount: {
      totalAmount: number
      vatAmount: number
      amountWithoutVAT: number
    }
  }[] = loading
    ? Array(Number(installmentsCount)).fill({ position: -1, totalAmount: 0, feeAmount: 0 })
    : data?.installments || []

  if (installments.length === 0) {
    return null
  }

  return (
    <FinancingDetailsContainer $loading={loading}>
      <Typography $variant="body-3-semibold">{t("creditWireTransfer.applicationForm.financingPlan")}</Typography>

      <Typography $variant="caption-2">{installmentsCount}x</Typography>

      {[...installments].sort(sortByPosition).map((installment, i) => (
        <FinancingDetailsRow key={amount + "-" + i + "-" + installment.position}>
          <Typography>
            {installment.position === 0 && t("creditWireTransfer.applicationForm.firstInstallment")}
            {installment.position !== 0 &&
              t("creditWireTransfer.applicationForm.installment", { count: installment.position })}
          </Typography>

          <InstallmentAmount>
            <Typography>{toEuros(installment.totalAmount)}</Typography>
            <InstallmentFee $variant="caption-2">
              {t("creditWireTransfer.applicationForm.installmentFee", {
                euros: toEuros(installment.feeAmount.totalAmount),
              })}
            </InstallmentFee>
          </InstallmentAmount>
        </FinancingDetailsRow>
      ))}

      {loading && (
        <div className="loader">
          <CenteredLoading />
        </div>
      )}
    </FinancingDetailsContainer>
  )
}

const sortByPosition = (a: { position: number }, b: { position: number }) => a.position - b.position
