import { DeprecatedBadge, RefreshIcon, ValidIcon, WarningIcon, WatchLaterIcon } from "@hero/krypton"
import { useMemo } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { PaymentStatus } from "../../enums/PaymentCore.enum"

const StyledBadge = styled(DeprecatedBadge)`
  height: unset;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

export const PaymentStatusBadge = ({ paymentStatus }: { paymentStatus: PaymentStatus }) => {
  const { t } = useDashboardTranslation()

  const paymentStatuses = useMemo(
    () => ({
      [PaymentStatus.NOT_STARTED]: {
        label: t("collections.payment.statuses.notStarted"),
        $variant: "disabled" as const,
        icon: <WatchLaterIcon />,
      },
      [PaymentStatus.FINISHED]: {
        label: t("collections.payment.statuses.finished"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.IN_PROGRESS]: {
        label: t("collections.payment.statuses.inProgress"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.REFUNDED]: {
        label: t("collections.payment.statuses.refunded"),
        $variant: "primary" as const,
        icon: <ValidIcon />,
      },
      [PaymentStatus.PARTIALLY_REFUNDED]: {
        label: t("collections.payment.statuses.partiallyRefunded"),
        $variant: "warning" as const,
        icon: <RefreshIcon />,
      },
      [PaymentStatus.LATE]: {
        label: t("collections.payment.statuses.late"),
        $variant: "danger" as const,
        icon: <WarningIcon />,
      },
      [PaymentStatus.DEFAULT]: {
        label: t("collections.payment.statuses.default"),
        $variant: "danger" as const,
        icon: <WarningIcon />,
      },
    }),
    [t],
  )

  const { label, $variant, icon } = paymentStatuses[paymentStatus]

  return (
    <StyledBadge $variant={$variant} icon={icon}>
      {label}
    </StyledBadge>
  )
}
