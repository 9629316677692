import { useQuery } from "@apollo/client"
import {
  ChevronDownIcon,
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedButton,
  DeprecatedHeader,
  DeprecatedPagination,
  DeprecatedSpinner,
  DeprecatedTable,
  DeprecatedTableSection,
  DeprecatedTBody,
  DeprecatedTHead,
  RefreshIcon,
  Typography,
  TypographyVariant,
  ValidIcon,
} from "@hero/krypton"
import { DateTime } from "luxon"
import { ElementType, ReactNode, useEffect } from "react"
import { Link, Navigate, useMatch } from "react-router-dom"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { PaymentType } from "../../../../business/enums/Payment.enum"
import {
  getTransactionWordingByFlowType,
  getTransactionWordingByStatus,
  isTransactionReceivableGenerator,
} from "../../../../business/rules/Transaction.rules"
import { HEADER_COLORS } from "../../../../env_variables"
import { ApiErrors } from "../../../../Legacy/components/ApiErrors"
import {
  GET_MERCHANT_CUSTOMER_TRANSACTIONS,
  GetMerchantCustomerTransactionsQueryArgsType,
  GetMerchantCustomerTransactionsQueryResult,
  Transaction,
} from "./clientTransactionsDetails.requests"

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
`

const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: fit-content;
`

const BackButton = styled<ElementType>(DeprecatedButton)`
  min-width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  margin-right: 0.5rem;

  & svg {
    transform: rotate(90deg);
  }
`

const Inner = styled.div`
  padding: 1rem;
`

const TableContainer = styled(DeprecatedTableSection)`
  overflow-x: auto;
`

const CustomPagination = styled(DeprecatedPagination)`
  margin-top: 1.5rem;
`

const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CenteringTD = styled.td`
  text-align: center;
`

const CustomerInfoContainer = styled.div`
  padding: 1rem;
  margin: 1.5rem 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
`

const CustomerInfo = styled.div`
  display: flex;
  gap: 6rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`

const CustomerInfoLabel = styled(Typography)<{ $variant?: TypographyVariant }>`
  color: ${(props) => props.theme.colors.grey.$600};
`

const CustomerInfoValue = styled(Typography)<{ $variant?: TypographyVariant }>`
  color: ${(props) => props.theme.colors.grey.$500};
`

const CustomerCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const BadgeVariantIconMapping: Record<string, ReactNode> = {
  primary: <ValidIcon />,
  secondary: <ValidIcon />,
  warning: <RefreshIcon />,
  danger: <CloseStrokeIcon />,
}

const PAGE_SIZE = 10

type SearchParams = {
  pageNumber: string
}

export const ClientTransactionsDetailsScreen: React.FC = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const { t, i18n } = useDashboardTranslation()
  const { unsafeT: unsafeCommonT } = useCommonTranslation()

  const toLocaleDate = (date: string) => DateTime.fromISO(date).setLocale(i18n.language).toLocaleString()

  const localizePaymentType = (paymentType: PaymentType | undefined) => {
    switch (paymentType) {
      case "Pay1X":
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.Pay1X")
      case "Pay3X":
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.Pay3X")
      case "Pay4X":
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.Pay4X")
      case "Pay30D":
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.Pay30D")
      case "Pay60D":
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.Pay60D")
      default:
        return t("checkoutAndCash.clientsTransactionDetails.paymentType.unknown")
    }
  }

  const searchParams: SearchParams = {
    pageNumber: getSearchParam("pageNumber"),
  }

  const match = useMatch("/checkout_cash/clients/:referenceOrSiren/transactions")
  const referenceOrSiren = match?.params?.referenceOrSiren

  const { pageNumber: pageNumberString } = searchParams

  const pageNumber = isNaN(Number(pageNumberString)) ? 1 : Number(pageNumberString)

  useEffect(() => {
    if (!pageNumberString) {
      setSearchParam({ pageNumber: "1" })
    }
  }, [pageNumberString, setSearchParam])

  const { data, error, loading, previousData } = useQuery<
    GetMerchantCustomerTransactionsQueryResult,
    GetMerchantCustomerTransactionsQueryArgsType
  >(GET_MERCHANT_CUSTOMER_TRANSACTIONS, {
    variables: {
      pagination: {
        pageSize: PAGE_SIZE,
        pageNumber,
      },
      referenceOrSiren: referenceOrSiren as string,
    },
    skip: !referenceOrSiren,
  })

  if (loading && !previousData) {
    return <DeprecatedSpinner />
  }

  if (error) {
    if (error.message === "UNAUTHORIZED") {
      return <Navigate to={"/checkout_cash/clients"} />
    }

    return <div>{error.message}</div>
  }

  if (!data) {
    return <div>{t("checkoutAndCash.clientsTransactionDetails.noPaymentsFound")}</div>
  }

  const { companyInfo, outstandingAmount, maxOutstandingLimit, transactions, transactionsSum, paginationInfo } =
    data.merchantCustomerTransactions

  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <HeaderActions>
          <LinkContainer to={"/checkout_cash/clients"}>
            <BackButton leftIcon={ChevronDownIcon} isLoading={false} />
            <Typography>{t("checkoutAndCash.clientsTransactionDetails.backButton")}</Typography>
          </LinkContainer>
        </HeaderActions>
      </DeprecatedHeader>
      <CustomerInfoContainer>
        <Inner>
          <CustomerInfo>
            <IndicatorContainer>
              <CustomerCompanyInfo>
                <CustomerInfoLabel $variant="body-3-semibold">
                  {companyInfo.lastNameUsed ?? t("checkoutAndCash.clientsTransactionDetails.unknownCompany")}
                </CustomerInfoLabel>
                <CustomerInfoValue $variant="body-4-regular">{companyInfo.lastEmailUsed}</CustomerInfoValue>
                <CustomerInfoValue $variant="body-4-regular">
                  {t("checkoutAndCash.clientsTransactionDetails.siretSiren")}&nbsp;{companyInfo.lastSirenOrSiretUsed}
                </CustomerInfoValue>
              </CustomerCompanyInfo>
            </IndicatorContainer>
            <IndicatorContainer>
              <CustomerInfoLabel $variant="body-3-semibold">{toEuros(outstandingAmount)}</CustomerInfoLabel>
              <CustomerInfoValue $variant="body-4-regular" color="">
                {t("checkoutAndCash.clientsTransactionDetails.consumedOutstanding")}
              </CustomerInfoValue>
            </IndicatorContainer>
            <IndicatorContainer>
              <CustomerInfoLabel $variant="body-3-semibold">{toEuros(maxOutstandingLimit)}</CustomerInfoLabel>
              <CustomerInfoValue $variant="body-4-regular">
                {t("checkoutAndCash.clientsTransactionDetails.maxOutstanding")}
              </CustomerInfoValue>
            </IndicatorContainer>
            <IndicatorContainer>
              <CustomerInfoLabel $variant="body-3-semibold">{toEuros(transactionsSum)}</CustomerInfoLabel>
              <CustomerInfoValue $variant="body-4-regular">
                {t("checkoutAndCash.clientsTransactionDetails.totalPayments")}
              </CustomerInfoValue>
            </IndicatorContainer>
          </CustomerInfo>
        </Inner>
      </CustomerInfoContainer>
      <TableContainer>
        <DeprecatedTable>
          <DeprecatedTHead>
            <tr>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.type")}</th>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.orderId")}</th>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.paymentDate")}</th>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.schedule")}</th>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.amount")}</th>
              <th>{t("checkoutAndCash.clientsTransactionDetails.table.header.status")}</th>
            </tr>
          </DeprecatedTHead>
          <DeprecatedTBody $clickable={!!transactions.length}>
            {loading && !previousData && (
              <tr>
                <td colSpan={6}>
                  <CenteringContainer>
                    <DeprecatedSpinner />
                  </CenteringContainer>
                </td>
              </tr>
            )}
            {transactions.length ? (
              transactions.map((transaction: Transaction) => {
                const isReceivableGenerator = isTransactionReceivableGenerator(transaction)
                const detailLink = `/transactions/${transaction.id}?origin=${encodeURIComponent(
                  `/checkout_cash/clients/${referenceOrSiren}/transactions?pageNumber=${pageNumber ?? 1}`,
                )}`
                const displayedType = getTransactionWordingByFlowType(unsafeCommonT, transaction)
                const transactionStatusWording = getTransactionWordingByStatus(unsafeCommonT, transaction)

                return (
                  <tr key={transaction.id}>
                    <td>
                      <TableCellLink to={detailLink} />
                      {displayedType}
                    </td>
                    <td>
                      <TableCellLink to={detailLink} />
                      {transaction.orderId}
                    </td>
                    <td>
                      <TableCellLink to={detailLink} />
                      {!isReceivableGenerator && toLocaleDate(transaction.createdAt)}
                    </td>
                    <td>
                      <TableCellLink to={detailLink} />
                      {localizePaymentType(transaction.paymentType)}
                    </td>
                    <td>
                      <TableCellLink to={detailLink} />
                      {toEuros(transaction.amount)}
                    </td>
                    <td>
                      <TableCellLink to={detailLink} />
                      <DeprecatedBadge
                        $variant={transactionStatusWording.color}
                        icon={BadgeVariantIconMapping[transactionStatusWording.color] ?? <ValidIcon />}
                      >
                        {transactionStatusWording.text}
                      </DeprecatedBadge>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <CenteringTD colSpan={6}>{t("checkoutAndCash.clientsTransactionDetails.noPaymentsFound")}</CenteringTD>
              </tr>
            )}
          </DeprecatedTBody>
        </DeprecatedTable>
        <ApiErrors err={error} />
      </TableContainer>
      <CustomPagination
        currentPage={pageNumber ?? 1}
        totalPages={paginationInfo.totalPage}
        onPageChange={(pageNumber) => setSearchParam({ pageNumber: pageNumber.toString() })}
      />
    </>
  )
}
