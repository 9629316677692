import { DeprecatedBadge, RefreshIcon } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 1rem;
  height: fit-content;
`

export const Accounting = () => {
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <DeprecatedBadge $variant="secondary" as="div" icon={<RefreshIcon />}>
        {t("collection.settings.accounting.wip")}
      </DeprecatedBadge>
    </Container>
  )
}
