import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const query = gql`
  mutation handleIncreaseExposureLimitRequest {
    handleIncreaseExposureLimitRequest {
      ... on HandleIncreaseExposureLimitRequestOutput {
        success
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useSendIncreaseExposureLimitRequest = () => {
  return useHeroMutation<{ success: boolean }, undefined>({
    gqlQuerySchema: query,
  })
}
