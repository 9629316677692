import { useApolloClient } from "@apollo/client"
import { DeprecatedSpinner } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import { jwtDecode } from "jwt-decode"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { isMobile } from "react-device-detect"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ONBOARDING_LOGOUT_URL, ONBOARDING_SIGNIN_URL } from "../../env_variables"
import { useMutationWith2fa } from "../Challenge2fa/useMutationWith2fa"
import {
  Autologin2faFunctionalError,
  AUTOLOGIN_DASHBOARD_REQUEST,
  AutologinArgs,
  AutologinResponse,
  AutologinSuccess,
  convertAutologin2faErrorToSignin2faError,
  Setup2faResponse,
  SigninFunctionalError,
} from "../Login/login.requests"
import { use2faSetup } from "../Setup2fa/setup2fa.hooks"

const isAutologinSuccess = (response: AutologinResponse): response is AutologinSuccess => {
  return (response as AutologinSuccess).autologin.token !== undefined
}

const is2faSetupRequired = (response: AutologinResponse): response is Autologin2faFunctionalError => {
  const autologin2faError = response as Autologin2faFunctionalError
  return (
    autologin2faError.autologin.errorCode === "FUNCTIONAL_ERROR" &&
    autologin2faError.autologin.message === "2FA_SETUP_REQUIRED"
  )
}

const is2faSetupInProgress = (response: AutologinResponse): response is Autologin2faFunctionalError => {
  const autologin2faError = response as Autologin2faFunctionalError
  return (
    autologin2faError.autologin.errorCode === "FUNCTIONAL_ERROR" &&
    autologin2faError.autologin.message === "2FA_SETUP_IN_PROGRESS"
  )
}

const isUnknownError = (data: AutologinResponse) => {
  return !isAutologinSuccess(data) && !is2faSetupRequired(data) && !is2faSetupInProgress(data)
}

export const Autologin = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const [queryParams] = useSearchParams()
  const path = queryParams.get("path")
  const urlToRedirectTo = useMemo(() => path || "/", [path])
  const autologinToken = queryParams.get("autologinToken")
  const autologinEmail = queryParams.get("autologinEmail")
  const [autologin] = useMutationWith2fa<AutologinResponse, AutologinArgs>(AUTOLOGIN_DASHBOARD_REQUEST)
  const hasStarted = useRef(false)
  const [setup2fa] = use2faSetup<Setup2faResponse>()

  const redirectToDashboard = useCallback(
    async (token: string) => {
      localStorage.setItem("token", token)
      // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
      await client.clearStore()
      Sentry.captureMessage(`[Autologin] Redirect to ${urlToRedirectTo}`)
      navigate(urlToRedirectTo, { replace: true })
    },
    [client, navigate, urlToRedirectTo],
  )

  const redirectWithError = useCallback((error: string) => {
    const params = new URLSearchParams({ error }).toString()

    const url = `${ONBOARDING_SIGNIN_URL}?${params}`
    Sentry.captureMessage(`[Autologin] Redirect to ${url} due to error: ${error}`)
    window.location.href = url
  }, [])

  const handle2FASetup = useCallback(
    async (args: { signin2faError: SigninFunctionalError; email: string; autologinToken: string }) => {
      const { signin2faError, email, autologinToken } = args

      await setup2fa({
        data: signin2faError,
        onCompleted: async (challenge) => {
          const checkEnrollChallenge = challenge.check2FAEnrollChallenge
          if ("status" in checkEnrollChallenge && checkEnrollChallenge.status === "SUCCESS") {
            await autologin({
              variables: {
                email,
                autologinToken,
                platform: isMobile ? "mobile" : "desktop",
              },
              onError: (autologinError) => redirectWithError(autologinError.message),
              onCompleted: async (data) => {
                if (!isAutologinSuccess(data)) {
                  return redirectWithError("UNKNOWN_ERROR")
                }
                await redirectToDashboard(data.autologin.token)
              },
            })
          }
        },
        onError: (setupError) => redirectWithError(setupError.check2FAEnrollChallenge.errorCode),
      })
    },
    [autologin, redirectToDashboard, redirectWithError, setup2fa],
  )

  const handleAutologin = useCallback(
    async (email: string, autologinToken: string) => {
      try {
        await autologin({
          variables: {
            email,
            autologinToken,
            platform: isMobile ? "mobile" : "desktop",
          },
          onError: (autologinError) => redirectWithError(autologinError.message),
          onCompleted: async (data) => {
            if (isAutologinSuccess(data)) {
              return redirectToDashboard(data.autologin.token)
            }
            if (is2faSetupRequired(data)) {
              const signin2faError = convertAutologin2faErrorToSignin2faError(data)
              await handle2FASetup({ signin2faError, email, autologinToken })
              return
            }
            if (is2faSetupInProgress(data)) {
              return redirectWithError("2FA_SETUP_IN_PROGRESS")
            }
            if (isUnknownError(data)) {
              return redirectWithError("UNKNOWN_ERROR")
            }
          },
        })
      } catch (e) {
        if (e instanceof Error) {
          redirectWithError(e.message)
          return
        }
        if (typeof e === "string") {
          redirectWithError(e)
          return
        }
        Sentry.captureException(e)
        redirectWithError("UNKNOWN_ERROR")
      }
    },
    [autologin, handle2FASetup, redirectToDashboard, redirectWithError],
  )

  useEffect(() => {
    if (hasStarted.current) {
      return
    }

    // If autologinToken is expired, redirect back to onboarding
    if (autologinToken) {
      const { exp } = jwtDecode(autologinToken)
      if (exp && exp <= Date.now() / 1000) {
        Sentry.captureMessage(`[Autologin] Redirect to ${ONBOARDING_LOGOUT_URL} due to expired token`)
        window.location.href = ONBOARDING_LOGOUT_URL
        return
      }
    }

    // If autologinToken or autologinEmail is missing, redirect back to onboarding, as this is an invalid autologin attempt
    if (!autologinToken || !autologinEmail) {
      Sentry.captureMessage(`[Autologin] Redirect to ${ONBOARDING_LOGOUT_URL} due to missing token or email`)
      window.location.href = ONBOARDING_LOGOUT_URL
      return
    }

    // Bypass autologin if token is already set
    if (localStorage.getItem("token")) {
      Sentry.captureMessage(`[Autologin] Redirect to ${urlToRedirectTo} due to existing token`)
      navigate(urlToRedirectTo, { replace: true })
      return
    }

    hasStarted.current = true
    handleAutologin(autologinEmail, autologinToken)
  }, [autologinEmail, autologinToken, handleAutologin, navigate, urlToRedirectTo])

  return <DeprecatedSpinner />
}
