import React from "react"
import styled, { keyframes } from "styled-components"

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const StyledSkeleton = styled.div<{ width?: string; height?: string }>`
  animation: ${pulse} 1.5s infinite;
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
`

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string
  height?: string
}

const Skeleton: React.FC<SkeletonProps> = ({ className, width, height, ...props }) => {
  return <StyledSkeleton className={className} width={width} height={height} {...props} />
}

export { Skeleton }
