import { DeprecatedTab, DeprecatedTabs } from "@hero/krypton"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"

const membersPath = "/user-management/members"

const TabsContainer = styled(DeprecatedTabs)`
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;

    & button,
    a {
      flex: 1;
      text-align: center;
      justify-content: center;
      margin-right: 0;
    }
  }
`

export const UserManagementHeaderTabs = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()

  const isMembersSelected = location.pathname.startsWith(membersPath)

  return (
    <TabsContainer>
      <DeprecatedTab as={Link} $isSelected={isMembersSelected} to={membersPath}>
        {t("userManagement.list.tabs.members")}
      </DeprecatedTab>
    </TabsContainer>
  )
}
