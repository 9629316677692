import {
  CloseStrokeIcon,
  DeprecatedBadge,
  DeprecatedErrorBlock,
  DeprecatedHeader,
  DeprecatedPagination,
  DeprecatedSpinner,
  DeprecatedTab,
  DeprecatedTable,
  DeprecatedTabs,
  DeprecatedTBody,
  DeprecatedTHead,
  RefreshIcon,
  Typography,
  ValidIcon,
} from "@hero/krypton"
import { DateTime } from "luxon"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { APStatus } from "../../../../business/enums/AP.enum"
import { HEADER_COLORS } from "../../../../env_variables"
import { LogoMarketplace } from "../components/LogoMarketplace.component"
import { NoMarketplaceLogoIcon } from "../components/NoMarketplaceLogoIcon"
import { Filters } from "./PaymentFilters"
import { useGetListMerchantAps } from "./useGetListMerchantAps"
import { useHandleFilledPaymentsSearchParams } from "./useHandleFilledPaymentsSearchParams"

const PAGE_SIZE = 20

const EmptyTable = styled.td`
  text-align: center;
`

const MarketplaceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CustomPagination = styled(DeprecatedPagination)`
  margin: 1.5rem 0;
`

const Title = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
`

const ErrorWrapper = styled.div`
  margin: 1rem 3rem;
`

const PageHead = styled.div``

export type statusParam = "in_progress" | "finished" | "all"

const getStatusVariable = (statusParam: statusParam) => {
  if (statusParam === "finished") {
    return [APStatus.FINISHED]
  }
  if (statusParam === "in_progress") {
    return [APStatus.PARTIALLY_REPAID, APStatus.IN_PROGRESS]
  }
  return []
}

export type SearchParams = {
  status: statusParam
  page: number
  marketplaces?: string[]
  amountUnder?: number
  amountOver?: number
  amountEqual?: number
  startDate?: string
  endDate?: string
}

export const FilledPayments = () => {
  const { t, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const { amountEqual, amountOver, amountUnder, endDate, marketplaces, pageNumber, startDate, status, setSearchParam } =
    useHandleFilledPaymentsSearchParams()

  const { data, error, loading } = useGetListMerchantAps(
    {
      status: getStatusVariable(status),
      marketplaceNames: marketplaces,
      fundedAmountMin: amountOver,
      fundedAmountMax: amountUnder,
      fundedAmountEqual: amountEqual,
      createdBefore: endDate ? DateTime.fromISO(endDate).toJSDate() : undefined,
      createdAfter: startDate ? DateTime.fromISO(startDate).toJSDate() : undefined,
    },
    { pageNumber, pageSize: PAGE_SIZE },
    { useDemoData: isDemoMerchant },
  )

  const tabs = [
    { type: "all" as statusParam, label: t("ap.payments.tabs.all") },
    { type: "in_progress" as statusParam, label: t("ap.payments.tabs.inProgress") },
    { type: "finished" as statusParam, label: t("ap.payments.tabs.finished") },
  ]

  return (
    <>
      <PageHead>
        <DeprecatedHeader $colors={HEADER_COLORS}>
          <Typography $variant="title-3-semibold">{t("ap.payments.title")}</Typography>
          <DeprecatedTabs>
            {tabs.map((tab) => (
              <DeprecatedTab
                data-test-id={`ap-payments-tab-${tab.type}`}
                key={tab.type}
                $isSelected={status === tab.type}
                onClick={() =>
                  setSearchParam({
                    status: tab.type,
                    marketplaces,
                    page: pageNumber,
                    amountUnder,
                    amountOver,
                    amountEqual,
                    startDate,
                    endDate,
                  })
                }
              >
                {tab.label}
              </DeprecatedTab>
            ))}
          </DeprecatedTabs>
        </DeprecatedHeader>

        <Filters
          listMarketplaces={data?.listMarketplaces || []}
          setSearchParam={setSearchParam}
          pageNumber={pageNumber}
          status={status}
          marketplaces={marketplaces}
          amountUnder={amountUnder}
          amountOver={amountOver}
          amountEqual={amountEqual}
          startDate={startDate}
          endDate={endDate}
        />
      </PageHead>

      {loading && (
        <LoadingWrapper>
          <DeprecatedSpinner />
          <Title>{t("loading")}...</Title>
        </LoadingWrapper>
      )}

      {data && (
        <>
          <DeprecatedTable data-test-id="merchant-ap-payments-table">
            <DeprecatedTHead>
              <tr>
                <th>{t("ap.payments.table.header.marketplace")}</th>
                <th>{t("ap.payments.table.header.amount")}</th>
                <th>{t("ap.payments.table.header.status")}</th>
                <th>{t("ap.payments.table.header.date")}</th>
              </tr>
            </DeprecatedTHead>

            <DeprecatedTBody $clickable={!!data.acceleratedPayouts.length}>
              {!data.acceleratedPayouts.length && (
                <tr>
                  <EmptyTable colSpan={4}>{t("ap.payments.table.empty")}</EmptyTable>
                </tr>
              )}
              {data.acceleratedPayouts.map(
                ({ id, marketplace, marketplaceLogoUrl, fundedAmount, status, createdAt }) => {
                  const url = `/ap/payments/${id}`

                  return (
                    <tr key={id}>
                      <td>
                        <TableCellLink to={url} />
                        <MarketplaceContainer>
                          {marketplaceLogoUrl ? (
                            <LogoMarketplace src={marketplaceLogoUrl} alt={marketplace} />
                          ) : (
                            <NoMarketplaceLogoIcon />
                          )}
                          {titleCase(marketplace)}
                        </MarketplaceContainer>
                      </td>
                      <td>
                        <TableCellLink to={url} />
                        {toEuros(fundedAmount)}
                      </td>
                      <td>
                        <TableCellLink to={url} />
                        <BadgeWithStatus status={status} />
                      </td>
                      <td>
                        <TableCellLink to={url} />
                        {toInternationalDate({ date: createdAt, language: i18n.language })}
                      </td>
                    </tr>
                  )
                },
              )}
            </DeprecatedTBody>
          </DeprecatedTable>

          <CustomPagination
            currentPage={pageNumber}
            totalPages={Math.ceil(data.totalCount / PAGE_SIZE)}
            onPageChange={(pageNumber: number) =>
              setSearchParam({
                status,
                marketplaces,
                page: pageNumber,
                amountUnder,
                amountOver,
                amountEqual,
                startDate,
                endDate,
              })
            }
          />
        </>
      )}

      {error && (
        <ErrorWrapper>
          <DeprecatedErrorBlock>{error.translatedMessage}</DeprecatedErrorBlock>
        </ErrorWrapper>
      )}
    </>
  )
}

const BadgeWithStatus = ({ status }: { status: APStatus }) => {
  const { t } = useDashboardTranslation()

  if (status === APStatus.FINISHED) {
    return (
      <DeprecatedBadge $variant="primary" icon={<ValidIcon />}>
        {t("ap.payments.badge.finished")}
      </DeprecatedBadge>
    )
  }

  if (status === APStatus.IN_PROGRESS || status === APStatus.PARTIALLY_REPAID) {
    return (
      <DeprecatedBadge title={t("ap.payments.badge.inProgressTitle")} $variant="warning" icon={<RefreshIcon />}>
        {t("ap.payments.badge.inProgress")}
      </DeprecatedBadge>
    )
  }

  if (status === APStatus.CANCELED) {
    return (
      <DeprecatedBadge $variant="danger" icon={<CloseStrokeIcon />}>
        {t("ap.payments.badge.canceled")}
      </DeprecatedBadge>
    )
  }

  return null
}
