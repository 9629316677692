import { DeprecatedToggle, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useBOV3Context } from "../../../00_shared/context/bov3.context"
import { useBusinessAccountContext } from "../../BusinessAccount/01_context/businessAccount.context"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
  white-space: nowrap;
`

const Caption = styled(Typography)<{ mode: "dark" | "light" }>`
  color: ${({ theme, mode }) => (mode === "light" ? theme.colors.grey.$500 : theme.colors.white)};
`

export const BoSwitcher = ({ isReduced }: { isReduced: boolean }) => {
  const { isToggleButtonActive, isBOV3Active, toggleBOVersion } = useBOV3Context()
  const { isUserAllowedToViewAccounts } = useBusinessAccountContext()
  const navigate = useNavigate()

  if (!isToggleButtonActive) {
    return null
  }

  const handleBoV3Redirection = () => {
    if (isUserAllowedToViewAccounts) {
      return "/accounts/transactions"
    }
    return "/cards/list"
  }

  return (
    <Container>
      <Caption $variant="body-4-medium" mode={isBOV3Active ? "light" : "dark"}>
        {!isReduced && isBOV3Active && "✨ New back-office ✨"}
        {!isReduced && !isBOV3Active && "Old back-office"}
      </Caption>

      <DeprecatedToggle
        id="bo-switch"
        aria-label="switch-dynamic"
        checked={isBOV3Active}
        onClick={() => {
          navigate(!isBOV3Active ? handleBoV3Redirection() : "/checkout_cash/cockpit")
          toggleBOVersion()
        }}
      />
    </Container>
  )
}
