import { useQuery } from "@apollo/client"
import { DeprecatedHeader, DeprecatedTab, DeprecatedTabs, InfoIcon, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { toEuros } from "../../../00_shared/utils/currency.converter"
import { HeroErrorDisplayer, unwrapGraphQLResponse } from "../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"
import { HEADER_COLORS } from "../../../env_variables"
import { GET_MARKETPLACES_QUERY, GetMarketplacesResponse, GetMarketplacesSuccessResponse } from "./Balance.requests"
import { BalanceAllTable } from "./BalanceAllTable/BalanceAllTable"
import { BalanceIncomeTable } from "./BalanceIncomingTable/BalanceIncomeTable"
import { BalanceOutgoingTable } from "./BalanceOutgoingTable/BalanceOutgoingTable"

const BalanceBoxTop = styled.div``

const BalanceAmountBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const BalanceAmount = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const InfoTooltipBox = styled.div`
  position: relative;
`

const InfoTooltipIcon = styled(InfoIcon)`
  width: 1rem;
  height: 1rem;
`

const InfoTooltipData = styled.div`
  position: absolute;
  z-index: 1;
  top: -50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.8rem;
  padding: 1rem;
`

const MarketplaceBox = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 15rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const BalanceBoxBottom = styled.div``

export const FilledBalance = () => {
  const { t } = useDashboardTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get("page")
  const { currentUser } = useAuthContext()

  const transaction = searchParams.get("transaction")
  useEffect(() => {
    if (!transaction || !page) {
      setSearchParams({ transaction: "income", page: "1" })
    }
  }, [transaction, page, setSearchParams])

  const [isBoxOpen, setIsBoxOpen] = useState(false)

  const { data, error } = useQuery<GetMarketplacesResponse>(GET_MARKETPLACES_QUERY)

  const { data: unwrappedResponse } = unwrapGraphQLResponse<GetMarketplacesSuccessResponse>(
    data?.merchantGetMarketplaces,
  )

  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <BalanceBoxTop>
          <BalanceAmountBox>
            <BalanceAmount $variant="title-3-inter-bold">
              {currentUser.isDemoMerchant ? "0,00 €" : toEuros(unwrappedResponse?.totalAccountsBalance ?? 0)}
            </BalanceAmount>

            <InfoTooltipBox>
              <InfoTooltipIcon onMouseEnter={() => setIsBoxOpen(true)} onMouseLeave={() => setIsBoxOpen(false)} />

              {isBoxOpen && (
                <InfoTooltipData>
                  {unwrappedResponse?.marketplaces.length ? (
                    unwrappedResponse.marketplaces.map((mkp, index) => (
                      <MarketplaceBox key={`mkp-${index}`}>
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Typography $variant="caption-2">Account - {mkp.name}</Typography>
                        <Typography $variant="caption-1">{toEuros(mkp.accountBalance)}</Typography>
                      </MarketplaceBox>
                    ))
                  ) : (
                    <MarketplaceBox>
                      <Typography $variant="caption-1">{t("ap.balance.empty")}</Typography>
                    </MarketplaceBox>
                  )}
                </InfoTooltipData>
              )}
            </InfoTooltipBox>
          </BalanceAmountBox>
        </BalanceBoxTop>

        <BalanceBoxBottom>
          <DeprecatedTabs>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={transaction === "all"}
              to="/ap/balance?transaction=all&page=1"
              data-test-id="merchant-ap-balance-all-tab"
            >
              {t("ap.balance.tabs.all")}
            </DeprecatedTab>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={transaction === "income"}
              to="/ap/balance?transaction=income&page=1"
              data-test-id="merchant-ap-balance-incoming-tab"
            >
              {t("ap.balance.tabs.incoming")}
            </DeprecatedTab>
            <DeprecatedTab<typeof Link>
              as={Link}
              $isSelected={transaction === "outgoing"}
              to="/ap/balance?transaction=outgoing&page=1"
              data-test-id="merchant-ap-balance-outgoing-tab"
            >
              {t("ap.balance.tabs.outgoing")}
            </DeprecatedTab>
          </DeprecatedTabs>
        </BalanceBoxBottom>
      </DeprecatedHeader>

      <HeroErrorDisplayer
        data={data?.merchantGetMarketplaces}
        err={error}
        messages={{ UNAUTHORIZED: t("ap.balance.unauthorized") }}
      />

      {transaction === "income" && <BalanceIncomeTable />}
      {transaction === "outgoing" && <BalanceOutgoingTable />}
      {transaction === "all" && <BalanceAllTable />}
    </>
  )
}
