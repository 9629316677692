import { DeprecatedModal, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { Card, CardContent, CardHeader } from "../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import CardIcon from "../icons/card-physical-give.png"
import QrCodeIcon from "../icons/qr-code.png"

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.grey.$500};
  font-size: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`

const AdviceToDownloadAppModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t } = useDashboardTranslation()
  return (
    <DeprecatedModal isOpen={isOpen} onClose={() => onClose()}>
      <Card $variant="plain" style={{ alignItems: "center" }}>
        <CardHeader style={{ alignItems: "center" }}>
          <img src={QrCodeIcon} width={320} alt="Download the app" style={{}} />
        </CardHeader>
        <CardContent>
          <Typography $variant="body-3-regular" $color="grey.$500">
            {t("issuing.adviceToDownloadAppModal.title")}
          </Typography>
        </CardContent>
      </Card>
    </DeprecatedModal>
  )
}

export const AdviceToDownloadAppCard = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useDashboardTranslation()
  const [isVisible, setIsVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const isAdviceDismissed = localStorage.getItem("issuing_advice_to_download_app_dismissed")
    if (isAdviceDismissed) {
      setIsVisible(false)
    }
  }, [])

  const handleClose = () => {
    localStorage.setItem("issuing_advice_to_download_app_dismissed", "true")
    setIsVisible(false)
    onClose && onClose()
  }

  if (!isVisible) return null

  return (
    <Card style={{ position: "relative", padding: "1rem", height: "6.5rem" }}>
      <AdviceToDownloadAppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <FlexContainer $gap="1rem" $align="center">
        <FlexContainer $gap="1rem">
          <img src={CardIcon} width={60} alt="Download the app" />
          <FlexContainer $direction="column" $gap=".75rem" style={{ width: "40rem" }}>
            <Typography $variant="body-4-medium">{t("issuing.adviceToDownloadApp.title")}</Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {t("issuing.adviceToDownloadApp.subtitle")}
            </Typography>
          </FlexContainer>
        </FlexContainer>
        <button onClick={() => setIsModalOpen(true)} style={{ all: "unset" }}>
          <img src={QrCodeIcon} width={70} height={70} alt="Download the app" style={{ cursor: "pointer" }} />
        </button>
      </FlexContainer>
      <CloseButton onClick={handleClose} aria-label="Close">
        &times;
      </CloseButton>
    </Card>
  )
}
