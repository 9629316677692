"use client"

import { CloseStrokeIcon, DeprecatedButton } from "@hero/krypton"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import * as React from "react"
import styled, { css } from "styled-components"

// Dialog components
const Dialog = DialogPrimitive.Root
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogTrigger = DialogPrimitive.Trigger
const DialogPortal = DialogPrimitive.Portal
const DialogClose = DialogPrimitive.Close

// Types for props with className and state
interface DialogOverlayProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> {
  state?: "open" | "closed"
  $disableAnimations?: boolean
}

interface DialogContentProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  state?: "open" | "closed"
  $disableAnimations?: boolean
  width?: string
  height?: string
  disableCloseButton?: boolean
  topOffset?: number
}

// Styled components
const Overlay = styled(DialogPrimitive.Overlay)<DialogOverlayProps>`
  ${({ state, $disableAnimations }) => css`
    position: fixed;
    inset: 0;
    z-index: 50;
    ${!$disableAnimations &&
    css`
      animation: ${state === "open" ? "fadeIn 0.2s" : "fadeOut 0.2s"};

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    `}
  `}
`

const Content = styled(DialogPrimitive.Content)<DialogContentProps>`
  ${({ state, $disableAnimations, width, height, topOffset }) => css`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 50;
    display: grid;
    width: ${width || "100%"};
    max-width: ${width || "600px"};
    height: ${height || "auto"};
    max-height: ${height || "auto"};
    transform: translate(-50%, -50%);
    gap: 16px;
    margin-top: ${topOffset}px;
    background: ${({ theme }) => theme.colors.white};
    ${!$disableAnimations &&
    css`
      animation: ${state === "open" ? "zoomIn 0.2s" : "zoomOut 0.2s"};

      @keyframes zoomIn {
        from {
          transform: scale(0.95) translate(-50%, -50%);
        }
        to {
          transform: scale(1) translate(-50%, -50%);
        }
      }

      @keyframes zoomOut {
        from {
          transform: scale(1) translate(-50%, -50%);
        }
        to {
          transform: scale(0.95) translate(-50%, -50%);
        }
      }
    `}
  `}
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  @media (min-width: 640px) {
    text-align: left;
  }
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogFooter = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
  }
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogTitle = styled(DialogPrimitive.Title)`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogDescription = styled(DialogPrimitive.Description)`
  font-size: 0.875rem;
  color: #6b7280;
`

const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, DialogOverlayProps>(
  ({ ...props }, ref) => <Overlay ref={ref} {...props} />,
)
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
  ({ children, width, height, disableCloseButton, topOffset, ...props }, ref) => (
    <DialogPortal>
      <DialogOverlay {...props} />
      <Content ref={ref} width={width} height={height} topOffset={topOffset} {...props}>
        {children}
        {!disableCloseButton && (
          <DialogClose asChild>
            <CloseButtonWrapper>
              <DeprecatedButton $variant="round" leftIcon={CloseStrokeIcon} isLoading={false} />
            </CloseButtonWrapper>
          </DialogClose>
        )}
      </Content>
    </DialogPortal>
  ),
)
DialogContent.displayName = DialogPrimitive.Content.displayName

export { Dialog, DialogContent }
