import { DeprecatedButton, DownloadIcon, Separator, Typography } from "@hero/krypton"
import { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../../00_shared/components/Sheet"
import { useIsWireProofActive } from "../../../../00_shared/hooks/useFeatureFlag.hook"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { OperationType } from "../../00_shared/business"
import { TransactionNote } from "../../00_shared/components/TransactionNote"
import { TransactionReceipt } from "../../00_shared/components/TransactionReceipt"
import { TransactionSteps } from "../../pro-account/AccountTransactions/components/transactions/TransactionInformations/TransactionSteps"
import useWireProofDownloadPdf from "../../pro-account/AccountTransactions/hooks/useWireProofDownloadPdf"
import { useExternalTransferInformation } from "./hooks/useExternalTransferInformation"

const ScrollAreaContainer = styled(ScrollArea)`
  height: calc(100% - 4.5rem);
  padding-right: 0.5rem;
`

const Amount = styled(Typography).attrs(() => ({
  $variant: "title-3-semibold",
}))`
  color: ${({ theme }) => theme.colors.black.$900};
`

const Date = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey.$500};
`

const CreateAtTypography = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  margin-right: 0.2rem;
`

const DetailsContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.5rem;
`

const DetailItem = styled(FlexContainer)`
  justify-content: space-between;
`

const DetailLabel = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const WireProofContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const WireProofLabel = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const TransferHistoryTransactionInformations = () => {
  const { t } = useDashboardTranslation()
  const { t: tc } = useCommonTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { externalTransferInformation, loading } = useExternalTransferInformation(state?.transactionId, state.ledgerId)
  const { currentUser } = useAuthContext()
  const isWireProofActive = useIsWireProofActive(currentUser.merchantId)
  const {
    handleDownloadWireProofClicked,
    loading: wireProofLoading,
    error: wireProofError,
  } = useWireProofDownloadPdf(state.transactionId)

  const transactionIsNotFound = externalTransferInformation === undefined && !loading

  const handleAnimationEnd = () => {
    navigateBack()
  }

  const navigateBack = useCallback(() => {
    if (!isOpen || transactionIsNotFound) {
      navigate("..")
    }
  }, [isOpen, transactionIsNotFound, navigate])

  if (transactionIsNotFound) {
    navigateBack()
    return null
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
        <SheetHeader>
          <SheetTitle>{t("transfers.transaction.title")}</SheetTitle>
        </SheetHeader>
        <ScrollAreaContainer height="calc(100vh - 5rem)" width="100%">
          {(!externalTransferInformation || loading) && <CenteredLoading />}
          {externalTransferInformation && !loading && (
            <FlexContainer $direction="column" $gap="1.2rem">
              <FlexItem>
                <FlexContainer $justify="space-between" $align="center">
                  <Typography $variant="title-3-semibold">{externalTransferInformation.creditor}</Typography>
                  <Amount>{externalTransferInformation.amount}</Amount>
                </FlexContainer>
                <Date>
                  <CreateAtTypography $color="grey.$600">
                    {t("transfers.transaction.details.createdAt")}
                  </CreateAtTypography>
                  {externalTransferInformation.createdAt}
                </Date>
              </FlexItem>
              <Separator />
              {externalTransferInformation.status && (
                <FlexItem>
                  <TransactionSteps statuses={externalTransferInformation.status} />
                </FlexItem>
              )}
              <Separator />
              <FlexContainer $direction="column" $gap=".2rem">
                <Typography $variant="body-4-medium">{t("transfers.transaction.details.label")}</Typography>
                <DetailsContainer>
                  <DetailItem>
                    <DetailLabel>{t("transfers.transaction.details.creditor")}</DetailLabel>
                    <Typography $variant="body-4-medium">{externalTransferInformation.creditor}</Typography>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>{t("transfers.transaction.details.reference")}</DetailLabel>

                    <Typography $variant="body-4-medium">{externalTransferInformation.reference}</Typography>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>{t("transfers.transaction.details.debtor")}</DetailLabel>
                    <Typography $variant="body-4-medium">{externalTransferInformation.debtor}</Typography>
                  </DetailItem>
                  <DetailItem>
                    <DetailLabel>{t("transfers.transaction.details.createdAt")}</DetailLabel>
                    <Typography $variant="body-4-medium">{externalTransferInformation.createdAt}</Typography>
                  </DetailItem>
                  {isWireProofActive &&
                    externalTransferInformation.operationType === OperationType.SEPA_CREDIT_TRANSFER_OUT &&
                    externalTransferInformation.isCompleted && (
                      <WireProofContainer>
                        <WireProofLabel>{tc("ba.wireProof.title")}</WireProofLabel>
                        <DeprecatedButton
                          $variant="underline"
                          leftIcon={DownloadIcon}
                          disabled={wireProofLoading || Boolean(wireProofError)}
                          onClick={handleDownloadWireProofClicked}
                        >
                          {tc("ba.wireProof.cta")}
                        </DeprecatedButton>
                      </WireProofContainer>
                    )}
                </DetailsContainer>
              </FlexContainer>
              <Separator />
              <FlexItem>
                <TransactionReceipt
                  title={t("transfers.transaction.attachments.label")}
                  operationId={state.transactionId}
                  flow={state.flow}
                  ledgerMovementId={state.ledgerMovementId}
                  ledgerId={state.ledgerId}
                  fileUrl={externalTransferInformation.justificativePath} // assuming the API provides this information
                />
              </FlexItem>
              <Separator />
              <FlexItem>
                <TransactionNote
                  title={t("transfers.transaction.note.label")}
                  operationId={state.transactionId}
                  flow={state.flow}
                  ledgerMovementId={state.ledgerMovementId}
                  note={externalTransferInformation.note} // assuming the API provides this information
                  ledgerId={state.ledgerId}
                />
              </FlexItem>
            </FlexContainer>
          )}
        </ScrollAreaContainer>
      </SheetContent>
    </Sheet>
  )
}
