import { DeprecatedSpinner, DeprecatedTooltip, InfoIcon, Typography } from "@hero/krypton"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useGetMerchantKPIs } from "./KPIHeader.requests"

const KPIContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin: 1.5rem 3rem;

  & > div {
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1.5rem 2rem;
    border-radius: 1rem;

    .with-tooltip {
      display: flex;
      align-items: center;
    }
  }
`

const KPILabel = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconTooltip = styled(InfoIcon)`
  width: 1rem;
  height: 1rem;
`

const CaptionKPITypography = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
  white-space: nowrap;
`

export const KPIHeader: React.FC = () => {
  // const { currentUser } = useAuthContext()
  const { data, error, loading } = useGetMerchantKPIs({
    // useDemoData: checkIfIsDemoMerchant(currentUser.merchantId),
    useDemoData: false,
  })
  const { t } = useDashboardTranslation()

  if (loading) {
    return <DeprecatedSpinner data-test-id="spinner" />
  }

  if (error) {
    return <div>{error.message}</div>
  }

  if (!data) {
    return <div>{t("checkoutAndCash.cockpit.kpiHeader.noData")}</div>
  }

  const {
    merchantGetKPIs: { totalCashedIn, totalLateAndDefault },
    merchantGetMoney: {
      merchantFundingRate,
      merchantMinimumReserve,
      holdAccountsBalance,
      totalPaymentsReserve,
      balance,
    },
  } = data

  return (
    <KPIContainer>
      <div>
        <CaptionKPITypography $variant="caption-2">
          {t("checkoutAndCash.cockpit.kpiHeader.balance")}
        </CaptionKPITypography>
        <Typography data-test-id="indicators-balance" $variant="title-1-bold">
          {toEuros(balance)}
        </Typography>
      </div>
      {holdAccountsBalance > 0 && (
        <div>
          <KPILabel>
            <CaptionKPITypography $variant="caption-2" className="with-tooltip">
              {t("checkoutAndCash.cockpit.kpiHeader.holdAccountsBalance")}
            </CaptionKPITypography>
            <DeprecatedTooltip
              content={t("checkoutAndCash.cockpit.kpiHeader.holdAccountsBalanceTooltip")}
              id="holdAccountsBalance"
            >
              <IconTooltip />
            </DeprecatedTooltip>
          </KPILabel>
          <Typography data-test-id="indicators-hold-accounts-balance" $variant="title-1-bold">
            {toEuros(holdAccountsBalance)}
          </Typography>
        </div>
      )}
      {totalPaymentsReserve > 0 && (
        <div>
          <KPILabel>
            <CaptionKPITypography $variant="caption-2" className="with-tooltip">
              {t("checkoutAndCash.cockpit.kpiHeader.totalPaymentsReserve")}
            </CaptionKPITypography>
            <DeprecatedTooltip
              content={t("checkoutAndCash.cockpit.kpiHeader.totalPaymentsReserveTooltip", {
                debit: 100 - merchantFundingRate,
                merchantMinReserve: toEuros(merchantMinimumReserve),
              })}
              id="totalPaymentsReserve"
            >
              <IconTooltip />
            </DeprecatedTooltip>
          </KPILabel>
          <Typography data-test-id="indicators-total-payments-reserve" $variant="title-1-bold">
            {toEuros(totalPaymentsReserve)}
          </Typography>
        </div>
      )}
      <div>
        <CaptionKPITypography $variant="caption-2">
          {t("checkoutAndCash.cockpit.kpiHeader.totalCashIn")}
        </CaptionKPITypography>
        <Typography data-test-id="indicators-total-encaisse" $variant="title-1-bold">
          {toEuros(totalCashedIn)}
        </Typography>
      </div>
      <div>
        <KPILabel>
          <CaptionKPITypography $variant="caption-2" className="with-tooltip">
            {t("checkoutAndCash.cockpit.kpiHeader.late")}
          </CaptionKPITypography>
          <DeprecatedTooltip
            content={t("checkoutAndCash.cockpit.kpiHeader.lateTooltip")}
            id="last"
            position="bottom-left"
          >
            <IconTooltip />
          </DeprecatedTooltip>
        </KPILabel>
        <Typography data-test-id="indicators-late" $variant="title-1-bold">
          {toEuros(totalLateAndDefault)}
        </Typography>
      </div>
    </KPIContainer>
  )
}
