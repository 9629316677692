import { useApolloClient } from "@apollo/client"
import { DeprecatedSpinner } from "@hero/krypton"
import { useEffect } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { LOCAL_SIGNIN, ONBOARDING_LOGOUT_URL } from "../../env_variables"

export const Logout = () => {
  const client = useApolloClient()
  const [queryParams] = useSearchParams()

  const logout = async () => {
    await client.clearStore()

    localStorage.removeItem("token")
    localStorage.removeItem("BO_V3")

    const query = queryParams.toString()

    window.location.href = query.length > 0 ? `${ONBOARDING_LOGOUT_URL}?${query}` : ONBOARDING_LOGOUT_URL
  }

  useEffect(() => {
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (LOCAL_SIGNIN) {
    return <Navigate to="/" />
  }

  return <DeprecatedSpinner />
}
