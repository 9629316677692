import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { HamburgerMenu } from "../00_shared/components/HamburgerMenu"
import HeroIcon from "../assets/logo-hero.png"
import { ImpersonateBanner } from "./00_shared/components/ImpersonateBanner"
import { DeprecatedMenu } from "./merchant.menu"

export const BOV3Dashboard = ({ isMenuReduced }: { isMenuReduced: boolean }) => {
  return (
    <Wrapper>
      <MobileHeader>
        <HamburgerMenu />
        <img src={HeroIcon} alt="Hero logo" width={80} />
      </MobileHeader>
      <DeprecatedMenu />
      <Main>
        <ImpersonateBanner />
        <Outlet />
      </Main>
    </Wrapper>
  )
}

const isMobile = "max-width: 768px"
const isNotMobile = "min-width: 769px"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;

  @media (${isMobile}) {
    flex-direction: column;
  }
`

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.white};

  @media (${isNotMobile}) {
    display: none;
  }
`
