import { AddIcon, DeprecatedButton, DeprecatedSpinner, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import CheckoutEmpty from "../../../assets/collection/checkout_empty.png"
import { EmptyScreen } from "../00_shared/components/EmptyScreen"
import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"

const ActionButton = styled(DeprecatedButton)`
  width: fit-content;
  min-width: unset;
  padding: 0.25rem;
`

export const EmptyScreenCheckout = () => {
  const { t } = useDashboardTranslation()
  const productContext = useProductContext()
  const navigate = useNavigate()

  if (!productContext) {
    return <DeprecatedSpinner />
  }

  const isProductActivated =
    productContext.checkoutEnabled && productContext.checkoutScope !== PaymentConfigurationScope.NONE

  if (!isProductActivated) {
    return (
      <EmptyScreen
        imgSrc={CheckoutEmpty}
        titleKey="collection.checkout.notActivated.title"
        descriptionKey="collection.checkout.notActivated.description"
      />
    )
  }

  return (
    <EmptyScreen
      imgSrc={CheckoutEmpty}
      titleKey="collection.checkout.empty.title"
      descriptionKey="collection.checkout.empty.description"
      action={
        <ActionButton
          leftIcon={AddIcon}
          size="medium"
          onClick={() => {
            navigate("/collection/settings/plugins")
          }}
        >
          <Typography $variant="body-4-semibold" $color="white">
            {t("collection.checkout.empty.action")}
          </Typography>
        </ActionButton>
      }
    />
  )
}
