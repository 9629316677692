import {
  ChevronDownIcon,
  DeprecatedButton,
  DeprecatedHeader,
  DeprecatedSpinner,
  deprecatedToaster,
  Typography,
} from "@hero/krypton"
import { ElementType, useEffect } from "react"
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { TransactionFlowType } from "../../../../business/enums/Transaction.enum"
import * as TransactionRules from "../../../../business/rules/Transaction.rules"
import { HEADER_COLORS } from "../../../../env_variables"
import { TransactionAttachments } from "./component/Attachments"
import { TransactionMainInfo } from "./component/MainInfos"
import { PaymentLinkDetails } from "./component/PaymentLinkDetails"
import { Refunds, useTransaction } from "./transaction.hooks"

const BackButton = styled<ElementType>(DeprecatedButton)`
  min-width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  margin-right: 0.5rem;

  & svg {
    transform: rotate(90deg);
  }
`

const Container = styled.div`
  margin: 1.5rem 20%;
  padding-bottom: 2rem;
`

const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: fit-content;
`

export const TransactionScreen: React.FC = () => {
  const { t } = useDashboardTranslation()

  const { id } = useParams<{ id: string }>()
  const [searchParms] = useSearchParams()
  const origin = searchParms.get("origin") ?? "/"
  const navigate = useNavigate()

  const { loading, error: technicalError, transaction, refundsResponse, refetch } = useTransaction(id)
  const { error: refundsError, data: refunds } = unwrapGraphQLResponse<Refunds>(refundsResponse)

  useEffect(() => {
    if (technicalError || refundsError) {
      deprecatedToaster.error(t("checkoutAndCash.cockpit.transaction.toasterError"))
    }
  }, [technicalError, refundsError, t])

  const isPayGenWithPayment = TransactionRules.isPayGenerator(transaction?.transactionType) && transaction?.paymentId

  useEffect(() => {
    if (!transaction) {
      return
    }
    if (isPayGenWithPayment) {
      navigate(`/transactions/${transaction.paymentId}?origin=${origin}`, { replace: true })
    }
  }, [transaction, isPayGenWithPayment, navigate, origin])

  const isLoading = loading || isPayGenWithPayment

  if (isLoading) {
    return <DeprecatedSpinner />
  }

  if (technicalError || !transaction || !refunds) {
    return <Navigate to="/" />
  }

  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <LinkContainer to={origin}>
          <BackButton isLoading={false} leftIcon={ChevronDownIcon} />
          <Typography $variant="body-3-medium">{t("checkoutAndCash.cockpit.transaction.backButton")}</Typography>
        </LinkContainer>
      </DeprecatedHeader>
      <Container data-test-id="transaction-screen">
        {transaction.flowType === TransactionFlowType.OFFLINE ? (
          <PaymentLinkDetails
            url={transaction.url}
            transactionType={transaction.transactionType}
            transactionId={transaction.id}
          />
        ) : null}

        <TransactionMainInfo transaction={transaction} refunds={refunds} refetch={refetch} />

        <TransactionAttachments attachments={transaction.attachments} flowType={transaction.flowType} />
      </Container>
    </>
  )
}
