import { gql } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useCommonTranslation } from "../../../../../01_technical/translations"

const GET_MERCHANT_BUSINESS_ACCOUNT_QUERY = gql`
  query GetMerchantBusinessAccount($businessAccountLedgerId: String!) {
    getMerchantBusinessAccount(businessAccountLedgerId: $businessAccountLedgerId) {
      ... on GetMerchantBusinessAccountOutput {
        businessAccount {
          name
          balance
          ledgerId
          virtualIbanId
          VIban
          BIC
          isMainAccount
          accountClosedAt
        }
        merchantInfo {
          owner {
            firstname
            lastname
          }
          address {
            line1
            line2
            city
            zipCode
          }
          juridicalName
        }
      }

      ... on SimpleApiError {
        errorCode
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
  isMainAccount: boolean
  accountClosedAt: string | null
}

type Address = {
  line1?: string
  line2?: string
  city?: string
  zipCode?: string
}

type Owner = {
  firstname?: string
  lastname?: string
}

export type MerchantInfo = {
  owner?: Owner
  address?: Address
  juridicalName?: string
}

interface BusinessAccountVariables {
  businessAccountLedgerId?: string
}

export const useBusinessAccount = (initialBusinessAccountLedgerId?: string) => {
  const [variables, setVariables] = useState<BusinessAccountVariables>({
    businessAccountLedgerId: initialBusinessAccountLedgerId,
  })
  const { accountNb } = useParams<{ accountNb: string }>()
  const location = useLocation()
  const { t } = useCommonTranslation()

  const { data, refetch, loading, error } = useHeroQuery<{
    businessAccount: BusinessAccount
    merchantInfo?: MerchantInfo
  }>({
    gqlQuerySchema: GET_MERCHANT_BUSINESS_ACCOUNT_QUERY,
    variables: {
      businessAccountLedgerId: variables.businessAccountLedgerId,
    },
    skip: !variables.businessAccountLedgerId,
    fetchPolicy: "cache-and-network",
  })

  const setBusinessAccountLedgerId = (businessAccountLedgerId?: string) => {
    setVariables({ businessAccountLedgerId })
  }

  const updatedBusinessAccount = useMemo(() => {
    if (data && data.businessAccount) {
      return {
        ...data.businessAccount,
        name: data.businessAccount.isMainAccount ? t("onboarding.businessAccount.name") : data.businessAccount.name,
      }
    }
    return undefined
  }, [data, t])

  useEffect(() => {
    setVariables({ businessAccountLedgerId: accountNb })
  }, [accountNb, setVariables])

  useEffect(() => {
    if (variables.businessAccountLedgerId) {
      refetch()
    }
  }, [location, refetch, variables.businessAccountLedgerId])

  return {
    businessAccount: updatedBusinessAccount,
    merchantInfo: data?.merchantInfo,
    loading,
    error,
    refetch,
    setBusinessAccountLedgerId,
    setVariables,
  }
}
