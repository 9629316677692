import { AddIcon } from "@hero/krypton"
import { useMemo } from "react"
import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom"
import { EmptyState } from "../../../../00_shared/components/EmptyState"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useCapability } from "../../../../Auth/auth.context"
import { useBusinessAccountContext } from "../../01_context/businessAccount.context"
import { useCreateTransferNavigationContext } from "../../01_context/createTransferNavigation.context"
import ExternalTransferIcon from "../00_shared/icons/external-transfer-icon.png"
import { TransferHistoryList } from "./components/TransferHistoryList"
import { TransferHistoryTable } from "./components/TransferHistoryTable"
import { useExternalTransfers } from "./hooks/useExternalTransfers"

export const TransferHistory = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { navigateToCreateTransfer } = useCreateTransferNavigationContext()
  const canInternalTransfer = useCapability("send-internal-transfer", "any")
  const canExternalTransfer = useCapability("send-external-transfer", "any")
  const canTransfer = canInternalTransfer || canExternalTransfer
  const navigate = useNavigate()
  const { state } = useNavigation()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { externalTransfers, loading, pagination } = useExternalTransfers()
  const { t } = useDashboardTranslation()

  const handleCreateTransfer = () => {
    navigateToCreateTransfer({ updateTransferOrigin: true })
  }

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  if (externalTransfers.length === 0 && !loading && state === "idle") {
    return (
      <EmptyState
        illustration={<img src={ExternalTransferIcon} alt="External transfer" width={100} />}
        title={t("transfers.emptyState.title")}
        text={t("transfers.emptyState.text")}
        buttonText={t("transfers.emptyState.buttonText")}
        buttonIcon={<AddIcon />}
        onButtonClick={() => handleCreateTransfer()}
        disabledButton={!isBaActive || !canTransfer}
      />
    )
  }

  return (
    <>
      {!isMobile && (
        <>
          <TransferHistoryTable
            isLoading={loading}
            externalTransfers={externalTransfers}
            pagination={pagination}
            onPageChange={onPageChange}
          />
        </>
      )}
      {isMobile && (
        <>
          <ScrollArea height="100%">
            <TransferHistoryList externalTransfers={externalTransfers} isLoading={loading} />
          </ScrollArea>
        </>
      )}
      <Outlet />
    </>
  )
}
