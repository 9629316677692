import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const Title = styled(Typography)`
  font-size: 1.5rem;
`

const Subtitle = styled(Typography)`
  font-weight: 400;
`

export const CreditWireTransferHeader = () => {
  const { t } = useDashboardTranslation()

  return (
    <Header>
      <Title $variant="title-2-bold">{t("creditWireTransfer.title")}</Title>
      <Subtitle $color="grey.$400" $variant="body-4-medium">
        {t("creditWireTransfer.subtitle")}
      </Subtitle>
    </Header>
  )
}

const Header = styled.header`
  padding: 1.5rem 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 64rem) {
    padding: 1.5rem;
  }

  @media (max-width: 48rem) {
    padding: 1rem;
  }
`
