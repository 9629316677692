import { DeprecatedErrorBlock } from "@hero/krypton"
import styled from "styled-components"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { useCommonTranslation } from "../../../01_technical/translations"
import ActiveMRFScreen from "./ActiveMRF.screen/ActiveMRF.screen"
import { useGetActiveMarketplaceReserveFundings } from "./MarketplaceReserveFinancing.request"
import { NonActiveMRFScreen } from "./NonActiveMRF.screen"

export const MarketplaceReserveFinancing = () => {
  const { loading, data, error } = useGetActiveMarketplaceReserveFundings()

  if (loading) {
    return <CenteredLoading height="screen" />
  }

  if (error) {
    return <Error />
  }

  if (!data || data?.length === 0) {
    return <NonActiveMRFScreen />
  }

  return <ActiveMRFScreen mrfs={data} />
}

const StyledError = styled.div`
  margin: 0 1.4rem;
`

const Error = () => {
  const { t } = useCommonTranslation()

  return (
    <StyledError>
      <DeprecatedErrorBlock>{t("network.error")}</DeprecatedErrorBlock>
    </StyledError>
  )
}
