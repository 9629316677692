import { DeprecatedButton, FilterIcon, UploadIcon } from "@hero/krypton"
import { useCallback, useMemo, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useIsBankStatementExportCSVActive } from "../../../../00_shared/hooks/useFeatureFlag.hook"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { checkIfIsCoruscantMerchant } from "../../../../00_shared/utils/coruscantAccount"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { UserLocale } from "../../../../business/enums/User.enum"
import { PaginationContainer } from "../../00_shared/components/PaginationContainer"
import { TableContainer } from "../../00_shared/components/TableContainer"
import { useBusinessAccountContext } from "../../01_context/businessAccount.context"
import { useBusinessAccounts } from "../00_shared/hooks/useBusinessAccounts"
import { formatMovementOperations } from "../00_shared/utils"
import { ExportTransactionsModal } from "./components/ExportTransactionsModal"
import { FiltersSheet } from "./components/transactions/TransactionFilters/FiltersSheet"
import { TransactionTableFilters } from "./components/transactions/TransactionFilters/TransactionTableFilters"
import { TransactionList } from "./components/transactions/TransactionList"
import TransactionTable from "./components/transactions/TransactionTable"
import { SearchParams } from "./hooks/filters/useTransactionFilters"
import { mapMethodToFlows, mapMethodToOperationTypes, mapTypeToFlows, mapTypeToOperationTypes } from "./utils"

const ButtonContainer = styled.div`
  position: absolute;
  top: 8rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }
`

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1.5rem;
`

export const AccountTransactions = () => {
  const { accountOperations } = useBusinessAccountContext()

  const { operations, pagination, loading, setOperationsFilter } = accountOperations
  const { businessAccounts } = useBusinessAccounts()
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useCommonTranslation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const { accountNb } = useParams<{ accountNb: string }>()
  const { currentUser } = useAuthContext()
  const isCoruscantUser = checkIfIsCoruscantMerchant(currentUser.merchantId)
  const { currentPage, totalPages } = pagination
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { track } = useAmplitude()

  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)

  const isAllAccountView = !accountNb

  const formattedOperations = formatMovementOperations({
    operations,
    t,
    local: UserLocale[i18n.language as keyof typeof UserLocale],
  })

  const filterBusinessAccountOperations = useCallback(
    async (params: SearchParams) => {
      const methodOperationTypes = params.method?.map((method) => mapMethodToOperationTypes(method)).flat() || []
      const methodFlows = params.method?.map((method) => mapMethodToFlows(method)).flat() || []
      const typeFlows = params.method?.map((method) => mapTypeToFlows(method)).flat() || []
      const typeOperationTypes = params.transactionType?.map((type) => mapTypeToOperationTypes(type)).flat() || []

      setOperationsFilter({
        pagination: { page: params.page, limit: 10 },
        filters: {
          ...(accountNb && {
            productLedgerIds: [accountNb],
          }),
          operationTypes: [...methodOperationTypes, ...typeOperationTypes],
          flow: [...methodFlows, ...typeFlows],
          amount: {
            equal: params.amountEqual,
            greaterThan: params.amountOver,
            lessThan: params.amountUnder,
          },
          date: {
            from: params.dateFrom ? new Date(new Date(params.dateFrom).setHours(0, 0, 0, 0)) : undefined,
            to: params.dateTo ? new Date(new Date(params.dateTo).setHours(23, 59, 59, 999)) : undefined,
          },
        },
      })
    },
    [accountNb, setOperationsFilter],
  )

  const isBankStatementExportCSVActive = useIsBankStatementExportCSVActive() || isCoruscantUser
  const allLedgerIds = businessAccounts.map((ba) => ba.ledgerId)

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  const handleExportClicked = () => {
    const event = isAllAccountView
      ? "business_account_export_from_all_ba_cliked"
      : "business_account_export_from_selected_account_ba_cliked"
    track(event)

    setIsExportModalOpen(true)
  }

  return (
    <>
      {!isMobile && (
        <TableContainer style={{ flex: 1 }}>
          <TableHeaderWrapper>
            <TransactionTableFilters filterBusinessAccountOperations={filterBusinessAccountOperations} />
            {isBankStatementExportCSVActive && (
              <DeprecatedButton
                size="small"
                $variant="tertiary"
                leftIcon={UploadIcon}
                onClick={() => handleExportClicked()}
              >
                {t("accounts.transaction.list.export")}
              </DeprecatedButton>
            )}
          </TableHeaderWrapper>
          <TransactionTable operations={formattedOperations} isLoading={loading} isAllAccountView={isAllAccountView} />
          <div style={{ flex: 1 }} />
          <PaginationContainer
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
          />
          <Outlet />
          <ExportTransactionsModal
            isOpen={isExportModalOpen}
            close={() => setIsExportModalOpen(false)}
            ledgerIds={isAllAccountView ? allLedgerIds : [accountNb]}
          />
        </TableContainer>
      )}
      {isMobile && (
        <>
          <ScrollArea height="">
            <TransactionList operations={formattedOperations} isLoading={loading} isAllAccountView={isAllAccountView} />
          </ScrollArea>
          <ButtonContainer>
            <DeprecatedButton
              size="small"
              isLoading={false}
              $variant="tertiary"
              onClick={() => setIsFilterSheetOpen(true)}
            >
              <FilterIcon />
            </DeprecatedButton>
          </ButtonContainer>
          <FiltersSheet
            isOpen={isFilterSheetOpen}
            setIsOpen={setIsFilterSheetOpen}
            onFiltersChange={filterBusinessAccountOperations}
          />
          <Outlet />
        </>
      )}
    </>
  )
}
