import { AddIcon, DeprecatedButton, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"

const NewLinkButtonStyled = styled(DeprecatedButton)`
  width: fit-content;
  min-width: unset;
  padding: 0.25rem;
`

export const NewLinkButton = () => {
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()
  const productContext = useProductContext()

  if (!productContext || !productContext.linkEnabled || productContext.linkScope === PaymentConfigurationScope.NONE) {
    return null
  }

  return (
    <NewLinkButtonStyled
      leftIcon={AddIcon}
      size="medium"
      onClick={() => {
        navigate("/collection/new-link")
      }}
    >
      <Typography $variant="body-4-semibold" $color="white">
        {t("collection.list.newLink")}
      </Typography>
    </NewLinkButtonStyled>
  )
}
