import {
  DeprecatedBreadcrumb,
  DeprecatedBreadcrumbItem,
  DeprecatedErrorBlock,
  DeprecatedHeader,
  DeprecatedSpinner,
  Typography,
} from "@hero/krypton"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useBreadcrumbs } from "../../../../../00_shared/hooks/useBreadcrumbs.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"
import { HEADER_COLORS } from "../../../../../env_variables"
import { TransferDetails, humanFriendlyDate } from "../TransferDetails.component"
import { useGetIncomingTransferDetails } from "./useGetIncomingTransferDetails"

export const IncomingTransactionDetails = () => {
  const { t, unsafeT, i18n } = useDashboardTranslation()
  const { swanTransactionId } = useParams<{ swanTransactionId: string }>()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (!swanTransactionId) {
      navigate(-1)
    }
  }, [navigate, swanTransactionId])

  const { data, error, loading } = useGetIncomingTransferDetails(
    { swanTransactionId },
    { useDemoData: currentUser.isDemoMerchant },
  )

  return (
    <>
      <DeprecatedHeader $colors={HEADER_COLORS}>
        <Typography $variant="body-1-medium" as="h1">
          {t("ap.balance.title")}
        </Typography>
        <DeprecatedBreadcrumb>
          <DeprecatedBreadcrumbItem<typeof Link> as={Link} to={breadcrumbs[0].to}>
            {unsafeT(breadcrumbs[0].label)}
          </DeprecatedBreadcrumbItem>
          <DeprecatedBreadcrumbItem<"p"> as={"p"}>{breadcrumbs[1].label}</DeprecatedBreadcrumbItem>
        </DeprecatedBreadcrumb>
      </DeprecatedHeader>

      {loading && (
        <LoaderWrapper>
          <DeprecatedSpinner />
          <LoaderTitle>{t("loading")}...</LoaderTitle>
        </LoaderWrapper>
      )}

      {!!data && (
        <TransferDetails
          status={data.status}
          marketplaceName={data.marketplaceName}
          amount={data.amount}
          createdAt={
            data.allocationDate
              ? humanFriendlyDate(data.allocationDate, i18n.language)
              : t("ap.balance.incoming.pending")
          }
          iban={data.creditorIban}
          accountName={data.account.name}
          accountNumber={data.account.number}
          referenceId={data.reference ?? data.swanTransactionId}
          label={data.label}
          items={data.balanceBeforeAndAfterMovements.flatMap((movement) => {
            return [
              {
                amount: movement.balanceBefore,
                label: t("ap.balance.movement.beforeOperation", {
                  accountNumber: data.account.number,
                }),
              },
              {
                amount: movement.movement.amount,
                label: t(
                  movement.isRefund ? "ap.balance.movement.marketplaceRefund" : "ap.balance.movement.marketplace",
                ),
                isHighlight: true,
                highlightColor: movement.isRefund ? "red" : "blue",
                isMovement: true,
                id: movement.movement.id,
                createdAt: movement.movement.createdAt,
              },
              {
                amount: movement.balanceAfter,
                label: t("ap.balance.movement.afterOperation", {
                  accountNumber: data.account.number,
                }),
              },
            ]
          })}
        />
      )}

      {error && <DeprecatedErrorBlock $margin="2rem 3rem">{error?.translatedMessage}</DeprecatedErrorBlock>}
    </>
  )
}

const LoaderWrapper = styled.div<{ $screenHeight?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 2rem 3rem;
`

const LoaderTitle = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`
