import {
  AddFilledIcon,
  DeleteIcon,
  DeprecatedSpinner,
  DeprecatedTooltip,
  Typography,
  deprecatedToaster,
  theme,
} from "@hero/krypton"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"
import FilePreviewIcon from "../../assets/file-preview.svg"
import { FlexContainer } from "./Flex"

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const FilePlaceholder = styled.div<{ $isDraggedOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.5rem;
  height: 5rem;
  cursor: pointer;
  position: relative;
  background-color: ${({ $isDraggedOver, theme }) => ($isDraggedOver ? theme.colors.grey.$100 : "transparent")};
`

const FilePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border-radius: 0.5rem;
  height: 5rem;
  position: relative;
  padding: 0.5rem;
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  &:hover .overlay {
    opacity: 0.7;
  }

  &:hover button {
    visibility: visible;
  }
`

const ImagePreview = styled.img<{ $isLoaded: boolean }>`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.5rem;
  transition: opacity 0.3s ease;
  opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0.5)};
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 0.5rem;
`

const SpinnerOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
`

const TooltipContainer = styled.div`
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
`

const DeleteButton = styled.button`
  background: ${({ theme }) => theme.colors.danger.$300};
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  outline: 2px solid ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease;
  visibility: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.colors.danger.$200};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`

export enum PreviewType {
  IMAGE = "IMAGE",
  PDF = "PDF",
}

interface FileUploadPreviewProps {
  fileUrl?: string | null
  isLoading?: boolean
  allowedFileTypes?: string[]
  placeholder?: string
  style?: React.CSSProperties
  onFileChange?: (file: File) => void
  onDelete?: () => void
  onPreview?: (previewType: PreviewType) => void
}

export const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  fileUrl,
  isLoading = false,
  allowedFileTypes = ["image/*"],
  placeholder,
  style,
  onFileChange,
  onDelete,
  onPreview,
}) => {
  const { t } = useDashboardTranslation()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const [previewType, setPreviewType] = useState<PreviewType>(PreviewType.IMAGE)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!fileUrl) setImageLoaded(false)
  }, [fileUrl])

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      setIsUploadingFile(true)
      if (onFileChange) onFileChange(file)
      setIsUploadingFile(false)
    }
  }

  const handlePlaceholderClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggedOver(true)
  }

  const handleDragLeave = () => {
    setIsDraggedOver(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggedOver(false)

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0]
      if (!allowedFileTypes.some((type) => type.includes(file.type) || file.type.startsWith(type))) {
        deprecatedToaster.error(t("fileUpload.error.invalidFileType"))
        return
      }

      setIsUploadingFile(true)
      if (onFileChange) onFileChange(file)
      setIsUploadingFile(false)
    }
  }

  const resetPreviewType = () => {
    setPreviewType(PreviewType.IMAGE)
  }

  return (
    <FileContainer>
      {fileUrl || isUploadingFile || isLoading ? (
        <FilePreview onClick={() => onPreview && previewType && onPreview(previewType)} style={style}>
          <Overlay className="overlay" />
          {fileUrl && (
            <ImagePreview
              src={fileUrl}
              alt="File Preview"
              $isLoaded={imageLoaded}
              onLoad={() => setImageLoaded(true)}
              onError={(event) => {
                setPreviewType(PreviewType.PDF)
                event.currentTarget.src = FilePreviewIcon
                event.currentTarget.width = 50
                event.currentTarget.height = 50
                event.currentTarget.onerror = null
              }}
            />
          )}
          {(isLoading || isUploadingFile) && (
            <SpinnerOverlay>
              <DeprecatedSpinner />
            </SpinnerOverlay>
          )}
          <TooltipContainer>
            <DeprecatedTooltip id="delete-file-tooltip" content="Delete the file" position="top-right">
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation()
                  resetPreviewType()
                  if (onDelete) onDelete()
                }}
              >
                <DeleteIcon />
              </DeleteButton>
            </DeprecatedTooltip>
          </TooltipContainer>
        </FilePreview>
      ) : (
        <FilePlaceholder
          onClick={handlePlaceholderClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          $isDraggedOver={isDraggedOver}
          style={style}
        >
          {isUploadingFile && (
            <SpinnerOverlay>
              <DeprecatedSpinner />
            </SpinnerOverlay>
          )}
          {!isUploadingFile && (
            <>
              <FlexContainer $direction="column" $align="center">
                <AddFilledIcon color={theme.colors.grey.$400} />
                {placeholder && (
                  <Typography $variant="caption-2" $color="grey.$500">
                    {placeholder}
                  </Typography>
                )}
              </FlexContainer>
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </>
          )}
        </FilePlaceholder>
      )}
    </FileContainer>
  )
}
